import React, { useState } from "react";
import { Box, Tabs, Tab, Paper } from "@material-ui/core";
import TabPanel from "../components/TabPanel";
import CertUpload from "./CertUpload";
import RestExportConfig from "./RestExportConfig";

const Ingestor = () => {
  // Temporarily hardcoding vendors
  // eventually we'll have a backend for this
  // const vendors = [
  //   { value: 0, name: "devo", label: "Devo" },
  //   { value: 1, name: "rest", label: "REST export" },
  // ];

  const [tabValue, setTabValue] = useState(0);

  // Tabs
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box pt={3} pb={5} style={{ maxWidth: 800, margin: "0 auto" }}>
      <Paper>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="secondary tabs"
        >
          <Tab
            label="Devo"
            id="secondary-tab-1"
            aria-controls="secondary-tabpanel-1"
          />
          <Tab
            label="REST"
            id="secondary-tab-2"
            aria-controls="secondary-tabpanel-2"
          />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <Box m={1}>
            <CertUpload />
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Box m={1}>
            <RestExportConfig />
          </Box>
        </TabPanel>
      </Paper>
    </Box>
  );
};

export default Ingestor;
