import React from "react";
import ListItemText from "@material-ui/core/ListItemText";
import { StyledMenu, StyledMenuItem } from "../../components/StyleMenu";

export default function ConnectorsMenu({
  anchorEl,
  handleClose,
  handleDelete,
  handleManage,
}) {
  return (
    <div>
      <StyledMenu
        id="connectors-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={handleManage}>
          <ListItemText
            primary="Manage Connector"
            data-test="connector-action-edit"
          />
        </StyledMenuItem>
        <StyledMenuItem onClick={handleDelete}>
          <ListItemText
            primary="Delete Connector"
            data-test="connector-action-delete"
          />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
