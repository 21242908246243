import React, { useEffect, useState } from "react";
import { Typography, Card, Grid } from "@material-ui/core";
import { ResponsivePie } from "@nivo/pie";
import { useApi } from "../../API";
import ChartWrapper from "../../components/ChartWrapper";
import ChartWrapperHeader from "../../components/ChartWrapperHeader";
import { numberWithCommas } from "../../utils";
import { useHistory } from "react-router-dom";

function RiskPosture() {
  const [riskData, setRiskData] = useState({});
  const [highImpactRiskData, setHighImpactRiskData] = useState({});
  const [highImpactRiskAgg, setHighImpactRiskAgg] = useState({});
  const [allRiskAgg, setAllRiskAgg] = useState({});
  const [pieData, setPieData] = useState([]);
  const [highImpactPieData, setHighImpactPieData] = useState([]);
  const history = useHistory();

  const api = useApi();

  useEffect(async () => {
    const res = await api.riskAggregation("asset_type", null, "true");
    const riskAgg = await api.highRiskAggregation();
    setAllRiskAgg(riskAgg.data.all);
    setHighImpactRiskAgg(riskAgg.data.high_impact);
    setPieData([
      {
        id: "High Risk",
        label: "High Risk",
        color: '#fbb4ae',
        value: riskAgg.data.all.high_risk,
      },
      {
        id: "Low Risk",
        label: "Low Risk",
        color: "#ccecc5",
        value: riskAgg.data.all.low_risk,
      },
      {
        id: "Medium Risk",
        label: "Medium Risk",
        color: "#ffffcc",
        value: riskAgg.data.all.medium_risk,
      },
    ]);
    setHighImpactPieData([
      {
        id: "High Risk",
        label: "High Risk",
        color: '#fbb4ae',
        value: riskAgg.data.high_impact.high_risk,
      },
      {
        id: "Low Risk",
        label: "Low Risk",
        color: "#ccecc5",
        value: riskAgg.data.high_impact.low_risk,
      },
      {
        id: "Medium Risk",
        label: "Medium Risk",
        color: "#ffffcc",
        value: riskAgg.data.high_impact.medium_risk,
      },
    ]);

    setRiskData(res.data.risk_score);
    setHighImpactRiskData(res.data.high_impact_risk_score);
  }, []);

  return (
    <ChartWrapper excludeHeader>
      <ChartWrapperHeader chartName="Your Risk Posture" />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={2}
        alignItems="center"
        style={{ marginTop: "24px", marginBottom: "16px" }}
      >
        <Grid container item xs={4} justifyContent="center">
          <strong>All Assets</strong>
        </Grid>
        <Grid container item xs={4} justifyContent="center">
          {" "}
        </Grid>
        <Grid container item xs={4} justifyContent="center">
          <strong>High Impact Assets</strong>
        </Grid>
      </Grid>
      <Card>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          textAlign="center"
          style={{ marginTop: "24px", marginBottom: "24px" }}
        >
          <Grid container item xs={4} justifyContent="center" onClick={() => history.push('/app/inventory/assets?sort_by=risk_score&sort_direction=desc')} style={{ cursor: "pointer" }}>
            <Typography variant="h5">
              {numberWithCommas(Math.round(riskData.risk_score))}{" "}
            </Typography>
          </Grid>
          <Grid container item xs={4} justifyContent="center">
            Average Risk Score
          </Grid>
          <Grid container item xs={4} justifyContent="center" onClick={() => history.push('/app/inventory/assets?is_critical=true&sort_by=risk_score&sort_direction=desc')} style={{ cursor: "pointer" }}>
            <Typography variant="h5">
              {numberWithCommas(Math.round(highImpactRiskData.risk_score))}{" "}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          textAlign="center"
          style={{ marginTop: "24px", marginBottom: "24px" }}
        >
          <Grid container item xs={4} justifyContent="center" onClick={() => history.push('/app/inventory/assets?risk_score=80%2C100&sort_by=risk_score&sort_direction=desc')} style={{ cursor: "pointer" }}>
            <Typography variant="h5">
              {numberWithCommas(allRiskAgg.high_risk)}{" "}
            </Typography>
          </Grid>
          <Grid container item xs={4} justifyContent="center">
            Number of High-Risk Assets
          </Grid>
          <Grid container item xs={4} justifyContent="center" onClick={() => history.push('/app/inventory/assets?is_critical=true&risk_score=80%2C100&sort_by=risk_score&sort_direction=desc')} style={{ cursor: "pointer" }}>
            <Typography variant="h5">
              {numberWithCommas(highImpactRiskAgg.high_risk)}{" "}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          textAlign="center"
          style={{ marginTop: "24px", marginBottom: "24px" }}
        >
          <Grid style={{ height: 180 }} xs={4}>
            <ResponsivePie
              data={pieData}
              onClick={(data) => {
                let url = `/app/inventory/assets?risk_score=${data.id == 'Low Risk' ? `0%2C39` : data.id == 'Medium Risk' ? `40%2C79` : `80%2C100`}&sort_by=risk_score&sort_direction=desc`
                history.push(url);
              }}
              onMouseEnter={(_datum, event) => {
                event.currentTarget.style.cursor = "pointer";
              }}
              margin={{ top: 20, right: 10, bottom: 40, left: 10 }}
              innerRadius={0}
              colors={{ datum: 'data.color' }}
              padAngle={0}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
              }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
            />
          </Grid>
          <Grid xs={4} direction="column"
            justifyContent="center"
            style={{ "textAlign": "center", "alignContent": "center" }}><div>Risk Breakdown</div></Grid>
          <Grid style={{ height: 180 }} xs={4}>
            <ResponsivePie
              data={highImpactPieData}
              onClick={(data) => {
                let url = `/app/inventory/assets?is_critical=true&risk_score=${data.id == 'Low Risk' ? `0%2C39` : data.id == 'Medium Risk' ? `40%2C79` : `80%2C100`}&sort_by=risk_score&sort_direction=desc`
                history.push(url);
              }}
              onMouseEnter={(_datum, event) => {
                event.currentTarget.style.cursor = "pointer";
              }}
              colors={{ datum: 'data.color' }}
              margin={{ top: 20, right: 10, bottom: 40, left: 10 }}
              innerRadius={0}
              padAngle={0}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
              }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </ChartWrapper>
  );
}
export default RiskPosture;
