import React from "react";
import { Tab, Tabs, AppBar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const RoundedTabs = withStyles(() => ({
  root: {
    minHeight: 45,
  },
  indicator: {
    display: "none",
  },
}))((props) => {
  return (
    <AppBar
      elevation={1}
      style={{
        padding: "0em 1.3em",
        marginTop: "4.5em",
        color: "black",
        backgroundColor: "white",
        height: 45,
      }}
    >
      <Tabs {...props} />
    </AppBar>
  );
});

const RoundedTab = withStyles((theme) => ({
  root: {
    minHeight: 45,
    minWidth: 120,
    textTransform: "capitalize",
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2),
    padding: ".2em 3em",
    color: "black",
    "&:hover": {
      opacity: 1,
    },
    "&$selected": {
      fontWeight: "bold",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export { RoundedTab, RoundedTabs };
