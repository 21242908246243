import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ToastContext from "../../context/ToastContext";
import { useApi } from "../../API";
import StyledModal from "../../components/StyledModal";
import { palette } from "../../utils/theme";

const useStyles = makeStyles(() => ({
  logo: {
    width: "100px",
    marginTop: ".1rem",
    marginRight: "2rem",
  },
  hoursInput: {
    width: "80px",
  },
  select: {
    width: "300px",
  },
  tableName: {
    width: "800px",
  },
  smallerInput: {
    fontSize: "90%",
  },
  jsonInput: {
    color: "#F1F1F1",
    fontFamily: "monospace",
    fontSize: "90%",
    backgroundColor: "#313131",
    borderBottom: "none",
    padding: "1em",
  },
}));
const defaultConnectorData = {
  json_config: "",
  datasource_id: "",
  solution_name: "",
  kc_type: "",
  key_map: "",
  table_name: "",
  hours: 24,
  is_enabled: false,
  track_coverage: false,
};

export default function ConnectorDialog(props) {
  const { connectorId, open, refreshDropdowns } = props;
  const [data, setData] = useState(null);
  const [datasources, setDatasources] = useState(null);
  const [hasError, setError] = useState(true);
  const api = useApi();
  const classes = useStyles();
  const [kcTypes, setKcTypes] = useState([]);
  const [validSolutions, setValidSolutions] = useState([]);
  const toastContext = useContext(ToastContext);
  const dialogTitleText = connectorId ? "Edit Connector" : "New Connector";
  const [jsonError, setJsonError] = useState(false);
  const [connectorData, setConnectorData] = useState(defaultConnectorData);

  const checkError = (connectorData) => {
    setError(false);
    if (!connectorId) {
      if (
        !connectorData?.kc_type?.length ||
        !connectorData?.solution_name?.length ||
        !connectorData?.datasource_id ||
        !connectorData?.hours
      ) {
        setError(true);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    let newValue = value;
    if (name === "is_enabled" || name === "track_coverage") {
      newValue = checked;
    }

    if (name === "json_config") {
      if (value) {
        try {
          JSON.parse(value);
          setJsonError(false);
          setError(false);
        } catch {
          setJsonError(true);
          setError(true);
        }
      } else {
        setJsonError(false);
        setError(false);
      }
    }

    const newData = {
      ...data,
      ...connectorData,
      [name]: newValue,
    };

    checkError(newData);
    setConnectorData(newData);
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    setConnectorData({
      ...data,
      json_config: data.json_config ? JSON.stringify(data.json_config) : "",
    });
  }, [data]);

  const handleSaveConnector = () => {
    const id = connectorData.id ? connectorData.id : "new";
    const requestData = {
      ...data,
      ...connectorData,
    };
    api
      .upsertConnector(id, requestData)
      .then((res) => {
        if (res.status === "OK") {
          toastContext.showToast(
            "open",
            "success",
            "Connector has been saved."
          );
          setConnectorData({
            defaultConnectorData,
          });
          props.handleClose();
        } else {
          toastContext.showToast(
            "open",
            "error",
            "There was an error saving the configuration. Please check and try again."
          );
          setConnectorData({
            defaultConnectorData,
          });
          props.handleClose();
        }
      })
      .catch(() => {
        toastContext.showToast(
          "open",
          "error",
          "There was an error saving the configuration. Please check and try again."
        );
        setConnectorData({
          defaultConnectorData,
        });
        props.handleClose();
      });
  };

  useEffect(() => {
    api
      .getDatasources()
      .then((response) => {
        setDatasources(response);
      })
      .catch((error) => console.log(error));
  }, [refreshDropdowns]);

  useEffect(() => {
    api
      .getConnectorOptions()
      .then((resp) => {
        setKcTypes(resp?.kc_types);
        setValidSolutions(resp?.solutions);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (!connectorId || connectorId < 1) {
      return;
    }
    api
      .getConnector(connectorId)
      .then((response) => {
        setData(response);
      })
      .catch((error) => console.log(error));
  }, [connectorId]);

  return (
    <StyledModal
      onClose={() => {
        setConnectorData({
          ...data,
          defaultConnectorData,
        });
        props.handleClose();
      }}
      open={open}
      title={dialogTitleText}
      submitDisabled={hasError}
      onSubmit={handleSaveConnector}
      submitDataTest="connector-dialog-save"
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          {validSolutions && (
            <TextField
              select={!connectorId}
              disabled={!!connectorId}
              label="Solution"
              placeholder="Solution"
              id="solution-select"
              error={!connectorId && !connectorData?.solution_name?.length}
              value={
                data?.solution?.display_name || connectorData.solution_name
              }
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              name="solution_name"
              data-test="connector-form-solution"
            >
              {validSolutions.map((solution) => (
                <MenuItem key={solution.name} value={solution.name}>
                  {solution.display_name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>

        <Grid item>
          {datasources && (
            <TextField
              select={!connectorId}
              disabled={!!connectorId}
              label="Data Source"
              placeholder="Data Source"
              id="datasource-select"
              error={!connectorId && !connectorData?.datasource_id}
              value={
                data?.datasource?.display_name || connectorData.datasource_id
              }
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              name="datasource_id"
              data-test="connector-form-datasource"
            >
              {datasources?.map((datasource, idx) => (
                <MenuItem key={idx} value={datasource.id}>
                  {datasource.display_name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>
        <Grid item>
          {kcTypes && (
            <TextField
              select
              placeholder="Category"
              label="Category"
              id="type-select"
              value={connectorData.kc_type || data?.kc_type}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              error={!connectorData.kc_type?.length}
              name="kc_type"
              data-test="connector-form-type"
            >
              {kcTypes.map((dataType) => (
                <MenuItem key={dataType.id} value={dataType.name}>
                  {dataType.display_name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>
        <Grid item>
          <TextField
            name="table_name"
            label="Bucket Prefix or SIEM Table Name"
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            value={connectorData.table_name || ""}
            data-test="connector-form-tablename"
            InputProps={{
              classes: {
                input: classes.smallerInput,
              },
            }}
          />
        </Grid>
        <Grid container spacing={2} justifyContent="space-between" item>
          <Grid item>
            <TextField
              name="hours"
              onChange={handleInputChange}
              variant="outlined"
              label="Run Every"
              value={connectorData.hours || ""}
              data-test="connector-form-hours"
              helperText="In Hours"
            />
          </Grid>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    name="is_enabled"
                    checked={connectorData.is_enabled}
                    onChange={handleInputChange}
                    data-test="connector-form-enabled"
                  />
                }
                label="Enabled"
              />
              <FormControlLabel
                control={
                  <Switch
                    name="track_coverage"
                    checked={connectorData.track_coverage}
                    onChange={handleInputChange}
                    data-test="connector-form-track-coverage"
                  />
                }
                label="Track Coverage"
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              className={classes.heading}
              style={{ color: palette.purple1 }}
            >
              Advanced Settings
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.row}
            >
              <Box item component={Grid} xs={12}>
                <Typography variant="overline" display="block">
                  JSON configuration for Data Ingestion
                </Typography>
              </Box>
              <Box item component={Grid} xs={12} className={classes.inputItems}>
                <TextField
                  placeholder="Paste Valid JSON here"
                  error={jsonError}
                  helperText={jsonError && "Must be valid JSON"}
                  name="json_config"
                  onChange={handleInputChange}
                  fullWidth
                  size="small"
                  multiline
                  minRows={8}
                  value={connectorData.json_config || ""}
                  InputProps={{
                    spellCheck: "false",
                    classes: {
                      input: classes.jsonInput,
                    },
                  }}
                />
              </Box>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </StyledModal>
  );
}
