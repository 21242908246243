/**
 * For some of the out of the box solutions we natively support, it doesn't make
 * sense to ask users to have to provide a logo url and it can be empty. If it
 * is empty, we will just default to predefined logo format of /vendors/solution.name.
 */
const getLogoUrl = (solution) => {
  if (!solution.logo_url && solution.name) {
    return `/vendors/${solution.name}.png`;
  }
  return solution.logo_url;
};

export default getLogoUrl;
