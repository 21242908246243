import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TableHead,
  Grid,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useApi } from "../API";
import ChartWrapper from "../components/ChartWrapper";
import ChartWrapperHeader from "../components/ChartWrapperHeader";
import AssetIcon from "../components/AssetIcon";
import useSettings from "../hooks/useSettings";
import { abbrCurrencyFormatter } from "../utils";
import { palette } from "../utils/theme";

function AverageRiskTable() {
  const api = useApi();
  const history = useHistory();
  const [data, setData] = useState({ data: [], loading: true, error: false });
  const [settings] = useSettings("financial_risk_enabled");
  const [financialRisk, setFinancialRisk] = useState(false);
  const [groupBy, setGroupBy] = useState("asset_type");
  const sortData = (data, financialRisk) => {
    return data.sort((a, b) => {
      if (financialRisk) {
        return a.risk_dollars < b.risk_dollars ? 1 : -1;
      }
      return a.risk_score < b.risk_score ? 1 : -1;
    });
  };

  const getRiskColor = (score) => {
    if (score < 40) {
      return palette.green;
    }
    if (score < 80 && score >= 40) {
      return palette.yellow;
    }
    if (score >= 80 && score <= 100) {
      return palette.red;
    }
    return "transparent";
  };

  useEffect(() => {
    if (settings.loading === false) {
      const financial = settings?.data === true;
      setFinancialRisk(financial);
      const orderBy = financial ? "risk_dollars" : "risk_score";

      api
        .riskAggregation(groupBy, orderBy)
        .then((res) => {
          // const scale = d3
          //   .scaleLog()
          //   .domain([
          //     1,
          //     Math.max(...res.data.map((el) => parseInt(el.risk_dollars))),
          //   ])
          //   .range([1, 100]);
          // setDataScale(() => scale);
          const fetchedData = res.data.map((el) => {
            return {
              ...el,
              risk_score: parseInt(el.risk_score),
              risk_dollars: parseInt(el.risk_dollars),
            };
          });
          setData({
            data: sortData(fetchedData, financial),
            loading: false,
            error: false,
          });
        })
        .catch((error) => {
          setData({
            data: [],
            loading: false,
            error: true,
          });
          console.log(error);
        });
    }
  }, [groupBy, settings.loading]);

  if (data.loading) {
    return <div>Loading...</div>;
  }

  if (data.error) {
    return <div>There was an error</div>;
  }

  return (
    <>
      <ChartWrapper excludeHeader>
        <ChartWrapperHeader
          chartName={
            financialRisk ? "Total Risk in Dollars" : "Average Risk Score"
          }
        >
          <FormControl size="small" variant="outlined">
            <InputLabel id="native-simple-aggregate-risk">Group By</InputLabel>
            <Select
              labelId="native-simple-aggregate-risk"
              id="native-simple-aggregate-risk"
              placeholder="Select"
              label="Group By"
              value={groupBy}
              onChange={({ target: { value } }) => setGroupBy(value)}
            >
              <MenuItem value="asset_type">Asset Type</MenuItem>
              <MenuItem value="business_unit">Business Unit</MenuItem>
              <MenuItem value="application_id">Business Process</MenuItem>
              <MenuItem value="location">Location</MenuItem>
            </Select>
          </FormControl>
        </ChartWrapperHeader>
        <Paper variant="outlined">
          <TableContainer>
            <Table size="small">
              <colgroup>
                <col style={{ width: "30%" }} />
                <col style={{ width: "70%" }} />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {groupBy === "business_unit"
                      ? "Business Unit"
                      : groupBy === "location"
                      ? "Location"
                      : groupBy === "application_id"
                      ? "Business Process"
                      : "Asset Type"}
                  </TableCell>
                  <TableCell>
                    {financialRisk ? "Risk in Dollars" : "Average Risk Score"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.data.map((row, i) => (
                  <TableRow
                    key={i}
                    hover
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push(
                        `/app/inventory/assets?${groupBy}=${row[groupBy]}`
                      );
                    }}
                  >
                    <TableCell>
                      {groupBy === "application_id"
                        ? row.asset.application && row.asset.application
                        : row[groupBy] && row[groupBy]?.toString()}
                    </TableCell>
                    <TableCell>
                      {financialRisk ? (
                        abbrCurrencyFormatter(row.risk_dollars)
                      ) : (
                        <Grid container alignContent="center" spacing={1}>
                          <Grid item alignItems="center" xs={2}>
                            <AssetIcon risk riskPercentage={row.risk_score} />
                          </Grid>
                          <Grid
                            item
                            alignItems="center"
                            xs={10}
                            style={{
                              padding: ".75em 0",
                            }}
                          >
                            <div
                              style={{
                                height: "1.75em",
                                width: `${row.risk_score}%`,
                                backgroundColor: getRiskColor(row.risk_score),
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </ChartWrapper>
    </>
  );
}

export default AverageRiskTable;
