import React, { useEffect, useState, useRef } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ResponsiveBar } from "@nivo/bar";
import _ from "lodash"; // eslint-disable-line import/no-extraneous-dependencies
import { useHistory } from "react-router-dom";
import { useApi } from "../API";
import UseResizeObserver from "../components/UseResizeObserver";
import { palette } from "../utils/theme";
import ChartWrapperHeader from "../components/ChartWrapperHeader";
import ChartWrapper from "../components/ChartWrapper";
import HighImpactSwitch from "../components/HighImpactSwitch";
import logo from "../assets/logo-black.svg";
import ActiveAssetsSwitch from "../components/ActiveAssetsSwitch";

const useStyles = makeStyles(() => ({
  chartWrapper: {
    height: "400px",
  },
}));

// Hotfix for demo - capitalize the name, really we should be using the display name of the solution.
const renderTooltip = (data) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        color: "inherit",
        fontSize: "inherit",
        borderRadius: "2px",
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
        padding: "5px 9px",
      }}
    >
      {(data.id === "critical_assets"
        ? "High Impact Assets: "
        : "All Assets: ") + _.capitalize(data.indexValue?.replaceAll("_", " "))}
      : <strong>{data.value?.toFixed(0)}%</strong>
    </div>
  );
};

export default function CoverageHorizontal(props) {
  const history = useHistory();
  const classes = useStyles();
  const api = useApi();
  const [data, setData] = useState([]);
  const [dateFilter, setDateFilter] = useState(true);
  const [criticalOnly, setCriticalOnly] = useState(true);
  const [logoData, setLogoData] = useState(null);
  const parentRef = useRef();
  const dimensions = UseResizeObserver(parentRef);

  const CustomTick = (tick) => {
    const logoUrl = logoData.find((el) => el.name === tick.value)?.logo_url;
    const displayName = logoData.find((el) => el.name === tick.value)?.name;
    if (!logoUrl || logoUrl === "") {
      return (
        <g transform={`translate(${tick.x - 90},${tick.y - 10})`}>
          <text>{displayName}</text>
        </g>
      );
    }
    return (
      <g transform={`translate(${tick.x - 90},${tick.y - 10})`}>
        <image
          width="80px"
          // href={logoData?.find((el) => el.name === tick.value)?.logo_url}
          alt={`${displayName} logo`}
          href={tick.value === "keycaliber" ? logo : logoUrl}
        />
      </g>
    );
  };
  const formatResponseForChart = (response) => {
    const formatted = [];
    // formatted.push({
    //   all_assets: 100,
    //   critical_assets: 100,
    //   key: "keycaliber",
    //   id: "-1",
    // });
    for (const solution of response) {
      formatted.push({
        all_assets: solution.percent,
        critical_assets: solution.high_impact_percent,
        key: solution.name,
        id: solution.id,
      });
    }

    return formatted.sort((a, b) => (b.id > a.id ? -1 : 1));
  };

  useEffect(() => {
    api
      .coverageSummary(dateFilter)
      .then((response) => {
        setLogoData(response.logos);
        setData(formatResponseForChart(response.data));
      })
      .catch((e) => {
        console.log(e);
      });
  }, [dateFilter]);

  return (
    <ChartWrapper excludeHeader>
      <ChartWrapperHeader chartName="Security Solutions Coverage">
        <Grid container spacing={1}>
          {/* <Grid item>
            <TextField
              id="date-last-seen-select"
              value={dateFilter}
              onChange={({ target: { value } }) => setDateFilter(value)}
              label="Assets seen in"
              variant="outlined"
              placeholder="Anytime"
              select
              size="small"
            >
              <MenuItem value="anytime">Anytime</MenuItem>
              <MenuItem value="1">Past day</MenuItem>
              <MenuItem value="2">Past 2 days</MenuItem>
              <MenuItem value="7">Past week</MenuItem>
              <MenuItem value="14">Past 2 weeks</MenuItem>
              <MenuItem value="30">Past 30 days</MenuItem>
            </TextField>
          </Grid> */}
          <Grid item>
            <HighImpactSwitch
              checked={criticalOnly}
              handleSwitchChange={() => setCriticalOnly(!criticalOnly)}
            />
          </Grid>
          <Grid item>
            <ActiveAssetsSwitch
              checked={dateFilter}
              handleSwitchChange={({ target: { checked } }) =>
                setDateFilter(checked)
              }
            />
          </Grid>
        </Grid>
      </ChartWrapperHeader>
      <div ref={parentRef}>
        {data?.length === 0 && (
          <Typography>No Assets Found. Try different filters.</Typography>
        )}
        {dimensions && data?.length > 0 && (
          <Grid container spacing={0}>
            <Grid
              item
              // style={{ paddingTop: "1em" }}
              xs={12}
              className={classes.chartWrapper}
              data-test="assets-location-wrapper1"
            >
              <ResponsiveBar
                onClick={(data) => {
                  let url = `/app/inventory/assets?missing_coverage=${
                    data.data.id
                  }${data.id === "critical_assets" ? "&is_critical=true" : ""}`;
                  if (dateFilter) {
                    url += "&stale=false";
                  }
                  history.push(url);
                }}
                enableLabel
                labelTextColor="white"
                label={(d) => `${parseFloat(d.value).toFixed()}%`}
                maxValue={100}
                minValue={0}
                data={data}
                keys={criticalOnly ? ["critical_assets"] : ["all_assets"]}
                indexBy="key"
                height={props.height || 400}
                margin={{
                  top: 30,
                  right: 80,
                  bottom: 60,
                  left: 145,
                }}
                axisLeft={{
                  tickSize: 0,
                  renderTick: CustomTick,
                }}
                gridXValues={5}
                axisBottom={{
                  tickSize: 0,
                  tickValues: 4,
                  legendPosition: "middle",
                  legendOffset: 50,
                  legend: "% Assets Covered",
                  format: (d) => `${d}%`,
                }}
                groupMode="grouped"
                colors={criticalOnly ? [palette.purple1] : [palette.keyBlue]}
                padding={0.3}
                layout="horizontal"
                borderColor="inherit:darker(1.6)"
                enableGridX
                enableGridY
                isInteractive
                theme={{
                  axis: {
                    domain: {
                      line: {
                        stroke: "#a9acbe",
                        strokeWidth: 1,
                      },
                    },
                  },
                  grid: {
                    line: {
                      stroke: "#e7e8ec",
                      strokeWidth: 1,
                    },
                  },
                }}
                // legends={[
                //   {
                //     dataFrom: "keys",
                //     data: [
                //       {
                //         color: palette.purple1,
                //         id: 1,
                //         label: "High Impact Assets",
                //       },
                //       {
                //         color: palette.keyBlue,
                //         id: 2,
                //         label: "All Assets",
                //       },
                //     ],
                //     anchor: "bottom-left",
                //     direction: "row",
                //     translateY: 50,
                //     itemWidth: 150,
                //     itemHeight: 20,
                //     itemDirection: "left-to-right",
                //     symbolSize: 20,
                //   },
                // ]}
                tooltip={renderTooltip}
              />
            </Grid>
          </Grid>
        )}
      </div>
    </ChartWrapper>
  );
}
