import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  Paper,
  TextField,
  Typography,
  Divider,
  Link,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ModifiedCoverageHorizontal from "../reporting/ModifiedCoverageHorizontal";
import { useApi } from "../API";
import ChartWrapper from "../components/ChartWrapper";
import ChartWrapperHeader from "../components/ChartWrapperHeader";
import ActiveAssetsCheckbox from "../components/ActiveAssetsSwitch";

const names = {
  business_unit: "Business Unit",
  owner: "Owner",
  location: "Location",
  asset_type: "Asset Type",
  application_id: "Business Process",
  os: "OS",
};

const SectionCard = (props) => {
  const {
    title,
    data,
    riskyData,
    logoData,
    // solutionIds,
    groupBy,
    value,
  } = props;
  const totalAssets = riskyData.find((el) => el.id === "level_1").total;
  // const risky2 = riskyData.find((el) => el.id === "level_2").total;
  // const risky3 = riskyData.find((el) => el.id === "level_3").total;

  return (
    <Grid item xs={4}>
      <Paper variant="outlined" elevation={0}>
        <div style={{ padding: "1rem", display: "flex" }}>
          <div style={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{title}</Typography>
          </div>
          <div style={{ flexGrow: 1 }}>
            <div style={{ float: "right" }}>
              <Link href={`/app/inventory/assets?${groupBy}=${value}`}>
                <Typography style={{ color: "#595959" }} variant="body2">
                  {totalAssets} {totalAssets === 1 ? " Asset" : " Assets"}
                </Typography>
              </Link>
            </div>
          </div>
        </div>
        <Divider />
        <div style={{ padding: "0 1em", marginBottom: "1em" }}>
          <ModifiedCoverageHorizontal data={data} logoData={logoData} />
        </div>
      </Paper>
    </Grid>
  );
};

const AssetOverview = () => {
  const api = useApi();
  const [cachedData, setCachedData] = useState();
  const [cachedRiskyData, setCachedRiskyData] = useState();
  const [data, setData] = useState();
  const [dateFilter, setDateFilter] = useState(true);
  const [riskyData, setRiskyData] = useState();
  const [groupBy, setGroupBy] = useState("business_unit");
  const [cachedGroupBy, setCachedGroupby] = useState("business_unit");
  const [groupByFilter, setGroupByFilter] = useState();
  const [businessUnits, setBusinessUnits] = useState();
  const [logoData, setLogoData] = useState();
  const [loading, setLoading] = useState(true);
  const [solutionIds, setSolutionIds] = useState();
  const [error, setError] = useState(false);
  const [reloading, setReloading] = useState(false);
  const [sortOrder, setSortOrder] = useState([]);

  useEffect(async () => {
    if (groupBy !== cachedGroupBy) {
      setCachedGroupby(groupBy);
      setLoading(true);
      setError(false);
      setBusinessUnits(null);
      setReloading(false);
    }

    try {
      if (!businessUnits || groupBy !== cachedGroupBy) {
        const facets = await api.assetsFacets();
        const businessUnitsResult = facets.find(
          (el) => el.request_arg === groupBy
        )?.options;
        setBusinessUnits([
          ...businessUnitsResult,
          { display_name: `None`, value: `None` },
        ]);
      }

      if (groupByFilter) {
        setReloading(true);
        const response = await api.assetOverview(
          groupBy,
          groupByFilter.value,
          dateFilter
        );
        setData(response.coverage_data);
        setRiskyData(response.risky_data);
      } else if (!cachedData || !cachedRiskyData) {
        const response = await api.assetOverview(groupBy, null, dateFilter);
        setCachedData(response.coverage_data);
        setCachedRiskyData(response.risky_data);
        setLogoData(response.logos);
        setSolutionIds(response.solution_ids);
        setData(response.coverage_data);
        setRiskyData(response.risky_data);
        setSortOrder(response.sort_order);
      } else {
        setData(cachedData);
        setRiskyData(cachedRiskyData);
      }
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setLoading(false);
      setReloading(false);
    }
  }, [groupByFilter, groupBy, dateFilter]);

  if (error) {
    return <div>There was an error.</div>;
  }
  return (
    <div>
      <ChartWrapper excludeHeader>
        <ChartWrapperHeader chartName={`Coverage by ${names[groupBy]}`}>
          <Grid
            container
            alignContent="center"
            justifyContent="flex-end"
            spacing={1}
          >
            <Grid item>
              <FormControl
                variant="outlined"
                size="small"
                style={{ backgroundColor: "white", width: "200px" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Group By
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={groupBy}
                  onChange={(e) => {
                    setReloading(true);
                    setError(false);
                    setGroupBy(e.target.value);
                    setGroupByFilter(null);
                    setCachedRiskyData(null);
                    setCachedData(null);
                    setData(null);
                    setRiskyData(null);
                  }}
                  label="Group By"
                >
                  <MenuItem value="business_unit">Business Unit</MenuItem>
                  <MenuItem value="owner">Owner</MenuItem>
                  <MenuItem value="location">Location</MenuItem>
                  <MenuItem value="asset_type">Asset Type</MenuItem>
                  <MenuItem value="application_id">Business Process</MenuItem>
                  <MenuItem value="os">OS</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Autocomplete
                size="small"
                autoComplete
                value={groupByFilter}
                onChange={(event, newValue) => {
                  event?.preventDefault();
                  setGroupByFilter(newValue);
                }}
                onInputChange={(event, value, reason) => {
                  event?.preventDefault();
                  if (reason === "clear") {
                    setGroupByFilter(null);
                  }
                }}
                id="controllable-states-demo"
                options={businessUnits || []}
                getOptionLabel={(option) => option.display_name}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    style={{ backgroundColor: "white" }}
                    {...params}
                    label={`Select ${names[groupBy]}`}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item>
              <ActiveAssetsCheckbox
                checked={dateFilter}
                handleSwitchChange={(event) => {
                  event.preventDefault();
                  setDateFilter(event.target.checked);
                  setLoading(true);
                  setError(false);
                  setGroupByFilter(null);
                  setCachedRiskyData(null);
                  setCachedData(null);
                  setData(null);
                  setRiskyData(null);
                }}
              />
            </Grid>
          </Grid>
        </ChartWrapperHeader>
        <Typography variant="caption">
          {groupByFilter?.value === "None"
            ? `Showing assets with no ${names[groupBy]}`
            : `Showing 
                  ${
                    groupByFilter
                      ? "1"
                      : data
                      ? `${
                          Object.keys(data)?.filter(
                            (el) => el !== "None" && el !== "totals"
                          )?.length
                        }`
                      : 0
                  }
                  of ${
                    businessUnits?.filter((el) => el.value !== "None")?.length
                  } total ${names[groupBy]}s`}
        </Typography>

        <div style={{ paddingTop: "1em", minHeight: "80vh" }}>
          {loading || !businessUnits ? (
            <div>
              <CircularProgress />
            </div>
          ) : (
            <Grid container spacing={2}>
              {!reloading &&
                data &&
                Object.keys(data)
                  ?.sort((a, b) => {
                    return sortOrder.indexOf(a) - sortOrder.indexOf(b);
                  })
                  ?.map((name, idx) => {
                    if (!["totals"].includes(name)) {
                      const graphData = data[name];
                      return (
                        <SectionCard
                          dateFilter={dateFilter}
                          key={idx}
                          title={
                            // eslint-disable-next-line eqeqeq
                            businessUnits?.find((el) => el.value == name)
                              ?.display_name || name
                          }
                          data={graphData}
                          value={name}
                          logoData={logoData}
                          riskyData={riskyData[name]?.data}
                          solutionIds={solutionIds}
                          groupBy={groupBy}
                        />
                      );
                    }
                    return <></>;
                  })}
            </Grid>
          )}
        </div>
      </ChartWrapper>
    </div>
  );
};

export default AssetOverview;
