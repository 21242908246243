import React, { useRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { IconButton } from "@material-ui/core";
import {
  setupSimulation,
  setupForceGraph,
  setupForceLinks,
  setupForceNodes,
  setupRadialForce,
  setupClusterForce,
} from "../utils/D3Utils";
import UseResizeObserver from "../components/UseResizeObserver";
import AssetHoverPopover from "../components/AssetHoverPopover/AssetHoverPopover";
import { svgIcons } from "../icons/Icons";
import Icon from "../icons/icon";
import { palette } from "../utils/theme";
import {
  HoverPopoverProvider,
  useHoverPopover,
} from "../components/HoverPopover";
import FilterGlow from "./FilterGlow";

const useStyles = makeStyles(() => ({
  root: {
    padding: "30px 0px",
  },
  visToolbar: {
    margin: "0 10px",
  },
  visWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "center",
    height: "100%",
  },
  svg: {
    background: "#fff",
    width: "100%",
    height: "100%",
    margin: 0,
    padding: 0,
  },
}));

const NetworkSimple = (props) => {
  const classes = useStyles();
  const [highlightedIds, setHighlightedIds] = useState([]);
  const [oneHops, setOneHops] = useState([]);
  const clusterKey = "application_name";

  const svgRef = useRef();
  const gRef = useRef();
  const linksRef = useRef();
  const clusterRef = useRef();
  const wrapperRef = useRef();
  const legendRef = useRef();
  const [initialDimensions, setInitialDimensions] = useState({
    height: 400,
    width: 400,
  });
  const dimensions = UseResizeObserver(wrapperRef);

  const { data, setRedrawCount } = props;
  const redrawCount = props.redrawCount ? props.redrawCount : 0;
  const showRedrawButton = props.showRedrawButton
    ? props.showRedrawButton
    : false;

  const { showPopover, hidePopover } = useHoverPopover();

  let tagFilter;

  if (props.tagValueFilter && props.tagNameFilter) {
    tagFilter = {
      name: props.tagNameFilter,
      value: props.tagValueFilter,
    };
  } else {
    tagFilter = undefined;
  }

  const handleDoubleClick = (event, d) => {
    event.stopPropagation();
    if (d.ipv4 !== "1.1.1.1") {
      const win = window.open(
        `/app/resilience/connections/asset/${d.id}`,
        "_blank"
      );
      win.focus();
    }
  };

  const handleClick = (event, d) => {
    event.stopPropagation();
    if (typeof props.selectClickedAsset !== "undefined") {
      props.selectClickedAsset(d);
    }

    if (event.shiftKey) {
      // props.onShiftClick(d);
      const value = d.hostname ? d.hostname : d.ipv4;
      if (value && props.highlight !== value) {
        props.onUpdateHighlight(value);
      } else {
        props.onUpdateHighlight("");
      }
    }
  };

  // Series of useEffects designed to setup the resilience graph.

  useEffect(() => {
    if (!dimensions) return;
    if (redrawCount < 1) {
      setInitialDimensions(dimensions);
    }

    console.log(`current dimensions: ${dimensions.width}`);
    console.log(`initial dimensions: ${initialDimensions.width}`);
  }, [dimensions]);

  // When the data is populated, run the highlight and/or hops mode function
  // if neither are set, go ahead and draw the graph.
  useEffect(() => {
    if (!initialDimensions || !data) return;

    if (props.hopsMode) {
      // first build the hops array (NOTE: this is really a backend concern and something we should move)
      runHops();
    } else {
      drawGraph();
    }
  }, [data, initialDimensions]);

  // once the hops and/or highlight functions run,
  // draw the graph
  useEffect(() => {
    if (!initialDimensions || !data) return;
    drawGraph();
  }, [oneHops, highlightedIds, props.showLabels, props.tagValueFilter]);

  useEffect(() => {
    if (!data) return;
    runHighlight();
  }, [props.highlight]);

  useEffect(() => {
    if (!data) return;
    runHops();
  }, [props.hopsMode]);

  function drawGraph() {
    const { svgObj, gObj, linksObj, clusterObj, legendObj } = setupForceGraph(
      svgRef,
      gRef,
      linksRef,
      clusterRef,
      legendRef,
      data
    );

    const simulation = setupSimulation(
      svgObj,
      gObj,
      linksObj,
      clusterObj,
      initialDimensions || dimensions,
      data,
      redrawCount,
      props.useCase.visScale,
      legendObj
    );

    setupForceLinks(
      simulation,
      data,
      linksObj,
      highlightedIds,
      initialDimensions || dimensions,
      props.useCase.value !== "one-hop-internet"
    );

    setupForceNodes(
      simulation,
      data,
      gObj,
      highlightedIds,
      props.showLabels,
      showPopover,
      hidePopover,
      handleDoubleClick,
      handleClick,
      props.assetFilter,
      initialDimensions || dimensions,
      tagFilter
    );

    if (props.hopsMode) {
      setupRadialForce(
        simulation,
        oneHops,
        initialDimensions || dimensions,
        clusterObj
      );
    }

    if (props.useCase.value === "application-connections") {
      setupClusterForce(
        simulation,
        data,
        clusterKey,
        props.appFilter,
        initialDimensions || dimensions,
        clusterObj,
        legendObj
      );

      setTimeout(() => {
        setupClusterForce(
          simulation,
          data,
          clusterKey,
          props.appFilter,
          initialDimensions || dimensions,
          clusterObj,
          legendObj
        );
      }, 500);
    }

    if (typeof setRedrawCount !== "undefined") {
      setRedrawCount(redrawCount + 1);
    }
  }

  const runHighlight = () => {
    const { highlight } = props;

    const highlighted = [];
    const adjacentNodes = [];

    if (data && highlight && highlight.length > 0) {
      data.nodes.map((node) => {
        if (
          (node.name && node.name.indexOf(highlight) !== -1) ||
          (node.hostname && node.hostname.indexOf(highlight) !== -1) ||
          (node.ipv4 && node.ipv4.indexOf(highlight) !== -1) ||
          (node.id && node.id.toString() === highlight.toString())
        ) {
          highlighted.push({ id: node.id, root: true });
        }
        return true;
      });

      data.links.forEach((link) => {
        const linkSourceId = link.source.id ? link.source.id : link.source;
        const linkTargetId = link.target.id ? link.target.id : link.target;

        for (const h of highlighted) {
          if (h.id === linkSourceId && linkSourceId !== linkTargetId) {
            adjacentNodes.push({ id: linkTargetId, root: false });
          } else if (h.id === linkTargetId && linkSourceId !== linkTargetId) {
            adjacentNodes.push({ id: linkSourceId, root: false });
          }
        }
      });
    }

    const allHighlighted = adjacentNodes.concat(highlighted);
    setHighlightedIds(allHighlighted);
  };

  const runHops = () => {
    const { data, hopsMode } = props;

    if (hopsMode) {
      const center = props?.assetFilter?.id;

      const oneHops = [];
      const adjacentNodes = [];

      if (data && center) {
        data.nodes.forEach((node) => {
          if (node.id && node.id.toString() === center.toString()) {
            oneHops.push({ id: node.id, root: true });
          }
        });

        data.links.forEach((link) => {
          const linkSourceId = link.source.id ? link.source.id : link.source;
          const linkTargetId = link.target.id ? link.target.id : link.target;

          for (const h of oneHops) {
            if (h.id === linkSourceId && linkSourceId !== linkTargetId) {
              adjacentNodes.push({ id: linkTargetId, root: false });
            } else if (h.id === linkTargetId && linkSourceId !== linkTargetId) {
              adjacentNodes.push({ id: linkSourceId, root: false });
            }
          }
        });
      }
      const allHops = adjacentNodes.concat(oneHops);

      setOneHops(allHops);
    } else {
      setOneHops(undefined);
    }
  };

  // --

  return (
    <>
      <AssetHoverPopover />

      <div className={classes.visWrapper} ref={wrapperRef}>
        {data?.links && data?.links?.length > 0 && (
          <svg className={classes.svg} ref={svgRef}>
            <defs>
              <radialGradient id="critical-fill-color">
                <stop offset="0%" stopColor={palette.purple1} />
                <stop offset="70%" stopColor={palette.purple1} />
                <stop offset="71%" stopColor="white" />
                <stop offset="100%" stopColor="white" />
              </radialGradient>
              <radialGradient id="non-critical-fill-color">
                <stop offset="0%" stopColor={palette.keyBlue} />
                <stop offset="70%" stopColor={palette.keyBlue} />
                <stop offset="71%" stopColor="white" />
                <stop offset="100%" stopColor="white" />
              </radialGradient>
              <FilterGlow id="tag-glow" color={palette.black} />
              <FilterGlow id="high-risk-glow" color={palette.purple1} />
              <FilterGlow id="medium-risk-glow" color={palette.keyBlue} />
            </defs>
            <g>
              <g ref={clusterRef} />
              <g ref={linksRef} />
              <g ref={gRef} />
              {props.useCase.value === "application-connections" &&
                !props.visLoading && <g ref={legendRef} />}
            </g>
          </svg>
        )}
        {(!data?.links || !data?.links?.length) && (
          <div style={{ textAlign: "center" }}>No data available.</div>
        )}
      </div>
      {showRedrawButton && (
        <IconButton
          style={{
            position: "relative",
            left: "16px",
            bottom: "72px",
            background: "white",
          }}
          onClick={props.resetZoom}
          color="secondary"
          title="Re-Center Graph"
        >
          <Icon icon={svgIcons.compass} foreground={palette.darkGray} />
        </IconButton>
      )}
    </>
  );
};

/**
 * A wrapper for the NetworkSimple component, needed so that the hover tip
 * context can be provided to the graph nodes.
 */
const NetworkSimpleWrapper = (props) => (
  <HoverPopoverProvider>
    <NetworkSimple {...props} />
  </HoverPopoverProvider>
);

export default NetworkSimpleWrapper;
