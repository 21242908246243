import React, { useState } from "react";
import DataTable from "../table/DataTable";

function AssetsTable(props) {
  const [orderBy, setOrderBy] = useState("criticality");
  const [order, setOrder] = useState(false);
  const { rowsPerPage } = props;
  const onRequestSort = (property) => {
    props.updateSortingQueryParams(property, !order);
    setOrder(!order);
    setOrderBy(property);
  };

  if (!props.data) {
    return <div />;
  }

  return (
    <DataTable
      dense
      reloadTable={props.reloadTable}
      loading={props.loading}
      selectable
      dataIsPaginated
      data={props.data}
      defaultRowsPerPage={rowsPerPage}
      hideEditRow
      n_assets={props.n_assets}
      setSelected={props.setSelected}
      sortable
      onRequestSort={onRequestSort}
      disableClientSorting
      order={order === true ? "asc" : "desc"}
      orderBy={orderBy}
      page={props.page}
      setPage={props.setPage}
      setRowsPerPage={props.setRowsPerPage}
      tableActions={props.tableActions}
      applications={props.applications}
      // tablePaginationActions={props.tablePaginationActions}
    />
  );
}

export default AssetsTable;
