import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  Radio,
  Typography,
  TextField,
  MenuItem,
} from "@material-ui/core";
import moment from "moment";
import OpenInNew from "@material-ui/icons/OpenInNew";
import ToastContext from "../context/ToastContext";
import { useApi } from "../API";
import { palette } from "../utils/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2em 20em",
    // paddingRight: "8em"
    // margin: "10px",
    // "& .MuiTextField-root": {
    //   margin: theme.spacing(1),
    // },
  },
  keyCaliberColumn: {
    marginLeft: theme.spacing(1),
  },
  criticalityContainer: {
    margin: "30px 30px 30px 0",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
  },
  criticalitySliderContainer: {
    flex: 1,
    margin: "0px 20px 0px 0px",
  },
  criticalityAssetIcon: {
    margin: "0px 20px 30px 20px",
  },
  criticalitySlider: {
    margin: "30px 0px 0px 0px",
  },
  rowLabel: {
    textAlign: "right",
    paddingRight: theme.spacing(3),
  },
  descriptionField: {
    // paddingRight: theme.spacing(2),
    width: "100%",
  },
  textField: {
    // paddingRight: theme.spacing(4),
    width: "100%",
  },
  hostnameTextField: {
    width: 350,
  },
  btnBar: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  button: {
    margin: theme.spacing(1),
  },
  header: {
    margin: "0 0 10px 0",
  },
  errors: {
    color: "red",
    margin: "10px 0",
  },
  updateTime: {
    margin: "1em 0",
  },
  radioIcon: {
    marginRight: "8px",
  },
}));

export default function EditAsset() {
  const api = useApi();
  const history = useHistory();
  const { assetId } = useParams();
  const toastContext = useContext(ToastContext);

  const initialAssetState = {
    id: assetId,
    name: null,
    criticality: null,
    ipv4: null,
    hostname: null,
    location: null,
    asset_type: null,
    owner: null,
    business_unit: null,
    environment: null,
    description: null,
    is_critical: null,
    application_id: null,
  };
  const fields = [
    {
      name: "name",
      label: "Alias",
    },
    {
      name: "ipv4",
      label: "IP Address",
    },
    {
      name: "hostname",
      label: "Hostname",
    },
    {
      name: "asset_type",
      label: "Asset Type",
    },
    {
      name: "location",
      label: "Location",
    },
    {
      name: "business_unit",
      label: "Business Unit",
    },
    {
      name: "environment",
      label: "Environment",
    },
    {
      name: "owner",
      label: "Owner",
    },
  ];

  const [assetData, setAssetData] = useState(initialAssetState);
  const [assetUpdates, setAssetUpdates] = useState({});
  const [overrideData, setOverrideData] = useState(initialAssetState);
  const [errors, setErrors] = useState(null);
  const [guardrailWarning, setGuardrailWarning] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [isCriticalEnabled, setIsCriticalEnabled] = useState(true);
  const [isCriticalSelection, setIsCriticalSelection] = useState(false);
  // if for some reason this api call doesn't work, set lowestCritical to 0 so it won't get triggered
  const [lowestCritical, setLowestCritical] = useState(0);
  const [applications, setApplications] = useState([]);

  useEffect(async () => {
    const applications = await api.getApplications();
    setApplications(applications);
  }, []);

  useEffect(() => {
    if (assetId && applications) {
      api
        .crownJewelsDetails(assetId)
        .then((result) => {
          // we have to do this for the slider at the moment
          const roundedCriticality = parseFloat(result.criticality.toFixed(1));
          result.criticality = roundedCriticality;

          setAssetData(result);
          console.log(result);
          if (result.overrides) {
            setOverrideData(result.overrides);
            setIsCriticalEnabled(result.overrides.is_critical !== null);
            setIsCriticalSelection(
              result.overrides.is_critical
                ? "true"
                : result.overrides.is_critical === false
                ? "false"
                : null
            );
          } else {
            setOverrideData(initialAssetState);
          }
        })
        .catch((error) => console.log(error));

      api.lowestCritical().then((result) => {
        setLowestCritical(result);
      });
    }
  }, [assetId, applications]);

  const handleCancel = () => {
    setOverrideData(initialAssetState);
    history.push(`/app/asset/${assetId}`);
  };

  const throwLowestCriticalError = (value, isCritical) => {
    if (
      value !== null &&
      value < lowestCritical &&
      isCritical !== "false" &&
      isCritical !== "null" &&
      isCritical
    ) {
      setGuardrailWarning(
        `*Note: High Impact assets are usually above ${parseFloat(
          lowestCritical
        )?.toFixed()}.`
      );
    } else {
      setGuardrailWarning("");
    }
  };

  const handleAssetUpdatesChange = (e) => {
    const { name, value } = e.target;
    setAssetUpdates({
      ...assetUpdates,
      [name]: value,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let val;
    if (value === "") {
      val = null;
    } else {
      val = value;
    }

    setOverrideData({
      ...overrideData,
      [name]: val,
    });

    if (name === "criticality") {
      if (
        overrideData.is_critical === true ||
        overrideData.is_critical === "true"
      ) {
        throwLowestCriticalError(val, true);
      } else if (assetData.is_critical === true) {
        if (
          overrideData.is_critical !== false &&
          overrideData.is_critical !== "false"
        ) {
          throwLowestCriticalError(val, true);
        }
      } else if (assetData.is_critical === true) {
        if (
          overrideData.is_critical === false ||
          overrideData.is_critical === "false"
        ) {
          throwLowestCriticalError(val, false);
        }
      }
    }
  };

  const handleIsCriticalChange = (e) => {
    const { value } = e.target;

    let val;
    if (isCriticalSelection === value) {
      val = assetData.is_critical;
      setIsCriticalSelection(null);
      setOverrideData({ ...overrideData, is_critical: null });
    } else {
      val = value;
      setIsCriticalSelection(value);
      setOverrideData({ ...overrideData, is_critical: value });
    }

    if (overrideData.criticality) {
      throwLowestCriticalError(overrideData.criticality, val);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors(null);
    api
      .updateAsset(assetId, overrideData, assetUpdates)
      .then((response) => {
        if (response.status !== "OK") {
          setErrors(response.message);
          toastContext.showToast(
            "open",
            "error",
            `Failed to update asset. ${response.message}`
          );
          return;
        }

        // props.redrawTopBar();
        assetSaved();
      })
      .catch((err) => {
        setErrors(err.message);
        toastContext.showToast("open", "error", err.message);
      });
  };

  const assetSaved = async () => {
    toastContext.showToast("open", "success", "Asset Saved");
    await api.crownJewelsBubbleCacheRegenerate();

    // window.location.href = `/app/asset/${assetId}`;
  };

  const isValidImpactScore = (n) => {
    if ((n <= 100 && n >= 0) || n === "") {
      return false;
    }
    return true;
  };

  const classes = useStyles();

  let errorComponent = <div />;
  if (errors) {
    errorComponent = (
      <Typography className={classes.errors}>
        Please Try again.{" Error: "} {errors}
      </Typography>
    );
  }

  return (
    <div className={classes.root}>
      {errorComponent}

      {assetData.criticality >= 0 && (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={8}>
            <Grid item container spacing={2} xs={6}>
              <Grid item>
                <Typography variant="h6">Override Values</Typography>
                <Typography variant="body2" style={{ fontSize: "small" }}>
                  {overrideData.date_last_edited &&
                    `Last Updated 
                      ${moment(overrideData.date_last_edited)
                        .local()
                        .fromNow()}`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  variant="outlined"
                  size="small"
                >
                  <RadioGroup name="Impact" value={isCriticalSelection}>
                    <FormControlLabel
                      value="true"
                      control={<Radio onClick={handleIsCriticalChange} />}
                      label="High Impact"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio onClick={handleIsCriticalChange} />}
                      label="Low Impact"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <div style={{ minHeight: "8em" }}>
                  <TextField
                    label="Impact Score"
                    name="criticality"
                    variant="outlined"
                    onChange={handleInputChange}
                    defaultValue={overrideData.criticality || ""}
                    value={overrideData.criticality || ""}
                    error={isValidImpactScore(overrideData.criticality)}
                    helperText="Number 0-100."
                    inputProps={{ "data-test": "adjusted-criticality-input" }}
                  />
                  <Typography display="block" variant="caption">
                    {guardrailWarning && guardrailWarning}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid item container spacing={2} xs={6}>
              <Grid item container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h6">KeyCaliber Values</Typography>
                  <Typography variant="body2" style={{ fontSize: "small" }}>
                    Last Updated{" "}
                    {moment(assetData?.date_last_seen).local().fromNow()}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    color="secondary"
                    variant="outlined"
                    target="_blank"
                    size="large"
                    href={`/app/asset/${assetId}`}
                    data-test="view-asset-button"
                    endIcon={<OpenInNew />}
                  >
                    Asset Details
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  variant="outlined"
                  size="small"
                >
                  <RadioGroup value={assetData.is_critical}>
                    <FormControlLabel
                      value
                      disabled
                      control={<Radio />}
                      label="High Impact"
                    />
                    <FormControlLabel
                      value={false}
                      disabled
                      control={<Radio />}
                      label="Low Impact"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <div style={{ minHeight: "8em" }}>
                  <TextField
                    label="KeyCaliber Impact Score"
                    name="criticality"
                    disabled
                    variant="outlined"
                    value={Number.parseInt(assetData.criticality) || ""}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <input name="id" value={assetData.id || ""} type="hidden" />

          <Grid container spacing={2} direction="column">
            <Grid container spacing={4} item>
              <Grid item xs={12}>
                <TextField
                  multiline
                  label="Description"
                  defaultValue={overrideData.description || ""}
                  name="description"
                  minRows={4}
                  variant="outlined"
                  onChange={handleInputChange}
                  className={classes.textField}
                  value={overrideData.description || ""}
                  inputProps={{
                    "data-test": `adjusted-${"description"}-input`,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={4} item>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  value={
                    assetUpdates.application_id ??
                    (assetData?.application_id || -1)
                  }
                  select
                  fullWidth
                  name="application_id"
                  onChange={handleAssetUpdatesChange}
                  label="Business Process"
                  placeholder="Business Process"
                  style={{ backgroundColor: "white" }}
                >
                  <MenuItem value="">
                    <em>Add Asset to a Business Process</em>
                  </MenuItem>
                  <MenuItem
                    value={-1}
                    style={{ color: palette.red, fontWeight: 500 }}
                  >
                    No Business Process
                  </MenuItem>
                  {applications?.map((el, idx) => {
                    return (
                      <MenuItem key={idx} value={el.id}>
                        {el.display_name}{" "}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
            {/* Field rows */}
            {fields.map((field, index) => (
              <Grid container spacing={4} item key={index}>
                <Grid item xs={6}>
                  <TextField
                    label={field.label}
                    defaultValue={overrideData[field.name] || ""}
                    name={field.name}
                    variant="outlined"
                    onChange={handleInputChange}
                    className={classes.textField}
                    value={overrideData[field.name] || ""}
                    inputProps={{ "data-test": `adjusted-${field.name}-input` }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    label={`KeyCaliber ${field.label}`}
                    defaultValue={overrideData[field.name] || ""}
                    name={field.name}
                    variant="outlined"
                    className={classes.textField}
                    value={assetData[field.name] || ""}
                    data-test={`asset-${field.name}-input`}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid
            container
            style={{ paddingTop: "2em" }}
            justifyContent="space-between"
          >
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                size="large"
                startIcon={<CheckIcon />}
                data-test="save-asset-button"
              >
                Save Asset
              </Button>
            </Grid>
            <Grid item>
              <Button
                startIcon={<CloseIcon />}
                onClick={handleCancel}
                variant="outlined"
                size="large"
                data-test="cancel-asset-button"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </div>
  );
}
