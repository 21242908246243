import React from "react";
import { makeStyles, Card } from "@material-ui/core";
import ChartWrapperHeader from "./ChartWrapperHeader";

const useStyles = makeStyles(() => ({
  chartWrapper: {
    padding: ".5rem 1rem",
    height: "100%",
    background: "white",
  },
}));

function ChartWrapper(props) {
  const classes = useStyles();

  return (
    <Card className={classes.chartWrapper} variant="outlined">
      {!props.excludeHeader && (
        <ChartWrapperHeader
          chartName={props.chartName}
          subtitle={props.subtitle}
        />
      )}
      {props.chart || props.children}
    </Card>
  );
}

export default ChartWrapper;
