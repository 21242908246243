import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function ControlsIcon(props) {
  return (
    <SvgIcon {...props}>
      <g>
        <path className="cls-1" d="M6.74,13.77V7A.74.74,0,1,0,5.26,7v6.75Z" />
        <rect className="cls-1" x="8.53" y="9.08" width="2.75" height="2.24" />
        <rect
          className="cls-1"
          x="12.47"
          y="12.62"
          width="2.75"
          height="2.24"
        />
        <rect className="cls-1" x="16.4" y="5.99" width="2.75" height="2.24" />
        <path
          className="cls-1"
          d="M9.18,13.08V16.9a.75.75,0,0,0,1.49,0V13.08Z"
        />
        <path className="cls-1" d="M10.67,7.33V7A.75.75,0,0,0,9.18,7v.31Z" />
        <path
          className="cls-1"
          d="M13.11,16.63v.27a.74.74,0,1,0,1.48,0v-.27Z"
        />
        <path className="cls-1" d="M14.59,10.89V7a.74.74,0,0,0-1.48,0v3.87Z" />
        <path className="cls-1" d="M17,10V16.9a.74.74,0,1,0,1.48,0V10Z" />
        <rect
          className="cls-1"
          x="4.63"
          y="15.55"
          width="2.75"
          height="2.24"
        />{" "}
      </g>
    </SvgIcon>
  );
}

export default ControlsIcon;
