import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function DashboardIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        className="cls-1"
        d="M20,11.73a2.55,2.55,0,0,0-2.43,1.8H15.45a3.49,3.49,0,0,0-.83-1.62l1.17-2.17h.27a2.53,2.53,0,1,0-1.47-.47l-1,1.84A3.42,3.42,0,0,0,12,10.74a3.59,3.59,0,0,0-1.31.25L9.51,9a2.51,2.51,0,0,0,.78-1.82A2.54,2.54,0,1,0,7.76,9.75a2.59,2.59,0,0,0,.63-.08l1.22,2a3.49,3.49,0,0,0-1.06,1.86H6.42a2.54,2.54,0,1,0,0,1.28h2.1a3.52,3.52,0,0,0,7,0h2.08A2.53,2.53,0,1,0,20,11.73ZM16.06,6a1.25,1.25,0,1,1-1.25,1.25A1.25,1.25,0,0,1,16.06,6Zm-8.3,2.5A1.25,1.25,0,1,1,9,7.22,1.25,1.25,0,0,1,7.76,8.47ZM4,15.42a1.25,1.25,0,1,1,1.25-1.25A1.25,1.25,0,0,1,4,15.42Zm8,1.09a2.25,2.25,0,1,1,2.25-2.25A2.25,2.25,0,0,1,12,16.51Zm8-1a1.25,1.25,0,1,1,1.25-1.25A1.25,1.25,0,0,1,20,15.51Z"
      />
    </SvgIcon>
  );
}

export default DashboardIcon;
