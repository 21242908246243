import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import ConnectorDialog from "./ConnectorDialog";
import ConnectorsMenu from "./ConnectorMenu";
import ToastContext from "../../context/ToastContext";
import { useApi } from "../../API";
import getLogoUrl from "./ConnectorUtils";
import StyledModal from "../../components/StyledModal";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  buttons: {
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  logo: {
    width: "100px",
    marginTop: ".1rem",
  },
  headerCell: {
    fontWeight: 800,
    backgroundColor: theme.palette.lightGray,
  },
  actionsCell: {
    width: "100px",
  },
  solutionName: {
    marginLeft: ".75rem",
    verticalAlign: "top",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: "white",
    border: "2px solid #000",
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: `translate(-${50}%, -${50}%)`,
  },
}));

export default function ConnectorTable({ refreshDropdowns }) {
  const classes = useStyles();
  const history = useHistory();

  const [data, setData] = useState(null);
  const api = useApi();
  const [connectorOptions, setConnectorOptions] = useState();
  const [stats, setStats] = useState(null);
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [newModalOpen, setNewModalOpen] = useState(false);
  // const [week, setWeek] = useState();
  const [warningAlert, setWarningAlert] = useState(false);
  const [editConnectorId, setEditConnectorId] = useState(-1);
  const [deletedConnectorId, setDeletedConnectorId] = useState(false);
  const toastContext = useContext(ToastContext);

  const handleClickNewConnector = () => {
    setNewModalOpen(true);
  };

  const handleCloseNewConnector = () => {
    setNewModalOpen(false);
  };

  const handleCloseEditDialog = () => {
    setEditConnectorId(-1);
    setEditModalOpen(false);
  };

  const handleActionsMenuClick = (event) => {
    setEditConnectorId(event.currentTarget.getAttribute("data-connectorid"));
    setActionsAnchorEl(event.currentTarget);
  };

  const handleActionsMenuClose = () => {
    setEditConnectorId(-1);
    setActionsAnchorEl(null);
  };

  const handleActionsMenuManage = () => {
    setActionsAnchorEl(null);
    history.push('/app/settings/connectors/' + editConnectorId)

  };

  const handleClearWarning = () => {
    setWarningAlert(false);
  };

  const handleActionsMenuDelete = () => {
    setActionsAnchorEl(null);
    setWarningAlert(true);
  };

  const handleDeleteConnector = () => {
    setWarningAlert(false);
    api
      .deleteConnector(editConnectorId)
      .then((res) => {
        if (res.status === "OK") {
          toastContext.showToast(
            "open",
            "success",
            "Connector has been successfully deleted."
          );

          setDeletedConnectorId(res.id);
        } else {
          toastContext.showToast(
            "open",
            "error",
            "There was an error deleting the connector."
          );
        }
      })
      .catch(() => {
        toastContext.showToast(
          "open",
          "error",
          "There was an error deleting the connector."
        );
      });
  };

  useEffect(async () => {
    const res = await api.connectorStats();
    setStats(res.stats);

    const resp = await api.getConnectorOptions();
    setConnectorOptions(resp);
  }, []);

  useEffect(() => {
    api
      .getConnectors()
      .then((response) => {
        response.forEach((connector) => {
          connector.solution.logo_url = getLogoUrl(connector.solution);
        });

        setData(response);
      })
      .catch((error) => console.log(error));
  }, [editModalOpen, newModalOpen, editConnectorId, deletedConnectorId]);

  return (
    <div className={classes.root}>
      <div className={classes.buttons}>
        <Grid container justifyContent="space-between">
          <Grid item />
          <Grid item />
        </Grid>
      </div>
      {data && (
        <TableContainer
          component={Paper}
          elevation={0}
          variant="outlined"
          data-test="connector-table-container"
        >
          <Table aria-label="connector table" size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Solution</TableCell>
                <TableCell>Data Source</TableCell>
                <TableCell>Enabled</TableCell>
                <TableCell>Last Ran</TableCell>
                <TableCell>Table</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Run Every</TableCell>
                <TableCell
                  style={{ textAlign: "right" }}
                // className={`${classes.headerCell} ${classes.actionsCell}`}
                >
                  <Button
                    color="secondary"
                    variant="contained"
                    disableElevation
                    data-test="add-connector-button"
                    onClick={handleClickNewConnector}
                    startIcon={<AddIcon />}
                    size="small"
                  >
                    New Connector
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((connector) => {
                const connectorStats = stats?.find(
                  (el) => el.connector === connector.id
                );

                return (
                  <TableRow
                    key={connector.id}
                    data-test-connector-id={connector.id}
                  >
                    {connector.solution.logo_url &&
                      connector.solution.logo_url !== "" ? (
                      <TableCell align="right">
                        <img
                          src={connector.solution.logo_url}
                          width="80px"
                          // className={classes.logo}
                          alt={`${connector.solution.display_name} Logo`}
                        />{" "}
                      </TableCell>
                    ) : (
                      <TableCell>{connector.solution.display_name}</TableCell>
                    )}
                    {/* <span className={classes.solutionName}>
                       
                      </span> */}

                    <TableCell>
                      <img
                        src={connector.datasource.logo_url}
                        // className={classes.logo}
                        width="80px"
                        alt={`${connector.datasource.display_name} Logo`}
                      />
                    </TableCell>
                    {/* <TableCell>
                      {numberWithCommas(connectorStats?.asset_count)}
                    </TableCell> */}
                    {/* <TableCell>
                      {week
                        ? numberWithCommas(connectorStats?.weeks[week]) || "n/a"
                        : "n/a"}
                    </TableCell> */}
                    <TableCell
                      style={{
                        color: connector.is_enabled ? "green" : "black",
                      }}
                    >
                      {connector.is_enabled ? "Enabled" : "Disabled"}
                    </TableCell>
                    <TableCell>
                      {connectorStats?.date_last_run ? (
                        <>
                          <span>
                            {`${moment(connectorStats?.date_last_run)
                              .local()
                              .fromNow()} `}
                          </span>

                          <Typography
                            style={{ color: "#797979" }}
                            display="block"
                            variant="caption"
                          >
                            {connectorStats?.date_last_run}
                          </Typography>
                        </>
                      ) : (
                        "--"
                      )}
                    </TableCell>
                    <TableCell>{connector.table_name}</TableCell>
                    <TableCell>
                      {
                        connectorOptions?.kc_types?.find(
                          (el) => el.name === connector.kc_type
                        )?.display_name
                      }
                    </TableCell>
                    <TableCell>{connector.hours} hours</TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <IconButton
                        onClick={handleActionsMenuClick}
                        data-connectorid={connector.id}
                        data-test={`connector-action-menu-${connector.id}`}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <StyledModal
        open={warningAlert}
        onClose={handleClearWarning}
        title="Delete Connector"
        destructive
        submitDataTest="warning-delete-connector-ok"
        onSubmit={handleDeleteConnector}
      >
        <Typography variant="subtitle1">
          Are you sure you want to delete this connector?
        </Typography>
        <Typography variant="subtitle1">
          Telemetry data will no longer be ingested for this control.
        </Typography>
      </StyledModal>
      <ConnectorsMenu
        anchorEl={actionsAnchorEl}
        handleClose={handleActionsMenuClose}
        handleManage={handleActionsMenuManage}
        handleDelete={handleActionsMenuDelete}
      />
      <ConnectorDialog
        open={editModalOpen}
        handleClose={handleCloseEditDialog}
        connectorId={editConnectorId}
        refreshDropdowns={refreshDropdowns}
      />
      <ConnectorDialog
        open={newModalOpen}
        handleClose={handleCloseNewConnector}
        refreshDropdowns={refreshDropdowns}
      />
    </div>
  );
}
