import React from "react";
import { makeStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(() => ({
  root: {
    margin: "5px 0",
    width: "300px",
  },
  fieldContainer: {
    margin: "10px 0",
    width: "300px",
  },
  inputItem: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const directions = [
  {
    index: 0,
    value: 0,
    label: "Inbound",
  },
  {
    index: 1,
    value: 1,
    label: "Outbound",
  },
  {
    index: 2,
    value: 2,
    label: "Any Directionality",
  },
];

export default function DirectionDropdown({
  onChangeDirection,
  currentDirection,
  disabled,
}) {
  const classes = useStyles();

  const handleChange = (event) => {
    onChangeDirection(directions[event.target.value]);
  };

  return (
    <>
      <TextField
        value={currentDirection}
        disabled={disabled}
        onChange={handleChange}
        size="small"
        label="Traffic Orientation"
        variant="outlined"
        className={classes.fieldContainer}
        select
      >
        {directions.map((option, index) => (
          <MenuItem key={index} value={index}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
}
