import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { AssetPropType } from "../../utils/propTypes";

const useStyles = makeStyles((theme) => ({
  assetLink: {
    color: theme.palette.darkBlue,
    marginLeft: "8px",
    fontSize: "22px",
    cursor: "pointer",
    paddingRight: ".5rem",
  },
  assetName: {
    marginLeft: "8px",
    fontSize: "22px",
    paddingRight: ".5rem",
  },
}));

const isAssetASubnet = (asset) => {
  return (
    asset?.name?.endsWith("/8") ||
    asset?.name?.endsWith("/16") ||
    asset?.name?.endsWith("/24") ||
    asset?.ipv4?.endsWith("/8") ||
    asset?.ipv4?.endsWith("/24") ||
    asset?.ipv4?.endsWith("/24")
  );
};

const AssetHoverPopoverTitle = ({ asset, titleLink }) => {
  const classes = useStyles();

  // If we have asset ID then we can link to the resilience connections
  // and to the asset details page. If not,
  // just show the name/IP
  return (
    <>
      {isAssetASubnet(asset) ? (
        <Typography className={classes.assetName} component="h4" variant="h5">
          {asset.name || asset.ipv4}
        </Typography>
      ) : (
        <Link className={classes.assetLink} to={titleLink} target="_blank">
          {asset.name || asset.ipv4}
        </Link>
      )}
    </>
  );
};

AssetHoverPopoverTitle.propTypes = {
  asset: AssetPropType.isRequired,
};

export default AssetHoverPopoverTitle;
