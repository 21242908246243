import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Badge,
} from "@material-ui/core";
import AccordionSection from "./AccordionSection";

const useStyles = makeStyles(() => ({
  checkbox: {
    padding: ".25rem .25rem 0 0",
    margin: "0 0 .4rem .70rem",
  },
  showMoreBtn: {
    marginTop: ".2rem",
    width: "100%",
    textAlign: "left",
  },
}));

function CheckboxesFacet(props) {
  const classes = useStyles();
  const { facetOptions, facetName, onChange, clearCt, valuesAlreadyChecked } =
    props;
  const [facetOpts, setFacetOpts] = useState([]);
  const [checkCount, setCheckCount] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [showAll, setShowAll] = useState(true);
  // # checked checkboxes below the "show more" fold
  const [foldChecked, setFoldChecked] = useState(0);
  const [checkedValues, setCheckedValues] = useState([]);
  const facetLimit = 5;
  // const showAllCheckboxes = () => {
  //   setShowAll(!showAll);
  // };

  const updateCheckbox = (i, facet, value) => {
    let tempCheckCount = checkCount;
    let values = checkedValues;

    // if it was checked and is being unchecked
    if (facetOpts[i].checked === true) {
      // eliminate unchecked option from checkedValues list
      values = values.filter((v) => v !== value);
      setCheckedValues(values);

      // keep track of how many are checked
      setCheckCount(checkCount - 1);
      tempCheckCount -= 1;

      // display only first four + any checked below the "fold"
      if (i > facetLimit - 1) {
        setFoldChecked(foldChecked - 1);
      }
    } else {
      values.push(value);
      setCheckedValues(values);

      setCheckCount(checkCount + 1);
      tempCheckCount += 1;

      if (i > facetLimit - 1) {
        setFoldChecked(foldChecked + 1);
      }
    }

    // keep track of active/inactive so as not to include inactive facet in query string
    let isActive;
    if (tempCheckCount === 0) {
      isActive = false;
    } else {
      isActive = true;
    }

    // perform the actual checking/unchecking of the box!
    facetOpts[i].checked = !facetOpts[i].checked;

    // send the data on up the chain
    onChange(facet, values, isActive);
  };

  useEffect(() => {
    if (facetOptions) {
      const fO = facetOptions.map((option) => {
        return {
          name: option.display_name,
          value: option.value,
          checked: valuesAlreadyChecked
            ? valuesAlreadyChecked.includes(option.value.toString())
            : false,
        };
      });
      setCheckedValues(valuesAlreadyChecked || []);
      setCheckCount(valuesAlreadyChecked ? valuesAlreadyChecked.length : 0);
      setFacetOpts(fO);
    }
  }, [clearCt]);

  if (!facetOpts || facetOpts?.length === 0) {
    return <div>No options</div>;
  }

  return (
    <AccordionSection
      small
      title={
        <Badge badgeContent={checkCount} color="secondary" variant="dot">
          <Typography variant="body2">{props.title}</Typography>
        </Badge>
      }
      expanded={props.open || checkCount > 0}
      flat
    >
      <FormGroup>
        {facetOpts &&
          facetOpts.map((option, i) => (
            <div key={`${facetName}-${i}}`}>
              {((showAll === false && (i < facetLimit || option.checked)) ||
                showAll === true) && (
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      name={option.name}
                      size="small"
                      checked={option.checked}
                      onChange={() =>
                        updateCheckbox(
                          i,
                          facetName,
                          encodeURIComponent(option.value)
                        )
                      }
                    />
                  }
                  label={<Typography variant="body2">{option.name}</Typography>}
                />
              )}
            </div>
          ))}
      </FormGroup>
      {/* {facetOpts.length > facetLimit &&
        foldChecked < facetOpts.length - facetLimit && (
          <>
            {!showAll && (
              <Button
                onClick={showAllCheckboxes}
                size="small"
                style={{ justifyContent: "flex-start" }}
                className={classes.showMoreBtn}
              >
                show more
              </Button>
            )}
            {showAll && (
              <Button
                onClick={showAllCheckboxes}
                size="small"
                className={classes.showMoreBtn}
              >
                show less
              </Button>
            )}
          </>
        )} */}
    </AccordionSection>
  );
}

export default CheckboxesFacet;
