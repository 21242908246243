import {
  drag,
  forceLink,
  forceSimulation,
  forceManyBody,
  forceRadial,
  forceX,
  forceY,
  forceCollide,
  select,
  zoom,
  zoomIdentity,
  scaleLinear,
} from "d3";
import { svgIcons } from "../icons/Icons";

const oneHopRadius = 250;
const twoHopRadius = 500;
const linkStrokeColor = "#aaa";
const hopRingStrokeColor = "#eee";
const hopRingStrokeWidth = "2px";

export const nodeIcon = (d) => {
  let icon;
  if (d.asset_type) {
    icon = svgIcons[d.asset_type.toLowerCase()];
  }
  if (!icon) {
    icon = svgIcons.default;
  }
  return icon;
};

export const nodeSize = (d) => {
  if (d.asset_type === "internet") {
    return { size: 60, offset: 40 };
  }
  if (d.is_critical) {
    return { size: 15, offset: 24 };
  }
  return { size: 12, offset: 12 };
};

export const nodeRadius = (d) => {
  if (d.asset_type === "internet") {
    return 30;
  }
  if (d.is_critical) {
    return 15;
  }
  return 12;
};

export const nodeLabel = (d, showLabels, tagFilter) => {
  if (d.asset_type === "internet") {
    return "";
  }
  if (!showLabels && tagFilter && tagFilter.value) {
    return tagFilter.value;
  }
  if (d.name) {
    return d.name;
  }
  if (d.ipv4) {
    return d.ipv4;
  }
  return "";
};

export const linkOpacity = (d, highlightedIds) => {
  if (!highlightedIds || highlightedIds.length === 0) return 0.5;

  for (const highlighted of highlightedIds) {
    if (highlighted.id === d.source.id && highlighted.root) {
      return 0.5;
    }
    if (highlighted.id === d.target.id && highlighted.root) {
      return 0.5;
    }
  }

  return 0.1;
};

export const nodeOpacity = (d, highlightedIds) => {
  if (!highlightedIds || highlightedIds.length === 0) return 1.0;

  for (const highlighted of highlightedIds) {
    if (highlighted.id === d.id) {
      return 1.0;
    }
  }

  return 0.1;
};

export const hopDistance = (d, highlightedIds) => {
  // NOTE: this is confusing as it currently reads, I'm not sure what highlightIds have to do with
  // hopDistance
  if (!highlightedIds || highlightedIds.length === 0) return twoHopRadius;

  // otherwise loop through highlighted
  for (const node of highlightedIds) {
    if (node.id === d.id) {
      // if root, put in center, else, put in middle ring
      return node.root ? 0 : oneHopRadius;
    }
  }

  // if it's not a highlightedId, put in outer ring
  return twoHopRadius;
};

export const nodeColors = (d) => {
  const colorScale = scaleLinear()
    .domain([1, 8, 10])
    // kc bg-grey, orange, red
    .range(["#d1d9db", "#ed865b", "#ed1d35"]);

  if (d.is_critical) {
    return {
      fill: "url(#critical-fill-color)",
      stroke: colorScale(d.cvss_score),
    };
  }

  if (d.asset_type) {
    switch (d.asset_type) {
      case "internet": {
        return { fill: "#000", stroke: "#000" };
      }
      default: {
        return {
          fill: "url(#non-critical-fill-color)",
          stroke: colorScale(d.cvss_score),
        };
      }
    }
  }

  return { fill: "url(#non-critical-fill-color)" };
};

export const setupDrag = (simulation) => {
  let x;
  let y;

  function dragstarted(event, d) {
    if (!event.active) simulation.alphaTarget(0.7).restart();
    d.fx = d.x;
    d.fy = d.y;
    y = d.y;
    x = d.x;
  }

  function dragged(event, d) {
    d.fx = event.x;
    d.fy = event.y;
  }

  function dragended(event, d) {
    if (!event.active) simulation.alphaTarget(0);
    if (d.fx === x && d.fy === y) {
      d.fx = null;
      d.fy = null;
    }
  }

  return drag()
    .on("start", dragstarted)
    .on("drag", dragged)
    .on("end", dragended);
};

export const setupZoom = (dimensions) =>
  zoom()
    .extent([
      [0, 0],
      [dimensions.width, dimensions.height],
    ])
    .scaleExtent([0.1, 4]);

export const setupForceGraph = (
  svgRef,
  gRef,
  linksRef,
  clusterRef,
  legendRef
) => {
  const svgObj = select(svgRef.current);
  const gObj = select(gRef.current);
  const linksObj = select(linksRef.current);
  const clusterObj = select(clusterRef.current);
  const legendObj = select(legendRef.current);

  return {
    svgObj,
    gObj,
    linksObj,
    clusterObj,
    legendObj,
  };
};

export const setupSimulation = (
  svgObj,
  gObj,
  linkObj,
  clusterObj,
  dimensions,
  data,
  redrawCount,
  visScale,
  legendObj
) => {
  const zoom = setupZoom(dimensions);
  redrawCount = 0;
  // keep a tighter graph when the nodes are smaller and a wider graph when bigger
  const maxDistance = 800;
  const chargeStrength = -125;

  // cleanup use case-specific artifacts
  clusterObj.selectAll("*").remove();
  legendObj.selectAll("*").remove();

  const simulation = forceSimulation(data.nodes)
    .force(
      "charge",
      forceManyBody().strength(chargeStrength).distanceMax(maxDistance)
    )
    // .force("collision", forceCollide().radius( 3 ).strength(5).iterations(2))
    .force("x", forceX(dimensions.width / 2))
    .force("y", forceY(dimensions.height / 2));

  // add zoom capabilities
  function zoomed({ transform }) {
    gObj.attr("transform", transform);
    linkObj.attr("transform", transform);
    clusterObj.attr("transform", transform);
  }
  svgObj.call(zoom.on("zoom", zoomed)).on("dblclick.zoom", null);

  if (redrawCount === 0) {
    svgObj.transition().call(zoom.transform, zoomIdentity);
    setTimeout(() => {
      svgObj.transition().call(zoom.scaleBy, visScale);
    }, 1000);
  }
  return simulation;
};

export const setupForceNodes = (
  simulation,
  data,
  gObj,
  highlightedIds,
  showLabels,
  showPopover,
  hidePopover,
  handleDoubleClick,
  handleClick,
  selectedAsset,
  dimensions,
  tagFilter
) =>
  simulation.on("tick.nodes", () => {
    const extent = {
      xMin: 0,
      xMax: dimensions.width,
      yMin: 0,
      yMax: dimensions.height,
    };

    // commenting out for now in case we decide to add
    // simpler popovers in the future.
    const handleMouseOver = (event, asset) => {
      if (!showPopover || asset.asset_type === "internet") return;
      showPopover({
        data: {
          name: asset.hostname || asset.name || asset.ipv4,
          criticality: asset.criticality,
          is_critical: asset.is_critical,
          ipv4: asset.ipv4,
          summary: true,
        },
        trigger: event.target,
      });
    };

    const handleMouseOut = (event, asset) => {
      if (!hidePopover || asset.asset_type === "internet") return;
      hidePopover({ trigger: event.target });
    };

    gObj
      .selectAll(".resilience-node")
      .data(data.nodes)
      .join("circle")
      .attr("class", "resilience-node")
      .attr("opacity", (d) => nodeOpacity(d, highlightedIds))
      .attr("fill", (d) => nodeColors(d).fill)
      .attr("stroke", (d) => {
        return nodeColors(d).stroke;
      })
      .attr("stroke-width", 2)
      .attr("r", (d) => {
        if (selectedAsset && d.id === selectedAsset.id) {
          return nodeRadius(d) * 2;
        }
        return nodeRadius(d);
      })
      .attr("filter", (d) => {
        if (
          tagFilter &&
          d?.tags?.length &&
          d.tags.filter((tag) => {
            return (
              tag?.name === tagFilter?.name && tag?.value === tagFilter?.value
            );
          }).length
        ) {
          return "url(#tag-glow)";
        }

        return null;
      })
      .attr("cx", (d) => {
        extent.xMin = Math.min(extent.xMin, d.x);
        extent.xMax = Math.max(extent.xMax, d.x);
        return d.x;
      })
      .attr("cy", (d) => {
        extent.yMin = Math.min(extent.xMin, d.y);
        extent.yMax = Math.max(extent.xMax, d.y);

        return d.y;
      })
      .attr("cursor", "pointer")
      .on("mouseover", handleMouseOver)
      .on("mouseout", handleMouseOut)
      .on("dblclick", handleDoubleClick)
      .on("click", handleClick)
      .call(setupDrag(simulation));

    gObj
      .selectAll(".icon")
      .data(data.nodes)
      .join("svg:path")
      .attr("class", "icon")
      .attr("opacity", (d) => nodeOpacity(d, highlightedIds))
      .attr("fill", "#fff")
      .attr("d", (d) => nodeIcon(d))
      .attr("transform", (d) => {
        let { size } = nodeSize(d);
        if (selectedAsset && d.id === selectedAsset.id) {
          size *= 2;
        }

        return `translate(${d.x - size / 2}, ${d.y - size / 2}) scale(${
          size / 24
        })`;
      })
      .attr("pointer-events", "none")
      .call(setupDrag(simulation));

    if (showLabels || tagFilter) {
      gObj
        .selectAll(".label")
        .data(data.nodes)
        .join("text")
        .attr("class", "label")
        .attr("text-anchor", "middle")
        .attr("opacity", (d) => {
          const tagMatch = d?.tags?.filter(
            (t) => t?.name === tagFilter?.name && t?.value === tagFilter?.value
          )[0];
          if (!showLabels && !tagMatch) return 0.0;
          return nodeOpacity(d, highlightedIds);
        })
        .text((d) => {
          return nodeLabel(d);
        })
        .attr("transform", (d) => {
          let { size } = nodeSize(d);
          if (selectedAsset && d.id === selectedAsset.id) {
            size *= 2;
          }

          return `translate(${d.x - size / 2}, ${d.y - size / 2})`;
        })
        // Mouse actions on the label should apply as if they were on the node
        .on("mouseover", handleMouseOver)
        .on("mouseout", handleMouseOut)
        .on("dblclick", handleDoubleClick)
        .on("click", handleClick)
        .call(setupDrag(simulation));
    } else {
      gObj.selectAll(".label").data(data.nodes).remove();
    }
  });

export const setupForceLinks = (simulation, data, linkObj, highlightedIds) => {
  return simulation
    .force(
      "link",
      forceLink(data.links)
        .id((d) => d.id)
        .distance((d) => {
          if (
            d.source.asset_type === "internet" ||
            d.target.asset_type === "internet"
          ) {
            return 100;
          }
          if (d.source.is_critical && d.target.is_critical) {
            return 200;
          }
          return 50;
        })
    )
    .on("tick.links", () => {
      linkObj
        .selectAll(".link")
        .data(
          data.links.filter(
            (d) =>
              d.source.asset_type !== "limit" && d.target.asset_type !== "limit"
          )
        )
        .join("line")
        .attr("stroke", () => {
          return linkStrokeColor;
        })
        .attr("stroke-width", 1)
        .attr("stroke-opacity", (d) => linkOpacity(d, highlightedIds))
        .attr("class", "link")
        .attr("fill", "none")
        .attr("x1", (link) => {
          return link.source.x;
        })
        .attr("y1", (link) => {
          return link.source.y;
        })
        .attr("x2", (link) => {
          return link.target.x;
        })
        .attr("y2", (link) => {
          return link.target.y;
        });
    });
};

// settings for radial force graph
export const setupRadialForce = (
  simulation,
  oneHopIds,
  dimensions,
  clusterObj
) => {
  simulation
    // .force("charge", forceCollide().radius(10).iterations(2))
    .force("collision", forceCollide().radius(15).iterations(2))
    .force("link", null)
    .force("x", null)
    .force("y", null)
    .force(
      "r",
      forceRadial(
        // function to set the hopdistance
        // based on data from resilience
        (d) => hopDistance(d, oneHopIds),
        // width and height
        dimensions.width / 2,
        dimensions.height / 2
        // force strength
      ).strength(1)
    );

  clusterObj
    .insert("circle", ":first-child")
    .attr("id", "oneHopCircle")
    .attr("stroke", hopRingStrokeColor)
    .attr("stroke-width", hopRingStrokeWidth)
    .attr("fill", "transparent")
    .attr("r", oneHopRadius)
    .attr("cx", dimensions.width / 2)
    .attr("cy", dimensions.height / 2);

  clusterObj
    .insert("circle", ":first-child")
    .attr("id", "twoHopCircle")
    .attr("stroke", hopRingStrokeColor)
    .attr("stroke-width", hopRingStrokeWidth)
    .attr("fill", "transparent")
    .attr("r", twoHopRadius)
    .attr("cx", dimensions.width / 2)
    .attr("cy", dimensions.height / 2);
};

const clusterColor = "#ad95d0";

// settings for radial force graph
// for Application view
export const setupClusterForce = (
  simulation,
  data,
  clusterKey,
  clusterValue,
  dimensions,
  clusterObj,
  legendObj
) => {
  const appRadius = 75;

  // clean up before starting over
  legendObj.selectAll("*").remove();
  clusterObj.selectAll("*").remove();

  if (clusterKey && clusterValue) {
    updateApplicationCircle(
      data,
      clusterKey,
      clusterValue,
      dimensions,
      clusterObj
    );
  }

  simulation
    // give the forceManyBody a charge + strength
    .force("charge", forceManyBody().strength(-50))
    .force(
      "r",
      forceRadial(
        // function to set the hopdistance
        // based on data from resilience
        (d) => {
          // if (d) {
          //   hasData = true;
          // }
          if (d[clusterKey] === clusterValue) {
            return (appRadius * 10) / 9;
          }

          if (d.hops === 1) {
            // if (d.ipv4 !== "1.1.1.1") {
            //   hasOneHops = true;
            // }
            return (oneHopRadius * 10.2) / 9;
          }

          if (d.hops === 2) {
            // hasTwoHops = true;
            return (twoHopRadius * 10.5) / 9;
          }
          return (twoHopRadius * 10.5) / 9;
        },
        // width and height
        dimensions.width / 2,
        dimensions.height / 2
      )
        // radial strength keeps nodes
        // in a circle
        .strength(0.5)
    )
    .force(
      "link",
      forceLink()
        .id((d) => {
          return d.id;
        })
        // links strength.
        .strength(0.15)
    );
};

// based on coords ... get
// size of the blue ball???
const getSize = (coords) => {
  const distances = [];
  coords.map((i) => {
    return coords.map((j) => {
      const distance = Math.sqrt((j[0] - i[0]) ** 2 + (j[1] - i[1]) ** 2);
      return distances.push(distance);
    });
  });
  return Math.max(...distances);
};

// RESIZE BLUE APPLICATION CIRCLE DYNAMICALLY

const updateApplicationCircle = (
  data,
  clusterKey,
  clusterValue,
  dimensions,
  clusterObj
) => {
  // take data, nodes
  // put them together to create the coordinates
  // only if the cluster key matches the value
  const coordinates = [];
  data.nodes.map((d) => {
    if (d[clusterKey] === clusterValue) {
      coordinates.push([d.x, d.y]);
    }
    return false;
  });

  // get the radians of the blue ball
  const circleRadius = getSize(coordinates) / 2 + 24;

  // add blue circle
  clusterObj
    .insert("circle", ":first-child")
    .attr("id", "clusterCircle")
    .attr("stroke", clusterColor)
    .attr("stroke-width", "2px")
    .attr("fill", clusterColor)
    .attr("r", circleRadius)
    .attr("cx", dimensions.width / 2)
    .attr("cy", dimensions.height / 2);
};
