import React, { useState } from "react";
import {
  ButtonGroup,
  TextField,
  Grid,
  Button,
  Typography,
  Paper,
} from "@material-ui/core";
import { useApi } from "../API";

const ResetPasswordForm = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState();
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [confirmedError, setConfirmedError] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const api = useApi();

  const isValid = (string) => {
    return string.length >= 8 && string.length <= 128;
  };

  const resetPassword = async () => {
    setError(false);
    setLoading(true);

    try {
      await api.resetPassword(currentPassword, newPassword);
      await api.logout();
      window.location.href = "/login";
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
      setConfirmedError();
      setNewPasswordError();
      setConfirmedPassword("");
      setNewPassword("");
      setCurrentPassword("");
    }
  };
  if (error) {
    return <div>Error resetting password</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Paper variant="outlined" style={{ padding: "3em" }}>
      <Grid container direction="column" justifyContent="center" spacing={3}>
        <Grid item xs={3}>
          <Typography variant="subtitle1">Reset password</Typography>
          <Typography variant="body2">
            You will redirected to the login page.
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            type="password"
            value={currentPassword}
            onInput={(e) => {
              setCurrentPassword(e.target.value);
            }}
            variant="outlined"
            label="Current Password"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            value={newPassword}
            type="password"
            onInput={(e) => {
              setNewPassword(e.target.value);
              setNewPasswordError(!isValid(e.target.value));
            }}
            variant="outlined"
            label="New Password"
            error={newPasswordError}
            helperText="Must be at least 8 characters long"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            value={confirmedPassword}
            onInput={(e) => {
              setConfirmedPassword(e.target.value);
              setConfirmedError(e.target.value !== newPassword);
            }}
            variant="outlined"
            label="Confirm New Password"
            type="password"
            error={confirmedError}
          />
        </Grid>
        <Grid item>
          <ButtonGroup>
            <Button
              disableElevation
              disabled={
                confirmedError ||
                newPasswordError ||
                !currentPassword?.length ||
                !newPassword.length ||
                !confirmedPassword.length
              }
              color="secondary"
              onClick={resetPassword}
              variant="contained"
            >
              Reset Password
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ResetPasswordForm;
