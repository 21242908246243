import React, { useEffect, useState } from "react";
import { Typography, Grid, TextField, Link } from "@material-ui/core";
import { useApi } from "../API";
import { palette } from "../utils/theme";
import StyledModal from "../components/StyledModal";

const validateFields = (application) => {
  let valid = true;
  if (!application?.display_name?.trim()) {
    valid = false;
  }

  if (application?.annual_revenue) {
    if (Number.isNaN(parseFloat(application.annual_revenue))) {
      valid = false;
    }
  }

  return valid;
};

const EditModal = ({ application, open, onClose, toggleRefresh }) => {
  const api = useApi();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [editedApplication, setEditedApplication] = useState({});

  const handleEdit = async () => {
    setLoading(true);
    setError(false);
    try {
      await api.updateApplication(application.id, editedApplication);
      onClose();
      toggleRefresh();
    } catch (e) {
      setError(true);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setEditedApplication(application);
  }, [application]);

  return (
    <StyledModal
      open={open}
      title={`Edit Business Process: ${application?.display_name}`}
      onClose={() => {
        setEditedApplication({});
        setError(false);
        onClose();
      }}
      onSubmit={handleEdit}
      submitDisabled={!validateFields(editedApplication)}
    >
      <Grid container spacing={2} direction="column">
        {error && (
          <Typography variant="subtitle1" style={{ color: palette.red }}>
            Error editing Business Process.
          </Typography>
        )}
        <Grid item>
          <Typography variant="body2">
            Tip: add or remove assets from {application?.display_name} in the
            asset inventory by clicking on View Assets. Or go{" "}
            <Link
              color="secondary"
              href={`/app/inventory/assets?application_id=${application?.id}`}
            >
              here
            </Link>
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            label="Name"
            variant="outlined"
            helperText="Required"
            error={!editedApplication?.display_name?.trim()}
            value={editedApplication?.display_name}
            onChange={(e) =>
              setEditedApplication({
                ...editedApplication,
                display_name: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            label="Owner"
            variant="outlined"
            value={editedApplication?.owner}
            onChange={(e) =>
              setEditedApplication({
                ...editedApplication,
                owner: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            label="Annual Revenue"
            helperText="Must be a valid number"
            error={
              editedApplication?.annual_revenue &&
              Number.isNaN(parseFloat(editedApplication?.annual_revenue))
            }
            variant="outlined"
            value={editedApplication?.annual_revenue}
            onChange={(e) =>
              setEditedApplication({
                ...editedApplication,
                annual_revenue: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            label="Description"
            variant="outlined"
            multiline
            minRows={8}
            value={editedApplication?.description}
            onChange={(e) =>
              setEditedApplication({
                ...editedApplication,
                description: e.target.value,
              })
            }
          />
        </Grid>
      </Grid>
    </StyledModal>
  );
};

export default EditModal;
