import React from "react";
import Grid from "@material-ui/core/Grid";
import AlertsOverTimeBar from "./AlertsOverTime";
import TrendsLineCharts from "./TrendsReportingCharts";

function SecOpsSection() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container item spacing={3}>
          <Grid item xs={12}>
            <AlertsOverTimeBar
              metricNames={[
                "total_with_alerts_low_sev_high_impact",
                "total_with_alerts_med_sev_high_impact",
                "total_with_alerts_high_sev_high_impact",
                "total_with_alerts_crit_sev_high_impact",
              ]}
              title="High Impact Assets with Alerts (by Alert Severity)"
            />
          </Grid>
          <Grid item xs={12}>
            <AlertsOverTimeBar
              metricNames={[
                "total_with_alerts_low_sev",
                "total_with_alerts_med_sev",
                "total_with_alerts_high_sev",
                "total_with_alerts_crit_sev",
              ]}
              title="Assets with Alerts (by Alert Severity)"
            />
          </Grid>
        </Grid>
      </Grid>
      <TrendsLineCharts sectionName="secops" />
    </Grid>
  );
}

export default SecOpsSection;
