import React from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@material-ui/core";

function Icon(props) {
  return (
    <SvgIcon htmlColor={props.foreground}>
      <path d={props.icon} />
    </SvgIcon>
  );
}

export default Icon;

Icon.propTypes = {
  foreground: PropTypes.string,
  icon: PropTypes.string,
};

Icon.defaultProps = {
  foreground: "white",
};
