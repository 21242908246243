import React, { useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Bubble from "../crownJewels/Bubble";
import AssetHoverPopover from "../components/AssetHoverPopover/AssetHoverPopover";
import {
  HoverPopoverProvider,
  useHoverPopover,
} from "../components/HoverPopover";

const useStyles = makeStyles((theme) => ({
  root: theme.layout.root,
  content: theme.layout.content,
  contentWrapper: theme.layout.contentWrapper,
  bubbleMapWrapper: {
    width: "100%",
    minHeight: (props) =>
      props.hideAssetTable === true ? "none" : "calc(100vh - 13vh)",
    padding: theme.spacing(2, 2),
    justifyContent: (props) =>
      props.hideAssetTable === true ? "center" : "none",
  },
  buttonbar: {
    position: "relative",
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    // this is a hack to get the vertical spacing right on the body components
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  secondaryNav: theme.layout.secondaryNav,
  tabPanel: {
    background: theme.palette.background,
  },
  secondaryNavToolbar: {
    "& > *": {
      margin: `0 ${theme.spacing(1)}px`,
    },
    marginTop: "-8px",
  },
  paper: {
    display: "flex",
  },
  modalPaper: {
    width: "calc(100% - 32px)",
    maxWidth: "1400px",
    margin: "100px auto",
    display: "block",
  },
}));

export const baseRoute = "/app";

function SubnetsInner(props) {
  const classes = useStyles({ hideAssetTable: props.hideAssetTable });
  const parentRef = useRef(null);
  const { showPopover, hidePopover } = useHoverPopover();

  const reload = 0;
  const groups = [
    {
      id: 0,
      name: "Grouping",
      options: ["subnet", "asset_type"],
      selected: "subnet",
    },
  ];

  const filters = useState([
    { id: 0, name: "High Impact Asset", type: "Checkbox", value: false },
    { id: 1, name: "Other Asset", type: "Checkbox", value: false },
    {
      id: 2,
      name: "Impact Score",
      type: "Range",
      options: [0, 100],
      value: [0, 100],
    },
    {
      id: 3,
      name: "Asset Type",
      type: "Dropdown",
      options: [
        "All Types",
        "SCADA",
        "Database",
        "Webserver",
        "Workstation",
        "Other",
      ],
      selected: "All Types",
    },
  ]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      p={1}
      m={1}
      ref={parentRef}
      className={classes.bubbleMapWrapper}
    >
      <AssetHoverPopover showPopover={showPopover} hidePopover={hidePopover} />
      <Bubble
        groups={groups}
        filters={filters}
        parentRef={parentRef}
        reload={reload}
        hidePopover={hidePopover}
        hideAssetTable={props.hideAssetTable}
        showPopover={showPopover}
      />
    </Box>
  );
}

const Subnets = (props) => {
  return (
    <HoverPopoverProvider>
      <SubnetsInner
        redrawTopBar={props.redrawTopBar}
        hideAssetTable={props.hideAssetTable}
      />
    </HoverPopoverProvider>
  );
};

export default Subnets;
