import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ToastContext from "../context/ToastContext";
import { theme } from "../utils/theme";

function Toast(props) {
  const [close, setClose] = useState(true);
  const [scrolled, setScrolled] = useState(false);
  let headerHeight;

  if (props.position === "hybrid") {
    headerHeight = 109;
  } else if (props.position === "no-nav") {
    headerHeight = 51;
  }

  function handleScroll() {
    if (window.pageYOffset > headerHeight) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }

  useEffect(() => {
    handleScroll();
    window.onscroll = () => {
      handleScroll();
    };
  }, []);

  let bg;
  let color = "white";

  if (props.type === "success") {
    bg = theme.palette.green;
    color = "white";
  } else if (props.type === "error") {
    bg = theme.palette.red;
  } else if (props.type === "info") {
    bg = theme.palette.keyBlue;
  } else if (props.type === "warning") {
    bg = theme.palette.yellow;
  }

  const useStyles = makeStyles((theme) => ({
    toast: {
      position: scrolled ? "fixed" : "absolute",
      zIndex: 1299,
      top: scrolled ? 0 : `${headerHeight}px`,
      left: `0`,
      width: "100%",
      height: "58px",
      background: theme.palette.keyBlue,
      color,
      cursor: "pointer",
      display: "flex",
      transition: ".2s opacity ease-in-out",
    },
    butter: {
      width: "100%",
      height: "100%",
      position: "absolute",
      backgroundColor: bg,
      color: "white",
      zIndex: 0,
      opacity: 1,
      transition: "opacity .4s ease-out",
    },
    jam: {
      width: "100%",
      height: "100%",
      position: "absolute",
      backgroundColor: bg,
      color: "white",
      opacity: ".75",
      zIndex: 0,
      transition: "opacity .5s ease-in",
    },
    spread: {
      opacity: 0,
    },
    close: {
      opacity: 0,
    },
    crust: {
      position: "relative",
      zIndex: 1,
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    clearIconWrapper: {
      position: "absolute",
      right: "0",
      top: "0",
      paddingRight: "22px",
      height: "100%",
      display: "flex",
      alignItems: "center",
      zIndex: 1,
    },
    clearIcon: {},
  }));

  const classes = useStyles();

  // Use Imported ToastContext
  const toastContext = useContext(ToastContext);

  function handleClose() {
    toastContext.showToast("close");
  }

  useEffect(() => {
    setClose(false);
    setTimeout(() => toastContext.showToast("close"), 8000);
  }, []);

  return (
    <Box
      className={`${classes.toast} ${close && classes.close}`}
      onClick={handleClose}
      data-test="toast-message"
    >
      <div className={`${classes.butter} ${close && classes.spread}`} />
      <div className={`${classes.jam} ${close && classes.spread}`} />
      <div className={classes.crust}>
        {props.type === "success" && (
          <CheckIcon style={{ marginRight: "8px" }} />
        )}
        {props.type === "error" && <CloseIcon style={{ marginRight: "8px" }} />}
        {props.type === "warn" && (
          <WarningIcon style={{ marginRight: "8px" }} />
        )}
        <Typography variant="subtitle2">{props.msg}</Typography>
      </div>
      <div className={classes.clearIconWrapper}>
        <ClearIcon
          className={classes.clearIcon}
          data-test="toast-message-clear-button"
        />
      </div>
    </Box>
  );
}

export default Toast;
