import React from "react";

export default function FilterGlow({ id, color }) {
  return (
    <filter id={id} height="300%" width="300%" x="-150%" y="-150%">
      {/* <!-- Thicken out the original shape --> */}
      <feMorphology
        operator="dilate"
        radius="3"
        in="SourceAlpha"
        result="thicken"
      />
      {/* <!-- Use a gaussian blur to create the soft blurriness of the glow --> */}
      <feGaussianBlur in="thicken" stdDeviation="3" result="blurred" />
      {/* <!-- Change the colour --> */}
      <feFlood floodColor={color} result="glowColor" />
      {/* <!-- Color in the glows --> */}
      <feComposite
        in="glowColor"
        in2="blurred"
        operator="in"
        result="softGlow_colored"
      />
      {/* <!--	Layer the effects together --> */}
      <feMerge>
        <feMergeNode in="softGlow_colored" />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
  );
}
