import React from "react";
import HoverPopover, { useHoverPopover } from "../HoverPopover";
import AssetHoverPopoverBody from "./AssetHoverPopoverBody";

const AssetHoverPopover = (props) => {
  const { data: asset } = useHoverPopover();

  // This component may be rendered even when no asset is hovered, in that case
  // we don't want to show anything
  if (!asset) {
    return null;
  }

  // The module the AssetHoverPopover is in will dictate how the card title link is built
  let { module } = props;
  if (!module) {
    module = "inventory";
  }

  // TURN OFF TOOLTIPS FOR ANYTHING OTHER THAN AN ASSET AT THIS TIME
  // we can tell it is not just an asset because it has children and is likely a subnet in the
  // bubble graph
  if (typeof asset.children !== "undefined") {
    return null;
  }

  return (
    <HoverPopover {...props}>
      <AssetHoverPopoverBody asset={asset} module={module} />
    </HoverPopover>
  );
};

export default AssetHoverPopover;
