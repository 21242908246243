import React, { useMemo } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withOktaAuth } from "@okta/okta-react";
import Cookies from "universal-cookie";
import API, { APIContext } from "./API";
import SPA from "./SPA";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
});

const Home = (props) => {
  const cookies = new Cookies();

  const api = useMemo(() => {
    if (props.authType === "okta") {
      return new API(props.authState.accessToken.accessToken);
    }

    const authToken = cookies.get("jwt_auth_token");
    if (authToken === undefined) {
      window.location.href = "/login";
    }

    return new API(authToken);
  }, []);

  return (
    <APIContext.Provider value={api}>
      <div>
        <SPA />
      </div>
    </APIContext.Provider>
  );
};

export default withStyles(styles)(withOktaAuth(Home));
