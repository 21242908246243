import React, { createContext, useState } from "react";

export const ResFilterContext = createContext(null);

const ResProvider = ({ children }) => {
  const [filters, setFilters] = useState([
    {
      id: 0,
      name: "MCA ID",
      type: "Dropdown",
      options: [],
      allOption: true,
      selected: null,
    },
    {
      id: 1,
      name: "Subnet",
      type: "Dropdown",
      options: ["255.255.255.255", "255.255.255.0", "255.255.0.0", "255.0.0.0"],
      selected: "255.255.0.0",
    },
  ]);

  const changeFilters = (id, selected, res) => {
    const state = [];
    // console.log('changeFilters', res)
    // push new filters to state array
    filters.forEach((filter) => {
      const nf = { ...filter };
      if (id === filter.id) {
        nf.selected = selected;
      }
      state.push({ ...nf });
    });
    const nf = [{ ...state[0], options: res }, state[1]];
    // console.log('nf', nf);
    setFilters(nf);
  };

  return (
    <ResFilterContext.Provider value={{ filters, changeFilters }}>
      {children}
    </ResFilterContext.Provider>
  );
};

export default ResProvider;
