import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { numberWithCommas } from "../utils";
import { useApi } from "../API";

const TopBar = (props) => {
  const api = useApi();
  const [data, setData] = useState([
    ["Network", "", "topbar-company-name"],
    ["High Impact", "", "topbar-total-high-value-assets"],
    ["Active", "", "topbar-total-active-assets"],
    ["Total", "", "topbar-total-assets"],
  ]);

  useEffect(() => {
    api.settingsNavbar().then((res) => {
      const newData = [...data];
      newData[0][1] = res.name;
      newData[1][1] = res.num_critical;
      newData[2][1] = res.num_active;
      newData[3][1] = res.num_total;

      setData(newData);
    });
  }, [props.redraw]);

  return (
    <>
      {data.map((obj, i) => {
        if (i === 0) {
          return <></>;
        }
        return (
          <Grid
            container
            spacing={1}
            key={i}
            xs={3}
            direction="column"
            item
            justifyContent="space-around"
          >
            <Grid item>
              <Typography
                variant="body1"
                style={{ color: "#EEEEEE", fontSize: "small" }}
              >
                <strong>
                  {obj[2] === "topbar-company-name"
                    ? obj[1] || "N/A"
                    : numberWithCommas(obj[1])}
                </strong>{" "}
                {obj[0]}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default TopBar;
