import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(() => ({
  small: {
    margin: "0 !important",
  },
  default: {},
}));

const AccordionSection = (props) => {
  const [open, setOpen] = useState(props.expanded);
  const classes = useStyles();
  useEffect(() => {
    setOpen(props.expanded);
  }, [props.expanded]);

  return (
    <Accordion
      classes={{
        expanded: props.small ? classes.small : classes.default,
      }}
      TransitionProps={{ timeout: 250 }}
      expanded={open}
      onChange={() => {
        setOpen(!open);
      }}
      elevation={props.flat ? 0 : 3}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {props.title.children ? (
          props.title
        ) : (
          <Typography variant={props.titleVariant || "h6"}>
            {props.title}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails style={props.noFlex && { display: "block" }}>
        {props.children || props.content}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionSection;
