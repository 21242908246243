import React from "react";
import Grid from "@material-ui/core/Grid";
import AlertsOverTimeBar from "./AlertsOverTime";
import TrendsLineCharts from "./TrendsReportingCharts";

function VulnSection() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <AlertsOverTimeBar
                vuln
                metricNames={[
                  "total_with_vulns_low_sev_high_impact",
                  "total_with_vulns_med_sev_high_impact",
                  "total_with_vulns_high_sev_high_impact",
                  "total_with_vulns_crit_sev_high_impact",
                ]}
                title="High Impact Assets with Vulnerabilities (by Severity)"
              />
            </Grid>
            <Grid item xs={12}>
              <AlertsOverTimeBar
                vuln
                metricNames={[
                  "total_with_vulns_low_sev",
                  "total_with_vulns_med_sev",
                  "total_with_vulns_high_sev",
                  "total_with_vulns_crit_sev",
                ]}
                title="Assets with Vulnerabilities (by Severity)"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TrendsLineCharts sectionName="vulnMgmt" />
    </Grid>
  );
}

export default VulnSection;
