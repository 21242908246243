import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import ToastContext from "../../context/ToastContext";
import { useApi } from "../../API";
import StyledModal from "../../components/StyledModal";

export default function DatasourceDialog({
  datasourceId,
  handleClose,
  handleDatasourcesChanged,
  open,
}) {
  const [data, setData] = useState(null);
  const [datasourceData, setDatasourceData] = useState({});
  const [validDatasources, setValidDatasources] = useState([]);
  const api = useApi();
  const toastContext = useContext(ToastContext);
  const dialogTitleText = datasourceId ? "Edit Data Source" : "New Data Source";

  const handleClearValue = (name) => {
    const val =
      name === "credential_key"
        ? "credential_key_exists"
        : name === "credential_secret"
        ? "credential_secret_exists"
        : "";
    setDatasourceData({
      ...datasourceData,
      [val]: false,
      [name]: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedFields = {
      [name]: value,
    };
    if (name === "name") {
      updatedFields = validDatasources.find((el) => el.name === value);
    }

    setDatasourceData({
      ...datasourceData,
      ...updatedFields,
    });
  };

  const onClose = () => {
    setDatasourceData({});
    handleClose();
  };
  useEffect(() => {
    if (!data) {
      return;
    }
    setDatasourceData(data);
  }, [data]);

  const handleSaveDatasource = () => {
    const id = datasourceData.id ? datasourceData.id : "new";

    api.upsertDatasource(id, datasourceData).then((res) => {
      if (res.status === "OK") {
        handleDatasourcesChanged();
        onClose();
        toastContext.showToast(
          "open",
          "success",
          "Data Source has been saved."
        );
      } else {
        onClose();
        toastContext.showToast(
          "open",
          "error",
          "There was an error saving the configuration. Please check and try again."
        );
      }
    });
  };

  useEffect(() => {
    api
      .getConnectorOptions()
      .then((resp) => {
        setValidDatasources(resp?.datasources);
      })
      .catch((error) => console.log(error));
  }, []);
  useEffect(() => {
    if (!datasourceId || datasourceId < 1) {
      return;
    }

    api
      .getDatasource(datasourceId)
      .then((response) => {
        setData(response);
      })
      .catch((error) => console.log(error));
  }, [datasourceId]);

  return (
    <StyledModal
      onClose={onClose}
      open={open}
      title={dialogTitleText}
      onSubmit={handleSaveDatasource}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography gutterBottom>
            A datasource represents where the telemetry data is coming from.
            This can be a remote file server, a SIEM or the security solution
            itself if it has a REST API for querying data.
          </Typography>
        </Grid>
        {!datasourceData.id && (
          <Grid item>
            {/* <Autocomplete
              id="combo-box-demo"
              options={validDatasources.sort((a, b) => a.display_name > b.display_name ? 1 : -1)}
              getOptionLabel={(option) => option.display_name}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Data Source" variant="outlined" />}
            /> */}

            <TextField
              select
              id="outlined-select-currency-native"
              name="name"
              label="Data Source"
              value={datasourceData.name}
              fullWidth
              style={{ width: "100%" }}
              onChange={handleInputChange}
              variant="outlined"
            >
              {validDatasources
                .sort((a, b) => (a.display_name > b.display_name ? 1 : -1))
                .map((option, idx) => (
                  <MenuItem key={idx} value={option.name}>
                    {option.display_name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
        )}
        <Grid item>
          <TextField
            label="Base URL or Bucket Name"
            name="base_url"
            onChange={handleInputChange}
            variant="outlined"
            value={datasourceData.base_url || ""}
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            disabled={datasourceData.credential_key_exists}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClearValue("credential_key")}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {datasourceData.credential_key_exists && <ClearIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            name="credential_key"
            onChange={handleInputChange}
            variant="outlined"
            type="password"
            label="API Key"
            autoComplete={false}
            value={
              datasourceData.credential_key_exists
                ? "**********"
                : datasourceData.credential_key || ""
            }
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            disabled={datasourceData.credential_secret_exists}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClearValue("credential_secret")}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {datasourceData.credential_secret_exists && <ClearIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            name="credential_secret"
            onChange={handleInputChange}
            variant="outlined"
            type="password"
            label="API Secret"
            autoComplete={false}
            value={
              datasourceData.credential_secret_exists
                ? "**********"
                : datasourceData.credential_secret || ""
            }
            fullWidth
          />
        </Grid>
      </Grid>
    </StyledModal>
  );
}
