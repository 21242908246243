import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function RiskIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        className="cls-1"
        d="M3.94,15.33v.12H2.52A.52.52,0,0,1,2,14.92,10,10,0,0,1,17.3,7a.5.5,0,0,1,0,.83l-.74.53a.48.48,0,0,1-.54,0,8.07,8.07,0,0,0-12.09,7Zm18-.41A9.92,9.92,0,0,0,19.5,8.84a.49.49,0,0,0-.77,0l-.52.71a.5.5,0,0,0,0,.62,8,8,0,0,1,1.83,5.12v.12h1.42A.52.52,0,0,0,22,14.92Zm-3.6-7.23h-.07l-6.13,4.39H12a2.17,2.17,0,1,0,2.16,2.16v-.1c1.4-1.86,4.33-5.75,4.54-6.12C18.74,7.73,18.51,7.69,18.39,7.69Z"
      />
    </SvgIcon>
  );
}

export default RiskIcon;
