import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  ButtonGroup,
  TextField,
  Grid,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { useApi } from "../API";
import { palette } from "../utils/theme";
import { emailIsValid } from "../utils";
import StyledModal from "../components/StyledModal";

const Users = () => {
  const api = useApi();
  const [newUserEmailError, setNewUserEmailError] = useState(false);
  const [users, setUsers] = useState({ loading: true, error: false, data: [] });
  const [newUser, setNewUser] = useState({
    loading: false,
    error: false,
    data: "",
  });
  const [deleteUser, setDeleteUser] = useState({
    loading: false,
    error: false,
    data: "",
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState({});
  const [reloadUsers, setReloadUsers] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [confiredEmail, setConfirmedEmail] = useState("");

  const addUser = async () => {
    setNewUser({ ...newUser, loading: true, error: false });
    try {
      await api.inviteUser(newUserEmail);
      setNewUser({
        loading: false,
        error: false,
        data: "Successfully added user.",
      });
      setAddUserModalOpen(false);
      setReloadUsers(!reloadUsers);
    } catch (e) {
      setNewUser({ loading: false, error: true, data: "Error adding user" });
    } finally {
      setNewUserEmail("");
      setConfirmedEmail("");
      setNewUserEmailError(false);
    }
  };

  const handleDeleteUser = async (userId) => {
    setDeleteUser({ ...deleteUser, loading: true, error: false });
    try {
      await api.deleteUser(userId);
      setDeleteUser({
        loading: false,
        error: false,
        data: "Successfully deleted user.",
      });
      setUserToDelete({});
      setDeleteModalOpen(false);
      setReloadUsers(!reloadUsers);
    } catch (e) {
      console.log("delete error is", e);
      setDeleteUser({
        loading: false,
        error: true,
        data: "Error deleting user",
      });
    }
  };

  useEffect(async () => {
    setUsers({ ...users, loading: true, error: false });
    try {
      const data = await api.getUsers();
      setUsers({ loading: false, error: false, data });
    } catch (e) {
      setUsers({ loading: false, error: true, data: [] });
    }
  }, [reloadUsers]);

  const handleOpenDeleteModal = (user) => {
    setUserToDelete(user);
    setDeleteModalOpen(true);
  };

  if (users.loading) {
    return <div>Loading...</div>;
  }

  if (users.error) {
    return <div>Error</div>;
  }

  return (
    <div>
      <Paper variant="outlined">
        <TableContainer>
          <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="5%" />
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Account Created</TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  <Button
                    startIcon={<AddIcon />}
                    variant="contained"
                    color="secondary"
                    size="small"
                    disableElevation
                    onClick={() => {
                      setAddUserModalOpen(true);
                    }}
                  >
                    New User
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.data.map((user, idx) => (
                <TableRow key={idx}>
                  <TableCell width="5%">
                    <Avatar>
                      <PersonIcon style={{ color: "white" }} />
                    </Avatar>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">
                      {" "}
                      {user.username}
                    </Typography>
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.reg_date}</TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    <ButtonGroup>
                      <IconButton
                        onClick={() => {
                          handleOpenDeleteModal(user);
                        }}
                      >
                        <CloseIcon style={{ color: palette.red }} />
                      </IconButton>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <StyledModal
          open={deleteModalOpen}
          title="Delete User"
          onClose={() => {
            setDeleteUser({
              loading: false,
              error: false,
              data: "",
            });
            setUserToDelete({});
            setDeleteModalOpen(false);
          }}
          onClick={(e) => {
            e.preventDefault();
            handleDeleteUser(userToDelete.id);
          }}
          destructive
          onSubmit={(e) => {
            e.preventDefault();
            handleDeleteUser(userToDelete.id);
          }}
          submitDisabled={confiredEmail !== newUserEmail || newUserEmailError}
        >
          <Grid container spacing={2} direction="column">
            {deleteUser.error && (
              <Typography style={{ color: "red" }}>
                There was an error deleting the user.
              </Typography>
            )}
            {deleteUser.loading && <Typography>Loading...</Typography>}
            <Grid item>
              <Typography variant="subtitle1">
                Are you sure you want to delete{" "}
                <strong>{userToDelete.username}</strong>?
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                This user will lose all access to KeyCaliber and must be
                re-invted to join again.
              </Typography>
            </Grid>
          </Grid>
        </StyledModal>
        <StyledModal
          open={addUserModalOpen}
          title="Invite a New User to KeyCaliber"
          onClose={() => {
            setNewUser({
              loading: false,
              error: false,
              data: "",
            });
            setNewUserEmail("");
            setConfirmedEmail("");
            setNewUserEmailError(false);
            setAddUserModalOpen(false);
          }}
          onSubmit={addUser}
          submitDisabled={confiredEmail !== newUserEmail || newUserEmailError}
        >
          <Grid container spacing={2} direction="column">
            {newUser.error && (
              <Typography style={{ color: "red" }}>
                There was an error adding the user.
              </Typography>
            )}
            {newUser.loading && <Typography>Loading...</Typography>}
            <Grid item>
              <TextField
                fullWidth
                value={newUserEmail}
                onInput={(e) => {
                  setNewUserEmailError(!emailIsValid(e.target.value));
                  setNewUserEmail(e.target.value);
                }}
                variant="outlined"
                label="New User Email"
                error={newUserEmailError}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                value={confiredEmail}
                onInput={(e) => {
                  setConfirmedEmail(e.target.value);
                }}
                variant="outlined"
                error={confiredEmail !== newUserEmail}
                label="Re-type New User Email"
              />
            </Grid>
          </Grid>
        </StyledModal>
      </Paper>
    </div>
  );
};

export default Users;
