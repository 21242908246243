import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import logo from "./assets/logo.svg";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorInfo: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <AppBar position="absolute">
            {/* Logo bar */}
            <Toolbar
              style={{
                minHeight: "3.25rem",
                height: "3.25rem",
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#383D3A",
              }}
            >
              <a href="/app" style={{ height: "23px", marginLeft: ".3em" }}>
                <img height="23px" src={logo} alt="Logo" />
              </a>
            </Toolbar>
          </AppBar>

          <div
            style={{
              height: "400px",
              background: "#fff",
              margin: "3em",
              marginTop: "9em",
            }}
          >
            <h4
              style={{
                textAlign: "center",
                fontWeight: 100,
                fontSize: "30px",
                color: "black",
              }}
            >
              Looks like there was an error. If the problem persists, email{" "}
              <b>techservices [at] keycaliber.com</b>
            </h4>
          </div>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
