import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Checkbox,
  FormControlLabel,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import Close from "@material-ui/icons/Close";
import { useApi } from "../API";

const useStyles = makeStyles(() => ({
  fieldContainer: {
    margin: "5px",
    width: "300px",
  },
  selectMenu: {
    minWidth: "200px",
    marginLeft: "48px",
  },
}));

export default function NetworkInteractions({
  onUpdateHighlight,
  onUpdateHighlightTemp,
  highlightTemp,
  useCase,
  disabled,
  toggleHighlightByTag,
  assetFilter,
  highlightByTag,
  tagNameChanged,
  tagValueChanged,
  toggleHopsMode,
  hopsMode,
  toggleShowLabels,
  handleClearHighlightClick,
}) {
  const api = useApi();
  const classes = useStyles();

  const [allTags, setAllTags] = useState(undefined);
  const [currTag, setCurrTag] = useState(undefined);
  const [currTagValues, setCurrTagValues] = useState(undefined);

  const handleHighlightChange = (event) => {
    onUpdateHighlightTemp(event.target.value);
  };

  const handleHighlightKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onUpdateHighlight(event.target.value);
    }
  };

  const valueChanged = (tag, value) => {
    tagValueChanged(tag, value);
  };

  const tagTypeChanged = (e) => {
    tagNameChanged(e.target.value);
    const tagId = allTags.filter((tag) => tag.name === e.target.value)[0].id;
    setCurrTag(tagId);
    api
      .tagValues(tagId)
      .then((res) => {
        setCurrTagValues(res);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    api.allTags().then((res) => {
      setAllTags(res);
    });
  }, []);

  return (
    <>
      <FormControlLabel
        disabled={disabled}
        control={
          <Checkbox
            onChange={toggleHighlightByTag}
            checked={highlightByTag === true}
            color="primary"
          />
        }
        label="Highlight By Tag"
        className={classes.fieldContainer}
      />

      {highlightByTag && (
        <>
          <Box mb={1}>
            <Select
              variant="outlined"
              className={classes.selectMenu}
              id="tagTypeSelect"
              placeholder="Select a Tag Type"
              onChange={(tag) => tagTypeChanged(tag)}
            >
              {allTags.map((tag) => (
                <MenuItem key={tag.id} value={tag.name}>
                  {tag.display_name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {currTag && currTagValues && (
            <Select
              variant="outlined"
              className={classes.selectMenu}
              id="tagValuesSelect"
              placeholder="Select a Tag Value"
              onChange={(currTag, val) =>
                valueChanged(currTag.target.value, val)
              }
            >
              {currTagValues.map((val) => (
                <MenuItem key={val.id} value={val.value}>
                  {val.value}
                </MenuItem>
              ))}
            </Select>
          )}
        </>
      )}
      {useCase.value === "asset-connections" && (
        <FormControlLabel
          disabled={disabled || !assetFilter}
          control={
            <Checkbox
              onChange={toggleHopsMode}
              checked={!!assetFilter && hopsMode === true}
              color="primary"
            />
          }
          label="Show Distance in Hops"
          className={classes.fieldContainer}
        />
      )}
      {useCase.value !== "critical-connection-levels" && (
        <FormControlLabel
          disabled={disabled}
          control={<Checkbox onChange={toggleShowLabels} color="primary" />}
          label="Show Labels"
          className={classes.fieldContainer}
        />
      )}

      {useCase.value !== "critical-connection-levels" && (
        <TextField
          disabled={disabled}
          onChange={handleHighlightChange}
          onKeyPress={handleHighlightKeyPress}
          value={highlightTemp || ""}
          label="Asset Hostname or IPv4"
          variant="outlined"
          size="small"
          className={classes.fieldContainer}
          helperText="Press enter to highlight an asset"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={disabled}
                  size="small"
                  onClick={handleClearHighlightClick}
                >
                  <Close />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    </>
  );
}
