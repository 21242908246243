import React from "react";
import Grid from "@material-ui/core/Grid";
import TrendsLineCharts from "../TrendsReportingCharts";

function DataGovernanceMetricsSection() {
  return (
    <Grid container spacing={2}>
      <TrendsLineCharts sectionName="dataGov" />
    </Grid>
  );
}

export default DataGovernanceMetricsSection;
