import React from "react";
import Button from "@material-ui/core/Button";
import { withOktaAuth } from "@okta/okta-react";
import { Redirect } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import logo from "../assets/logo.svg";

class OktaAuth extends React.Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
  }

  async login() {
    this.props.oktaAuth.signInWithRedirect({ originalUri: "/app" });
  }

  render() {
    if (this.props.authState.isAuthenticated) {
      return <Redirect to="/app" />;
    }

    return (
      <div>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" edge="start" />
            <img height="23px" src={logo} alt="Logo" />
          </Toolbar>
        </AppBar>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          spacing={2}
        >
          <Grid item xs={12}>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              spacing={2}
            >
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onKeyPress={this.login}
                  onClick={this.login}
                >
                  Login with Okta
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withOktaAuth(OktaAuth);
