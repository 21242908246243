import React, { useState } from "react";
import { Typography, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import AssetIcon from "./components/AssetIcon";
import { useApi } from "./API";

const useStyles = makeStyles(() => ({
  searchbg: {
    position: "absolute",
    width: "80%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    padding: "8px 8px 8px 20px",
    borderRadius: "4px",
  },
  hideTitle: {
    position: "absolute",
    width: 0,
    height: 0,
    overflow: "hidden",
  },
  searchField: {
    flexGrow: 1,
    width: "100%",
    fontSize: "48px",
    borderBottom: "none",
    padding: "1rem",
    background: "transparent",
  },
  searchIcon: {
    marginRight: ".5rem",
  },
  inputItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  assetIcon: {
    marginRight: "10px",
  },
}));

const pages = [
  "/",
  "/app",
  "/home",
  "/reports",
  "/resilience",
  "/inventory",
  "/upload",
  "/settings",
];

const demoPages = ["/risk", "/risk/assets"];

function getModalStyle() {
  const top = 12;
  const left = 10;

  return {
    top: `${top}%`,
    left: `${left}%`,
  };
}

const QuickSearch = React.forwardRef((props, ref) => {
  const [options, setOptions] = useState([]);
  const [modalStyle] = useState(getModalStyle);
  const [assetFilter, setAssetFilter] = useState();

  const api = useApi();
  const history = useHistory();
  const classes = useStyles();

  const typeaheadChanged = (event, newValue) => {
    if (newValue) {
      api
        .allAssetsSearch(newValue)
        .then((res) => {
          const data = [];
          data.push(...res.data);
          data.push(...pages);
          if (window._env_.MOCK_OUT_DEMO === "true") {
            data.push(...demoPages);
          }
          setOptions(data);
        })
        .catch((error) => console.log(error));
    }
    else {
      const data = [];
      data.push(...pages);
      if (window._env_.MOCK_OUT_DEMO === "true") {
        data.push(...demoPages);
      }
      setOptions(data);
    }
  };

  const assetFilterChanged = (event, newValue) => {
    if (newValue) {
      setAssetFilter(newValue);
      if (typeof newValue === "object") {
        history.push(`/app/inventory/details/${newValue.id}`);
      } else if (
        newValue === "/home" ||
        newValue === "/app" ||
        newValue === "/"
      ) {
        history.push("/app");
      } else if (
        ["/inventory", "/new"].filter((value) => value === newValue)[0] ===
        newValue
      ) {
        history.push(`/app/inventory${newValue}`);
      } else {
        history.push(`/app${newValue}`);
      }
      props.closeModal();
    }
  };

  return (
    <div style={modalStyle} className={classes.searchbg} ref={ref}>
      <h2 id="simple-modal-title" className={classes.hideTitle}>
        Quick Search
      </h2>
      <SearchIcon />
      <Autocomplete
        fullWidth
        data-test="quicksearch"
        value={assetFilter}
        id="quick-search-autocomplete"
        options={options}
        onChange={assetFilterChanged}
        onInputChange={typeaheadChanged}
        // Label in TextInput
        getOptionLabel={(option) => {
          // if it's an asset
          if (typeof option !== "string") {
            return `${option.name} (${option.ipv4}, ${option.hostname}${option.asset_uuid ? ', ' + option.asset_uuid : ''})`;
          }
          return option.split("/")[1];
        }}
        // Dropdown Items
        renderOption={(option) => (
          <>
            {typeof option !== "string" && (
              <div className={classes.inputItem}>
                <div className={classes.assetIcon}>
                  <AssetIcon
                    svgWidth={24}
                    criticalityPercentage={option.criticality}
                    isCritical={option.is_critical}
                  />
                </div>
                <div>
                  {option.name || "No Name"}
                  <br />
                  <Typography variant="caption" component="div">
                    {option.ipv4}, {option.asset_type}, {option.hostname}{option.asset_uuid ? ', ' + option.asset_uuid : ''}
                  </Typography>
                </div>
              </div>
            )}

            {
              // If it's a page
              typeof option === "string" && option
            }
          </>
        )}
        // Text Input itself
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            fullWidth
            variant="standard"
            placeholder="Enter an Asset Name, Hostname, IP Address, or Asset UUID"
            className={classes.searchField}
            color="primary"
            size="medium"
          />
        )}
      />
    </div>
  );
});

export default QuickSearch;
