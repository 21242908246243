import React, { useEffect, useState } from "react";
import {
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  TablePagination,
} from "@material-ui/core";
import { useApi } from "../API";
import SeverityIcon from "../components/SeverityIcon";

export default function AssetCVETable(props) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const api = useApi();
  const { assetId } = props;
  const CVE_INFO_BASE_URL = "https://nvd.nist.gov/vuln/detail";
  const cveFormat = /^CVE-\d{4}-\d{4,7}$/;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const matchesCVEFormat = (cveId) => {
    return cveFormat.test(cveId);
  };

  useEffect(() => {
    api
      .assetCVEs(assetId)
      .then((res) => {
        if (res.data.length > 0) {

          const dataArr = res.data?.filter((cve) => cve.cvss_v3 > 0).sort((a, b) => b.cisa_identification - a.cisa_identification)

          return setData(dataArr);
        }
        return setData([]);
      })
      .catch((e) => console.log(e));
  }, [props.assetId]);

  if (!data.length) {
    return <div>No active vulnerabilities found</div>;
  }

  return (
    <div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>CVSSv3 Score</TableCell>
              <TableCell>CISA Known Exploited</TableCell>
              <TableCell>Vulnerability ID</TableCell>
              <TableCell>More Info</TableCell>
              <TableCell>Vulnerability Description</TableCell>
              <TableCell>Port</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>First Detected</TableCell>
              <TableCell>Date Resolved</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((cve) => (
                <TableRow key={cve.id}>
                  <TableCell>
                    <SeverityIcon score={cve.cvss_v3} />
                  </TableCell>
                  <TableCell>
                    {cve.cisa_identification ? "Known Exploited ⚠️" : ""}
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Link
                      target="_blank"
                      href={`/app/inventory/assets?cve=${cve.cve_id}`}
                      underline="hover"
                    >
                      {cve.cve_id}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {matchesCVEFormat(cve.cve_id) && (
                      <Link
                        target="_blank"
                        href={`${CVE_INFO_BASE_URL}/${cve.cve_id.toUpperCase()}`}
                        underline="hover"
                      >
                        NIST {cve.cve_id}
                      </Link>
                    )}
                  </TableCell>
                  <TableCell>{cve.cve_name}</TableCell>
                  <TableCell>{cve.port || "--"}</TableCell>
                  <TableCell>{cve.status || "--"}</TableCell>
                  <TableCell>{cve.first_detected || "--"}</TableCell>
                  <TableCell>{cve.last_fixed || "--"}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
