import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Modal,
  Typography,
  Grid,
  Divider,
} from "@material-ui/core";
import { getModalStyle } from "../utils";
import { palette } from "../utils/theme";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    padding: "2em 3em",
    minWidth: "40%",
    borderRadius: "5px",
    overflowY: "auto",
  },
}));

const StyledModal = (props) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="styled-modal"
      aria-describedby="styled-modal-description"
    >
      <Box style={modalStyle} className={classes.paper}>
        <Typography variant="h6">{props.title}</Typography>
        <Divider />
        <Grid container spacing={2} direction="column">
          <Grid container item style={{ paddingTop: "2.5em" }}>
            {props.content || props.children}
          </Grid>
          <Grid
            container
            item
            alignItems="flex-end"
            justifyContent="space-between"
            style={{ paddingTop: "1em" }}
          >
            <Grid item>
              <Button
                disableElevation
                onClick={props.onSubmit}
                color="secondary"
                variant="contained"
                style={
                  props.destructive
                    ? { color: "white", backgroundColor: palette.red }
                    : {}
                }
                data-test={props.submitDataTest}
                disabled={props.submitDisabled}
              >
                {props.submitButtonText ||
                  (props.destructive ? "Delete" : "Submit")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={props.onClose || props.onCancel}
              >
                {props.cancelButtonText || "Cancel"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default StyledModal;
