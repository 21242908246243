import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Grid } from "@material-ui/core";
import { palette } from "../utils/theme";
import { svgIcons } from "../icons/Icons";
import Icon from "../icons/icon";

const useStyles = makeStyles(() => ({
  legend: {
    // position: "absolute",
    // width: "300px",
    padding: "1rem",
    height: "2em",
    top: "1rem",
    right: "2rem",
    background: "white",
    // border: `1px solid ${palette.lightGray}`,
    // boxShadow: "0px 3px 1px -2px rgb(180 180 180 / 45%)",
  },
  legendWrapper: {
    display: "block",
    alignItems: "center",
    paddingBottom: "8px",
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    paddingRight: "8px",
  },
  legendCircle: {
    width: ".75rem",
    height: ".75rem",
    borderRadius: ".75rem",
    float: "left",
    marginRight: "2px",
  },
  legendSmallCircle: {
    width: ".5rem",
    height: ".5rem",
    borderRadius: ".5rem",
    float: "left",
    marginRight: "4px",
    marginLeft: "2px",
  },
  legendLargeCircle: {
    width: "2rem",
    height: "2rem",
    borderRadius: "2rem",
    float: "left",
    background: palette.purple1,
    marginRight: "8px",
    display: "flex",
    justifyContent: "center",
    paddingTop: "4px",
  },
  moreLink: {
    color: palette.darkBlue,
    textAlign: "center",
    cursor: "pointer",
    border: `1px solid ${palette.lightGray}`,
    position: "absolute",
    bottom: "-1rem",
    right: "1rem",
    background: "white",
    padding: ".25rem",
  },
}));

function ResilienceLegend() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [showMore, setShowMore] = useState(false);

  return (
    <Grid container item spacing={1}>
      <Divider display="flex" />
      <Grid item xs={12}>
        <div className={classes.legendItem}>
          <div
            className={classes.legendCircle}
            style={{ background: palette.purple1 }}
          />{" "}
          {t("criticalAssetLabel")}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.legendItem}>
          <div
            className={classes.legendCircle}
            style={{ background: palette.keyBlue }}
          />{" "}
          Other Asset
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.legendItem}>
          <div
            className={classes.legendSmallCircle}
            style={{ boxShadow: `0 0 2px 2px ${palette.red}` }}
          />
          High {t("riskScoreLabel")} Asset
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.legendItem}>
          <div
            className={classes.legendSmallCircle}
            style={{ boxShadow: `0 0 2px 2px black` }}
          />{" "}
          Has Selected Tag
        </div>
      </Grid>
      {/* <div className={classes.legendWrapper} style={{ paddingBottom: "1rem" }}> */}
      <div className={classes.legendItem}>
        <div
          className={classes.legendCircle}
          style={{ background: "#ad95d0" }}
        />{" "}
        Application
      </div>
      {/* </div> */}
      {showMore && (
        <>
          <Grid item xs={12}>
            <div className={classes.legendItem}>
              <div
                className={classes.legendLargeCircle}
                style={{ background: "black" }}
              >
                <Icon icon={svgIcons.internet} />
              </div>
              Internet
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.legendItem}>
              <div className={classes.legendLargeCircle}>
                <Icon icon={svgIcons.workstation} />
              </div>
              Workstation
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.legendItem}>
              <div className={classes.legendLargeCircle}>
                <Icon icon={svgIcons.database} />
              </div>
              Database
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.legendItem}>
              <div className={classes.legendLargeCircle}>
                <Icon icon={svgIcons["web application"]} />
              </div>
              Web Application
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.legendItem}>
              <div className={classes.legendLargeCircle}>
                <Icon icon={svgIcons["api server"]} />
              </div>
              API Server
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.legendItem}>
              <div className={classes.legendLargeCircle}>
                <Icon icon={svgIcons.authentication} />
              </div>
              Authentication
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.legendItem}>
              <div className={classes.legendLargeCircle}>
                <Icon icon={svgIcons.infrastructure} />
              </div>
              Infrastructure
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.legendItem}>
              <div className={classes.legendLargeCircle}>
                <Icon icon={svgIcons.hypervisor} />
              </div>
              Hypervisor
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.legendItem}>
              <div className={classes.legendLargeCircle}>
                <Icon icon={svgIcons.iot} />
              </div>
              IOT
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.legendItem}>
              <div className={classes.legendLargeCircle}>
                <Icon icon={svgIcons.default} />
              </div>
              Default (Asset)
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.legendItem}>
              <div
                className={classes.legendLargeCircle}
                style={{ background: palette.darkGray }}
              >
                <Icon icon={svgIcons.limit} />
              </div>
              Limit
            </div>
          </Grid>
        </>
      )}
      <div className={classes.moreLink} onClick={() => setShowMore(!showMore)}>
        {!showMore && <>Show More</>}
        {showMore && <>Show Less</>}
      </div>
    </Grid>
  );
}

export default ResilienceLegend;
