import React, { useState, useEffect } from "react";
import {
  Grid,
  Chip,
  Paper,
  Typography,
  TableCell,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableRow,
} from "@material-ui/core";
import moment from "moment";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import ErrorIcon from "@material-ui/icons/Error";
import { palette } from "../utils/theme";
import { useApi } from "../API";

const MOCK_DEMO = window._env_ && window._env_.MOCK_OUT_DEMO === "true";

const TelemetryHealth = () => {
  const api = useApi();
  const [data, setData] = useState();

  useEffect(async () => {
    const res = await api.connectorStats(12);
    res?.stats?.sort((a, b) => (b.solution.id > a.solution.id ? 1 : -1));
    setData(res);
  }, []);

  if (!data) {
    return <>Loading...</>;
  }

  return (
    <>
      <TableContainer component={Paper} variant="outlined">
        <Table size="small">
          <TableHead />
          <TableBody>
            {data?.stats?.map((connectorStat, idx) => {
              let isHealthy = connectorStat?.health?.healthy;
              if (MOCK_DEMO) {
                isHealthy = true;
              }

              if (!connectorStat.is_enabled) {
                return <></>;
              }

              if (!connectorStat.health && connectorStat.is_enabled) {
                return (
                  <div>
                    {/* {" "}
                    No health status information avaialbe for this connector. */}
                  </div>
                );
              }

              return (
                <TableRow key={idx}>
                  <TableCell>
                    <Grid
                      container
                      spacing={1}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item>
                        {!connectorStat.solution.logo_url ||
                        connectorStat.solution.logo_url === "" ? (
                          connectorStat.solution.display_name
                        ) : (
                          <img
                            src={connectorStat.solution.logo_url}
                            style={{
                              width: "100px",
                              ...(!connectorStat.is_enabled && {
                                filter: "grayscale(100%)",
                              }),
                            }}
                            alt={`${connectorStat.solution.display_name} Logo`}
                          />
                        )}
                      </Grid>
                      <Grid item style={{ textAlign: "left", margin: "0 1em" }}>
                        <Chip
                          variant="outlined"
                          icon={
                            !connectorStat.is_enabled ? (
                              <RemoveCircleIcon
                                style={{ color: "#C2C2C2", fontSize: "25px" }}
                              />
                            ) : isHealthy ? (
                              <CheckCircleIcon
                                style={{
                                  color: palette.green,
                                  fontSize: "25px",
                                }}
                              />
                            ) : (
                              <ErrorIcon
                                style={{ color: palette.red, fontSize: "25px" }}
                              />
                            )
                          }
                          label={
                            MOCK_DEMO
                              ? `${connectorStat.hours} hours ago`
                              : connectorStat?.health?.date_last_received
                              ? moment(
                                  connectorStat?.health?.date_last_received
                                )
                                  .local()
                                  .fromNow()
                              : ""
                          }
                        /> every {connectorStat.hours} hours
                      </Grid>

                      <Grid item>
                        <Typography
                          style={{ color: "#4F4F4F" }}
                          variant="caption"
                        >
                          Data Source -{" "}
                          <strong>
                            {connectorStat.datasource.display_name}
                          </strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={5} />
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TelemetryHealth;
