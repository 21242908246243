import React, { useEffect, useState } from "react";
import { MenuItem, Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ResponsiveHeatMap } from "@nivo/heatmap";
import { useHistory } from "react-router-dom";
import { useApi } from "../API";
import ChartWrapper from "../components/ChartWrapper";
import ChartWrapperHeader from "../components/ChartWrapperHeader";
import { palette } from "../utils/theme";
import logo from "../assets/logo-black.svg";
import HighImpactCheckbox from "../components/HighImpactSwitch";
import ActiveAssetsCheckbox from "../components/ActiveAssetsSwitch";

const useStyles = makeStyles(() => ({
  root: {
    margin: "1rem 2rem",
  },
  fieldContainer: {
    margin: "0 5px",
  },
  chartWrapper: {
    height: "400px",
  },
}));

const convertToHeatMap = (response, groupBy) => {
  const collected = {};

  response.forEach((row) => {
    if (row[groupBy] === "internet") return;

    if (!collected[row.id]) {
      collected[row.id] = { id: row.id };
    }

    if (!collected[row.id].data) {
      collected[row.id].data = [];
    }

    let percentage =
      Number.isNaN(row.covered / row.total) || row.total === 0
        ? 0
        : row.covered / row.total;

    if (percentage > 0 && percentage < 0.01) {
      percentage = 0.01;
    }

    collected[row.id].data.push({
      x: row[groupBy] || "None",
      // percentage covered for the row in question
      y: percentage,
      solutionDisplayName: row.display_name,
      coverageId: row.id,
      application: row.application,
    });
  });

  const formatted = [];

  for (const value of Object.values(collected)) {
    value.data.sort((a, b) =>
      a.solutionDisplayName > b.solutionDisplayName ? 1 : -1
    );
    formatted.push(value);
  }

  return formatted;
};

function CoverageMatrix({ criticalFilter }) {
  const classes = useStyles();
  const api = useApi();
  const history = useHistory();
  const [highValueOnly, setHighValueOnly] = useState(
    criticalFilter !== undefined ? criticalFilter : true
  );
  const [groupBy, setGroupBy] = React.useState("asset_type");
  const [data, setData] = useState([]);
  const [dateFilter, setDateFilter] = useState(true);
  const [logoData, setLogoData] = useState();
  const [applications, setApplications] = useState();

  const CustomTick = (tick) => {
    const logoUrl = logoData.find((el) => el.id === tick.value)?.logo_url;
    const displayName = logoData.find(
      (el) => el.id === tick.value
    )?.display_name;
    if (!logoUrl || logoUrl === "") {
      return (
        <g transform={`translate(${tick.x - 90},${tick.y - 10})`}>
          <text>{displayName}</text>
        </g>
      );
    }
    return (
      <g transform={`translate(${tick.x - 90},${tick.y - 10})`}>
        <image
          width="80px"
          href={tick.value === -1 ? logo : logoUrl}
          alt={`${displayName} logo`}
        />
      </g>
    );
  };

  useEffect(() => {
    api
      .getActiveSolutions()
      .then((res) => {
        setLogoData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (event) => {
    setGroupBy(event.target.value);
  };

  useEffect(() => {
    api.getApplications().then((res) => {
      setApplications(res);
    });
  }, []);

  useEffect(() => {
    if (applications) {
      api
        .coverageMatrix({
          group_by: groupBy,
          high_value_only: highValueOnly,
          active: dateFilter,
        })
        .then((response) => {
          if (response.length > 0) {
            const convertedData = convertToHeatMap(response, groupBy);
            return setData(convertedData);
          }
          setData([]);

          return { error: "No data" };
        })
        .catch((e) => {
          return { error: `Exception ${e}` };
        });
    }
  }, [groupBy, highValueOnly, applications, dateFilter]);

  const getId = (value, groupBy) => {
    if (groupBy === "application") {
      return applications.find((el) => el.display_name === value)?.id;
    }

    return encodeURIComponent(value.replaceAll(",", "%2C"));
  };
  // const names = {
  //   business_unit: "Business Unit",
  //   asset_type: "Asset Type",
  //   owner: "Owner",
  //   application: "Business Process",
  //   location: "Location",
  //   os: "OS",
  //   class_name: "OS Class",
  // };
  if (!logoData || !data) {
    return <div>Loading...</div>;
  }

  return (
    <ChartWrapper excludeHeader>
      <ChartWrapperHeader
        chartName={`Coverage Matrix${criticalFilter
            ? " for High Impact Assets"
            : criticalFilter === false
              ? " for All Assets"
              : ""
          }`}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <HighImpactCheckbox
              checked={highValueOnly}
              handleSwitchChange={({ target: { checked } }) =>
                setHighValueOnly(checked)
              }
            />
          </Grid>
          <Grid item>
            <ActiveAssetsCheckbox
              checked={dateFilter}
              handleSwitchChange={({ target: { checked } }) =>
                setDateFilter(checked)
              }
            />
          </Grid>
          <Grid item>
            <TextField
              id="groupby-select"
              value={groupBy}
              onChange={handleChange}
              label="Group By"
              variant="outlined"
              select
              style={{ minWidth: 150 }}
              size="small"
              className={classes.fieldContainer}
            >
              <MenuItem value="asset_type">Asset Type</MenuItem>
              <MenuItem value="business_unit">Business Unit</MenuItem>
              <MenuItem value="owner">Owner</MenuItem>
              <MenuItem value="application">Business Process</MenuItem>
              <MenuItem value="location">Location</MenuItem>
              <MenuItem value="os">OS</MenuItem>
              <MenuItem value="class_name">OS Class</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </ChartWrapperHeader>
      <div className={classes.chartWrapper}>
        {data?.length === 0 ? (
          <Typography>No Assets Found. Try different filters.</Typography>
        ) : (
          <ResponsiveHeatMap
            data={data?.sort((a, b) => (a.id > b.id ? -1 : 1))}
            margin={{ top: 50, right: 120, bottom: 60, left: 150 }}
            valueFormat=">-.0%"
            labelTextColor="#fff"
            xInnerPadding={0.01}
            yInnerPadding={0.05}
            xOuterPadding={0.1}
            yOuterPadding={0.1}
            theme={{
              fontSize: 14,
              axis: { ticks: { text: { fontSize: 14 } } },
            }}
            axisTop={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: -18,
              legend: "",
              legendOffset: 46,
              format: (v) => {
                return v && v.length > 15 ? (
                  <tspan>
                    {`${v.substring(0, 15)}...`}
                    <title>{v}</title>
                  </tspan>
                ) : (
                  v
                );
              },
            }}
            onClick={(d) => {
              if (d.data.coverageId > 0 && groupBy !== "os") {
                // OS is grouped by windows, mac, or linux instead of the specific OS, so clicking through wont work.
                let url = `/app/inventory/assets?missing_coverage=${d.data.coverageId
                  }&${groupBy === "application" ? "application_id" : groupBy
                  }=${getId(d.data.x, groupBy)}`;
                if (highValueOnly) {
                  url += "&is_critical=true";
                }
                if (dateFilter) {
                  url += "&stale=false";
                }

                history.push(url);
              }
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 10,
              tickRotation: 0,
              legendPosition: "middle",
              legendOffset: -72,
              renderTick: CustomTick,
            }}
            colors={{
              type: "quantize",
              colors: [
                palette.darkRed,
                palette.red,
                palette.orange,
                palette.yellow,
                palette.yellowGreen,
                palette.green,
              ],
              steps: 6,
              minValue: 0,
              maxValue: 1,
            }}
            tooltip={(data) => (
              <div
                style={{
                  backgroundColor: "white",
                  color: "inherit",
                  fontSize: "inherit",
                  borderRadius: "2px",
                  boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
                  padding: "5px 9px",
                }}
              >
                <div>
                  {
                    logoData.find(
                      (el) => el.id === parseInt(data.cell.data.coverageId)
                    )?.display_name
                  }{" "}
                  - {data.cell.data.x}:{" "}
                  <strong>{(data.cell.data.y * 100).toFixed(0)}%</strong>
                </div>
              </div>
            )}
            emptyColor="#555555"
            legends={[
              {
                anchor: "center",
                translateX: 0,
                translateY: 165,
                length: 320,
                thickness: 10,
                direction: "row",
                tickPosition: "after",
                tickSize: 0,
                tickSpacing: 4,
                tickOverlap: false,
                tickFormat: ">-.0%",
                titleAlign: "start",
                titleOffset: 4,
              },
            ]}
          />
        )}
      </div>
    </ChartWrapper>
  );
}
export default CoverageMatrix;
