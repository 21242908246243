import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { useApi } from "../../API";
import StandardTable from "../../table/GenericTable";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: "400px",
  },
  indexCell: {
    width: "1%",
  },
  idCell: {
    minWidth: "140px",
    whiteSpace: "nowrap",
  },
  cveCell: {
    minWidth: "240px",
  },
  iconNameCell: {
    minWidth: "140px",
  },
  bigNumberCell: {
    width: "100px",
  },
  bar: {
    height: "1.25rem",
    background: theme.palette.purple1,
  },
  assetCircle: {
    height: "20px",
    width: "20px",
    background: theme.palette.purple1,
    borderRadius: "20px",
  },
  nowrap: {
    whiteSpace: "nowrap",
  },
}));

function AssetsByCVEsTable({ data, criticalOnly }) {
  const history = useHistory();

  const headers = [
    {
      name: "hostname",
      display_name: "Hostname",
    },
    {
      name: "total_cves",
      display_name: "Total Vulnerabilities",
    },
    {
      name: "total_crit",
      display_name: "Critical",
    },
    {
      name: "total_high",
      display_name: "High",
    },
    {
      name: "total_med",
      display_name: "Medium",
    },
    {
      name: "total_low",
      display_name: "Low",
    },
  ];

  return (
    <StandardTable
      firstColWidth={300}
      onClick={(row) => {
        history.push(`/app/asset/${row.asset_id}`);
      }}
      data={{
        headers,
        data: criticalOnly ? data.critical_cves_breakdown : data.cves_breakdown,
      }}
    />
  );
}

function AssetsByCVEs(props) {
  const classes = useStyles();
  const [data, setData] = useState();
  const api = useApi();

  useEffect(() => {
    api
      .assetsCVEsBreakdown()
      .then((res) => {
        setData(res.data);
      })
      .catch(() => {
        if (props.handleShowHide) {
          props.handleShowHide(false);
        }
      });
  }, []);

  return (
    <div>
      {data && (
        <Box mt={2} className={classes.wrapper}>
          <AssetsByCVEsTable data={data} criticalOnly={props.criticalOnly} />
        </Box>
      )}
    </div>
  );
}

export default AssetsByCVEs;
