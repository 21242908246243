import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function DashboardIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        className="cls-1"
        d="M19.66,12.37l-7.44-7a.33.33,0,0,0-.44,0l-7.44,7a.32.32,0,0,0,.22.55H6.23v5.14H10V13.25h4v4.81h3.78V12.92h1.67A.32.32,0,0,0,19.66,12.37Z"
      />
    </SvgIcon>
  );
}

export default DashboardIcon;
