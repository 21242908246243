import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  GridList,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useApi } from "../API";

const useStyles = makeStyles(() => ({
  tableWrapper: {
    padding: "0rem",
  },

  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginTop: 6,
  },
}));

export default function DataDiscoveryResult(props) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const api = useApi();
  const { assetId } = props;

  useEffect(() => {
    api
      .assetDataDiscovered(assetId)
      .then((res) => {
        if (res.data.length > 0) {
          return setData(res.data);
        }
        return true;
      })
      .catch((e) => console.log(e));
  }, [props.assetId]);

  if (!data.length) {
    return <div>No Data Discovery available </div>;
  }

  return (
    <GridList cellHeight="auto" spacing={20} cols={1}>
      {data.map((classification, index) => (
        <div className={classes.tableWrapper} key={index}>
          <Typography variant="subtitle1" component="h6">
            {classification.name} Data Found
          </Typography>
          <TableContainer className={classes.table}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Database</TableCell>
                  <TableCell>Subject</TableCell>
                  <TableCell>Elements</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {classification.subjects.map((subject, index) => (
                  <TableRow key={index}>
                    <TableCell>{subject.database}</TableCell>
                    <TableCell>{subject.name}</TableCell>
                    <TableCell>{subject.elements.join(", ")}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ))}
    </GridList>
  );
}
