import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";

/**
 * A panel that contains content under a tabbed header.
 *
 * NOTE: In the future we should move toward controling tab panels by route
 * rather than route+state, which would make the index and value props here
 * obsolete. They remain in the mean time, but future pages should avoid them
 * and follow the pattern used on the criticality page instead.
 */
const TabPanel = ({ index, value, children, ...rest }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={index !== null ? `simple-tabpanel-${index}` : null}
    aria-labelledby={index !== null ? `simple-tab-${index}` : null}
    aria-live="polite"
    {...rest}
  >
    {value === index && <Box>{children}</Box>}
  </div>
);

TabPanel.propTypes = {
  index: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
};

TabPanel.defaultProps = {
  index: null,
  value: null,
};

export default TabPanel;
