import React from "react";
import { FormControlLabel, Checkbox, Typography } from "@material-ui/core";

const HighImpactCheckBox = (props) => {
  return (
    <FormControlLabel
      size="small"
      control={
        <Checkbox
          size="small"
          checked={props.checked}
          onChange={props.handleSwitchChange}
          name={props.name || "highImpact"}
          color="secondary"
        />
      }
      label={
        <Typography variant="subtitle1" style={{ fontSize: ".9em" }}>
          High Impact
        </Typography>
      }
    />
  );
};

export default HighImpactCheckBox;
