import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ResponsiveLine } from "@nivo/line";
import { useApi } from "../../API";
import ChartWrapper from "../../components/ChartWrapper";
import ChartWrapperHeader from "../../components/ChartWrapperHeader";
import TimePeriodDropDown from "../../components/TimePeriodDropDown";
import { abbreviateLargeValue } from "../../utils";

function NewAssetsOverTime() {
  const [selectedPeriod, setSelectedPeriod] = useState("biweekly");
  const [data, setData] = useState([]);

  const api = useApi();

  useEffect(async () => {
    const res = await api.allMetrics({
      metrics: ["total_assets", "total_high_impact"],
      period: selectedPeriod,
    });
    setData(formatMetricDataForNivo(null, res.data.metrics));
  }, [selectedPeriod]);

  const parseDate = (date) => {
    return new Date(Date.parse(date)).toISOString().slice(0, 10);
  };

  const handlePeriodChange = (val) => {
    setSelectedPeriod(val);
  };

  function renderTooltip({ point }) {
    return (
      <div
        style={{
          backgroundColor: "white",
          color: "inherit",
          fontSize: "inherit",
          borderRadius: "2px",
          boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
          padding: "5px 9px",
        }}
      >
        <div>
          <strong>{point.id.split(".")[0]}:</strong>
          <br />
          {abbreviateLargeValue(point.data.y.toFixed(0), true)} on{" "}
          {point.data.x.toLocaleDateString()}
        </div>
      </div>
    );
  }

  const formatMetricDataForNivo = (metricNames, metricsData) => {
    /**
     * TODO: We want to use percentages for the output, but the metric is in int format.
     * So divide by total assets to get percentages
     */
    let metrics = metricsData;

    if (metricNames && metricNames?.length) {
      metrics = metrics.filter((el) => metricNames.includes(el.name));
    }

    const nivoData = [];

    for (const metric of metrics) {
      const isPercentage = metric.format === "percentage";
      const dates = [];
      const formattedData = {
        data: [
          {
            x: parseDate(metric.date),
            y: isPercentage ? metric.value * 100 : metric.value,
          },
        ],
        id: metric.display_name,
      };
      dates.push(parseDate(metric.date));
      for (const pastMetric of metric.history) {
        if (!dates.includes(parseDate(pastMetric.date))) {
          formattedData.data.push({
            x: parseDate(pastMetric.date),
            y: isPercentage ? pastMetric.value * 100 : pastMetric.value,
          });
        }
      }

      nivoData.push(formattedData);
    }

    return nivoData;
  };
  return (
    <ChartWrapper excludeHeader>
      <ChartWrapperHeader chartName="New Assets">
        <TimePeriodDropDown handlePeriodChange={handlePeriodChange} />
      </ChartWrapperHeader>
      <Grid
        container
        direction="row"
        justifyContent="center"
        textAlign="center"
        style={{ marginTop: "24px" }}
      >
        <Grid style={{ height: 450 }} xs={12}>
          <ResponsiveLine
            data={data}
            height={450}
            animate
            margin={{ top: 40, right: 100, bottom: 50, left: 60 }}
            curve="monotoneX"
            xScale={{
              type: "time",
              format: "%Y-%m-%d",
              useUTC: false,
              precision: "day",
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{
              type: "linear",
              min: 0,
              max: "auto",
            }}
            axisLeft={{
              format: (d) => abbreviateLargeValue(d, true),
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              legendOffset: -40,
              legend: "Total Assets",
            }}
            axisBottom={{
              format: selectedPeriod === "year" ? "%b" : "%b %d",
              tickValues:
                selectedPeriod === "year"
                  ? "every month"
                  : selectedPeriod === "quarter" || selectedPeriod === "month"
                  ? "every week"
                  : "every day",
              legend:
                selectedPeriod === "year"
                  ? "Month"
                  : selectedPeriod === "quarter" || selectedPeriod === "month"
                  ? "Week"
                  : "Day",
              tickSize: 6,
              tickRotation: -30,
              legendOffset: 45,
              legendPosition: "middle",
            }}
            colors={{ scheme: "category10" }}
            pointSize={8}
            pointColor={["#fff"]}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            lineWidth={2}
            useMesh
            enableSlices={false}
            tooltip={renderTooltip}
            theme={{
              axis: {
                domain: {
                  line: {
                    stroke: "#a9acbe",
                    strokeWidth: 0.7,
                  },
                },
              },
              grid: {
                line: {
                  stroke: "#e7e8ec",
                  strokeWidth: 0.7,
                },
              },
            }}
            legends={[
              {
                anchor: "top-right",
                direction: "column",
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
              },
            ]}
          />
        </Grid>
      </Grid>
    </ChartWrapper>
  );
}
export default NewAssetsOverTime;
