import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import PublishIcon from "@material-ui/icons/Publish";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import DeleteIcon from "@material-ui/icons/Delete";
/**
 * A drag and drop file upload interface
 */
const FileUploadButton = ({ id, onDrop, ...rest }) => {
  const [files, setFiles] = React.useState([]);

  const handleStagedFileDisplay = (files) => {
    setFiles(files);
    onDrop(files);
  };

  const handleStagedFileDelete = (key) =>
    setFiles(
      files.slice(0, key) + key > files.length - 1 ? [] : files.slice(key + 1)
    );

  const uploaded = files.map((file, key) => (
    <Button
      key={key}
      onClick={handleStagedFileDelete.bind(null, key)}
      variant="outlined"
      startIcon={<InsertDriveFileIcon />}
      endIcon={<DeleteIcon />}
    >
      {file.path}
    </Button>
  ));

  return (
    <>
      <Dropzone id={id} onDrop={handleStagedFileDisplay}>
        {({ getRootProps, getInputProps }) => (
          <Paper variant="outlined" elevation={2}>
            <div
              style={{ textAlign: "center", cursor: "pointer" }}
              {...getRootProps(rest)}
            >
              <input {...getInputProps()} />
              <IconButton color="primary" component="span">
                <PublishIcon />
              </IconButton>
              {files.length ? uploaded : null}
              {!files.length && (
                <p>
                  Drag &apos;n&apos; drop some files here, or click to select
                  files
                </p>
              )}
            </div>
          </Paper>
        )}
      </Dropzone>
    </>
  );
};

FileUploadButton.propTypes = {
  id: PropTypes.string.isRequired,
  onDrop: PropTypes.func,
};

export default FileUploadButton;
