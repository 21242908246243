import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { withOktaAuth } from "@okta/okta-react";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import { Toolbar, Box } from "@material-ui/core";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { RoundedTab, RoundedTabs } from "./components/RoundedTab";
import CrownJewels from "./crownJewels/Root";
import Settings from "./settings/Root";
import Navigation from "./components/Navigation";
import Resilience from "./resilience/Root";
import Risk from "./risk/Root";
import ReportingRoot from "./reporting/Root";
import DashboardPage from "./dashboard/DashboardPage";
import DashboardIcon from "./icons/dashboardIcon";
import ControlsPage from "./controls/Root";
import InventoryIcon from "./icons/inventoryIcon";
import ResilienceIcon from "./icons/resilienceIcon";
import ReportingIcon from "./icons/reportingIcon";
import RiskIcon from "./icons/riskIcon";
import ControlsIcon from "./icons/controlsIcon";
import { svgIcons } from "./icons/Icons";
import Icon from "./icons/icon";
import PageNotFound from "./404";
import DrilldownRouter from "./external/DrilldownRouter";
import QuickSearch from "./QuickSearch";
import ResProvider from "./context/FilterContext";
import logo from "./assets/logo.svg";
import ToastContext from "./context/ToastContext";
import Users from "./settings/Users";
import ResetPasswordForm from "./settings/ResetPassword";
import ConsolidatedAssetDetails from "./crownJewels/ConsolidatedAssetDetails";
import EditAsset from "./crownJewels/EditAsset";
import TabPanel from "./components/TabPanel";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    display: "flex",
  },
  rootContent: theme.layout.root,
  contentWrapper: {
    minHeight: "100vh",
    paddingTop: "4.5rem",
    paddingBottom: "40px",
    backgroundColor: theme.palette.background,
  },
  contentWrapperInner: theme.layout.contentWrapper,
  content: theme.layout.content,
  headerbar: {
    backgroundColor: theme.palette.darkGray,
  },
  navbar: {
    backgroundColor: theme.navbar.background,
    height: "3.25rem",
    minHeight: "3.25rem",
  },
  footer: {
    backgroundColor: theme.palette.background,
    height: "50px",
    marginTop: "-50px",
    padding: ".75rem",
  },
  navlink: {
    fontSize: "1rem",
    textTransform: "uppercase",
    textDecoration: "none",
    borderBottom: "4px solid transparent",
    color: theme.palette.textColor,
    padding: ".5rem 1rem",
    "&:hover": {
      color: theme.palette.logo,
      textDecoration: "none",
      borderBottomColor: "blue",
    },
  },
  icon: {
    paddingRight: ".25rem",
    position: "relative",
    top: ".25rem",
  },
  text: {
    whiteSpace: "nowrap",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
}));

const MOCK_OUT_DEMO = window._env_ && window._env_.MOCK_OUT_DEMO === "true";

const Body = (props) => {
  const classes = useStyles();

  const appRedirect = "dashboard";

  /* The main website content */
  return (
    <Switch>
      <Route path="/app/external">
        <div className={classes.contentWrapper}>
          <DrilldownRouter />
        </div>
      </Route>
      <Route exact path="/app/inventory">
        <Redirect to="/app/inventory/assets" />
      </Route>
      <Route exact path="/app/resilience">
        <Redirect to="/app/resilience/connections" />
      </Route>

      {Object.keys(props.tabs).map((pane, i) => (
        <Route
          path={`/app${props.tabs[pane][2]}`}
          key={i}
          exact={pane === "Reporting"}
        >
          <div className={classes.contentWrapper}>{props.tabs[pane][0]}</div>
        </Route>
      ))}
      {Object.keys(props.util).map((pane, i) => (
        <Route path={`/app${props.util[pane][2]}`} key={i}>
          <div className={classes.contentWrapper}>{props.util[pane][0]}</div>
        </Route>
      ))}
      {/* <Route path="/app/settings">
        <div className={classes.contentWrapper}>
          <Settings />
        </div>
      </Route> */}

      {/* <Route exact path="/app/settings/connectors/:connectorId">
        <div className={classes.contentWrapper}>
          <ConnectorDetails />
        </div>
      </Route> */}

      <Route path="/app/reporting">
        <div className={classes.contentWrapper}>{props.tabs.Reports[0]}</div>
      </Route>
      {!MOCK_OUT_DEMO && (
        <Route path="/app/users">
          <div className={classes.contentWrapper}>
            <div className={classes.rootContent}>
              <div className={classes.contentWrapperInner}>
                <Box className={classes.content}>
                  <RoundedTabs value={0}>
                    <RoundedTab id={0} label="Users" />
                  </RoundedTabs>
                  <TabPanel value={0} index={0}>
                    <Users />
                  </TabPanel>
                </Box>
              </div>
            </div>
          </div>
        </Route>
      )}
      {!MOCK_OUT_DEMO && (
        <Route path="/app/account">
          <div className={classes.contentWrapper}>
            <div className={classes.rootContent}>
              <div className={classes.contentWrapperInner}>
                <Box className={classes.content}>
                  <RoundedTabs value={0}>
                    <RoundedTab id={0} label="My Account" />
                  </RoundedTabs>
                  <TabPanel value={0} index={0}>
                    <ResetPasswordForm />
                  </TabPanel>
                </Box>
              </div>
            </div>
          </div>
        </Route>
      )}

      <Route exact path="/app">
        <Redirect to={`/app/${appRedirect}`} />
      </Route>

      <Route exact path="/app/asset/:assetId">
        <div className={classes.contentWrapper}>
          <div className={classes.rootContent}>
            <div className={classes.contentWrapperInner}>
              <Box className={classes.content}>
                <RoundedTabs value={0}>
                  <RoundedTab id={0} label="Asset Details" />
                </RoundedTabs>
                <TabPanel value={0} index={0}>
                  <ConsolidatedAssetDetails />
                </TabPanel>
              </Box>
            </div>
          </div>
        </div>
      </Route>

      <Route exact path="/app/asset/edit/:assetId">
        <div className={classes.contentWrapper}>
          <div className={classes.rootContent}>
            <div className={classes.contentWrapperInner}>
              <Box className={classes.content}>
                <RoundedTabs value={0}>
                  <RoundedTab id={0} label="Edit Asset Details" />
                </RoundedTabs>
                <TabPanel value={0} index={0}>
                  <Paper variant="outlined">
                    <EditAsset />
                  </Paper>
                </TabPanel>
              </Box>
            </div>
          </div>
        </div>
      </Route>

      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
};

function SPA() {
  const { pathname } = useLocation();
  const cookies = new Cookies();
  const { t } = useTranslation();

  const [state, setState] = useState({});
  const [pane, setPane] = useState(
    pathname.includes("/app/dashboard") || pathname === "/app"
      ? "Dashboard"
      : ""
  );
  const [searchOpen, setSearchOpen] = useState(false);

  const [redrawTopBar, setRedrawTopBar] = useState(0);
  const [preHydrated, setPreHydrated] = useState(false);

  const redrawTopBarFunc = () => {
    setRedrawTopBar(redrawTopBar + 1);
  };

  const classes = useStyles();

  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const search = <QuickSearch closeModal={() => setSearchOpen(false)} />;

  const handleOpenSearch = () => {
    setSearchOpen(true);
  };

  const handleCloseSearch = () => {
    setSearchOpen(false);
  };

  const termCookie = cookies.get("kc_terms_and_conditions");
  if (termCookie === "true") {
    if (showDisclaimer === true) {
      setShowDisclaimer(false);
    }
  }

  if (window._env_.REACT_APP_SHOW_DISCLAIMER === "true") {
    if (showDisclaimer === false && termCookie !== "true") {
      setShowDisclaimer(true);
    }
  }

  const acceptTerms = () => {
    const current = new Date();
    const nextYear = new Date();

    nextYear.setFullYear(current.getFullYear() + 1);

    cookies.set("kc_terms_and_conditions", true, {
      path: "/",
      expires: nextYear,
    });
    setShowDisclaimer(false);
  };

  const toastContext = useContext(ToastContext);
  const history = useHistory();

  useEffect(() => {
    if (pathname.includes("/app/dashboard")) {
      setPane("Dashboard");
    }

    if (pathname.includes("/app/inventory")) {
      setPane("Inventory");
    }

    if (pathname.includes("/app/asset")) {
      setPane(null);
    }

    if (pathname.includes("/app/risk")) {
      setPane("Risk");
    }

    if (pathname.includes("/app/resilience")) {
      setPane("Resilience");
    }

    if (pathname.includes("/app/coverage")) {
      setPane("Coverage");
    }

    if (pathname.includes("/app/reporting")) {
      setPane("Reports");
    }
  }, [pathname]);
  useEffect(() => {
    if (preHydrated) {
      toastContext.setPosition("no-nav");
      history.push("/app/upload");
    }
  }, [preHydrated]);

  const util = {
    // Upload: [
    //   <Upload
    //     key="page"
    //     setPreHydrated={setPreHydrated}
    //     preHydrated={preHydrated}
    //   />,
    //   <Icon key="icon" icon={svgIcons.cloudUpload} />,
    //   "/upload",
    // ],
    Settings: [
      <Settings key="page" />,
      <Icon key="icon" icon={svgIcons.cog} />,
      "/settings",
    ],
  };

  const tabs = {};

  tabs.Dashboard = [
    <DashboardPage key="page" />,
    <DashboardIcon
      key="icon"
      style={{
        color: pane === "Dashboard" ? "white" : "black",
      }}
    />,
    "/dashboard",
  ];

  tabs.Inventory = [
    <CrownJewels key="page" redrawTopBar={redrawTopBarFunc} />,
    <InventoryIcon
      key="icon"
      style={{
        color: pane === "Inventory" ? "white" : "black",
      }}
    />,
    "/inventory",
  ];

  tabs.Risk = [
    <Risk key="page" />,
    <RiskIcon
      key="icon"
      style={{
        color: pane === "Risk" ? "white" : "black",
      }}
    />,
    "/risk",
  ];

  tabs.Resilience = [
    <ResProvider key="page">
      <Resilience />
    </ResProvider>,
    <ResilienceIcon
      key="icon"
      style={{
        color: pane === "Resilience" ? "white" : "black",
      }}
    />,
    "/resilience",
  ];

  tabs[t("coverageLabel")] = [
    <ControlsPage key="controls" />,
    <ControlsIcon
      key="icon"
      style={{
        color: pane === "Coverage" ? "white" : "black",
      }}
    />,
    "/coverage",
  ];

  tabs.Reports = [
    <ReportingRoot key="page" />,
    <ReportingIcon
      key="icon"
      style={{
        color: pane === "Reports" ? "white" : "black",
      }}
    />,
    "/reporting",
  ];

  return (
    <div>
      {showDisclaimer && (
        <div>
          <AppBar position="absolute" className={classes.headerbar}>
            {/* Logo bar */}
            <Toolbar
              className={classes.logobar}
              style={{
                minHeight: "3.25rem",
                height: "3.25rem",
                display: "flex",
                paddingLeft: "4em",
                justifyContent: "space-between",
              }}
            >
              <a href="/app">
                <img height="23px" src={logo} alt="Logo" />
              </a>
            </Toolbar>
          </AppBar>
          <div
            style={{
              height: "50px",
              marginTop: "100px",
              fontSize: "125%",
              padding: ".75rem",
            }}
          >
            {window._env_.REACT_APP_DISCLAIMER_TEXT}
          </div>
          <Button
            onClick={acceptTerms}
            variant="contained"
            style={{ marginTop: "75px", marginLeft: "75px" }}
          >
            Agree
          </Button>
        </div>
      )}{" "}
      {!showDisclaimer && (
        <div>
          <Navigation
            tabs={tabs}
            util={util}
            state={state}
            setState={setState}
            preHydrated={preHydrated}
            setPreHydrated={setPreHydrated}
            handleOpenSearch={handleOpenSearch}
            pane={pane}
            setPane={setPane}
            redrawTopBar={redrawTopBar}
          />
          <Body tabs={tabs} util={util} />
        </div>
      )}
      <Modal
        open={searchOpen}
        onClose={handleCloseSearch}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {search}
      </Modal>
    </div>
  );
}

export default withOktaAuth(SPA);
