import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@material-ui/core";
import { useApi } from "../API";
import { palette } from "../utils/theme";
import StyledModal from "../components/StyledModal";

const DeleteModal = ({ application, open, onClose, toggleRefresh }) => {
  const api = useApi();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [targetApplication, setTargetApplication] = useState();

  useEffect(() => {
    setTargetApplication(application);
  }, [application]);

  const handleDelete = async () => {
    setLoading(true);
    setError(false);
    try {
      await api.deleteApplication(targetApplication.id);
      toggleRefresh();
      onClose();
    } catch (e) {
      setError(true);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledModal
      open={open}
      title={`Delete Business Process: ${application?.display_name}`}
      onClose={() => {
        setError(false);
        setTargetApplication(null);
        onClose();
      }}
      destructive
      onSubmit={handleDelete}
    >
      <Grid container spacing={2} direction="column">
        {error && (
          <Typography variant="subtitle1" style={{ color: palette.red }}>
            Error deleting business process.
          </Typography>
        )}
        <Grid item>
          <Typography variant="subtitle1">
            Are you sure you want to delete this business process?
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            This will cause all assets associated with{" "}
            <strong>{application?.display_name}</strong> will no longer be
            associated with any business process.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            This action is <strong>permanent</strong> and cannot be undone.
          </Typography>
        </Grid>
      </Grid>
    </StyledModal>
  );
};

export default DeleteModal;
