import React, { useRef, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import { Button, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import logo from "../assets/logo.svg";
import { palette } from "../utils/theme";
import { emailIsValid } from "../utils";

const JWTAuth = () => {
  const history = useHistory();

  const emailInputRef = useRef(null);
  const pwdInputRef = useRef(null);

  const [forgotPWSuccess, setForgotPWSuccess] = useState();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [forgotPasswordView, setForgotPasswordView] = useState(false);
  const [badPassword, setBadPassword] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  function login() {
    setBadPassword(false);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username,
        password,
      }),
    };

    fetch("/api/v2/login", requestOptions)
      .then((response) => {
        if (response.status === 200) {
          history.push("/app");
        } else {
          setBadPassword(true);
          setPassword("");
          setUsername("");
          setEmail("");
        }
      })
      .catch(() => {
        setBadPassword(true);
        setPassword("");
        setEmail("");
        setUsername("");
      });
  }

  const handleSubmit = (e) => {
    if (password !== "" && username !== "") {
      e.preventDefault();

      login();
    } else {
      e.preventDefault();
      if (password === "") {
        pwdInputRef.current.focus();
        setShowErrorMsg(true);
      }
      if (username === "") {
        emailInputRef.current.focus();
        setShowErrorMsg(true);
      }
    }
  };

  const toggleForgotPasswordView = () => {
    setForgotPasswordView(!forgotPasswordView);
  };

  const handleForgotPasswordSubmit = async () => {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ email }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      await fetch(`/api/user/forgot-password`, requestOptions);
      setForgotPWSuccess(true);
      setEmail("");
    } catch {
      setForgotPWSuccess(false);
      setEmail("");
    }
  };

  if (forgotPasswordView) {
    return (
      <div>
        <AppBar>
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" edge="start" />
            <img height="23px" src={logo} alt="Logo" />
          </Toolbar>
        </AppBar>
        <Grid
          container
          style={{ paddingTop: "120px" }}
          justifyContent="center"
          alignItems="center"
          direction="column"
          spacing={3}
        >
          <Grid item xs={12} alignContent="center">
            {!forgotPWSuccess && (
              <Typography>Request Password Reset Link</Typography>
            )}
            {forgotPWSuccess && (
              <>
                <Typography
                  variant="body1"
                  style={{ color: palette.green, textAlign: "center" }}
                >
                  Check your email.
                </Typography>

                <Typography variant="body1" style={{ textAlign: "center" }}>
                  Try again if you do not receive an email soon.
                </Typography>
              </>
            )}
          </Grid>
          {!forgotPWSuccess && (
            <>
              <Grid item xs={12}>
                <TextField
                  value={email}
                  onInput={(e) => {
                    // TODO additional validation for a valid email address
                    setEmailInvalid(!emailIsValid(e.target.value));
                    setEmail(e.target.value);
                  }}
                  error={emailInvalid}
                  variant="outlined"
                  label="Email"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={handleForgotPasswordSubmit}
                  disabled={emailInvalid}
                  color="primary"
                  variant="contained"
                >
                  Send Email
                </Button>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Button
              color="primary"
              onClick={() => {
                setEmail("");
                setForgotPWSuccess(false);
                toggleForgotPasswordView();
              }}
            >
              Back to Login
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div>
      <AppBar>
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" edge="start" />
          <img height="23px" src={logo} alt="Logo" />
        </Toolbar>
      </AppBar>
      <Grid
        container
        style={{ paddingTop: "120px" }}
        justifyContent="center"
        alignItems="center"
        direction="column"
        spacing={0}
      >
        <Grid item xs={12}>
          <form noValidate autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
            {badPassword && (
              <FormHelperText
                style={{
                  color: palette.red,
                  textAlign: "center",
                  marginBottom: "16px",
                  height: "16px",
                }}
              >
                Sorry, invalid credentials. Please try again.
              </FormHelperText>
            )}
            {showErrorMsg && (
              <FormHelperText
                style={{
                  color: palette.red,
                  textAlign: "center",
                  marginBottom: "16px",
                  height: "16px",
                }}
              >
                Incomplete form. Please complete the form and try again.
              </FormHelperText>
            )}
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              spacing={2}
            >
              <Grid item xs={12}>
                <TextField
                  value={username}
                  onInput={(e) => setUsername(e.target.value)}
                  variant="outlined"
                  label="Username"
                  inputRef={emailInputRef}
                  data-test="login-username-input"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={password}
                  onInput={(e) => setPassword(e.target.value)}
                  variant="outlined"
                  label="Password"
                  type="password"
                  inputRef={pwdInputRef}
                  data-test="login-password-input"
                />
              </Grid>
            </Grid>
            <br />
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              spacing={2}
            >
              <Grid item xs={12}>
                <Button
                  data-test="login-submit-btn"
                  type="submit"
                  variant="contained"
                >
                  Login
                </Button>
              </Grid>
              <Button
                style={{
                  textTransform: "none",
                  fontWeight: "normal",
                  marginTop: "1em",
                  backgroundColor: "white",
                  border: "none",
                  color: palette.darkBlue,
                }}
                onClick={toggleForgotPasswordView}
              >
                Forgot Password?
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default JWTAuth;
