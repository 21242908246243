import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import { Switch, Route, useLocation } from "react-router-dom";
import TabPanel from "../components/TabPanel";
import { RoundedTab, RoundedTabs } from "../components/RoundedTab";
import OverviewTab from "./OverviewTab";
import AssetOverview from "../dashboard/AssetOverview";
import CoverageOverview from "./CoverageOverview";

const useStyles = makeStyles((theme) => ({
  // root: theme.layout.root,
  content: theme.layout.content,
  contentWrapper: theme.layout.contentWrapper,
  contentGrid: theme.layout.contentGrid,
  secondaryNav: theme.layout.secondaryNav,
}));

function ControlsPage() {
  const classes = useStyles();
  const baseRoute = "/app/coverage";

  // we are using pathnames as the current tab identifier in this view and we need
  // to make sure we grab the current pathname from the route to set the tab value
  // based on how the user navigated to the page
  const location = useLocation();
  const [docTitle, setDocTitle] = useState("KeyCaliber - Coverage");
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (window.location.pathname === baseRoute) {
      setDocTitle("KeyCaliber - Coverage");
    }
  }, [location]);

  document.title = docTitle;

  return (
    <Box className={classes.root} data-test="controls-page">
      <div className={classes.contentWrapper}>
        <Box className={classes.content}>
          <RoundedTabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="primary tabs example"
          >
            <RoundedTab
              label="Coverage"
              id="primary-tab-0"
              value={0}
              aria-controls="primary-tabpanel-0"
            />
            {/* <RoundedTab label="Grouped" value={1} id={1} /> */}
          </RoundedTabs>
          <Switch>
            <Route exact path="/app/coverage">
              <TabPanel value={tabValue} index={0}>
                <OverviewTab />

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CoverageOverview />
                  </Grid>
                  <Grid item xs={12}>
                    <AssetOverview />
                  </Grid>
                </Grid>
              </TabPanel>
            </Route>
          </Switch>
        </Box>
      </div>
    </Box>
  );
}

export default ControlsPage;
