import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AssetIcon from "../components/AssetIcon";
import { useApi } from "../API";

const useStyles = makeStyles(() => ({
  fieldContainer: {
    margin: "10px 0",
    width: "300px",
  },
  inputItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  assetIcon: {
    marginRight: "10px",
  },
}));

export default function QueryInputs({
  assetFilter,
  appFilter,
  onChangeAssetFilter,
  onChangeApplicationFilter,
  disabled,
  useCase,
  userMessage,
}) {
  const classes = useStyles();
  const [allAssets, setAllAssets] = useState([]);
  const [allApplications, setAllApplications] = useState([]);
  const api = useApi();

  const typeaheadChanged = (newValue) => {
    if (
      useCase.value === "asset-to-workstation" ||
      useCase.value === "asset-connections"
    ) {
      if (newValue === "") {
        api
          .criticalAssets()
          .then((res) => {
            setAllAssets(res.data);
          })
          .catch((error) => console.log(error));
      } else {
        api
          .allAssetsSearch(newValue)
          .then((res) => {
            setAllAssets(res.data);
          })
          .catch((error) => console.log(error));
      }
    } else if (useCase.value === "application-connections") {
      api
        .getApplications()
        .then((res) => {
          setAllApplications(res);
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <>
      {useCase &&
        (useCase.value === "asset-to-workstation" ||
          useCase.value === "asset-connections") && (
          <Autocomplete
            id="critical-assets-autocomplete"
            disabled={disabled}
            value={assetFilter}
            autoComplete
            className={classes.fieldContainer}
            options={allAssets}
            getOptionLabel={(option) => {
              if (option.id >= 0) {
                return `${option.name || "No Name"} (${option.ipv4}, ${
                  option.hostname
                })`;
              }
              return "Asset Name, IP, Hostname";
            }}
            onChange={onChangeAssetFilter}
            onInputChange={typeaheadChanged}
            renderOption={(option) => (
              <div className={classes.inputItem}>
                <div className={classes.assetIcon}>
                  <AssetIcon
                    svgWidth={24}
                    criticalityPercentage={option.criticality}
                    isCritical={option.is_critical}
                  />
                </div>
                <div>
                  {option.name || "No Name"}
                  <br />
                  <Typography variant="caption" component="div">
                    {option.ipv4}, {option.asset_type}, {option.hostname}
                  </Typography>
                </div>
              </div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                onFocus={() => typeaheadChanged("")}
                placeholder="Asset Name, IP, Hostname"
                variant="outlined"
                helperText={userMessage || ""}
                error={!!userMessage}
              />
            )}
          />
        )}
      {useCase && useCase.value === "application-connections" && (
        <Autocomplete
          id="applications-autocomplete"
          disabled={disabled}
          value={appFilter}
          autoComplete
          className={classes.fieldContainer}
          options={allApplications}
          getOptionLabel={(option) => {
            return option.display_name;
          }}
          onChange={onChangeApplicationFilter}
          onInputChange={typeaheadChanged}
          renderOption={(option) => (
            <div className={classes.inputItem}>
              <div className={classes.assetIcon}>{option.display_name}</div>
            </div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              onFocus={() => typeaheadChanged("")}
              placeholder="Enter Application Name"
              variant="outlined"
              helperText={userMessage}
              error={!!userMessage}
            />
          )}
        />
      )}
    </>
  );
}
