import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import ToastContext from "../context/ToastContext";
import { useApi } from "../API";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  outerWrapper: {
    minHeight: "400px",
    flexGrow: 1,
    maxWidth: "800px",
  },
  wrapper: {
    position: "relative",
    padding: "1rem",
  },
  innerWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(1),
  },
  buttonWrapper: {
    margin: theme.spacing(1),
    position: "relative",
    width: "100%",
  },
  option: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    flexGrow: 1,
    padding: "0 0 1rem",
  },
  formControlRoot: {
    width: "100%",
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonUpload: {
    width: 150,
  },
  circularProgress: {
    color: "black",
    padding: "0 1rem",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: "white",
    border: "2px solid #000",
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function UploadForm(props) {
  const classes = useStyles();
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [url, setUrl] = useState(null);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [batchSize, setBatchSize] = useState(null);
  const [batchLimit, setBatchLimit] = useState(null);

  const toastContext = useContext(ToastContext);

  useEffect(() => {
    handleCheckCreds();
  }, []);

  const FileUploadFeedback = (status) => {
    if (status.status === "OK") {
      return toastContext.showToast(
        "open",
        "success",
        `Configuration successfully updated.`
      );
    }
    return toastContext.showToast(
      "open",
      "error",
      `Oops. Something went wrong uploading the passed REST credentials. Please check the files and try again.`
    );
  };

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const handleCheckCreds = () => {
    api.getRestCredentials().then((res) => {
      if (res.url) {
        setUrl(res.url);
      }
      if (res.username) {
        setUsername(res.username);
      }
      if (res.password) {
        setPassword(res.password);
      }
      if (res.batchingSize) {
        setBatchSize(res.batchingSize);
      }
      if (res.batchLimit) {
        setBatchLimit(res.batchLimit);
      }
    });
  };

  const handleUrlChange = (e) => {
    const { value } = e.target;
    if (url !== value) {
      setUrl(value);
    }
  };

  const handleUsernameChange = (e) => {
    const { value } = e.target;
    if (username !== value) {
      setUsername(value);
    }
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    if (password !== value) {
      setPassword(value);
    }
  };

  const handleBatchSizeChange = (e) => {
    const { value } = e.target;
    if (batchSize !== value) {
      setBatchSize(value);
    }
  };

  const handleBatchLimitChange = (e) => {
    const { value } = e.target;
    if (batchLimit !== value) {
      setBatchLimit(value);
    }
  };

  const uploadData = (url, username, password, batchingSize, batchLimit) => {
    api
      .putRestExportCredentials(url, username, password, batchingSize, batchLimit)
      .then((res) => {
        if (res.status === "OK") {
          setSuccess(true);
          setLoading(false);
        } else {
          setSuccess(false);
        }
        FileUploadFeedback(res);
      });
  };

  const handleUpload = () => {
    if (!loading) {
      setLoading(true);
      setSuccess(false);
      uploadData(url, username, password, batchSize, batchLimit);
    }
  };

  return (
    <Box
      className={classes.root}
      p={4}
      style={{ position: "relative", top: props.preHydrated && "-80px" }}
    >
      <Box component="div">
        <Typography component="h5" variant="h6">
          REST Export Options
        </Typography>
      </Box>
      <Box className={classes.outerWrapper}>
        <div className={classes.wrapper}>
          <div className={classes.innerWrapper}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  label="URL"
                  placeholder="url"
                  id="url-input"
                  error={false}
                  value={url}
                  onChange={handleUrlChange}
                  fullWidth
                  variant="outlined"
                  name="url"
                  data-test="url-input"
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Username"
                  placeholder="username"
                  id="username-input"
                  error={false}
                  value={username}
                  onChange={handleUsernameChange}
                  fullWidth
                  variant="outlined"
                  name="username"
                  data-test="username-input"
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Password"
                  placeholder="password"
                  id="password-input"
                  type="password"
                  error={false}
                  value={password}
                  onChange={handlePasswordChange}
                  fullWidth
                  variant="outlined"
                  name="password"
                  data-test="password-input"
                />
              </Grid>
              <Grid item>
                <TextField
                  type="number"
                  disabled={false}
                  label="Batch Size"
                  placeholder="batchSize"
                  id="batchSize-input"
                  error={false}
                  value={batchSize}
                  onChange={handleBatchSizeChange}
                  fullWidth
                  variant="outlined"
                  name="batchSize"
                  data-test="batchSize-input"
                />
              </Grid>
              <Grid item>
                <TextField
                  type="number"
                  disabled={false}
                  label="Batch Limit"
                  placeholder="batchLimit"
                  id="batchLimit-input"
                  error={false}
                  value={batchLimit}
                  onChange={handleBatchLimitChange}
                  fullWidth
                  variant="outlined"
                  name="batchLimit"
                  data-test="batchLimit-input"
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  style={{ width: "100%" }}
                  className={buttonClassname}
                  disabled={
                    !url || !username || !password || !batchSize || !batchLimit || loading
                  }
                  onClick={handleUpload}
                >
                  Update
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </Box>
    </Box>
  );
}
