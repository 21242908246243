import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useApi } from "../API";
import TopAlertsTable from "./TopAlertsTable";
import ChartWrapper from "../components/ChartWrapper";
import BigNumberCard from "../components/BigNumberCard";

const AlertsDashboard = () => {
  const api = useApi();
  const [data, setData] = useState();
  const [connectors, setConnectors] = useState();

  const getSolutionIdsForType = (kcType) => {
    return connectors
      ?.filter((el) => el.kc_type === kcType)
      ?.map((el) => el?.solution?.id);
  };

  const getSolutionIdsExceptType = (kcType) => {
    return connectors
      ?.filter((el) => el.kc_type !== kcType)
      ?.map((el) => el?.solution?.id);
  };

  useEffect(async () => {
    try {
      const result = await api.alertsStats();
      const connectorRes = await api.getConnectors();
      setData(result);
      setConnectors(connectorRes);
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ChartWrapper
          chartName="Top Alerts"
          chart={
            <Grid container item spacing={3}>
              <Grid item xs={12}>
                <TopAlertsTable />
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <BigNumberCard
              value={data?.total}
              queryString="min_alerts=1"
              title="Total Assets with Alerts"
            />
          </Grid>
          <Grid item xs={4}>
            <BigNumberCard
              value={data?.new}
              queryString={`min_alerts=1&missing_coverage=${getSolutionIdsExceptType(
                "alert"
              )?.join(",")}`}
              title="Assets with Alerts Missing All Coverage"
            />
          </Grid>
          <Grid item xs={4}>
            <BigNumberCard
              value={data?.confidential}
              queryString="min_alerts=1&data_class=Confidential"
              title="Assets with Alerts with Confidential Data"
            />
          </Grid>

          <Grid item xs={4}>
            <BigNumberCard
              value={data?.missing_vuln}
              queryString={`min_alerts=1&missing_coverage=${getSolutionIdsForType(
                "vuln"
              )?.join(",")}`}
              title="Assets with Alerts with No Vulnerability Scanner"
            />
          </Grid>
          <Grid item xs={4}>
            <BigNumberCard
              value={data?.missing_edr}
              queryString={`min_alerts=1&missing_coverage=${getSolutionIdsForType(
                "endpoint"
              )?.join(",")}`}
              title="Assets with Alerts with No EDR"
            />
          </Grid>
          <Grid item xs={4}>
            <BigNumberCard
              value={data?.missing_cmdb}
              queryString={`min_alerts=1&missing_coverage=${getSolutionIdsForType(
                "cmdb"
              )?.join(",")}`}
              title="Assets with Alerts with No CMDB"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AlertsDashboard;
