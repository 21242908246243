/**
 * This module holds common prop type definitions that are used in multiple
 * places in the app.
 */

import PropTypes from "prop-types";

export const LocationPropType = PropTypes.oneOfType([
  PropTypes.string.isRequired,
  PropTypes.shape({
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
]);

/**
 * An "asset" object from the API
 */
export const AssetPropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  ipv4: PropTypes.string,
});
