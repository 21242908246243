import React, { useContext } from "react";
import Typography from "@material-ui/core/Typography";
import ToastContext from "../../context/ToastContext";
import { useApi } from "../../API";
import StyledModal from "../../components/StyledModal";

export default function DatasourceDeleteDialog({
  handleClose,
  datasourceId,
  handleDatasourcesChanged,
  open,
}) {
  const api = useApi();
  const toastContext = useContext(ToastContext);
  const handleDelete = () => {
    api
      .deleteDatasource(datasourceId)
      .then((res) => {
        if (res.status === "OK") {
          handleClose();
          handleDatasourcesChanged();
          toastContext.showToast(
            "open",
            "success",
            "Datasource has been deleted."
          );
        } else {
          handleClose();
          toastContext.showToast(
            "open",
            "error",
            "There was an error deleting the Datasource. Please try again."
          );
        }
      })
      .catch(() => {
        handleClose();
        toastContext.showToast(
          "open",
          "error",
          "There was an error deleting the Datasource. Please ensure the Datasource is not in use by any Connectors."
        );
      });
  };

  return (
    <StyledModal
      onClose={handleClose}
      open={open}
      title="Delete Datasource"
      onSubmit={handleDelete}
      destructive
    >
      <Typography gutterBottom>
        Are you sure you want to delete this Datasource?
      </Typography>
    </StyledModal>
  );
}
