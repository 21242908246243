import { createTheme } from "@material-ui/core/styles";

export const cyan = {
  50: "rgb(169, 234, 234)", // lightBlue
  100: "#00b4c6", // KeyCaliber Blue
  200: "#00b4c6",
  300: "#00b4c6",
  400: "#00b4c6",
  500: "#00b4c6",
  600: "#00b4c6",
  700: "#00b4c6",
  800: "#00b4c6",
  900: "#00b4c6",
  A100: "#00b4c6",
  A200: "#00b4c6",
  A400: "#00b4c6",
  A700: "#00b4c6",
};

export const keyBlue = "#38B3CF";
const highImpact = "#3C499B";
export const palette = {
  primary: {
    500: "#383D3A",
  },
  // secondary: {
  //   A400: "#3B73C7",
  // },
  secondary: {
    A400: highImpact,
  },
  logo: cyan.A100,
  keyBlue,
  lightBlue: cyan[50],
  limeGreen: "#00FF00",
  darkBlue: "#005ca2",
  purple1: highImpact,
  purple2: keyBlue,
  pink: "#FBCAF3",
  background: "#F5F7F9", // lighter gray
  foreground: "#383D3A", // same as darkgray
  border: "#D9DDE6",
  disabledText: "rgba(0,0,0,0.38)",
  lightGray: "#ECEFF0",
  darkGray: "#383D3A",
  textColor: "#383D3A",
  orange: "#E4A045",
  red: "#DB4935",
  darkRed: "#B11700",
  green: "#5ABD6C",
  yellowGreen: "#A2BD5A",
  bluegreen: "#41ffdc",
  yellow: "#DEB82C",
  turquoise: "#41ffdc",
  highImpact,
  lowImpact: "#6BC3E4",
};
export const chartPalette = {
  // d3 paired color palette
  paired1: "#a6cee3",
  paired2: "#1f78b4",
  paired3: "#b2df8a",
  paired4: "#33a02c",
  paired5: "#fb9a99",
  paired6: "#e31a1c",
  paired7: "#fdbf6f",
  paired8: "#ff7f00",
  paired9: "#cab2d6",
  paired10: "#6a3d9a",
  paired11: "#ffff99",
  paired12: "#b15928",
};

export const set2ChartPalette = [
  "#66c2a5",
  "#fc8d62",
  "#8da0cb",
  "#e78ac3",
  "#a6d854",
  "#ffd92f",
  "#e5c494",
  "#b3b3b3",
];

export const theme = createTheme({
  typography: {
    fontFamily: [
      '"Roboto"',
      '"Helvetica Neue"',
      '"Helvetica"',
      "sans-serif",
    ].join(","),
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        ".MuiBadge-colorSecondary": {
          color: "white!important",
        },
        html: {
          WebkitFontSmoothing: "auto",
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "white",
      },
    },
  },
  palette,
  chartPalette,
  navbar: {
    background: "#ECEFF0",
    text: "#383D3A",
    selected: keyBlue,
    icons: "#ffffff",
  },
  filtering: {
    background: "#f0f",
  },
  type: "dark",
  layout: {
    root: {
      minHeight: "100%",
      display: "flex",
      backgroundColor: palette.background,
    },
    content: {
      flexGrow: 1,
      padding: "0 1.5rem 1rem",
    },
    contentWrapper: {
      display: "block",
      marginTop: "2.5em",
      width: "100%",
      paddingTop: "1.5rem",
      paddingLeft: "2rem",
      paddingRight: "2rem",
      // window - header - footer
      minHeight: "calc(100vh - 7rem)",
    },
    contentGrid: {
      width: "100%",
    },
    secondaryNav: {
      backgroundColor: palette.background,
      icons: "#383D3A",
      color: "#383D3A!important",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
});
