import React, { useEffect, useState } from "react";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ResponsiveLine } from "@nivo/line";
import { useApi } from "../API";
import ChartWrapper from "../components/ChartWrapper";
import ChartWrapperHeader from "../components/ChartWrapperHeader";
import { abbreviateLargeValue } from "../utils";
import TimePeriodDropDown from "../components/TimePeriodDropDown";

const MOCK_OUT_DEMO = window._env_ && window._env_.MOCK_OUT_DEMO === "true";

function renderTooltip({ point }) {
  return (
    <div
      style={{
        backgroundColor: "white",
        color: "inherit",
        fontSize: "inherit",
        borderRadius: "2px",
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
        padding: "5px 9px",
      }}
    >
      <div>
        <strong>{point.id.split(".")[0]}:</strong>
        <br />
        {abbreviateLargeValue(point.data.y.toFixed(0), true)} on{" "}
        {point.data.x.toLocaleDateString()}
      </div>
    </div>
  );
}
const useStyles = makeStyles(() => ({
  item: {
    maxWidth: "100vw",
    flexGrow: 1,
    minWidth: 0,
    height: 600,
  },
}));

function CoverageOverTime() {
  const classes = useStyles();
  const api = useApi();
  const [cachedResponse, setCachedResponse] = useState({
    data: [],
    loading: true,
    error: false,
  });
  const [data, setData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("all_assets");
  const [selectedPeriod, setSelectedPeriod] = useState(
    MOCK_OUT_DEMO ? "year" : "biweekly"
  );

  const handlePeriodChange = (value) => {
    setSelectedPeriod(value);
  };

  const handleGroupChange = ({ target: { value } }) => {
    setSelectedGroup(value);
  };

  useEffect(() => {
    if (cachedResponse?.length) {
      if (selectedGroup !== "all_assets") {
        const metrics = [
          ...cachedResponse?.filter((el) => {
            // Need to come up with a better way to identify the metrics we want
            // by naming convention.
            return (
              el.name.endsWith(selectedGroup) &&
              el.name.startsWith("total_covered_by_")
            );
          }),
        ];
        setData(formatMetricDataForNivo(null, metrics));
      } else {
        const metrics = [
          ...cachedResponse?.filter(
            (el) =>
              (el.name.startsWith("total_covered_by_") ||
                el.name === "total_assets") &&
              !el.name.includes("_group:") &&
              !el.name.includes("_high_impact")
          ),
        ];
        setData(formatMetricDataForNivo(null, metrics));
      }
    }
  }, [selectedGroup, cachedResponse]);

  useEffect(async () => {
    setLoading(true);
    try {
      const res = await api.allMetrics({
        metrics: ["total_assets", "total_high_impact"],
        pattern: "total_covered_by*",
        period: selectedPeriod,
      });
      setCachedResponse(res.data?.metrics);

      const company = await api.getCompany();
      const customGroups = company.custom_rules_json?.groups;
      if (customGroups) {
        setGroupOptions([
          ...customGroups.map((option) => {
            return {
              name: option.display_name || option.value,
              value: `_group:${option.name}`,
            };
          }),
        ]);
      }
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [selectedPeriod]);

  const parseDate = (date) => {
    return new Date(Date.parse(date)).toISOString().slice(0, 10);
  };

  const formatMetricDataForNivo = (metricNames, metricsData) => {
    /**
     * TODO: We want to use percentages for the output, but the metric is in int format.
     * So divide by total assets to get percentages
     */
    let metrics = metricsData;

    if (metricNames && metricNames?.length) {
      metrics = metrics.filter((el) => metricNames.includes(el.name));
    }

    const nivoData = [];

    for (const metric of metrics) {
      const isPercentage = metric.format === "percentage";
      const dates = [];
      const formattedData = {
        data: [
          {
            x: parseDate(metric.date),
            y: isPercentage ? metric.value * 100 : metric.value,
          },
        ],
        id: metric.display_name,
      };
      dates.push(parseDate(metric.date));
      for (const pastMetric of metric.history) {
        if (!dates.includes(parseDate(pastMetric.date))) {
          formattedData.data.push({
            x: parseDate(pastMetric.date),
            y: isPercentage ? pastMetric.value * 100 : pastMetric.value,
          });
        }
      }

      nivoData.push(formattedData);
    }
    return nivoData;
  };

  if (error) {
    return <div>Error</div>;
  }

  return (
    <Grid item xs={12} className={classes.item}>
      <ChartWrapper excludeHeader>
        <ChartWrapperHeader chartName="Active Asset Coverage Over Time">
          <TextField
            id="standard-select-value"
            select
            label="Asset Group"
            size="small"
            style={{ width: 200, marginRight: ".5em" }}
            value={selectedGroup}
            variant="outlined"
            onChange={handleGroupChange}
          >
            <MenuItem value="all_assets">All Assets</MenuItem>
            <MenuItem value="_high_impact">High Impact Assets</MenuItem>
            {groupOptions?.map((el, idx) => {
              return (
                <MenuItem value={el.value} key={idx}>
                  {el.name}
                </MenuItem>
              );
            })}
          </TextField>
          <TimePeriodDropDown handlePeriodChange={handlePeriodChange} />
        </ChartWrapperHeader>
        <ResponsiveLine
          data={data}
          height={450}
          animate
          margin={{ top: 40, right: 325, bottom: 55, left: 60 }}
          curve="monotoneX"
          xScale={{
            type: "time",
            format: "%Y-%m-%d",
            useUTC: false,
            precision: "day",
          }}
          xFormat="time:%Y-%m-%d"
          yScale={{
            type: "linear",
            min: 0,
            max: "auto",
          }}
          axisLeft={{
            format: (d) => abbreviateLargeValue(d, true),
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: "middle",
            legendOffset: -40,
            legend: "Total Assets",
          }}
          axisBottom={{
            format: selectedPeriod === "year" ? "%b" : "%b %d",
            tickValues:
              selectedPeriod === "year"
                ? "every month"
                : selectedPeriod === "quarter" || selectedPeriod === "month"
                ? "every week"
                : "every day",
            legend:
              selectedPeriod === "year"
                ? "Month"
                : selectedPeriod === "quarter" || selectedPeriod === "month"
                ? "Week"
                : "Day",
            tickSize: 6,
            tickRotation: -30,
            legendOffset: 45,
            legendPosition: "middle",
          }}
          colors={{ scheme: "category10" }}
          pointSize={8}
          pointColor={["#fff"]}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          lineWidth={2}
          useMesh
          enableSlices={false}
          tooltip={renderTooltip}
          sliceTooltip={({ slice }) => (
            <div
              style={{
                background: "white",
                padding: "9px 12px",
                border: "1px solid #ccc",
              }}
            >
              <div>x: {slice.id}</div>
              {slice.points.map((point) => (
                <div
                  key={point.id}
                  style={{
                    color: point.serieColor,
                    padding: "1px 0",
                  }}
                >
                  <strong>
                    {selectedGroup} - {point.serieId}{" "}
                  </strong>{" "}
                  [{point.data.yFormatted}]
                </div>
              ))}
            </div>
          )}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: "#a9acbe",
                  strokeWidth: 0.7,
                },
              },
            },
            grid: {
              line: {
                stroke: "#e7e8ec",
                strokeWidth: 0.7,
              },
            },
          }}
          legends={[
            {
              anchor: "top-right",
              direction: "column",
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
            },
          ]}
        />
      </ChartWrapper>
    </Grid>
  );
}

export default CoverageOverTime;
