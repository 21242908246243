import React, { useEffect, useState } from "react";
import CheckIcon from "@material-ui/icons/Check";
import {
  Typography,
  Button,
  Grid,
  Divider,
  MenuItem,
  TextField,
  Popover,
  Box,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { useApi } from "../API";
import { palette } from "../utils/theme";

const EnhancedTableToolbar = (props) => {
  const api = useApi();
  const { selected } = props;
  const [application, setApplication] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setError(false);
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    setApplication(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(false);
    try {
      await updateApplication(selected);
      props.reloadTable();
      setAnchorEl(null);
    } catch (e) {
      setError(true);
      console.log(e);
    } finally {
      setLoading(false);
      setApplication(null);
    }
  };

  const updateApplication = (selected) => {
    if (application === -1) {
      api.removeApplicationAssets(selected);
    } else {
      api.addApplicationAssets(application, selected);
    }
  };

  const handleCancel = () => {
    setApplication(null);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (!selected?.length) {
    return <></>;
  }
  return (
    <Box p={1}>
      <Button
        aria-describedby={id}
        variant="outlined"
        onClick={handleClick}
        color="secondary"
        style={{ backgroundColor: "white" }}
        size="small"
      >
        Bulk Edit
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            minHeight: "20vh",
            padding: "1em",
            paddingBottom: ".5em",
            minWidth: 400,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography variant="subtitle1">
          Bulk Edit {selected?.length} Assets
        </Typography>
        <Divider />
        {error && (
          <Typography variant="subtitle1" style={{ color: palette.red }}>
            Error editing assets.
          </Typography>
        )}
        {loading ? (
          <Typography variant="subtitle1">Loading...</Typography>
        ) : (
          <div style={{ padding: "1em", paddingTop: "3em" }}>
            <Grid
              container
              direction="column"
              spacing={1}
              justifyContent="space-between"
            >
              <Grid item>
                <TextField
                  variant="outlined"
                  value={application}
                  select
                  fullWidth
                  onChange={handleChange}
                  label="Application"
                  placeholder="Application"
                  // variant="outlined"
                  style={{ backgroundColor: "white" }}
                >
                  <MenuItem value="">
                    <em>Add Assets to a Application</em>
                  </MenuItem>
                  <MenuItem
                    value={-1}
                    style={{ color: palette.red, fontWeight: 500 }}
                  >
                    No Application
                  </MenuItem>
                  {props.applications?.map((el, idx) => {
                    return (
                      <MenuItem key={idx} value={el.id}>
                        {el.display_name}{" "}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item style={{ minHeight: "6vh" }} />
              <Grid item style={{ bottom: 0 }}>
                <Grid container justifyContent="space-between" item spacing={1}>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      startIcon={<CheckIcon />}
                      disableElevation
                      color="secondary"
                    >
                      Confirm
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={handleCancel}
                      startIcon={<Close />}
                      disableElevation
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </Popover>
    </Box>
  );
};

export default EnhancedTableToolbar;
