import React from "react";
import {
  Grid,
  Card,
  Typography,
  Tooltip,
  IconButton,
  Icon,
  Divider,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { palette } from "../utils/theme";
import { numberWithCommas } from "../utils";

const BigNumberCard = (props) => {
  // const handleDownloadCSV = async (queryString) => {
  //   await api.assetsCsvFacetedSearch(queryString);
  // };

  return (
    <Card style={{ padding: ".5em 1em" }} variant="outlined">
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={11}>
          <Typography variant="subtitle1" style={{ fontSize: "1em" }}>
            {props.title}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          {/* <Tooltip placement="bottom" title="Download CSV">
                        <IconButton
                            onClick={() => handleDownloadCSV(props.queryString)}
                            size="small"
                        >
                            <Icon>
                                <img width="25px" src={downloadIcon} alt="Download CSV" />
                            </Icon>
                        </IconButton>
                    </Tooltip> */}
          <Tooltip placement="bottom" title="View in Inventory">
            <IconButton
              size="small"
              target="_blank"
              href={`/app/inventory/assets?${props.queryString}`}
            >
              <Icon style={{ color: palette.purple1 }}>
                <OpenInNewIcon />
              </Icon>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Divider />
      <div style={{ padding: "3em .5em", margin: "auto" }}>
        <Typography variant="h3">{numberWithCommas(props?.value)} </Typography>
      </div>
    </Card>
  );
};

export default BigNumberCard;
