import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  Button,
  AccordionDetails,
  AccordionActions,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
  TextField,
  Typography,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import { green } from "@material-ui/core/colors";
import { useHistory, useLocation, Switch, Route } from "react-router-dom";
import { svgIcons } from "../icons/Icons";
import Icon from "../icons/icon";
import TabPanel from "../components/TabPanel";
import { RoundedTab, RoundedTabs } from "../components/RoundedTab";
import { palette } from "../utils/theme";
import { basicCurrencyFormatter } from "../utils";
import { useApi } from "../API";
import useSettings from "../hooks/useSettings";
import ToastContext from "../context/ToastContext";
import DatasourceTable from "./connectors/DatasourceTable";
import ConnectorTable from "./connectors/ConnectorTable";
import RiskFactors from "./factors/RiskFactors";
import Ingestor from "./Ingestor";
import ConnectorDetails from "./connectors/ConnectorDetails";

const MOCK_DEMO =
  window._env_.MOCK_OUT_DEMO && window._env_.MOCK_OUT_DEMO === "true";

const useCustomRulesStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: "1em 0em",
    paddingRight: "4em",
  },
  rowLabel: {
    textAlign: "right",
    paddingRight: theme.spacing(3),
  },
  inputItems: {
    display: "flex",
  },
  jsonInput: {
    color: "#F1F1F1",
    fontFamily: "monospace",
    fontSize: "90%",
    backgroundColor: "#313131",
    borderBottom: "none",
    padding: "1em",
  },
}));

const AdvancedSettingsCustomRules = () => {
  const api = useApi();
  const classes = useCustomRulesStyles();
  const toastContext = useContext(ToastContext);

  const [expanded, setExpanded] = useState(false);
  const [cachedData, setCachedData] = useState();
  const [data, setData] = useState();
  const [jsonError, setJsonError] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(async () => {
    const result = await api.getCompany();

    const resultJson = result.custom_rules_json
      ? JSON.stringify(result.custom_rules_json, null, 2)
      : "";
    setData(resultJson);
    setCachedData(resultJson);
  }, [reload]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleInputChange = ({ target: { value } }) => {
    if (value) {
      try {
        JSON.parse(value);
        setJsonError(false);
      } catch {
        setJsonError(true);
      }
    } else {
      setJsonError(false);
    }

    setData(value);
  };

  const handleSave = async () => {
    try {
      await api.updateCompanyProfile({
        custom_rules_json: data,
      });
      setReload(!reload);
      setExpanded(false);
      toastContext.showToast("open", "success", "Saved rule configuration");
    } catch {
      toastContext.showToast(
        "open",
        "error",
        "Error saving rule configuration"
      );
    }
  };

  return (
    <Accordion
      elevation={0}
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          className={classes.heading}
          style={{ color: palette.purple1 }}
        >
          Advanced Settings
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.row}
        >
          <Typography variant="overline" display="block">
            JSON configuration for Custom Data Enrichment Rules
          </Typography>

          <TextField
            placeholder="Paste Valid JSON here"
            error={jsonError}
            helperText={jsonError && "Must be valid JSON"}
            name="custom_rules_json"
            onChange={handleInputChange}
            fullWidth
            size="small"
            multiline
            maxRows={24}
            value={data}
            InputProps={{
              spellCheck: "false",
              classes: {
                input: classes.jsonInput,
              },
            }}
          />
        </Grid>
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        <Grid container justifyContent="space-between">
          <Grid item container spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                disabled={jsonError}
                onClick={handleSave}
                color="secondary"
                disableElevation
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  setExpanded(false);
                  setData(cachedData);
                  setJsonError(false);
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
          <Grid item />
        </Grid>
      </AccordionActions>
    </Accordion>
  );
};

const useStyles = makeStyles((theme) => ({
  root: theme.layout.root,
  content: theme.layout.content,
  contentWrapper: theme.layout.contentWrapper,
  loading: {
    height: "100vh",
    width: "100vw",
    position: "fixed",
    opacity: ".5",
    background: "white",
    zIndex: 10000,
    top: -50,
    left: 0,
  },
  secondaryNav: theme.layout.secondaryNav,
  tabPanel: {
    background: theme.palette.background,
  },
  companySection: {
    padding: theme.spacing(2),
    background: "white",
  },
  wrapper: {
    position: "relative",
    margin: theme.spacing(1),
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const defaultSettings = {
  age_out_stale: {
    key: "age_out_stale",
    default_value: "14",
    display_name: "Mark assets as stale after",
    type: "days",
    value: null,
    min: 1,
  },
  age_out_delete: {
    key: "age_out_delete",
    default_value: "30",
    display_name: "Delete assets not seen in",
    type: "days",
    value: null,
    min: 30,
  },
  age_out_enabled: {
    key: "age_out_enabled",
    default_value: true,
    display_name: "Auto-Delete stale assets",
    type: "boolean",
    value: null,
  },
  financial_risk_enabled: {
    key: "financial_risk_enabled",
    default_value: false,
    display_name: "Show Financial Risk",
    type: "boolean",
    value: null,
    demo_only: true,
  },
};

const industryOptions = [
  { name: "Administrative", value: null },
  { name: "Agriculture", value: null },
  { name: "Construction", value: null },
  { name: "Education", value: null },
  { name: "Entertainment", value: null },
  { name: "Financial", value: null },
  { name: "Healthcare", value: null },
  { name: "Hospitality", value: null },
  { name: "Information", value: null },
  { name: "Management", value: null },
  { name: "Manufacturing", value: null },
  { name: "Mining", value: null },
  { name: "Other", value: null },
  { name: "Professional", value: null },
  { name: "Public", value: null },
  { name: "Real Estate", value: null },
  { name: "Retail", value: null },
  { name: "Trade", value: null },
  { name: "Transportation", value: null },
  { name: "Utilities", value: null },
];

function Settings() {
  const api = useApi();
  const classes = useStyles();
  const { pathname } = useLocation();

  // eslint-disable-next-line no-unused-vars
  const [settings, fetchSettings] = useSettings();
  const [tabValue] = useState(0);
  const [settingsEditMode, setSettingsEditMode] = useState(false);
  const [settingsData, setSettingsData] = useState({
    loading: true,
    error: false,
  });
  const [reloadSettings, setReloadSettings] = useState(false);
  const [newSettingsData, setNewSettingsData] = useState({});
  const [invalidSettings, setInvalidSettings] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [company, setCompany] = useState([
    {
      key: "name",
      label: "Company Name",
      icon: svgIcons.building,
      value: "",
    },
    {
      key: "industry",
      label: "Industry",
      icon: svgIcons.building,
      value: "",
    },
    {
      key: "revenue",
      label: "Yearly Revenue",
      icon: svgIcons.dollar,
      value: "",
    },
    {
      key: "num_employees",
      label: "Number of Employees",
      icon: svgIcons.accountCircle,
      value: "",
    },
  ]);

  const mergeWithDefaults = (defaultSetting, override) => {
    if (!override) {
      return defaultSetting;
    }

    const mergedSetting = { ...defaultSetting };

    for (const k in defaultSetting) {
      if (override[k] !== null && override[k] !== undefined) {
        mergedSetting[k] = override[k];
      }
    }
    return mergedSetting;
  };

  const mergeResponseWithDefaults = (data) => {
    const allowedSettings = Object.keys(defaultSettings).filter((el) =>
      defaultSettings[el].demo_only ? MOCK_DEMO : true
    );

    const merged = [];
    for (const settingKey of allowedSettings) {
      merged.push(
        mergeWithDefaults(
          defaultSettings[settingKey],
          data.find((el) => el.key === settingKey)
        )
      );
    }

    return [
      // ...data.filter((el) => !allowedSettings.includes(el.key)),
      ...merged,
    ].sort((a, b) => (a.key > b.key ? 1 : -1));
  };

  useEffect(() => {
    api
      .getSettings()
      .then((res) => {
        setSettingsData({
          data: mergeResponseWithDefaults(res.data),
          loading: false,
          error: false,
        });
      })
      .catch((err) => {
        console.log(err);
        setSettingsData({
          loading: false,
          error: true,
        });
      });
  }, [reloadSettings]);

  const handleSettingsSave = () => {
    api
      .updateSettings(Object.values(newSettingsData))
      .then(() => {
        setNewSettingsData({});
        setReloadSettings(!reloadSettings);
        fetchSettings();
      })
      .catch((err) => {
        console.log(err);
        // TODO handle error
      });
  };

  const handleSettingsChange = (newValue, item) => {
    const newData = {
      ...newSettingsData,
      [item.key]: { value: newValue, type: item.type, key: item.key },
    };
    setNewSettingsData(newData);
    setInvalidSettings(!isValidSettings(item, newData));
  };

  const formatSettingValue = (setting, withDescriptor = true) => {
    let formatted = "";
    let val = setting.value || setting.default_value;

    if (setting.type === "dollars") {
      val = parseFloat(val);
      if (withDescriptor) {
        formatted += "$";
      }

      formatted += `${parseFloat(val).toFixed(2)}`;
    } else if (setting.type === "days") {
      val = parseFloat(val);
      formatted = `${val.toFixed(0)}`;
      if (withDescriptor) {
        formatted += " Days";
      }
    } else {
      formatted = val;
    }

    return formatted;
  };

  useEffect(() => {
    api.companyProfile().then((res) => {
      const newCompany = JSON.parse(JSON.stringify(company));
      for (let i = 0; i < company.length; i += 1) {
        if (
          company[i].key === "revenue"
          // || company[i].key === "downtime_cost"
        ) {
          newCompany[i].value = basicCurrencyFormatter(res[company[i].key]);
        } else if (company[i].key === "num_employees") {
          newCompany[i].value = new Intl.NumberFormat().format(
            res[company[i].key]
          );
        } else {
          newCompany[i].value = res[company[i].key];
        }
      }
      setCompany(newCompany);
    });
  }, []);

  const isValidSettings = (item, newSettingsData) => {
    let valid = true;
    const setting = newSettingsData[item.key];
    const value = setting?.value;

    if (item.min !== undefined) {
      valid =
        value === "" || value === undefined || parseInt(value) >= item.min;
    } else if (item.type !== "text" && item.type !== "boolean") {
      valid = value === "" || value === undefined || parseInt(value) > 0;
    } else if (item.type === "boolean") {
      valid = [null, undefined, true, false].includes(value);
    }

    return valid;
  };

  const history = useHistory();

  const handleChange = (event, newValue) => {
    history.push(newValue);
  };

  const handleProfileSave = () => {
    api.updateCompanyProfile({
      name: company[0].value,
      industry: company[1].value,
      revenue: company[2].value,
      // downtime_cost: company[3].value,
      num_employees: company[3].value,
    });
  };

  const handleProfileChange = (event, label) => {
    const newCompany = JSON.parse(JSON.stringify(company));
    for (let i = 0; i < company.length; i += 1) {
      if (company[i].label === label) {
        newCompany[i].value = event.target.value;
      }
    }
    setCompany(newCompany);
  };

  document.title = "KeyCaliber - Settings";
  const tabs = [
    {
      label: "Settings",
      path: `/app/settings`,
      name: "settings",
      dataTest: "tab-button-settings",
    },
    {
      label: "Connectors",
      path: `/app/settings/connectors`,
      name: "connectors",
      dataTest: "tab-button-connectors",
    },
    {
      label: "Data Sources",
      path: `/app/settings/datasources`,
      name: "datasources",
      dataTest: "tab-button-datasources",
    },
    {
      label: "Risk Factors",
      path: `/app/settings/riskfactors`,
      name: "riskfactors",
      dataTest: "tab-button-riskfactors",
    },
    {
      label: "Export Settings",
      path: `/app/settings/export`,
      name: "export",
      dataTest: "tab-button-export",
    },
  ];
  return (
    <Box className={classes.root}>
      <div className={classes.contentWrapper}>
        <Box className={classes.content}>
          <RoundedTabs
            value={pathname}
            onChange={handleChange}
            aria-label="primary tabs example"
          >
            {tabs.map(({ name, label, path }) => (
              <RoundedTab
                key={name}
                id={`tab-${name}`}
                label={label}
                value={path}
                data-test={`settings-tab-${name}`}
              />
            ))}
          </RoundedTabs>

          {/* <TabPanel value={tabValue} index={2}>
            <Typography
              variant="body2"
              style={{ color: "#424242", padding: ".2em" }}
            >
              Solutions are the type of security solution data pulled into
              KeyCaliber. Coverage charts will show Solutions coverage.
            </Typography>
            <SolutionTable />
          </TabPanel> */}

          <Switch>
            <Route exact path="/app/settings/">
              <Paper className={classes.companySection} variant="outlined">
                {!editMode && (
                  <Box p={1}>
                    <Typography variant="h4" component="h1">
                      {company[0].value}
                      <IconButton
                        aria-label="edit"
                        onClick={() => setEditMode(true)}
                        style={{ float: "right" }}
                        data-test="edit-company-profile-button"
                      >
                        <EditIcon />
                      </IconButton>
                    </Typography>
                    <List style={{ display: "flex", flexWrap: "wrap" }}>
                      {company.map((item, i) => (
                        <React.Fragment key={i}>
                          {item.key !== "name" && (
                            <ListItem
                              key={`display-${item.key}`}
                              style={{ maxWidth: "25%" }}
                            >
                              <ListItemAvatar>
                                <Avatar>
                                  <Icon
                                    icon={item.icon}
                                    foreground={palette.darkGray}
                                    transform={0.8}
                                  />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={item.value}
                                secondary={item.label}
                              />
                            </ListItem>
                          )}
                        </React.Fragment>
                      ))}
                    </List>
                  </Box>
                )}{" "}
                {editMode && (
                  <Box>
                    <List style={{ display: "flex", flexWrap: "wrap" }}>
                      {company.map((item) => (
                        <ListItem
                          key={`edit-${item.key}`}
                          style={{ maxWidth: "25%" }}
                        >
                          <ListItemAvatar>
                            <Avatar>
                              <Icon
                                icon={item.icon}
                                transform={0.8}
                                foreground={palette.darkGray}
                              />
                            </Avatar>
                          </ListItemAvatar>
                          {item.key === "industry" ? (
                            <FormControl>
                              <InputLabel
                                shrink
                                id="demo-simple-select-placeholder-label-label"
                              >
                                Industry
                              </InputLabel>
                              <Select
                                id="industry-input"
                                value={item.value}
                                label="Industry"
                                onChange={(e) =>
                                  handleProfileChange(e, item.label)
                                }
                              >
                                <MenuItem value="" disabled>
                                  Select Industry
                                </MenuItem>
                                {industryOptions.map((el) => {
                                  return (
                                    <MenuItem value={el.value || el.name}>
                                      {el.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          ) : (
                            <TextField
                              style={{ width: "100%" }}
                              defaultValue={item.value}
                              label={item.label}
                              onChange={(e) =>
                                handleProfileChange(e, item.label)
                              }
                              data-test={`company-${item.key}-input`}
                            />
                          )}
                        </ListItem>
                      ))}
                    </List>
                    <Button
                      style={{ margin: "1rem" }}
                      variant="contained"
                      color="secondary"
                      disableElevation
                      onClick={() => {
                        setEditMode(false);
                        handleProfileSave();
                      }}
                      data-test="save-company-profile-button"
                    >
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      style={{ margin: "1rem 1rem 1rem 0" }}
                      onClick={() => {
                        setEditMode(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                )}
              </Paper>

              {settingsData.error && <div>Error loading settings data</div>}
              {settingsData.loading && <div>Loading settings data...</div>}

              <Paper
                className={classes.companySection}
                style={{ marginTop: "2em" }}
                variant="outlined"
              >
                {!settingsEditMode && (
                  <Box p={1}>
                    <Typography variant="h4" component="h1">
                      <IconButton
                        aria-label="edit"
                        onClick={() => setSettingsEditMode(true)}
                        style={{ float: "right" }}
                        data-test="edit-settings-button"
                      >
                        <EditIcon />
                      </IconButton>
                    </Typography>
                    <List style={{ display: "flex", flexWrap: "wrap" }}>
                      {settingsData.data &&
                        !settingsData.loading &&
                        !settingsData.error &&
                        settingsData.data?.map((item, i) => {
                          const displayName =
                            item.key === "age_out_delete" &&
                              item.display_name === "Delete stale assets after"
                              ? "Delete stale assets not seen in"
                              : item.display_name;

                          if (item.type === "boolean") {
                            const isChecked =
                              newSettingsData[item.key]?.value ??
                              item.value ??
                              item.default_value;
                            return (
                              <React.Fragment key={i}>
                                <ListItem
                                  key={`display-${item.key}`}
                                  style={{ maxWidth: "25%" }}
                                >
                                  <Switch disabled checked={isChecked} />
                                  <ListItemText
                                    primary={displayName}
                                    secondary={`${isChecked ? "Enabled" : "Disabled"
                                      }`}
                                  />
                                </ListItem>
                              </React.Fragment>
                            );
                          }
                          return (
                            <React.Fragment key={i}>
                              <ListItem
                                key={`display-${item.key}`}
                                style={{ maxWidth: "25%" }}
                              >
                                {/* <ListItemAvatar>
                                <Avatar>
                                  <Icon
                                    icon={
                                      item.type === "dollars"
                                        ? svgIcons.dollar
                                        : item.type === "days"
                                          ? svgIcons.clockAlert
                                          : svgIcons.building
                                    }
                                    foreground={palette.darkGray}
                                    transform={0.8}
                                  />
                                </Avatar>
                              </ListItemAvatar> */}
                                <ListItemText
                                  primary={item.display_name}
                                  secondary={formatSettingValue(item)}
                                />
                              </ListItem>
                            </React.Fragment>
                          );
                        })}
                    </List>
                  </Box>
                )}{" "}
                {settingsEditMode && (
                  <Box>
                    <List style={{ display: "flex", flexWrap: "wrap" }}>
                      {settingsData.data &&
                        settingsData.data?.map((item, i) => {
                          if (item.type === "boolean") {
                            const isChecked =
                              newSettingsData[item.key]?.value ??
                              item.value ??
                              item.default_value;
                            return (
                              <React.Fragment key={i}>
                                <ListItem
                                  key={`display-${item.key}`}
                                  style={{ maxWidth: "25%" }}
                                >
                                  <Switch
                                    checked={isChecked}
                                    onChange={(e) => {
                                      handleSettingsChange(
                                        e.target.checked,
                                        item
                                      );
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                  <ListItemText
                                    primary={item.display_name}
                                    secondary={`${isChecked ? "Enabled" : "Disabled"
                                      }`}
                                  />
                                </ListItem>
                              </React.Fragment>
                            );
                          }
                          return (
                            <ListItem
                              key={`edit-${item.key}`}
                              style={{ maxWidth: "25%" }}
                            >
                              {/* <ListItemAvatar>
                              <Avatar>
                                <Icon
                                  icon={
                                    item.type === "dollars"
                                      ? svgIcons.dollar
                                      : item.type === "days"
                                        ? svgIcons.clockAlert
                                        : svgIcons.building
                                  }
                                  transform={0.8}
                                  foreground={palette.darkGray}
                                />
                              </Avatar>
                            </ListItemAvatar> */}
                              {item.type === "dollars" ? "$" : ""}
                              <TextField
                                style={{ width: "100%" }}
                                defaultValue={formatSettingValue(item, false)}
                                label={item.display_name}
                                helperText={
                                  !isValidSettings(item, newSettingsData) &&
                                  (item.min !== undefined
                                    ? `Must be at least ${item.min}`
                                    : item.type !== "text"
                                      ? "Must be greater than 0"
                                      : "")
                                }
                                placeholder={
                                  newSettingsData[item.key]?.value === ""
                                    ? item.default_value
                                    : ""
                                }
                                error={!isValidSettings(item, newSettingsData)}
                                onChange={(e) =>
                                  handleSettingsChange(e.target.value, item)
                                }
                                data-test={`company-${item.key}-input`}
                              />
                              {item.type === "days" ? " Days" : ""}
                            </ListItem>
                          );
                        })}
                    </List>
                    <Button
                      disabled={invalidSettings}
                      style={{ margin: "1rem" }}
                      disableElevation
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setSettingsEditMode(false);
                        handleSettingsSave();
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      style={{ margin: "1rem 1rem 1rem 0" }}
                      variant="outlined"
                      onClick={() => {
                        setNewSettingsData({});
                        setSettingsEditMode(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                )}
              </Paper>

              <Paper
                className={classes.companySection}
                style={{ marginTop: "2em" }}
                variant="outlined"
              >
                <AdvancedSettingsCustomRules />
              </Paper>
            </Route>
            <Route exact path="/app/settings/connectors/">
              <Typography
                variant="body2"
                style={{ color: "#424242", padding: ".2em" }}
              >
                Connectors are data inputs into KeyCaliber. Connectors are
                comprised of a Solution and a Data Source.
              </Typography>
              <ConnectorTable />
            </Route>
            <Route exact path="/app/settings/datasources/">
              <Typography
                variant="body2"
                style={{ color: "#424242", padding: ".2em" }}
              >
                Data Sources are where KeyCaliber pulls Solution data from. This
                can be an external source or directly from the Solution API.
              </Typography>
              <DatasourceTable />
            </Route>
            <Route exact path="/app/settings/riskfactors/">
              <RiskFactors />
            </Route>
            <Route exact path="/app/settings/export/">
              <Ingestor />
            </Route>
            {/* <Route exact path="/app/settings/upload/">
              <Upload />
            </Route> */}
            <Route exact path="/app/settings/connectors/:connectorId">
              <ConnectorDetails />
            </Route>
          </Switch>
        </Box>
      </div>
    </Box>
  );
}

export default Settings;
