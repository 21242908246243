import React, { useEffect, useState } from "react";
import { Card, Grid, ListItemText } from "@material-ui/core";
import { ResponsiveSunburst } from "@nivo/sunburst";
import { useApi } from "../../API";
import ChartWrapper from "../../components/ChartWrapper";
import ChartWrapperHeader from "../../components/ChartWrapperHeader";
import { useHistory } from "react-router-dom";

function SecurityPosture() {
  const [allVulnData, setAllVulnData] = useState({});
  const [highImpactVulnData, setHighImpactVulnData] = useState({});
  const [allEDRData, setAllEDRData] = useState({});
  const [highImpactEDRData, setHighImpactEDRData] = useState({});
  const [vulnIds, setVulnIds] = useState('');
  const [edrIds, setEdrIds] = useState('');

  const history = useHistory();
  const api = useApi();

  useEffect(async () => {

    const res = await api.securityPostureStats();
    setAllVulnData({
      id: "nivo",
      children: [
        {
          name: `No Vuln Scan (${(res.missing_vuln.missing)})`,
          color: "#FFFFFF",

          value:
            ((res.missing_vuln.missing) /
              res.missing_vuln.all) *
            100,

        },
        {
          name: `Vuln Scan (${res.missing_vuln.all - res.missing_vuln.missing})`,
          color: "hsl(338, 70%, 50%)",
          value:
            ((res.missing_vuln.all - res.missing_vuln.missing) /
              res.missing_vuln.all) *
            100,
        },

      ],
    });
    setHighImpactVulnData({
      id: "nivo",
      color: "hsl(270, 70%, 50%)",
      children: [
        {
          name: `No Vuln Scan (${res.missing_vuln.high_impact_missing})`,
          color: "#002060",

          value:
            ((res.missing_vuln.high_impact_missing) /
              res.missing_vuln.high_impact_all) *
            100,

        },
        {
          name: `Vuln Scan (${res.missing_vuln.high_impact_all -
            res.missing_vuln.high_impact_missing})`,
          color: "hsl(338, 70%, 50%)",
          value:
            ((res.missing_vuln.high_impact_all -
              res.missing_vuln.high_impact_missing) /
              res.missing_vuln.high_impact_all) *
            100,
        },

      ],
    });
    setAllEDRData({
      id: "nivo",
      children: [
        {
          name: `No EDR (${res.missing_edr.missing})`,
          color: "#808080",
          value:
            (res.missing_edr.missing / res.missing_edr.all) *
            100
        },
        {
          name: `EDR (${res.missing_edr.all - res.missing_edr.missing})`,
          color: "#002060",
          value:
            ((res.missing_edr.all - res.missing_edr.missing) /
              res.missing_edr.all) *
            100,
        }

      ],
    });
    setHighImpactEDRData({
      id: "nivo",
      color: "hsl(270, 70%, 50%)",
      children: [
        {
          name: `No EDR (${res.missing_edr.high_impact_missing})`,
          color: "#002060",
          value:
            ((res.missing_edr.high_impact_missing) /
              res.missing_edr.high_impact_all) *
            100,

        },
        {
          name: `EDR (${(res.missing_edr.high_impact_all -
            res.missing_edr.high_impact_missing)})`,
          color: "hsl(338, 70%, 50%)",
          value:
            ((res.missing_edr.high_impact_all -
              res.missing_edr.high_impact_missing) /
              res.missing_edr.high_impact_all) *
            100,
        },

      ],
    });

    const connectors = await api.getConnectors()
    let edr = ''
    let vuln = ''
    for (const connector of connectors) {
      if (connector.kc_type === 'endpoint') {
        if (edr == '') {
          edr += `${connector.solution_id}`

        } else {
          edr += `%2C${connector.solution_id}`
        }
      }

      if (connector.kc_type === 'vuln') {
        if (vuln == '') {
          vuln += `${connector.solution_id}`

        } else {
          vuln += `%2C${connector.solution_id}`
        }
      }
    }

    setVulnIds(vuln)
    setEdrIds(edr)



  }, []);

  return (
    <ChartWrapper excludeHeader>
      <ChartWrapperHeader chartName="Security Coverage" />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={2}
        alignItems="center"
        style={{ marginTop: "12px", marginBottom: "12px" }}
      >
        <Grid container item xs={4} justifyContent="center">
          <strong>All Assets</strong>
        </Grid>
        <Grid container item xs={4} justifyContent="center">
          {" "}
        </Grid>
        <Grid container item xs={4} justifyContent="center">
          <strong>High Impact Assets</strong>
        </Grid>
      </Grid>
      <Card>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          textAlign="center"
          style={{ marginTop: "24px", marginBottom: "24px" }}
        >
          <Grid style={{ height: 250 }} xs={4}>
            <ResponsiveSunburst
              data={allVulnData}
              onClick={(data) => {
                let url = `/app/inventory/assets${data.id.includes('No Vuln Scan') ? `?missing_coverage=${vulnIds}` : `?coverage=${vulnIds}`}`
                history.push(url);
              }}
              onMouseEnter={(_datum, event) => {
                event.currentTarget.style.cursor = "pointer";
              }}
              margin={{ top: 60, right: 10, bottom: 60, left: 10 }}
              id="name"
              value="value"
              cornerRadius={3}
              colors={['#c9c8c8', '#1f78b4']}
              colorBy="id"
              enableArcLabels
              arcLabelsRadiusOffset="2"
              arcLabel="id"
              arcLabelsSkipAngle={1}
              arcLabelsTextColor="black"
            />
          </Grid>
          <div>Vuln Scan Converage</div>
          <Grid style={{ height: 250 }} xs={4}>
            <ResponsiveSunburst
              data={highImpactVulnData}
              onClick={(data) => {
                let url = `/app/inventory/assets?is_critical=true&${data.id.includes('No Vuln Scan') ? `missing_coverage=${vulnIds}` : `coverage=${vulnIds}`}`
                history.push(url);
              }}
              onMouseEnter={(_datum, event) => {
                event.currentTarget.style.cursor = "pointer";
              }}
              margin={{ top: 60, right: 10, bottom: 60, left: 10 }}
              id="name"
              value="value"
              cornerRadius={2}
              colors={['#c9c8c8', '#1f78b4']}
              colorBy="id"
              enableArcLabels
              arcLabelsRadiusOffset="3"
              arcLabel="id"
              arcLabelsSkipAngle={1}
              arcLabelsTextColor="black"
            />
          </Grid>
        </Grid>
      </Card>
      <Card>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          textAlign="center"
          style={{ marginTop: "12px", marginBottom: "12px" }}
        >
          <Grid style={{ height: 250 }} xs={4}>
            <ResponsiveSunburst
              data={allEDRData}
              onClick={(data) => {
                let url = `/app/inventory/assets${data.id.includes('No EDR') ? `?missing_coverage=${edrIds}` : `?coverage=${edrIds}`}`
                history.push(url);
              }}
              onMouseEnter={(_datum, event) => {
                event.currentTarget.style.cursor = "pointer";
              }}
              margin={{ top: 60, right: 10, bottom: 60, left: 10 }}
              id="name"
              value="value"
              cornerRadius={2}
              colors={['#c9c8c8', '#1f78b4']}
              colorBy="id"
              enableArcLabels
              arcLabelsRadiusOffset="3"
              arcLabel="id"
              arcLabelsSkipAngle={1}
              arcLabelsTextColor="black"
            />
          </Grid>
          <div>EDR Converage</div>
          <Grid style={{ height: 250 }} xs={4}>
            <ResponsiveSunburst
              data={highImpactEDRData}
              onClick={(data) => {
                let url = `/app/inventory/assets?is_critical=true&${data.id.includes('No EDR') ? `missing_coverage=${edrIds}` : `coverage=${edrIds}`}`
                history.push(url);
              }}
              onMouseEnter={(_datum, event) => {
                event.currentTarget.style.cursor = "pointer";
              }}
              margin={{ top: 60, right: 10, bottom: 60, left: 10 }}
              id="name"
              value="value"
              cornerRadius={2}
              colors={['#c9c8c8', '#1f78b4']}
              colorBy="id"
              enableArcLabels
              arcLabelsRadiusOffset="3"
              arcLabel="id"
              arcLabelsSkipAngle={1}
              arcLabelsTextColor="black"
            />
          </Grid>
        </Grid>
      </Card>
    </ChartWrapper>
  );
}
export default SecurityPosture;
