import React, { useState, useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import KnownExploitedTable from "../reporting/charts/KnownExploitedVulnsTable";
import ChartWrapper from "../components/ChartWrapper";
import AssetsByCVEsTable from "../reporting/charts/AssetsByCVEsTable";
import { useApi } from "../API";
import ChartWrapperHeader from "../components/ChartWrapperHeader";
import HighImpactSwitch from "../components/HighImpactSwitch";
import CyberAssetInventory from "../reporting/charts/CyberAssetInventory";
import Subnets from "../resilience/Subnets";
import RiskPosture from "../reporting/charts/RiskPosture";
import SecurityPosture from "../reporting/charts/SecurityPosture";
import NewAssetsOverTime from "../reporting/charts/NewAssetsOverTime";
import KevsBarChart from "../reporting/charts/KevsBarChart";
import CyberAssetChanges from "../reporting/charts/CyberAssetChanges";
import CyberAssetsByDatasource from "../reporting/charts/CyberAssetsByDatasource";
import BigNumberCard from "../components/BigNumberCard";

const useStyles = makeStyles((theme) => ({
  // root: theme.layout.root,
  content: theme.layout.content,
  contentWrapper: theme.layout.contentWrapper,
  contentGrid: theme.layout.contentGrid,
  secondaryNav: theme.layout.secondaryNav,
}));

function DashboardPage() {
  const api = useApi();
  const classes = useStyles();
  const [criticalData, setCriticalData] = useState();
  const [vulnCount, setVulnCount] = useState()
  const [edrCount, setEdrCount] = useState()
  const [connectors, setConnectors] = useState()
  const [networkCount, setNetworkCount] = useState()


  const [tabValue, setTabValue] = useState(0);
  const [data, setData] = useState();
  const [checkedState, setCheckedState] = useState({
    riskyHighImpact: true,
    assetVulns: true,
  });

  const handleSwitchChange = (name, checked) => {
    setCheckedState({ ...checkedState, [name]: checked });
  };

  const getUrlFromLevel = (levelId, criticalOnly, solutionIds) => {
    // let url = "/app/inventory/assets?";
    let url = "";

    if (levelId === "level_2") {
      url += "&cvss_score=9,10&min_cves=1";
    } else if (levelId === "level_3") {
      url += `&cvss_score=9,10&min_cves=1&missing_coverage=${solutionIds.join(
        ","
      )}`;
    }

    if (criticalOnly) {
      url += "&is_critical=true";
    }

    return url;
  };


  const getUrlForCoverage = (connectorType) => {
    // let url = "/app/inventory/assets?";
    let url = "&coverage=";

    let i = 0;
    for (const connector of connectors) {
      if (connector.kc_type == connectorType) {
        if (i > 0) {
          url += "%2C"
        }
        url += connector.solution_id;
        i++
      }
    }

    return url;
  };

  useEffect(async () => {
    document.title = "KeyCaliber";
    const criticalData = await api.riskyBreakdown(true, true);

    const connectors = await api.getConnectors()
    setConnectors(connectors)

    const allData = await api.riskyBreakdown(false, true);
    const res = await api.securityPostureStats();
    setEdrCount(res.missing_vuln.all - res.missing_vuln.missing)
    setVulnCount(res.missing_edr.all - res.missing_edr.missing)
    setNetworkCount(res.missing_network.all - res.missing_network.missing)




    const highImpact = criticalData?.data?.map((el) => {
      return {
        ...el,
        url: getUrlFromLevel(el.id, true, allData.solution_ids),
      };
    });

    const allDataRes = allData?.data?.map((el) => {
      return {
        ...el,
        url: getUrlFromLevel(el.id, false, allData.solution_ids),
      };
    });

    setData(allDataRes?.filter((el) => el.id !== "level_1"));
    setCriticalData(highImpact?.filter((el) => el.id !== "level_1"));
  }, []);


  if (!data || !criticalData) {
    return <div>Loading...</div>;
  }

  return (
    <Box className={classes.root} data-test="controls-page">
      <div className={classes.contentWrapper} style={{ marginTop: "0px" }}>
        <Box className={classes.content}>
          <Grid container justifyContent="space-between" spacing={2}>


            {/* <Grid item xs={3}>
              <BigNumberCard
                title="Assets Covered by EDR"
                queryString={getUrlForCoverage('endpoint')}
                value={edrCount}
              />
            </Grid>

            <Grid item xs={3}>
              <BigNumberCard
                title="Assets Covered by Vuln Scan"
                queryString={getUrlForCoverage('vuln')}
                value={vulnCount}
              />
            </Grid>
            <Grid item xs={3}>
              <BigNumberCard
                title="Assets Covered by Network Appliances"
                queryString={getUrlForCoverage('network')}
                value={networkCount}
              />
            </Grid>
            <Grid item xs={3}>
              <BigNumberCard
                title="Assets with Critical Vulns"
                queryString={data[1].url}
                value={data[1].total}
              />
            </Grid> */}


            <Grid item xs={12}>
              <CyberAssetInventory />
            </Grid>
            <Grid item xs={12}>
              <CyberAssetChanges />
            </Grid>

            <Grid item xs={12}>
              <CyberAssetsByDatasource />
            </Grid>


            <Grid item xs={12}>
              <KevsBarChart />
            </Grid>
            <Grid item xs={12}>
              <SecurityPosture />
            </Grid>


            <Grid item xs={12}>
              <RiskPosture />
            </Grid>

            {/* <Grid item xs={6}>
              <NewAssetsOverTime />
            </Grid> */}



            {/* <Grid item xs={12}>
              <KnownExploitedTable internetFacing />
            </Grid>
            <Grid item xs={12}>
              <KnownExploitedTable />
            </Grid>

            <Grid item xs={6}>
              <ChartWrapper excludeHeader>
                <ChartWrapperHeader chartName="Assets with Most Vulnerabilities">
                  <HighImpactSwitch
                    checked={checkedState.assetVulns}
                    handleSwitchChange={() =>
                      handleSwitchChange(
                        "assetVulns",
                        !checkedState.assetVulns
                      )
                    }
                    name="assetVulns"
                  />
                </ChartWrapperHeader>
                <AssetsByCVEsTable
                  criticalOnly={checkedState.assetVulns}
                />
              </ChartWrapper>
            </Grid> */}
            <Grid item xs={12}>
              <ChartWrapper excludeHeader>
                <ChartWrapperHeader chartName="Your Cyber Asset Map" />
                <Subnets hideAssetTable />
              </ChartWrapper>
            </Grid>


          </Grid>
        </Box>
      </div>
    </Box>
  );
}

export default DashboardPage;
