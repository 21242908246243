import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Paper,
  Button,
  Typography,
  Divider,
  Grid,
} from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import TuneIcon from "@material-ui/icons/Tune";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation, Switch, Route } from "react-router-dom";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import QueryInputs from "./QueryInputs";
import NetworkInteractions from "./NetworkInteractions";
import NetworkSimple from "./NetworkSimple";
import ResilienceLegend from "./ResilienceLegend";
import UseCases from "./UseCases";
import AssetHoverPopoverBody from "../components/AssetHoverPopover/AssetHoverPopoverBody";
import DirectionDropdown from "./DirectionDropdown";
import spinner from "../assets/res-spinner.svg";
import { useApi } from "../API";
import Icon from "../icons/icon";
import { palette } from "../utils/theme";
import { svgIcons } from "../icons/Icons";
import { RoundedTab, RoundedTabs } from "../components/RoundedTab";
import Subnets from "./Subnets";

const useStyles = makeStyles((theme) => ({
  root: theme.layout.root,
  contentWrapper: theme.layout.contentWrapper,
  contentGrid: { ...theme.layout.contentGrid, height: "100%" },
  content: {
    ...theme.layout.content,
    height: "100%",
  },
  sectionTitle: { padding: "0 0 8px 8px" },
  paper: {
    height: "100%",
    display: "flex",
  },
  sidePanel: {
    minWidth: "350px",
    padding: "16px 16px 16px 32px",
    position: "relative",
    borderLeft: `1px solid ${palette.lightGray}`,
  },
  toggleButton: {
    padding: ".5rem",
    width: "3rem",
    height: "3rem",
    background: "white",
    border: `1px solid ${palette.background}`,
    "&:hover": {
      background: palette.lightGray,
    },
  },
  filterPanel: {
    position: "relative",
    padding: "20px 20px",
    flex: "0 0 300px",
    borderRight: "1px solid",
    borderRightColor: palette.lightGray,
  },
  filtersHiddenPanel: {
    position: "relative",
    padding: "20px 10px",
    flex: "0 0 30px",
    borderRight: "1px solid",
    borderRightColor: palette.lightGray,
  },
  filterHeaderIcon: {
    position: "absolute",
    right: 5,
    top: 5,
    zIndex: 100,
  },
  insightPanel: {
    position: "absolute",
    width: "500px",
    padding: 5,
    top: 5,
    right: 5,
  },
  assetListPanel: {
    position: "absolute",
    width: "100%",
    height: "300px",
    backgroundColor: "white",
    padding: 5,
    borderTop: "1px solid",
    borderTopColor: palette.lightGray,
    bottom: 0,
    left: 0,
  },
  filterMessagePanel: {
    position: "absolute",
    width: "50%",
    color: palette.gray,
    padding: 5,
    top: 5,
    left: 5,
  },
  fieldContainer: {
    margin: "10px 0",
    width: "300px",
  },
  visPanel: {
    flex: 1,
    position: "relative",
  },
  facet: {
    margin: "0 0 20px 0",
  },

  sliderContainer: {
    margin: "15px 10px",
  },
  loadingImg: {
    marginRight: "20px",
    position: "absolute",
    borderRadius: "100px",
    height: "40px",
  },
  loadingText: {
    textIndent: "50px",
    whiteSpace: "nowrap",
    wordWrap: "keep-all",
  },
  loadingPlaceholder: {
    padding: "10px 20px",
    width: "80px",
  },
  filterMessage: {
    margin: "10px 20px",
  },
  inputWrapper: {
    display: "flex",
  },
}));

function Resilience() {
  const classes = useStyles();
  const api = useApi();
  const { t } = useTranslation();

  const useCases = [
    {
      index: 0,
      value: "most-critical-assets",
      label: t("criticalAssetLabelMany"),
      componentName: "NetworkSimple",
      baseUrl: "usecases/criticality",
      visScale: 0.8,
      filterMessage: "",
    },
    {
      index: 1,
      value: "one-hop-internet",
      label: `Internet Connected ${t("criticalAssetLabelMany")}`,
      componentName: "NetworkSimple",
      baseUrl: "usecases/internet-root",
      visScale: 2,
      filterMessage: "",
    },
    {
      index: 2,
      value: "asset-to-workstation",
      label: "Workstations Connected to an Asset",
      componentName: "NetworkSimple",
      baseUrl: "usecases/asset-to-workstation",
      visScale: 0.85,
      filterMessage: "",
      hasAssetFilter: true,
    },
    {
      index: 3,
      value: "asset-connections",
      label: "Asset Connections",
      componentName: "NetworkSimple",
      baseUrl: "asset",
      visScale: 0.75,
      hasAssetFilter: true,
      filterMessage: "",
    },
    {
      index: 4,
      value: "application-connections",
      label: "Business Process Connections",
      componentName: "NetworkSimple",
      baseUrl: "application",
      visScale: 1,
      hasAssetFilter: false,
      hasAppFilter: true,
      filterMessage: "",
    },
  ];

  const [useCase, setUseCase] = useState(useCases[0]);
  const [visLoading, setVisLoading] = useState(false);
  const [reloadVis, setReloadVis] = useState(0);
  const [nwsRedrawCt, setNwsRedrawCt] = useState(0);
  const [nwsData, setNwsData] = useState();
  const [showLabels, setShowLabels] = useState(false);
  const [assetFilter, setAssetFilter] = useState(null);
  const [assetFilterId, setAssetFilterId] = useState(null);
  const [clickedAsset, setClickedAsset] = useState(null);
  const [appFilter, setAppFilter] = useState(null);
  const [appFilterId, setAppFilterId] = useState(null);
  const [numCriticalAssets, setNumCriticalAssets] = useState(30);
  const [minCVSS, setMinCVSS] = useState(0);
  const [direction, setDirection] = useState(0);
  const [connectionLimit, setConnectionLimit] = useState(30);
  const [highlight, setHighlight] = useState(null);
  const [highlightTemp, setHighlightTemp] = useState(null);
  const [highlightByTag, setHighlightByTag] = useState(false);
  const [hopsMode, setHopsMode] = useState(false);
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const [infoPanelOpen, setInfoPanelOpen] = useState(false);
  const [userMessage, setUserMessage] = useState(null);
  // const [promptUserForFilter, setPromptUserForFilter] = useState(false);
  const [tagNameFilter, setTagNameFilter] = useState(null);
  const [tagValueFilter, setTagValueFilter] = useState(null);

  const history = useHistory();
  const location = useLocation();
  const filterRequired = useCase.hasAssetFilter || useCase.hasAppFilter;

  // API Call for data to pass to the NetworkSimple component
  const nwsApiCall = () => {
    // its possible that we can make several requests to the server as a result of
    // all the new user interaction in the UI that can pile up requests. we need to
    // make sure that only the most recent query results are what is rendered on the screen
    // so that a longer running request doesn't disrupt the user experience

    let baseUrl;

    if (assetFilter && useCase.hasAssetFilter) {
      baseUrl = `${useCase.baseUrl}/${assetFilter.id}`;
    } else if (assetFilterId && useCase.hasAssetFilter) {
      baseUrl = `${useCase.baseUrl}/${assetFilterId}`;
    } else {
      baseUrl = `${useCase.baseUrl}`;
    }

    // avoid loading the wrong path before checking url
    if (reloadVis > 0) {
      visLoadingChanged(true);

      api
        .resilienceBaseUrl(baseUrl, {
          connectionLimit,
          numCriticalAssets,
          minCVSS,
          direction,
        })
        .then((res) => {
          if (!assetFilter && !!assetFilterId) {
            // set asset filter to id from the url
            const newFilter = res.data.nodes.filter(
              (asset) => asset.id.toString() === assetFilterId
            )[0];
            setAssetFilter(newFilter);
          }
          setNwsRedrawCt(0);
          setNwsData(res.data);

          console.log(
            `Visualization has ${res.data.nodes.length} nodes, ${res.data.links.length} links`
          );
          visLoadingChanged(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const appsApiCall = () => {
    setVisLoading(true);
    api
      .getApplications()
      .then((res) => {
        const currAppFilter = location.pathname.split("application/")[1];
        const filter = res.filter(
          (d) => d.id.toString() === currAppFilter.toString()
        );
        setAppFilter(filter[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const appsNodesApiCall = (appId) => {
    if (appId) {
      const baseUrl = `${appId}`;

      // avoid loading the wrong path before checking url
      if (reloadVis > 0) {
        visLoadingChanged(true);

        api
          .resilienceApplicationConnections(baseUrl, {
            connectionLimit,
            numCriticalAssets,
            minCVSS,
          })
          .then((res) => {
            setNwsRedrawCt(0);
            setNwsData(res.data);
            console.log(
              `Visualization has ${res.data.nodes.length} nodes, ${res.data.links.length} links`
            );
            visLoadingChanged(false);
          });
      }
    }
  };

  // NetworkSimple API call UseEffect
  useEffect(() => {
    if (useCase.value !== "application-connections") {
      nwsApiCall();
    } else if (
      useCase.value === "application-connections" &&
      appFilterId &&
      appFilter
    ) {
      appsNodesApiCall(appFilterId);
    }
  }, [reloadVis, appFilter]);

  // URL Changed
  useEffect(() => {
    if (
      useCase.value === "application-connections" ||
      location.pathname.includes("/application")
    ) {
      appsApiCall();
    }
    // the url use case must be one of the use case baseUrls
    const newUseCase = useCases.filter((uc) =>
      location.pathname.includes(uc.baseUrl)
    );
    // if there's a match
    if (newUseCase.length) {
      useCaseChanged(newUseCase[0], true);
    }
    if (!useCase.assetFilter) {
      setUserMessage("");
    }
    setReloadVis(reloadVis + 1);
  }, []);

  // asset changed in left hand query panel
  const assetFilterChanged = (event, newValue) => {
    if (newValue) {
      history.push(
        `/app/resilience/connections/${useCase.baseUrl}/${newValue.id}`
      );
      setAssetFilter(newValue);
      setAssetFilterId(newValue.id);
      setReloadVis(reloadVis + 1);
      setHopsMode(true);
      setUserMessage(null);
      setClickedAsset(newValue);
    }
  };

  // app filter in the left hand query panel changed
  const appFilterChanged = (event, newValue) => {
    if (newValue && newValue !== appFilter) {
      history.push(`/app/resilience/connections/application/${newValue.id}`);
      setAppFilter(newValue);
      setAppFilterId(newValue.id);
      setReloadVis(reloadVis + 1);
      setHopsMode(false);
      setUserMessage(null);
    }
  };

  const numCriticalAssetsChanged = (event, newValue) => {
    setNumCriticalAssets(newValue);
    setReloadVis(reloadVis + 1);
  };

  const minCVSSChanged = (event, newValue) => {
    setMinCVSS(newValue);
    setReloadVis(reloadVis + 1);
  };

  const connectionLimitChanged = (event, newValue) => {
    setConnectionLimit(newValue);
    setReloadVis(reloadVis + 1);
  };

  const handleClearHighlightClick = () => {
    // highlightUpdated("");
    setHighlight("");
    setHighlightTemp("");
  };

  const useCaseChanged = (newUsecase, fromUrl) => {
    setUseCase(newUsecase);
    // We'll want to reload the visualisation
    // in some cases but not in others.
    let reload = true;
    // need to set the url
    let newUrl = `/app/resilience/connections/${newUsecase.baseUrl}`;
    if (newUsecase.hasAssetFilter) {
      newUrl += `/${assetFilterId}`;
    } else {
      setAssetFilter("");
      setAssetFilterId("");
    }
    if (!fromUrl) {
      history.push(newUrl);
    }

    // Different use cases require different actions
    // First two are unique.
    if (
      newUsecase.value === "one-hop-internet" ||
      newUsecase.value === "most-critical-assets"
    ) {
      setAssetFilter(null);
      setHopsMode(false);
    }
    // the second two use cases have to be filtered by asset
    // asset id is in the url.
    if (newUsecase.hasAssetFilter) {
      let assetId = location.pathname.split(newUsecase.baseUrl)[1];
      assetId = assetId ? assetId.slice(1) : "null";

      if (assetId === "null") {
        history.push("/app/resilience/connections/asset");
      }
      if (assetId && assetId.length > 0 && assetId !== "null") {
        // remove the '/'
        setAssetFilterId(assetId);
        // default to radial view
        setHopsMode(true);
      } else if (!assetFilterId) {
        // no visualization, open filter panel so
        // user can select an asset
        setFilterPanelOpen(true);
        setVisLoading(false);
        setAppFilter(0);
        setAppFilterId(0);
        // this keeps the spinner from persisting
        reload = false;
      }
      // set user message
      if (!assetFilter) {
        setUserMessage("Please select an asset to view");
      }
    }
    // Application Connections is unique, but similar to asset connections.
    if (newUsecase.value === "application-connections") {
      const currAppFilter = location.pathname.split("application/")[1];
      setAssetFilter(null);
      setAssetFilterId(null);
      setHopsMode(false);
      if (currAppFilter) {
        setAppFilterId(currAppFilter);
        // it's also necessary to set the
        // appFilter state to be the app object
        // because of race conditions, it's necessary to
        // set the appFilter in the api call above.
      } else {
        setAppFilter(-1);
        setAppFilterId(-1);
      }
      setUserMessage("Please select an application to view");
    }
    // in most cases we want to refresh the visualization
    if (reload) {
      setReloadVis(reloadVis + 1);
    }
    // default to highlight off
    // setHighlight(false);
  };

  const directionChanged = (newDirection) => {
    setDirection(newDirection.value);
    setReloadVis(reloadVis + 1);
  };

  const resetZoom = () => {
    setReloadVis(reloadVis + 1);
  };

  const toggleFilters = (isVisible) => {
    setFilterPanelOpen(isVisible);
  };

  const toggleHighlightSelected = () => {
    if (assetFilter && !highlight) {
      setHighlight(
        assetFilter.name || assetFilter.hostname || assetFilter.ipv4
      );
      setHighlightTemp(
        assetFilter.name || assetFilter.hostname || assetFilter.ipv4
      );
    } else if (
      assetFilter &&
      highlight &&
      highlight !== assetFilter.name &&
      highlight !== assetFilter.hostname &&
      highlight !== assetFilter.ipv4
    ) {
      handleClearHighlightClick();
      setHighlight(
        assetFilter.name || assetFilter.hostname || assetFilter.ipv4
      );
      setHighlightTemp(
        assetFilter.name || assetFilter.hostname || assetFilter.ipv4
      );
    } else if (
      assetFilter &&
      highlight &&
      (highlight === assetFilter.name ||
        highlight === assetFilter.hostname ||
        highlight === assetFilter.ipv4)
    ) {
      setHighlight("");
      setHighlightTemp("");
    }
  };

  const visLoadingChanged = (isLoading) => {
    if (
      !location.pathname.endsWith("/asset") &&
      !location.pathname.endsWith("/asset/") &&
      !location.pathname.endsWith("workstation") &&
      !location.pathname.endsWith("workstation/")
    ) {
      setVisLoading(isLoading);
    } else if (!assetFilter) {
      setFilterPanelOpen(true);
      setUserMessage("Please select an asset to view");
    }
  };

  const toggleShowLabels = (e) => {
    setShowLabels(e.target.checked);
  };

  const highlightUpdated = (value) => {
    setHighlight(value);
  };

  const highlightUpdatedTemp = (value) => {
    setHighlightTemp(value);
  };

  const drillDownAsset = (datum) => {
    window.location.href = `/app/resilience/connections/asset/${datum.id}`;
  };

  const toggleHighlightByTag = () => {
    setHighlightByTag(!highlightByTag);
    setTagNameFilter(null);
    setTagValueFilter(null);
  };

  const toggleHopsMode = () => {
    setHopsMode(!hopsMode);
  };

  const tagNameChanged = (tagName) => {
    setTagNameFilter(tagName);
  };

  const tagValueChanged = (val) => {
    setTagValueFilter(val);
  };

  const handleExportNodes = () => {
    let identifer;
    if (useCase.value === "asset-connections") {
      identifer = assetFilter && assetFilter.id;
    } else if (useCase.value === "application-connections") {
      identifer = appFilter && appFilter.id;
    }

    api.resilienceCsvBaseUrl(useCase.baseUrl, identifer, {
      minCVSS,
      numCriticalAssets,
      connectionLimit,
      direction,
    });
  };

  const selectClickedAsset = (d) => {
    if (d.asset_type === "limit") {
      return;
    }

    setClickedAsset(d);
    setInfoPanelOpen(true);
  };

  const handleTabChange = (event, newValue) => {
    setNwsRedrawCt(0);
    setReloadVis(reloadVis + 1);
    history.push(newValue);
  };

  document.title = "KeyCaliber - Terrain";
  const tabs = [
    {
      label: "Connections",
      path: `/app/resilience/connections`,
      name: "connections",
      dataTest: "tab-button-connections",
    },
    {
      label: "Subnets",
      path: `/app/resilience/subnets`,
      name: "subnets",
      dataTest: "tab-button-subnets",
    },
  ];
  return (
    <Box className={classes.root} data-test="resilience-page">
      <div className={classes.contentWrapper}>
        <Box className={classes.content}>
          <RoundedTabs
            value={
              location.pathname.includes("subnets")
                ? tabs[1].path
                : tabs[0].path
            }
            onChange={handleTabChange}
            aria-label="primary tabs example"
          >
            {tabs.map(({ name, label, path }) => (
              <RoundedTab
                key={name}
                id={`tab-${name}`}
                label={label}
                value={path}
                data-test={`inventory-tab-${name}-link`}
              />
            ))}
          </RoundedTabs>
          <Switch>
            <Route path="/app/resilience/connections">
              <Paper className={classes.paper} variant="outlined" elevation={0}>
                <div
                  className={classes.filtersHiddenPanel}
                  style={{ display: filterPanelOpen ? "none" : "initial" }}
                >
                  <IconButton
                    color="primary"
                    aria-label="open filters"
                    component="span"
                    onClick={() => toggleFilters(true)}
                    data-test="resilience-filter-toggle-open"
                  >
                    <TuneIcon />
                  </IconButton>
                </div>

                <div
                  className={classes.filterPanel}
                  style={{ display: filterPanelOpen ? "initial" : "none" }}
                >
                  <div className={classes.filterHeaderIcon}>
                    <IconButton
                      color="primary"
                      aria-label="close filters"
                      component="span"
                      style={{
                        position: "relative",
                        top: "3px",
                        left: "1.75rem",
                      }}
                      className={classes.toggleButton}
                      data-test="resilience-filter-toggle-close"
                      onClick={() => toggleFilters(false)}
                    >
                      <ChevronLeft />
                    </IconButton>
                  </div>

                  <div className={classes.facet}>
                    <Typography
                      variant="overline"
                      display="block"
                      className={classes.sectionTitle}
                    >
                      Query
                    </Typography>

                    <UseCases
                      onChangeUseCase={useCaseChanged}
                      disabled={visLoading}
                      useCases={useCases}
                      currentUseCase={useCase.index}
                    />
                    {filterRequired && (
                      <QueryInputs
                        userMessage={userMessage}
                        useCase={useCase}
                        disabled={visLoading}
                        assetFilter={assetFilter}
                        appFilter={appFilter}
                        onChangeAssetFilter={assetFilterChanged}
                        onChangeApplicationFilter={appFilterChanged}
                      />
                    )}

                    {useCase.value === "one-hop-internet" && (
                      <DirectionDropdown
                        onChangeDirection={directionChanged}
                        disabled={visLoading}
                        currentDirection={direction}
                      />
                    )}

                    {useCase.value === "most-critical-assets" && (
                      <div className={classes.sliderContainer}>
                        <div className={classes.fieldContainer}>
                          <Typography
                            id="critical-asset-count-slider"
                            gutterBottom
                          >
                            # of High Impact Assets
                          </Typography>
                          <Slider
                            defaultValue={30}
                            disabled={
                              (useCase.assetFilter && !assetFilter) ||
                              visLoading
                            }
                            onChangeCommitted={numCriticalAssetsChanged}
                            valueLabelDisplay="auto"
                            aria-labelledby="critical-asset-count-slider"
                            step={1}
                            marks={[
                              { value: 1, label: "1" },
                              { value: 100, label: "100" },
                              { value: 200, label: "200" },
                            ]}
                            min={1}
                            max={200}
                          />
                        </div>
                      </div>
                    )}

                    <div className={classes.sliderContainer}>
                      <div className={classes.fieldContainer}>
                        <Typography id="min-cvss-slider" gutterBottom>
                          Connection CVSS Score
                        </Typography>
                        <Slider
                          defaultValue={minCVSS}
                          disabled={
                            (useCase.assetFilter && !assetFilter) || visLoading
                          }
                          onChangeCommitted={minCVSSChanged}
                          valueLabelDisplay="auto"
                          aria-labelledby="min-cvss-slider"
                          step={0.5}
                          marks={[
                            { value: 0, label: "0" },
                            { value: 5, label: "5" },
                            { value: 10, label: "10" },
                          ]}
                          min={0}
                          max={10}
                        />
                      </div>
                    </div>

                    <div className={classes.sliderContainer}>
                      <div className={classes.fieldContainer}>
                        <Typography id="min-cvss-slider" gutterBottom>
                          Connection Limit
                        </Typography>
                        <Slider
                          defaultValue={30}
                          disabled={
                            (useCase.assetFilter && !assetFilter) || visLoading
                          }
                          onChangeCommitted={connectionLimitChanged}
                          valueLabelDisplay="auto"
                          aria-labelledby="connection-limit-slider"
                          step={10}
                          marks={[
                            { value: 10, label: "10" },
                            { value: 150, label: "150" },
                            { value: 300, label: "300" },
                          ]}
                          min={10}
                          max={300}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.facet}>
                    {/* {useCase.value !== "critical-connection-levels" && (
                      <Typography
                        variant="overline"
                        display="block"
                        className={classes.sectionTitle}
                      >
                        Visual
                      </Typography>
                    )}

                    <ToggleButtonGroup
                      value={graphType}
                      exclusive
                      onChange={handleGraphType}
                      aria-label="text alignment"
                    >
                      <ToggleButton
                        value="network-2d"
                        aria-label="left aligned"
                      >
                        2D
                      </ToggleButton>
                      <ToggleButton value="network-3d" aria-label="left aligned">
                        3D (BETA)
                      </ToggleButton>
                    </ToggleButtonGroup> */}

                    <NetworkInteractions
                      highlight={highlight}
                      highlightTemp={highlightTemp}
                      disabled={visLoading}
                      useCase={useCase}
                      assetFilter={assetFilter}
                      hopsMode={hopsMode}
                      handleClearHighlightClick={handleClearHighlightClick}
                      toggleShowLabels={toggleShowLabels}
                      toggleHighlightSelected={toggleHighlightSelected}
                      toggleHopsMode={toggleHopsMode}
                      onUpdateHighlight={highlightUpdated}
                      onUpdateHighlightTemp={highlightUpdatedTemp}
                      highlightByTag={highlightByTag}
                      tagNameChanged={tagNameChanged}
                      tagValueChanged={tagValueChanged}
                      toggleHighlightByTag={toggleHighlightByTag}
                    />
                  </div>
                  <Button
                    onClick={handleExportNodes}
                    startIcon={<CloudDownloadIcon />}
                  >
                    Export As CSV
                  </Button>
                  <Divider />
                  <Grid item xs={12} style={{ padding: ".5em" }}>
                    <ResilienceLegend />
                  </Grid>
                </div>

                <div className={classes.visPanel}>
                  <div className={classes.filterMessagePanel}>
                    {useCase.filterMessage !== "" ||
                      (!!userMessage && (
                        <div className={classes.filterMessage}>
                          {useCase.filterMessage}
                        </div>
                      ))}
                    {visLoading && (
                      <div className={classes.loadingPlaceholder}>
                        <img
                          src={spinner}
                          className={classes.loadingImg}
                          alt="Loading resilience graph"
                        />
                      </div>
                    )}
                    {(useCase.value === "asset-connections" ||
                      useCase.value === "asset-to-workstation") &&
                      visLoading === false &&
                      assetFilter === -1 && (
                        <div
                          style={{
                            fontSize: "24px",
                            top: "-4px",
                            color: palette.darkGray,
                            left: "32px",
                            position: "absolute",
                            width: "100%",
                            textAlign: "left",
                          }}
                        >
                          <Icon
                            style={{ float: "left" }}
                            icon={svgIcons.information}
                            foreground={palette.darkGray}
                          />
                          Please select an asset to view from the query menu.
                        </div>
                      )}
                  </div>

                  <NetworkSimple
                    resetZoom={resetZoom}
                    useCase={useCase}
                    showLabels={showLabels}
                    assetFilter={assetFilter}
                    assetFilterId={assetFilterId}
                    appFilter={appFilter?.name}
                    connectionLimit={connectionLimit}
                    numCriticalAssets={numCriticalAssets}
                    minCVSS={minCVSS}
                    filterRequired={filterRequired}
                    reload={reloadVis}
                    redrawCount={nwsRedrawCt}
                    data={nwsData}
                    highlight={highlight}
                    hopsMode={hopsMode}
                    tagNameFilter={tagNameFilter}
                    tagValueFilter={tagValueFilter}
                    highlightByTag={highlightByTag}
                    setRedrawCount={setNwsRedrawCt}
                    onUpdateHighlight={highlightUpdated}
                    onShiftClick={drillDownAsset}
                    visLoading={visLoading}
                    selectClickedAsset={selectClickedAsset}
                  />
                </div>
                <Box
                  className={classes.sidePanel}
                  style={{ minWidth: infoPanelOpen ? "350px" : "5rem" }}
                >
                  {!infoPanelOpen && (
                    <IconButton
                      onClick={() => setInfoPanelOpen(true)}
                      aria-label="open information panel"
                      style={{
                        position: "absolute",
                        top: "1rem",
                        left: "1rem",
                      }}
                    >
                      <InfoIcon />
                    </IconButton>
                  )}
                  {infoPanelOpen && (
                    <>
                      <IconButton
                        onClick={() => setInfoPanelOpen(false)}
                        color="primary"
                        aria-label="close information panel"
                        style={{
                          position: "absolute",
                          left: "-1.5rem",
                          top: ".5rem",
                        }}
                        className={classes.toggleButton}
                      >
                        <ChevronRight />
                      </IconButton>
                      {!clickedAsset && (
                        <p>Click any node in the graph for more information.</p>
                      )}
                      {clickedAsset && (
                        <AssetHoverPopoverBody
                          asset={clickedAsset}
                          onclick={(asset, applicationMode) =>
                            drillDownAsset(asset, applicationMode)
                          }
                        />
                      )}
                    </>
                  )}
                </Box>
              </Paper>
            </Route>
            <Route exact path="/app/resilience/subnets">
              <Paper
                className={classes.paper}
                elevation={0}
                variant="outlined"
                center
              >
                <Subnets />
              </Paper>
            </Route>
          </Switch>
        </Box>
      </div>
    </Box>
  );
}

export default Resilience;
