import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Typography, ListItem, List, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { numberWithCommas } from "../utils";
import { useApi } from "../API";

const useStyles = makeStyles((theme) => ({
  eight: {
    background: `${theme.palette.lightBlue}!important`,
  },
  sixteen: {
    background: `${theme.palette.keyBlue}!important`,
  },
  twentyfour: {
    background: `${theme.palette.darkGray}!important`,
    color: "white",
  },
  nwDetails: {
    width: "100%",
    position: "relative",
    zIndex: 2,
    overflowY: "hidden",
  },
  mcaWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    marginRight: "-.5rem",
    marginLeft: "-.5rem",
  },
  mcaThumbnail: {
    cursor: "pointer",
    background: "rgba( 236, 239, 240, .6 )",
    height: "calc(100% - 16px)",
    display: "flex",
    wordWrap: "break-word",
    whiteSpace: "break-spaces",
    wordBreak: "break-all",
    opacity: 0.9,
    "&:hover": {
      background: "rgba( 236, 239, 240, 1 )",
    },
  },
  assetIcon: {
    float: "left",
    marginRight: "8px",
  },
  bigNumberWrapper: {
    display: "flex",
    alignItems: "center",
  },
  countLabelWrapper: {
    display: "flex",
  },
  bigNumberIcon: {
    height: "16px",
    minWidth: "16px",
    width: "16px",
    borderRadius: "16px",
    marginRight: "4px",
    marginTop: "2px",
  },
  hvaIcon: {
    border: `1px solid ${theme.palette.purple1}`,
    background: "#fae9f9",
  },
  ipIcon: {
    background: "white",
    border: `1px solid ${theme.palette.darkGray}`,
  },
  mcaLabel: {
    paddingLeft: "8px",
  },
  viewAll: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  viewAllBtn: {
    background: theme.palette.keyBlue,
    margin: ".5rem",
    color: "white",
  },
}));

function AssetsPanel(props) {
  const [zAssetCountAll, setZAssetCountAll] = useState(undefined);
  const [MCACountAll, setMCACountAll] = useState(undefined);

  const { zoomClass, subnet, subnetsCount, zAssetCount, MCACount, filtered } =
    props;

  const api = useApi();

  useEffect(() => {
    api.settingsNavbar().then((res) => {
      setMCACountAll(res.num_critical);
      setZAssetCountAll(res.num_total);
    });
  }, []);

  const classes = useStyles();

  return (
    <div className={`${classes.nwDetails}`}>
      <Box p={4} pt={1}>
        <Typography data-test="criticality-info-title" variant="h5">
          {zoomClass === "root" ? "Entire Network" : subnet}
        </Typography>
        <ul
          style={{
            listStyle: "none",
            paddingLeft: 0,
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <li>
            <Box py={1} pr={4}>
              <Typography
                variant="h6"
                component="div"
                className={classes.bigNumberWrapper}
              >
                {zoomClass !== "root" &&
                  zAssetCount &&
                  numberWithCommas(zAssetCount)}{" "}
                {zoomClass === "root" &&
                  zAssetCountAll &&
                  numberWithCommas(zAssetCountAll)}{" "}
              </Typography>
              <div className={classes.countLabelWrapper}>
                <div>Total Asset{zAssetCount !== 1 && <>s</>}</div>
              </div>
            </Box>
          </li>
          <li>
            <Box py={1} pr={4}>
              <Typography
                variant="h6"
                component="div"
                className={classes.bigNumberWrapper}
              >
                {zoomClass !== "root" && MCACount && numberWithCommas(MCACount)}
                {zoomClass === "root" &&
                  MCACountAll &&
                  numberWithCommas(MCACountAll)}
              </Typography>
              <div className={classes.countLabelWrapper}>
                <div>High Impact Assets (shown below)</div>
              </div>
            </Box>
          </li>
          {zoomClass !== "twentyfour" && (
            <li>
              <Box py={1} pr={4}>
                <Typography
                  variant="h6"
                  component="div"
                  className={classes.bigNumberWrapper}
                >
                  {subnetsCount && numberWithCommas(subnetsCount)}
                </Typography>
                <div className={classes.countLabelWrapper}>
                  <div>Subnet{subnetsCount !== 1 && <>s</>} (/16)</div>
                </div>
              </Box>
            </li>
          )}
        </ul>
        <div
          className={classes.mcaWrapper}
          style={{ overflow: "auto", overflowX: "hidden" }}
        >
          <Grid container direction="column" style={{ maxHeight: "60vh" }}>
            <Grid item>
              <List>
                {filtered
                  ?.slice(0, Math.floor(filtered?.length / 2))
                  ?.map((d) => {
                    return (
                      <ListItem
                        key={d.id}
                        button
                        component="a"
                        href={`/app/inventory/details/${d.id}`}
                      >
                        <ListItemText primary={d.hostname} secondary={d.ipv4} />
                      </ListItem>
                    );
                  })}
              </List>
            </Grid>
            <Grid item>
              <List>
                {filtered
                  ?.slice(Math.floor(filtered?.length / 2, filtered?.length))
                  ?.map((d) => {
                    return (
                      <ListItem
                        key={d.id}
                        button
                        component="a"
                        href={`/app/inventory/details/${d.id}`}
                      >
                        <ListItemText primary={d.hostname} secondary={d.ipv4} />
                      </ListItem>
                    );
                  })}
              </List>
            </Grid>
          </Grid>
        </div>
      </Box>
    </div>
  );
}

export default AssetsPanel;
