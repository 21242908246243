import { useMemo, useState } from "react";
import { useApi } from "../API";

function useSettings(settingName) {
  const api = useApi();
  const [settings, setSettings] = useState({
    loading: true,
    error: false,
    data: null,
  });
  const [reloadSettings, setReloadSettings] = useState(false);

  const toggleReload = () => {
    setReloadSettings(!reloadSettings);
  };

  useMemo(async () => {
    try {
      const result = await api.getSettings();
      const data = settingName
        ? result.data?.find((el) => el.key === settingName)?.value
        : result.data;

      setSettings({ loading: false, error: false, data });
    } catch (e) {
      console.log(e);
      setSettings({ loading: false, error: true, data: null });
    }
  }, [reloadSettings]);

  return [settings, toggleReload];
}

export default useSettings;
