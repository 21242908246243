import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import { useApi } from "../API";
import useQuery from "../hooks/useQuery";

const useStyles = makeStyles((theme) => ({
  root: theme.layout.root,
  contentWrapper: theme.layout.contentWrapper,
  contentGrid: theme.layout.contentGrid,
  paperWrapper: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
  },
  message: {
    display: "flex",
    justifyContent: "center",
    padding: "5rem",
  },
}));

const DrilldownRouter = () => {
  const history = useHistory();
  const location = useLocation();
  const search = useQuery(location.search);
  const api = useApi();
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const view = search.get("view");
  const type = search.get("type");
  const value = search.get("value");

  useEffect(() => {
    setError(false);
    setLoading(true);

    api
      .attributeToAssetId(type, value)
      .then((res) => {
        if (view === "resilience") {
          history.push(`/app/${view}/asset/${res.data.id}`);
        } else if (view === "inventory") {
          history.push(`/app/${view}/details/${res.data.id}`);
        }
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, []);

  return (
    <div className={classes.root}>
      <div className={`${classes.contentWrapper} ${classes.paperWrapper}`}>
        <Paper className={classes.message}>
          {loading && (
            <Typography variant="body1" gutterBottom>
              Looking up an asset based on <strong>{type}</strong> and{" "}
              <strong>{value}</strong> and routing you to the{" "}
              <strong>{view}</strong> page.
            </Typography>
          )}
          {error && (
            <Typography variant="body1" gutterBottom>
              Error locating asset with <strong>{type}</strong>{" "}
              <strong>{value}</strong>
            </Typography>
          )}
        </Paper>
      </div>
    </div>
  );
};

export default DrilldownRouter;
