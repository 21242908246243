import React from "react";
import Grid from "@material-ui/core/Grid";
import { metricSectionsMap } from "./data";
import MetricsStackedLine from "./charts/MetricsStackedLine";

function TrendsLineCharts({ sectionName }) {
  return (
    <>
      {metricSectionsMap[sectionName].map((el, idx) => {
        return (
          <Grid item xs={12} key={idx}>
            <MetricsStackedLine
              metricNames={el.metrics}
              chartName={el.display_name}
            />
          </Grid>
        );
      })}
    </>
  );
}

export default TrendsLineCharts;
