import React from "react";
import Grid from "@material-ui/core/Grid";
import ChartWrapper from "../components/ChartWrapper";
import TelemetryOverTime from "../controls/TelemetryOverTime";
import CoverageOverTime from "../controls/CoverageOverTime";
import TrendsLineCharts from "./TrendsReportingCharts";

function SecEngSection() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container item spacing={3}>
          <Grid item xs={12}>
            <CoverageOverTime />
          </Grid>
          <Grid item xs={12}>
            <ChartWrapper
              chartName="Telemetry Ingested Over Time*"
              subtitle="*It is normal to see a dip for the current period as metrics aggregate."
            >
              <TelemetryOverTime />
            </ChartWrapper>
          </Grid>
        </Grid>
      </Grid>
      <TrendsLineCharts sectionName="seceng" />
    </Grid>
  );
}

export default SecEngSection;
