import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { abbreviateLargeValue } from "../../utils";

function defaultRenderTooltip({ point }) {
  return (
    <div
      style={{
        backgroundColor: "white",
        color: "inherit",
        fontSize: "inherit",
        borderRadius: "2px",
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
        padding: "5px 9px",
      }}
    >
      <div>
        <strong>{point.id.split(".")[0]}:</strong>
        <br />
        {abbreviateLargeValue(point.data.y.toFixed(0), true)} on{" "}
        {point.data.x.toLocaleDateString()}
      </div>
    </div>
  );
}

const StackedLine = ({
  data,
  percentageFormat,
  selectedPeriod,
  colorScheme,
  logScale,
  height,
  renderTooltip,
}) => {
  return (
    <ResponsiveLine
      data={data || []}
      height={height || 300}
      animate
      pointSize={8}
      pointColor={["#fff"]}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      lineWidth={2}
      margin={{ top: 40, right: 300, bottom: 55, left: 60 }}
      curve="monotoneX"
      xScale={{
        type: "time",
        format: "%Y-%m-%d",
        useUTC: false,
        precision: "day",
      }}
      xFormat="time:%Y-%m-%d"
      yScale={{
        type: logScale ? "symlog" : "linear",
        min: logScale ? 100 : 0,
        max: percentageFormat ? 100 : logScale ? 1000000000 : "auto",
      }}
      axisLeft={{
        format: (d) =>
          percentageFormat ? `${d}%` : abbreviateLargeValue(d, true),
        tickSize: 5,
        tickValues: logScale
          ? [
              0, 100, 1000, 10000, 100000, 1000000, 10000000, 100000000,
              1000000000,
            ]
          : undefined,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: "middle",
        legendOffset: -50,
        legend: "Total Assets",
      }}
      axisBottom={{
        format: selectedPeriod === "year" ? "%b" : "%b %d",
        tickValues:
          selectedPeriod === "year"
            ? "every month"
            : selectedPeriod === "quarter" || selectedPeriod === "month"
            ? "every week"
            : "every day",
        legend:
          selectedPeriod === "year"
            ? "Month"
            : selectedPeriod === "quarter" || selectedPeriod === "month"
            ? "Week"
            : "Day",
        // legendOffset: -12,
        tickSize: 6,
        tickRotation: 0,
        legendOffset: 45,
        legendPosition: "middle",
      }}
      colors={{ scheme: colorScheme || "category10" }}
      // pointSymbol={CustomSymbol}
      useMesh
      enableSlices={false}
      tooltip={renderTooltip || defaultRenderTooltip}
      sliceTooltip={({ slice }) => (
        <div
          style={{
            background: "white",
            padding: "9px 12px",
            border: "1px solid #ccc",
          }}
        >
          <div>x: {slice.id}</div>
          {slice.points?.map((point) => (
            <div
              key={point.id}
              style={{
                color: point.serieColor,
                padding: "1px 0",
              }}
            >
              <strong>{point.serieId}</strong> [{point.data.yFormatted}]
            </div>
          ))}
        </div>
      )}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: "#a9acbe",
              strokeWidth: 0.7,
            },
          },
        },
        grid: {
          line: {
            stroke: "#e7e8ec",
            strokeWidth: 0.7,
          },
        },
      }}
      legends={[
        {
          anchor: "top-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
        },
      ]}
    />
  );
};

export default StackedLine;
