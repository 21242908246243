import React from "react";
import ListItemText from "@material-ui/core/ListItemText";
import { StyledMenu, StyledMenuItem } from "../../components/StyleMenu";

export default function DatasourceMenu({
  anchorEl,
  handleClose,
  handleEdit,
  handleDelete,
}) {
  return (
    <div>
      <StyledMenu
        id="datasources-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={handleEdit}>
          <ListItemText primary="Edit Datasource" />
        </StyledMenuItem>
        <StyledMenuItem onClick={handleDelete}>
          <ListItemText primary="Delete Datasource" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
