import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Slider,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Typography,
  Button,
  Tooltip,
  Grid,
  IconButton,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useApi } from "../../API";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "1rem 0",
  },
  factorDescription: {
    margin: "1rem 0",
  },
  table: {
    minWidth: 650,
  },
  evidence: {
    fontWeight: 800,
    padding: "1rem 0 0 0",
  },
  headerCell: {
    fontWeight: 800,
    backgroundColor: theme.palette.lightGray,
  },
  sliderCell: {
    paddingRight: "3rem",
  },
  userInputContainer: {
    margin: "1rem 0",
  },
  userInput: {
    margin: "1rem 0",
    width: "100%",
  },
  riskInput: {
    textTransform: "capitalize",
  },
}));

const FactorSlider = ({ handleChangeCommitted, factor, disabled, reset }) => {
  const [tempValue, setTempValue] = useState(factor.weight ?? 3);

  const handleChange = (event, newValue) => {
    setTempValue(newValue);
  };

  useEffect(() => {
    setTempValue(factor.weight ?? 3);
  }, [reset]);

  return (
    <Slider
      onChange={handleChange}
      onChangeCommitted={(event, newValue) =>
        handleChangeCommitted(factor.name, newValue)
      }
      value={tempValue}
      disabled={disabled}
      marks={marks}
      step={1}
      min={0}
      max={5}
      valueLabelDisplay="auto"
    />
  );
};

export default function RiskFactors() {
  const classes = useStyles();
  const api = useApi();
  const [assetRiskFactors, setAssetRiskFactors] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [reset, setReset] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [weights, setWeights] = useState({});
  const [reload, setReload] = useState(false);

  const handleChangeCommitted = (kpiName, newValue) => {
    setWeights({
      ...weights,
      [kpiName]: newValue,
    });
  };

  const handleSave = async () => {
    try {
      await api.riskV2UpdateAssetParams(weights);
      setReload(!reload);
      setEditMode(false);
    } catch (e) {
      setError(e);
    }
  };

  const handleCancel = () => {
    setWeights({});
    setEditMode(false);
    setReset(reset + 1);
  };

  useEffect(async () => {
    try {
      const res = await api.riskV2AssetParams();
      setAssetRiskFactors(
        res.kpis?.sort((a, b) => (a.display_name > b.display_name ? 1 : -1))
      );
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [reload]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>There was an error.</div>;
  }

  return (
    <div>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Typography
            variant="body2"
            style={{ color: "#424242", padding: ".2em" }}
          >
            The list of factors that determine how <strong>risky</strong> an
            asset is. Each factor influences the overall Risk quanitification
            for an asset.
            <br />
            The relative amount of influence each factor has can be adjusted by
            using the influence sliders below. Please note that changes to
            factors may take up to 24 hours to be reflected in risk
            calculations.
          </Typography>
        </Grid>
        <Grid item>
          {!editMode ? (
            <Tooltip title="Edit Factors">
              <IconButton onClick={() => setEditMode(!editMode)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Grid container item spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  style={{ backgroundColor: "white" }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <TableContainer
        elevation={0}
        variant="outlined"
        component={Paper}
        className={classes.container}
      >
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Factor Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Influence on Risk Score</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assetRiskFactors?.map((factor) => (
              <TableRow key={factor.id}>
                <TableCell>{factor.display_name}</TableCell>
                <TableCell>{/* <div>{factor.description}</div> */}</TableCell>
                <TableCell className={classes.sliderCell}>
                  <FactorSlider
                    disabled={!editMode}
                    reset={reset}
                    handleChangeCommitted={handleChangeCommitted}
                    factor={factor}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
];
