import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as d3 from "d3";
import { useHistory } from "react-router-dom";
import { useHoverPopover } from "../components/HoverPopover";
import { useApi } from "../API";

const useStyles = makeStyles(() => ({
  bubbleMap: {
    borderRadius: "900px",
    boxSizing: "border-box",
    height: "100%",
    width: "100%",
    display: "flex",
    padding: "32px",
    background: "#383D3A",
    position: "relative",
    zIndex: 111,
    cursor: "pointer",
  },
  nodesViewWrapper: {
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 11,
    borderRadius: "900px",
    border: "none",
  },
  bubbleVisLoading: {
    display: "flex",
  },
  loadingImg: {
    marginRight: "20px",
  },
}));

function NodesView(props) {
  const [loadingNodes, setLoadingNodes] = useState(true);
  const [data, setData] = useState(true);
  const nodesMap = useRef(null);

  const classes = useStyles();
  const api = useApi();
  const history = useHistory();
  const { showPopover, hidePopover } = useHoverPopover();

  const { parentWidth, subnetData } = props;

  const diameter = Math.min(Math.max(parentWidth - 500, 500), props.mapsize);
  const margin = 20;
  const padding = 72;

  const loadNodesData = () => {
    setLoadingNodes(true);

    const [subnet, bits] = subnetData.data.name.split("/");

    api
      .crownJewelsBubbleSubnet(subnet, bits)
      .then((res) => {
        setData({ name: "root", children: res.data });
        setLoadingNodes(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    loadNodesData();
  }, []);

  useEffect(() => {
    if (data && nodesMap.current) {
      const wrapper = d3.select(nodesMap.current);
      wrapper.selectAll("*").remove();
      const g = wrapper.append("g");

      const pack = d3
        .pack()
        .size([diameter - padding - margin, diameter - padding - margin]);
      pack.padding(20);

      let root = data;
      root = d3
        .hierarchy(root)
        .sum((d) => {
          if (typeof d.size === "number") {
            return d.size;
          }
          return 0;
        })
        .sort((a, b) => b.value - a.value);

      root = root.count();

      const nodes = pack(root).descendants();

      const circleWrapper = g
        .selectAll("circle")
        .data(nodes)
        .enter()
        .append("g")
        .style("position", "relative")
        .attr("class", "circle-wrapper");

      circleWrapper
        .append("circle")
        .attr("cx", (d) => d.x)
        .attr("cy", (d) => d.y)
        .attr("r", (d) => d.r)
        .attr("class", "node")
        .attr("data-test", (d) => {
          if (d.data.name === "universe") {
            return "Entire Network";
          }
          return d.data.ipv4;
        })
        .attr("fill", (d) =>
          d.depth ? (d.data.is_critical ? "#BE00AA" : "white") : "transparent"
        )
        .style("cursor", (d) => (d.data.is_critical ? "pointer" : "default"))
        .on("mouseover", (event, d) => {
          if (d.depth) {
            showPopover({ data: d.data, trigger: event.target });
          }
          if (d.data.is_critical) {
            d3.select(this).classed("highlight node node--mca", true);
          }
        })
        .on("mouseout", (event, d) => {
          hidePopover({ trigger: event.target });
          if (d.data.is_critical) {
            d3.select(this).classed("highlight", false);
          }
        })
        .on("click", (event, d) => {
          history.push(`/app/inventory/details/${d.data.id}`);
        });

      circleWrapper
        .append("text")
        .text((d) => d.data.ipv4)
        .attr("font-weight", () => 700)
        .attr("font-size", () => "16px")
        .attr("fill", "black")
        .attr("fill-opacity", 1)
        .attr("y", () => "4")
        .style("position", "absolute")
        .style("top", "50%")
        .attr("text-anchor", "middle")
        .attr("transform", (d) => `translate(${d.x},${d.y})`);
    }
  }, [nodesMap.current, loadingNodes, parentWidth]);

  return (
    <div
      style={{
        width: Math.min(Math.max(parentWidth - 500, 500), props.mapsize),
        height: Math.min(Math.max(parentWidth - 500, 500), props.mapsize),
      }}
      className={classes.nodesViewWrapper}
    >
      {!loadingNodes && (
        /* Zoomed In BubbleMap */
        <svg
          className={`bubblemap ${classes.bubbleMap}`}
          data-test="bubble-vis"
          ref={nodesMap}
        />
      )}
    </div>
  );
}

export default NodesView;
