import React from "react";
import {
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { numberWithCommas } from "../utils";

const StandardTable = ({ data, onClick, firstColWidth }) => {
  const clickable = !!onClick;
  return (
    <Paper variant="outlined" elevation={0}>
      <TableContainer>
        <Table size="small">
          <colgroup>
            <col
              style={
                firstColWidth !== undefined ? { minWidth: firstColWidth } : {}
              }
            />
          </colgroup>
          <TableHead>
            <TableRow>
              {data.headers?.map((el, idx) => {
                return <TableCell key={idx}>{el.display_name}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.data?.map((row, idx) => (
              <TableRow
                hover={clickable}
                style={clickable ? { cursor: "pointer" } : {}}
                onClick={() => onClick(row)}
                key={idx}
              >
                {data.headers?.map((header) => {
                  return (
                    <TableCell key={`${idx}-${header.name}`}>
                      {header.format !== undefined
                        ? header.format(row)
                        : numberWithCommas(row[header.name])}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default StandardTable;
