import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { svgIcons } from "../icons/Icons";
import Icon from "../icons/icon";
import ToastContext from "../context/ToastContext";
import FileUploadButton from "../components/FileUploadButton";
import { palette } from "../utils/theme";
import { useApi } from "../API";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  outerWrapper: {
    minHeight: "400px",
    flexGrow: 1,
    maxWidth: "800px",
  },
  wrapper: {
    position: "relative",
    padding: "1rem",
  },
  innerWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(1),
  },
  buttonWrapper: {
    margin: theme.spacing(1),
    position: "relative",
    width: "100%",
  },
  option: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    flexGrow: 1,
    padding: "0 0 1rem",
  },
  formControlRoot: {
    width: "100%",
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonUpload: {
    width: 150,
  },
  circularProgress: {
    color: "black",
    padding: "0 1rem",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: "white",
    border: "2px solid #000",
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function UploadForm(props) {
  const classes = useStyles();
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [keyFile, setKeyFile] = useState(null);
  const [certFile, setCertFile] = useState(null);
  const [chainFile, setChainFile] = useState(null);
  const [hasCreds, setHasCreds] = useState(false);
  const [mode, setMode] = useState("add");

  const toastContext = useContext(ToastContext);

  useEffect(() => {
    handleCheckCreds();
  }, []);

  const FileUploadFeedback = (status) => {
    if (status.status === "OK") {
      return toastContext.showToast(
        "open",
        "success",
        `Credentials successfully uploaded.`
      );
    }
    return toastContext.showToast(
      "open",
      "error",
      `Oops. Something went wrong uploading the passed DEVO credentials. Please check the files and try again.`
    );
  };

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const handleCheckCreds = () => {
    api.getDevoSslCredentials().then((res) => {
      if (res.private_key && res.x509_certificate && res.chain_certificate) {
        setHasCreds(true);
        setMode("exists");
      } else {
        setHasCreds(false);
        setMode("add");
      }
    });
  };

  const handleKeyUpload = (files) => {
    setKeyFile(files[0]);
  };

  const handleCertUpload = (files) => {
    setCertFile(files[0]);
  };

  const handleChainUpload = (files) => {
    setChainFile(files[0]);
  };

  const uploadData = (keyfile, certfile, chainfile) => {
    setLoading(true);
    api.putDevoSslCredentials(keyfile, certfile, chainfile).then((res) => {
      if (res.status === "OK") {
        setSuccess(true);
        setLoading(false);
        setKeyFile(null);
        setCertFile(null);
        setChainFile(null);
        setMode("exists");
      } else {
        setSuccess(false);
      }
      FileUploadFeedback(res);
    });
  };

  const handleUpload = () => {
    if (!loading) {
      setLoading(true);
      setSuccess(false);
      uploadData(keyFile, certFile, chainFile);
    }
  };

  return (
    <Box
      className={classes.root}
      p={4}
      style={{ position: "relative", top: props.preHydrated && "-80px" }}
    >
      <Box component="div">
        <Typography component="h5" variant="h6">
          SSL Credentials
        </Typography>
      </Box>
      <Box className={classes.outerWrapper}>
        {(mode === "exists" || props.preHydrated) && (
          <div className={classes.wrapper}>
            <div className={classes.innerWrapper}>
              <CheckBoxIcon color="secondary" />
              <Box component="span" p={1}>
                Devo SSL Credentials present
              </Box>
              <Button
                variant="contained"
                className={buttonClassname}
                onClick={() => setMode("add")}
              >
                Update
              </Button>
            </div>
          </div>
        )}

        {(mode === "add" || props.preHydrated) && (
          <div className={classes.wrapper}>
            <div className={classes.innerWrapper}>
              {hasCreds === true && (
                <div className={classes.wrapper}>
                  <div
                    style={{
                      marginTop: "-16px",
                      paddingBottom: "8px",
                      marginBottom: "8px",
                    }}
                  >
                    <Button
                      component="div"
                      onClick={() => setMode("exists")}
                      startIcon={
                        <Icon
                          foreground={palette.darkGray}
                          icon={svgIcons.backArrow}
                        />
                      }
                    >
                      Back
                    </Button>
                  </div>
                </div>
              )}
              <Box pr={2} pt={2}>
                Private Key
                <FileUploadButton
                  id="key-upload-button"
                  className={buttonClassname}
                  variant="contained"
                  color="primary"
                  size="large"
                  onDrop={handleKeyUpload}
                />
              </Box>
              <Box pr={2} pt={2}>
                Certificate
                <FileUploadButton
                  id="cert-upload-button"
                  className={buttonClassname}
                  variant="contained"
                  color="primary"
                  size="large"
                  onDrop={handleCertUpload}
                />
              </Box>
              <Box pr={2} pt={2}>
                Chain Certificate
                <FileUploadButton
                  id="chain-upload-button"
                  className={buttonClassname}
                  variant="contained"
                  color="primary"
                  size="large"
                  onDrop={handleChainUpload}
                />
              </Box>
            </div>
            <div style={{ display: "flex", paddingTop: "16px" }}>
              <div className={classes.buttonWrapper}>
                <Button
                  variant="contained"
                  style={{ width: "100%" }}
                  className={buttonClassname}
                  disabled={!keyFile || !certFile || !chainFile || loading}
                  onClick={handleUpload}
                >
                  Upload
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </Box>
    </Box>
  );
}
