import React, { useRef, useEffect, useState } from "react";
// https://medium.com/@jeffbutsch/using-d3-in-react-with-hooks-4a6c61f1d102
import * as d3 from "d3";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import AssetsPanel from "./AssetsPanel";
import NodesView from "./NodesView";
import BubbleMapLegend from "./BubbleMapLegend";
import { palette, theme } from "../utils/theme";
import spinner from "../assets/res-spinner.svg";
import Icon from "../icons/icon";
import { svgIcons } from "../icons/Icons";
import { useApi } from "../API";

// ----------
/* JSS Wrapper Styles from Index.css */

const useStyles = makeStyles(() => ({
  bubbleMapContainerInner: {
    backgroundColor: "transparent",
    position: "relative",
  },
  bubbleMap: {
    borderRadius: "900px",
    border: "32px solid white",
    boxSizing: "content-box",
    marginLeft: "-24px",
    marginRight: "-24px",
    marginBottom: "-24px",
    position: "relative",
    zIndex: 1,
    cursor: "pointer",
  },
  bubbleVisLoading: {
    display: "flex",
  },
  loadingImg: {
    margin: "0 auto",
    display: "block",
    width: "120px",
  },
  zoomBtn: {
    position: "absolute",
    zIndex: 100,
    right: "8px",
    top: "8px",
    cursor: "pointer",
    padding: "8px",
    lineHeight: "40px",
    height: "40px",
  },
}));

// ----------
/* Create Demo Data for MCA Icons */
const mcaData = [];

function makemcaData() {
  let item;
  for (let i = 0; i < 30; i += 1) {
    item = [];
    item.push(Math.floor(105 - Math.random() * 25));
    item.push(Math.floor(105 - Math.random() * 25));
    item.push(
      `172.${Math.floor(Math.random() * 100)}.11.${Math.floor(
        Math.random() * 1000
      )}`
    );
    item.push(`MCA00${i}`);
    mcaData.push(item);
  }
}

makemcaData();

// ----------

// BubbleMap
const Bubble = (props) => {
  // Flag toggle for small network
  const SM_NETWORK = true;

  const history = useHistory();
  const api = useApi();

  /* The useRef Hook creates a variable that "holds on" to a value across rendering
       passes. In this case it will hold our component's SVG DOM element. It's
       initialized null and React will assign it later (see the return statement) */
  const d3Container = useRef(null);
  const pack = useRef(null);
  const node = useRef(null);
  const circle = useRef(null);
  const circleWrapper = useRef(null);
  const diameter = useRef(null);

  const zoomClass = useRef("root");

  const [parentWidth, setParentWidth] = useState(0);
  // const [parentHeight, setParentHeight] = useState(-1);

  const ipMcaDisplayCt = 18;
  const [subnet, setSubnet] = useState(null);
  const loadingNodes = true;
  const [viewType, setViewType] = useState("network");
  const [zoomNode, setZoomNode] = useState();
  const [lastEvent, setLastEvent] = useState();

  const classes = useStyles();

  function handleResize() {
    if (props.parentRef.current) {
      const newWidth = window.getComputedStyle(props.parentRef.current).width;
      // console.log(`resizing ${parentWidth} to ${newWidth}`);

      // setParentHeight(parseInt(curHeight.replace("px", "")));
      setParentWidth(parseInt(newWidth.replace("px", "")));
    }
  }
  /* d3 requires a width value in pixels. this grabs that information from the parent div */
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [data, setData] = React.useState(null);
  const [loading, setLoading] = useState(true);
  const [zAssetCount, setZAssetCount] = useState(null);
  const [subnetsCount, setSubnetsCount] = useState(null);
  const [MCACount, setMCACount] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [criticalAssets, setCriticalAssets] = useState([]);

  const zoomLevels = {
    root: 0,
    universe: 0,
    eight: 1,
    sixteen: 2,
    twentyfour: 3,
    thirtytwo: 4,
  };

  const openAssetDetails = (data) => {
    const assetId = data ? data.id : "";
    if (assetId) {
      history.push(`/app/inventory/details/${assetId}`);
    }
  };

  const loadBubbleData = (groupName) => {
    setLoading(true);
    api
      .crownJewelsBubble(groupName)
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const updatePreviewPanel = (nodes) => {
    let visibleAssetsCount = 0;
    let visibleSubnetsCount = 0;
    let visibleCriticalAssetsCount = 0;

    for (const datum of nodes) {
      if (datum.data) {
        if (datum.data.type === "twentyfour") {
          visibleAssetsCount += datum.data.size;
          visibleCriticalAssetsCount += datum.data.critical_assets;
        } else if (
          datum.data.type === "twentyfour" ||
          datum.data.type === "sixteen" ||
          datum.data.type === "eight"
        ) {
          visibleSubnetsCount += 1;
        }
      }
    }

    setSubnetsCount(visibleSubnetsCount);
    setZAssetCount(visibleAssetsCount);
    setMCACount(visibleCriticalAssetsCount);
  };

  // useEffect(() => {
  //   api.crownJewelsSubnet(subnet).then((res) => {
  //     setMcaData(res);
  //   });
  // }, [subnet, data]);

  useEffect(() => {
    api
      .crownJewelsBubbleCacheRegenerate()
      .then(() => {
        loadBubbleData(props.groups[0].selected);
      })
      .catch((error) => console.log(error));
    // props.reload is the dependency in the array.
    // if this increments, then we can run the effect.
  }, [props.reload]);

  useEffect(() => {
    loadBubbleData(props.groups[0].selected);
  }, [props.groups[0].selected]);

  const svg = d3.select(d3Container.current);
  // in order to embed foreign object html elements (text labels)
  svg.attr("xmlns:xhtml", "http://www.w3.org/1999/xhtml");

  const margin = 20;

  /* The useEffect Hook is for running side effects outside of React,
       for instance inserting elements into the DOM using D3 */
  useEffect(
    () => {
      // why is there a data.data here? this seems like a code smell
      if (data && data.data && d3Container.current) {
        diameter.current = +svg.attr("width");

        svg.selectAll("*").remove();

        const g = svg
          .append("g")
          .attr(
            "transform",
            `translate(${diameter.current / 2},${diameter.current / 2})`
          );

        pack.current = d3
          .pack()
          .size([diameter.current - margin, diameter.current - margin]);

        pack.current.padding(2);

        let root = data.data;
        root = d3
          .hierarchy(root)
          .sum((d) => {
            if (typeof d.size === "number") {
              return d.size;
            }
            return 0;
          })
          .sort((a, b) => b.value - a.value);

        root = root.count();

        const nodes = pack.current(root).descendants();
        updatePreviewPanel(nodes);

        circleWrapper.current = g
          .selectAll("circle")
          .data(nodes)
          .enter()
          .filter((d) => d.depth < 4)
          .append("g")
          .attr("class", "circle-wrapper");

        circle.current = circleWrapper.current
          .append("circle")
          // leaf node color here
          .attr("class", (d) => {
            if (d.depth === 0) {
              return "node node--root";
            }
            if (d.depth === 1) {
              return "node node--branch-outer";
            }
            if (d.depth === 2) {
              return "node node--branch-inner hidden";
            }
            if (d.depth === 3 && !d.children) {
              if (d.data.critical_assets === 0) {
                return "node node--leaf hidden";
              }
              return "node node--leaf node--mca hidden";
            }
            if (d.depth === 3) {
              if (d.data.critical_assets > 0) {
                return "node node--branch-inmost node--mca hidden";
              }
              return "node node--branch-inmost hidden";
            }
            if (d.depth === 4) {
              if (d.data.is_critical) {
                return "node node--leaf node--mca sixtyfour hidden";
              }
              return "node node--leaf sixtyfour hidden";
            }
            return "unknown";
          })
          .attr("data-test", (d) => {
            if (d.data.name === "universe") {
              return "Entire Network";
            }
            return d.data.name;
          })
          .style("stroke", (d) => {
            if (d.data.critical_assets > 0) {
              return theme.palette.purple1;
            }
            return "none";
          })
          .on("mouseenter", (event, d) => {
            if (d.depth !== zoomLevels[zoomClass.current]) {
              d3.select(this)
                .classed("highlight", true)
                .style("cursor", "pointer");
            } else {
              d3.select(this).style("cursor", "default");
            }
            if (d.depth > 0) {
              props.showPopover({ data: d.data, trigger: event.target });
            }
          })
          .on("mouseleave", (event) => {
            props.hidePopover({ trigger: event.target });
            d3.select(this).classed("highlight", false);
          })
          .on("click", (event, d) => {
            if (SM_NETWORK) {
              if (d.depth < 4) {
                zoom(event, d);
              } else {
                openAssetDetails(d.data);
              }
            } else if (d.depth < 3) {
              zoom(event, d);
            } else {
              openAssetDetails(d.data);
            }
            if (d.depth < 3) {
              setLastEvent(event);
            }
            event.stopPropagation();
          })
          .text((d) => d.data.name)
          .style("color", "black")
          .style("size", "38px");

        circleWrapper.current
          .append("foreignObject")
          .attr("width", (d) => {
            return d.depth === 3 ? d.r * 8 : d.r * 2;
          })
          .attr("height", (d) => {
            return d.depth === 3 ? d.r * 8 : d.r * 2;
          })
          .attr("x", (d) => (d.depth === 3 ? -d.r * 4 : -d.r))
          .attr("y", (d) => (d.depth === 3 ? -d.r * 4 : -d.r))
          .style("pointer-events", "none")
          .style("opacity", (d) => (d.depth === 1 ? 1 : 0))
          .append("xhtml:div")
          .attr("xmlns", "http://www.w3.org/1999/xhtml")
          .style("width", "100%")
          .style("max-width", (d) => (d.depth === 3 ? "100px" : "none"))
          .style("margin", "0 auto")
          .style("height", (d) => `${d.depth === 3 ? d.r * 8 : d.r * 2}px`)
          .style("display", "flex")
          .style("align-items", "center")
          .style("justify-content", "center")
          .append("xhtml:p")
          .attr("xmlns", "http://www.w3.org/1999/xhtml")
          .style("word-break", "break-all")
          .style("text-align", "center")
          .style("margin", 0)
          .text((d) => d.data.name || null)
          .style("font-weight", () => 700)
          .style("line-height", (d) => {
            return d.depth === 3
              ? `${d.r / 2 < 9 ? 9 : d.r / 2}px`
              : d.depth === 2
                ? `${d.r / 3}px`
                : `${d.r / 4}px`;
          })
          .style("font-size", (d) => {
            return d.depth === 3
              ? `${d.r / 2 < 9 ? 9 : d.r / 2}px`
              : d.depth === 2
                ? `${d.r / 3}px`
                : `${d.r / 4}px`;
          })
          .style("color", (d) => {
            if (d.data.critical_assets) {
              return "white";
            }
            return "black";
          });

        node.current = g.selectAll("circle,foreignObject");

        svg
          // .style('background', color(-1))
          .on("click", (event) => {
            zoom(event, root);
          });

        zoomTo([root.x, root.y, root.r * 2 + margin]);
      }
    },

    /*
            useEffect has a dependency array (below). It's a list of dependency
            variables for this useEffect block. The block will run after mount
            and whenever any of these variables change. We still have to check
            if the variables are valid, but we do not have to compare old props
            to next props to decide whether to rerender.
        */
    [data, d3Container.current, parentWidth]
  );

  const view = useRef(null);

  const handleZoomOut = () => {
    if (viewType === "node") {
      setViewType("network");
    }
    zoom(lastEvent, zoomNode.parent);
  };

  function hookGrouping(d) {
    setZoomNode(d);
    const cs = d.copy();
    const zoomNodes = pack.current(cs).descendants();

    zoomClass.current = d.data.type ? d.data.type : "root";

    if (d.data.type === "twentyfour") {
      setViewType("node");
    }
    updatePreviewPanel(zoomNodes);
  }

  function zoom(event, d) {
    // only allow zooming into 1 level max at once
    // if (zoomLevels[zoomClass.current] >= d.depth-1) {
    let zd = d;
    let pd = d.parent && d.parent.depth ? d.parent.depth : 0;
    runZoom();

    function runZoom() {
      // parent depth - 1
      if (zoomLevels[zoomClass.current] >= pd) {
        hookGrouping(zd);
        setSubnet(zd.data.name);
        const transition = d3
          .transition(event)
          .duration(event.altKey ? 7500 : 1000)
          .tween("zoom", () => {
            const i = d3.interpolateZoom(view.current, [
              zd.x,
              zd.y,
              zd.r * 2 + margin,
            ]);
            return (zd) => {
              zoomTo(i(zd));
            };
          });
        transition
          .selectAll("foreignObject")
          .style("opacity", (d) => {
            if (zd === d.parent) {
              return 1;
            }
            return 0;
          })
          // eslint-disable-next-line func-names
          .on("start", function (d) {
            if (d.parent === zd) {
              this.style.display = "inline";
            }
          })
          // eslint-disable-next-line func-names
          .on("end", function (d) {
            if (d.parent !== zd) this.style.display = "none";
          });
      } else {
        zd = zd.parent || zd;
        pd = zd.parent.depth;
        runZoom();
      }
    }

    // }
  }

  useEffect(() => {
    api.crownJewels().then((res) => {
      setCriticalAssets(res.data);
    });
  }, []);

  function zoomTo(v) {
    const k = diameter.current / v[2];
    view.current = v;
    node.current.attr(
      "transform",
      (d) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`
    );
    circle.current.attr("r", (d) => d.r * k);
  }

  const assetIsInSubnet = (subnet, asset, zoomClass) => {
    if (subnet === null || zoomClass.current === "root") {
      return true;
    }
    const cidr = subnet.split("/")[1];
    const octets = asset.ipv4 && asset.ipv4.split(".");
    const subnetOctets = subnet.split("/")[0].split(".");

    if (cidr === "8") {
      if (octets && octets[0] === subnetOctets[0]) {
        return true;
      }
    } else if (cidr === "16") {
      if (
        octets &&
        octets[0] === subnetOctets[0] &&
        octets[1] === subnetOctets[1]
      ) {
        return true;
      }
    } else if (cidr === "24") {
      if (
        octets &&
        octets[0] === subnetOctets[0] &&
        octets[1] === subnetOctets[1] &&
        octets[2] === subnetOctets[2]
      ) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (criticalAssets) {
      const filteredRows = [
        ...criticalAssets.filter((asset) => {
          return assetIsInSubnet(subnet, asset, zoomClass);
        }),
      ];
      setFiltered(filteredRows);
    }
  }, [zoomClass, subnet, criticalAssets]);

  const mapsize = parentWidth * (props.hideAssetTable ? 0.30 : 0.42);

  return (
    <>
      {/* Loading */}
      {loading && (
        <div
          style={{
            position: "absolute",
            marginTop:
              Math.min(Math.max(parentWidth - 500, 500), mapsize) / 2 - 60,
            zIndex: 0,
            width: mapsize,
          }}
          className={`${zoomClass.current} ${classes.bubbleVisLoading}`}
          data-test="bubble-vis-loading"
        >
          <img src={spinner} className={classes.loadingImg} alt="Loading" />
        </div>
      )}

      {/* BubbleMap */}
      <div
        style={{
          position: "relative",
          zIndex: 1,
          maxWidth: Math.min(Math.max(parentWidth - 500, 500), mapsize),
        }}
      >
        <div className={`${zoomClass} ${classes.bubbleMapContainerInner}`}>
          <>
            <div
              className={`${zoomClass.current} ${classes.bubbleMapContainerInner}`}
            >
              <BubbleMapLegend SM_NETWORK={SM_NETWORK} />
              <Button
                className={classes.zoomBtn}
                data-test="zoom-btn"
                style={{ opacity: zoomClass.current !== "root" ? 1 : 0 }}
                onClick={
                  zoomClass.current !== "root" ? handleZoomOut : undefined
                }
              >
                <Icon
                  foreground={palette.darkGray}
                  className={classes.zoomBtnIcon}
                  icon={svgIcons.zoomOut}
                />
                Zoom Out
              </Button>
              <svg
                className={`bubblemap ${classes.bubbleMap} ${viewType === "node" && "node-view"
                  }`}
                onClick={() => setSubnet(null)}
                width={Math.min(Math.max(parentWidth - 500, 500), mapsize)}
                height={Math.min(Math.max(parentWidth - 500, 500), mapsize)}
                ref={d3Container}
                data-test="bubble-vis"
              />
            </div>

            {/* Node View */}
            {viewType === "node" && (
              <NodesView
                parentWidth={parentWidth}
                loadingNodes={loadingNodes}
                subnetData={zoomNode}
                handleZoomOut={handleZoomOut}
                mapsize={mapsize}
              />
            )}
          </>
        </div>
      </div>
      {/* AssetsPanel */}
      {props.hideAssetTable !== true && (
        <AssetsPanel
          zoomClass={zoomClass.current}
          parentWidth={Math.min(Math.max(parentWidth - 500, 500), mapsize)}
          parentHeight={
            Math.min(Math.max(parentWidth - 500, 500), mapsize) + 32
          }
          subnet={subnet}
          subnetsCount={subnetsCount}
          zAssetCount={zAssetCount}
          MCACount={MCACount}
          filtered={filtered}
          ipMcaDisplayCt={ipMcaDisplayCt}
        />
      )}
    </>
  );
};

const BubbleWrapper = (props) => <Bubble {...props} />;

export default BubbleWrapper;
