import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { palette } from "./utils/theme";
import error from "./assets/error.svg";

function PageNotFoundInner(props) {
  const tablet = useMediaQuery("(max-width:1400px)");

  const useStyles = makeStyles(() => ({
    wrapper: {
      background: palette.keyBlue,
      position: "relative",
      height: "calc(100vh - 180px)",
      overflowY: "hidden",
      paddingTop: "2rem",
    },
    paper: {
      background: "white",
      margin: "0 auto",
      width: "calc(100% - 6rem)",
      height: "calc(100% - 200px)",
      marginTop: "120px",
      padding: "0 4rem",
      zIndex: 1,
    },
    heading: {
      textAlign: "center",
      fontWeight: 100,
      textTransform: "uppercase",
      position: "relative",
      fontSize: "120px",
      top: tablet ? "-108px" : "-148px",
      marginBottom: tablet ? "0" : "-226px",
      color: "white",
      lineHeight: tablet ? "120px" : "200px",
    },
    subheading: {
      position: "relative",
      marginBottom: tablet ? "-64px" : "-78px",
      top: tablet ? 0 : "-78px",
      color: "#D1D9DB",
    },
    paragraph: {
      textAlign: "center",
      marginBottom: "5rem",
    },
    image: {
      width: "60%",
      margin: "0 auto",
      display: "block",
    },
  }));

  const classes = useStyles();

  return (
    <>
      <div>
        <div className={classes.wrapper}>
          <div className={classes.paper}>
            <h1 className={classes.heading}>
              {props.errorStatus || "404"}
              <div className={classes.subheading}>
                {props.errorMsg || "Page Not Found"}
              </div>
            </h1>
            <p className={classes.paragraph}>
              {props.errorDetails ||
                "The URL you are trying to reach is unavailable. Please try again, or click one of the tabs above."}
            </p>
            <img src={error} className={classes.image} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default PageNotFoundInner;
