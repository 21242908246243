import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Box } from "@material-ui/core";
// import Alert from "@material-ui/lab/Alert";
import DataGovernanceMetricsTable from "./charts/DataGovernanceMetricsTable";
import VulnSection from "./VulnMgmtSection";
import SecOpsSection from "./SecOpsSection";
import CISOSection from "./CISOSection";
import SecEngSection from "./SecEngSection";
import { RoundedTab, RoundedTabs } from "../components/RoundedTab";
// import { palette } from "../utils/theme";
import { useApi } from "../API";

const useStyles = makeStyles((theme) => ({
  root: theme.layout.root,
  contentWrapper: theme.layout.contentWrapper,
  content: theme.layout.content,
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  reportSelect: {
    marginBottom: "1rem",
    width: "300px",
    background: "white",
  },
}));
function Reporting() {
  const api = useApi();
  const classes = useStyles();
  const { pathname } = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(false);
  const [hasData, setHasData] = useState(false);

  useEffect(async () => {
    // Explanation if there are not a lot of metrics yet in the instance.
    // Just pick a random metric that we know was introduce in the new version
    // and most instances probaly have data for
    if (!hasData) {
      const result = await api.allMetrics({
        pattern: "total_covered_by_*_high_impact",
        period: "week",
      });
      if (
        !result?.data?.metrics?.length ||
        (result?.data?.metrics?.length > 0 &&
          result?.data?.metrics[0].history.length <= 2)
      ) {
        setOpen(true);
      }
      setHasData(true);
    }
  }, [hasData]);

  const handleTabChange = (event, newValue) => {
    history.push(newValue);
  };

  const baseRoute = "/app/reporting";
  const tabs = [
    {
      label: "CIO & CISO",
      path: `${baseRoute}/ciso`,
      name: "ciso",
      dataTest: "tab-button-ciso",
    },
    {
      label: "Vulnerability Management",
      path: `${baseRoute}/vuln-mgmt`,
      name: "vuln-mgmt",
      dataTest: "tab-button-vuln-mgmt",
    },
    {
      label: "Security Operations",
      path: `${baseRoute}/sec-ops`,
      name: "sec-ops",
      dataTest: "tab-button-sec-ops",
    },
    {
      label: "Data Governance",
      path: `${baseRoute}/data-governance`,
      name: "data-governance",
      dataTest: "tab-button-data-governance",
    },
    {
      label: "Security Engineering",
      path: `${baseRoute}/sec-eng`,
      name: "sec-eng",
      dataTest: "tab-button-sec-eng",
    },
  ];

  const history = useHistory();

  document.title = "KeyCaliber - Trends";

  return (
    <Box
      className={classes.root}
      data-test="reports-page"
      data-testid="reporting"
    >
      <div className={classes.contentWrapper}>
        {/* <div
          style={{
            margin: "1em",
            height: "4em",
            width: "100%",
            ...(!open ? { display: "none" } : { display: "block" }),
          }}
        >
          <Snackbar
            style={{ width: "100%" }}
            anchorOrigin={{ vertical: "middle", horizontal: "center" }}
            open={open}
          >
            <Alert
              elevation={3}
              style={{
                backgroundColor: palette.highImpact,
                color: "white",
                width: "90%",
              }}
              variant="filled"
              onClose={() => setOpen(false)}
              severity="info"
            >
              Welcome to KeyCaliber Trends! Metrics collect on a daily basis. If
              you do not see data in Trends charts, come back in a week to see
              your metrics populated here.
            </Alert>
          </Snackbar>
        </div> */}
        <Box className={classes.content}>
          {/* <Dropdown history={history} /> */}
          <RoundedTabs
            value={pathname}
            onChange={handleTabChange}
            aria-label="primary tabs example"
          >
            {tabs.map((tab, idx) => {
              return (
                <RoundedTab
                  label={tab.label}
                  id={idx}
                  key={idx}
                  value={tab.path}
                />
              );
            })}
          </RoundedTabs>

          <Switch>
            <Route exact path="/app/reporting">
              <Redirect to="/app/reporting/ciso" />
            </Route>
            <Route exact path="/app/reporting/ciso">
              <CISOSection />
            </Route>
            <Route exact path="/app/reporting/vuln-mgmt">
              <VulnSection />
            </Route>
            <Route exact path="/app/reporting/sec-ops">
              <SecOpsSection />
            </Route>
            <Route exact path="/app/reporting/data-governance">
              <DataGovernanceMetricsTable />
            </Route>
            <Route exact path="/app/reporting/sec-eng">
              <SecEngSection />
            </Route>
          </Switch>
        </Box>
      </div>
    </Box>
  );
}

export default Reporting;
