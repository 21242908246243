import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function ScenariosIcon(props) {
  return (
    <SvgIcon {...props}>
      <g>
        <path
          className="cls-1"
          d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2M9 17H7v-7h2v7m4 0h-2V7h2v10m4 0h-2v-4h2v4Z"
        />
      </g>
    </SvgIcon>
  );
}

export default ScenariosIcon;
