import React, { useState, useEffect } from "react";
import { ResponsiveLine } from "@nivo/line";
import { abbreviateLargeValue } from "../utils";
import { useApi } from "../API";

function renderTooltip({ point }) {
  return (
    <div
      style={{
        backgroundColor: "white",
        color: "inherit",
        fontSize: "inherit",
        borderRadius: "2px",
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
        padding: "5px 9px",
      }}
    >
      <div>
        <strong>{point.id.split(".")[0]}</strong>
        <br />
        {abbreviateLargeValue(point.data.y.toFixed(0), true)} Rows
        <br /> For week beginning: {point.data.x.toDateString()}
      </div>
    </div>
  );
}

const TelemetryOverTime = () => {
  const api = useApi();
  const [data, setData] = useState();

  useEffect(async () => {
    const res = await api.connectorStats(12);
    setData(res);
  }, []);

  if (!data) {
    return <div> No historical run data found</div>;
  }

  const formatMetricDataForNivo = (data) => {
    const formatted = [];

    for (const connectorStats of data?.stats) {
      if (connectorStats.is_enabled) {
        const nivoData = {
          data: [],
          id: `${connectorStats.solution.display_name} <> ${connectorStats.datasource.display_name} `,
        };

        for (const [week, count] of Object.entries(connectorStats.weeks)) {
          nivoData.data.push({ x: week, y: count });
        }

        formatted.push(nivoData);
      }
    }

    return formatted;
  };

  return (
    <div style={{ height: 650, width: "100%" }}>
      <ResponsiveLine
        data={formatMetricDataForNivo(data)}
        height={650}
        animate
        margin={{ top: 40, right: 325, bottom: 80, left: 125 }}
        curve="monotoneX"
        yScale={{
          type: "symlog",
          min: 0,
          max: 1000000000,
        }}
        axisLeft={{
          format: (d) => abbreviateLargeValue(d, true),
          tickSize: 5,
          tickValues: [
            0, 100, 1000, 10000, 100000, 1000000, 10000000, 100000000,
            1000000000,
          ],
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: -70,
          legend: "# Rows Processed",
        }}
        axisBottom={{
          format: "%b %d",
          tickValues: "every week",
          legendPosition: "middle",
          legendOffset: 55,
          legend: "Week",
          tickSize: 6,
        }}
        xScale={{
          type: "time",
          format: "%Y-%m-%d",
          useUTC: false,
          precision: "day",
        }}
        xFormat="time:%Y-%m-%d"
        colors={{ scheme: "category10" }}
        pointSize={8}
        pointColor={["#fff"]}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        lineWidth={2}
        useMesh
        enableSlices={false}
        tooltip={renderTooltip}
        sliceTooltip={({ slice }) => (
          <div
            style={{
              background: "white",
              padding: "9px 12px",
              border: "1px solid #ccc",
            }}
          >
            <div>x: {slice.id}</div>
            {slice.points.map((point) => (
              <div
                key={point.id}
                style={{
                  color: point.serieColor,
                  padding: "1px 0",
                }}
              >
                <strong>{point.serieId}</strong> [{point.data.yFormatted}]
              </div>
            ))}
          </div>
        )}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: "#a9acbe",
                strokeWidth: 0.7,
              },
            },
          },
          grid: {
            line: {
              stroke: "#e7e8ec",
              strokeWidth: 0.7,
            },
          },
        }}
        // markers={
        //   data?.weeks?.length >= 0 && [
        //     {
        //       axis: "x",
        //       lineStyle: {
        //         stroke: "#F9F2D6",
        //         strokeWidth: 35,
        //         opacity: 0.5,
        //         zIndex: -2,
        //       },
        //       value: data.weeks[data.weeks.length - 1],
        //     },
        //   ]
        // }
        legends={[
          {
            anchor: "top-right",
            direction: "column",
            justify: false,
            translateX: 125,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
          },
        ]}
      />
    </div>
  );
};

export default TelemetryOverTime;
