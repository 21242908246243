import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import {
  useHistory,
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { Security, LoginCallback, SecureRoute } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import ErrorBoundary from "./ErrorBoundary";
import Toast from "./components/Toast";
import ToastContext from "./context/ToastContext";
import Auth from "./auth/Main";
import Home from "./Home";
import { theme } from "./utils/theme";
import ForgotPasswordForm from "./auth/ForgotPasswordForm";

const SecuredRoute = (props) => {
  if (props.authType === "okta") {
    return <SecureRoute {...props}>{props.children}</SecureRoute>;
  }
  return <Route {...props}>{props.children}</Route>;
};

const Main = () => {
  const history = useHistory();
  const authType = window._env_.REACT_APP_AUTH_TYPE;

  if (authType === "okta") {
    const oktaAuth = new OktaAuth({
      issuer: `https://${window._env_.REACT_APP_OKTA_DOMAIN}/oauth2/default`,
      clientId: window._env_.REACT_APP_OKTA_ID,
      redirectUri: `${window.location.origin}/okta_callback`,
      // pkce: false, // SOFIA TODO confirm we can remove this
    });

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
      history.replace(toRelativeUrl(originalUri, window.location.origin));
    };

    return (
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Switch>
          <Route path="/okta_callback" component={LoginCallback} />
          <SecuredRoute authType={authType} path="/app">
            <Home authType={authType} />
          </SecuredRoute>
          <Route path="/">
            <Auth authType={authType} />
          </Route>
          <Route path="/error">An error occured</Route>
        </Switch>
      </Security>
    );
  }

  return (
    <Switch>
      <SecuredRoute authType={authType} path="/app">
        <Home authType={authType} />
      </SecuredRoute>
      <Route path="/error">An error occured</Route>
      <Route path="/login" component={Auth} />
      <Route path="/reset-password" component={ForgotPasswordForm} />
      <Route>
        <Redirect to="/app" />
      </Route>
    </Switch>
  );
};

function App() {
  /* Main application wrapper. Sets up theme */
  const [toastOptions, setToastOptions] = React.useState(null);
  const [toastPosition, setToastPosition] = React.useState("hybrid");

  function showToast(action, type, msg) {
    if (action === "open") {
      setToastOptions([type, msg]);
    } else if (action === "close") {
      setToastOptions(null);
    } else {
      // eslint-disable-next-line no-console
      console.error(
        'Toast must receive "open" or "close" as the first argument.'
      );
    }
  }

  return (
    <>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ToastContext.Provider
            value={{
              showToast,
              position: toastPosition,
              setPosition: (pos) => setToastPosition(pos),
            }}
          >
            {toastOptions && (
              <Toast
                msg={toastOptions[1]}
                type={toastOptions[0]}
                position={toastPosition}
              />
            )}
            <Router>
              <Main />
            </Router>
          </ToastContext.Provider>
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
}

export default App;
