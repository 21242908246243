import React from "react";
import { Chip, Avatar } from "@material-ui/core";
import { palette } from "../utils/theme";

const SeverityIcon = ({ score }) => {
  if (score === undefined) {
    return <div>No Data</div>;
  }

  score = parseFloat(score).toFixed(1);

  if (score >= 9.0) {
    return (
      <Chip
        label="Critical"
        variant="outlined"
        style={{
          color: palette.darkRed,
          borderColor: palette.darkRed,
        }}
        icon={
          <Avatar
            style={{
              fontSize: ".6rem",
              width: 24,
              height: 24,
              color: "white",
              backgroundColor: palette.darkRed,
            }}
          >
            {score}
          </Avatar>
        }
      />
    );
  }

  if (score < 9.0 && score >= 7.0) {
    return (
      <Chip
        label="High"
        variant="outlined"
        style={{ color: palette.red, borderColor: palette.red }}
        icon={
          <Avatar
            style={{
              fontSize: ".6rem",
              width: 24,
              height: 24,
              color: "white",
              backgroundColor: palette.red,
            }}
          >
            {score}
          </Avatar>
        }
      />
    );
  }

  if (score < 7.0 && score >= 4.0) {
    return (
      <Chip
        label="Medium"
        variant="outlined"
        style={{
          color: palette.orange,
          borderColor: palette.orange,
        }}
        icon={
          <Avatar
            style={{
              fontSize: ".6rem",
              width: 24,
              height: 24,
              color: "white",
              backgroundColor: palette.orange,
            }}
          >
            {score && parseFloat(score).toFixed(1)}
          </Avatar>
        }
      />
    );
  }

  if (score < 4.0) {
    return (
      <Chip
        label="Low"
        variant="outlined"
        style={{
          color: palette.yellow,
          borderColor: palette.yellow,
        }}
        icon={
          <Avatar
            style={{
              fontSize: ".6rem",
              width: 24,
              height: 24,
              color: "white",
              backgroundColor: palette.yellow,
            }}
          >
            {score}
          </Avatar>
        }
      />
    );
  }

  return <div>No Data</div>;
};

export default SeverityIcon;
