import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import _ from "lodash"; // eslint-disable-line import/no-extraneous-dependencies
import { palette } from "../utils/theme";
// Hotfix for demo - capitalize the name, really we should be using the display name of the solution.
const renderTooltip = (data) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        color: "inherit",
        fontSize: "inherit",
        borderRadius: "2px",
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
        padding: "5px 9px",
      }}
    >
      {(data.id === "critical_assets"
        ? "High Impact Assets: "
        : "All Assets: ") + _.capitalize(data.indexValue?.replaceAll("_", " "))}
      : <strong>{Math.floor(data.value)}%</strong>
    </div>
  );
};

export default function CoverageHorizontal(props) {
  const [data, setData] = useState();
  const [logoData, setLogoData] = useState();

  const CustomTick = (tick) => {
    return (
      <g transform={`translate(${tick.x - 75},${tick.y - 10})`}>
        <image
          width="70px"
          href={logoData[tick.value]}
          alt={`${tick.value} logo`}
        />
      </g>
    );
  };

  const CustomTickBottom = (tick) => {
    return (
      <g
        transform={`translate(${
          tick.value === 100 ? tick.x - 35 : tick.x + 5
        },${tick.y + 10})`}
      >
        <text style={{ fontSize: 12 }} fill="grey">
          {tick.value === 100 || tick.value === 0 ? `${tick.value}%` : ""}
        </text>
      </g>
    );
  };

  const formatResponseForChart = (data, logoData) => {
    const formatted = [];

    for (const d of data) {
      formatted.push({
        all_assets: d.percent_all,
        critical_assets: d.percent_high_impact,
        key: d.name,
      });
    }

    for (const key in logoData) {
      if (!formatted.find((el) => el.key === key)) {
        formatted.push({
          all_assets: 0,
          critical_assets: 0,
          key,
        });
      }
    }

    return formatted.sort((a, b) => (b.key > a.key ? 1 : -1));
  };

  useEffect(() => {
    if (!data) {
      setData(formatResponseForChart(props.data, props.logoData));
    }
    if (!logoData) {
      setLogoData(props.logoData);
    }
  }, []);

  return (
    <div style={{ height: 275 }}>
      <ResponsiveBar
        enableLabel
        labelTextColor="white"
        label={(d) => Math.floor(d.value) > 0 && `${Math.floor(d.value)}%`}
        maxValue={100}
        minValue={0}
        data={data}
        keys={["all_assets"]}
        indexBy="key"
        height={275}
        margin={{
          top: 20,
          right: 10,
          bottom: 10,
          left: 80,
        }}
        axisLeft={{
          tickSize: 0,
          renderTick: CustomTick,
        }}
        gridXValues={5}
        axisBottom={{
          tickSize: 4,
          renderTick: CustomTickBottom,
          format: (d) => (d === 100 ? `${d}%` : ""),
        }}
        groupMode="grouped"
        colors={[palette.keyBlue]}
        padding={0.3}
        layout="horizontal"
        borderColor="inherit:darker(1.6)"
        isInteractive
        enableGridX
        enableGridY
        theme={{
          grid: {
            line: {
              stroke: "#e7e8ec",
              strokeWidth: 1,
            },
          },
        }}
        // legends={[
        //   {
        //     dataFrom: "keys",
        //     data: [
        //       {
        //         color: palette.purple1,
        //         id: 1,
        //         label: "High Impact",
        //       },
        //       {
        //         color: palette.keyBlue,
        //         id: 2,
        //         label: "All Assets",
        //       },
        //     ],
        //     anchor: "top-right",
        //     direction: "row",
        //     translateY: -30,
        //     translateX: 30,
        //     itemWidth: 100,
        //     itemHeight: 20,
        //     itemDirection: "left-to-right",
        //     symbolSize: 10,
        //     symbolShape: "circle",
        //   },
        // ]}
        tooltip={renderTooltip}
      />
    </div>
  );
}
