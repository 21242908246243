import React, { useState, useEffect } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { useApi } from "../API";
import { palette } from "../utils/theme";
import ChartWrapper from "../components/ChartWrapper";
import { abbreviateLargeValue } from "../utils";
import TimePeriodDropDown from "../components/TimePeriodDropDown";
import ChartWrapperHeader from "../components/ChartWrapperHeader";

const renderTooltip = (data, displayNames) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        color: "inherit",
        fontSize: "inherit",
        borderRadius: "2px",
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
        padding: "5px 9px",
      }}
    >
      <strong>{`${displayNames[data.id]}: ${data.value}`}</strong>
      <br />
      {`for week ${data.indexValue}`}
    </div>
  );
};

const AlertsOverTimeBar = ({ grouped, metricNames, title, vuln }) => {
  const api = useApi();
  const [response, setResponse] = useState({
    data: [],
    loading: true,
    error: false,
  });
  const [displayNames, setDisplayNames] = useState({});
  const [selectedPeriod, setSelectedPeriod] = useState("year");

  const handlePeriodChange = (value) => {
    setSelectedPeriod(value);
  };

  const getLegend = (name) => {
    return name.includes("crit")
      ? `with Critical ${vuln ? "Vulnerabilities" : "Alerts"}`
      : name.includes("high_sev")
      ? `with High ${vuln ? "Vulnerabilities" : "Alerts"}`
      : name.includes("med")
      ? `with Medium ${vuln ? "Vulnerabilities" : "Alerts"}`
      : name.includes("low")
      ? `with Low ${vuln ? "Vulnerabilities" : "Alerts"}`
      : `with ${vuln ? "Vulnerabilities" : "Alerts"}`;
  };

  const getColor = (bar) => {
    return bar.id.includes("crit")
      ? palette.darkRed
      : bar.id.includes("high_sev")
      ? palette.red
      : bar.id.includes("med")
      ? palette.yellow
      : bar.id.includes("low")
      ? palette.green
      : palette.keyBlue;
  };

  const parseDate = (date) => {
    if (selectedPeriod === "year" || selectedPeriod === "quarter") {
      return `${new Date(Date.parse(date)).toLocaleString("default", {
        month: "short",
      })}`;
    }

    return new Date(Date.parse(date)).toISOString().slice(5, 10);
  };

  useEffect(async () => {
    const res = await api.allMetrics({
      metrics: metricNames,
      period: selectedPeriod,
    });
    const names = res.data?.metrics
      ?.filter((el) => metricNames.includes(el.name))
      ?.reduce((acc, el) => {
        acc[el.name] = el.display_name;
        return acc;
      }, {});

    setDisplayNames(names);
    const data = res.data?.metrics
      ?.filter((el) => metricNames.includes(el.name))
      ?.reduce((acc, metric) => {
        if (!acc[parseDate(metric.date)]) {
          acc[parseDate(metric.date)] = {};
        }

        acc[parseDate(metric.date)][metric.name] = metric.value;

        for (const pastMetric of metric.history) {
          if (!acc[parseDate(pastMetric.date)]) {
            acc[parseDate(pastMetric.date)] = {};
          }

          acc[parseDate(pastMetric.date)][metric.name] = pastMetric.value;
        }

        return acc;
      }, {});

    const nivoData = [];

    for (const key of Object.keys(data)) {
      nivoData.push({
        date: key,
        ...data[key],
      });
    }

    setResponse({
      // hotfix for bug where for this chart metrics come in reverse order
      data: nivoData?.reverse(),
      loading: false,
      error: false,
    });
  }, [selectedPeriod]);

  return (
    <div style={{ height: 400 }}>
      <ChartWrapper excludeHeader>
        <ChartWrapperHeader chartName={title}>
          <TimePeriodDropDown handlePeriodChange={handlePeriodChange} />
        </ChartWrapperHeader>
        <ResponsiveBar
          colors={getColor}
          margin={{ top: 30, right: 190, bottom: 140, left: 80 }}
          padding={0.3}
          valueScale={{ type: "linear" }}
          axisTop={null}
          axisRight={null}
          animate
          tooltip={(data) => renderTooltip(data, displayNames)}
          axisBottom={{
            tickSize: 3,
            tickPadding: 10,
            // tickRotation: -25,
            legend: "Date",
            legendPosition: "middle",
            legendOffset: 45,
          }}
          label={(d) => (d.value > 0 ? d.value : null)}
          legendLabel={(data) => getLegend(data.id)}
          legends={[
            {
              dataFrom: "keys",
              anchor: "top-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          // enableLabel={!grouped}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            format: (d) => abbreviateLargeValue(d, true),
            tickRotation: 0,
            legend: "Assets",
            legendPosition: "middle",
            legendOffset: -50,
          }}
          keys={metricNames}
          indexBy="date"
          data={response.data}
          groupMode={grouped ? "grouped" : "stacked"}
        />
      </ChartWrapper>
    </div>
  );
};

export default AlertsOverTimeBar;
