import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  bubbleMapLegend: {
    position: "absolute",
    background: "white",
    padding: "8px",
    top: "8px",
    left: "8px",
    zIndex: 1000,
    border: `1px solid ${theme.palette.lightGray}`,
    boxShadow: "0px 3px 1px -2px rgb(180, 180, 180, .45)",
  },
  legendList: {
    listStyle: "none",
    paddingLeft: "8px",
    marginTop: 0,
    marginBottom: 0,
  },
  legendWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingBottom: "4px",
  },
  legendItem: {
    display: "flex",
    paddingRight: "8px",
    alignItems: "center",
  },
  legendTitle: {
    display: "flex",
    paddingRight: "8px",
    alignItems: "center",
    fontWeight: "bold",
  },
  legendColor: {
    width: "10px",
    height: "10px",
    display: "block",
    borderRadius: "10px",
    background: "blue",
    marginRight: "4px",
  },
  level1: {
    background: theme.palette.lightBlue,
  },
  level2: {
    background: theme.palette.keyBlue,
  },
  level2B: {
    background: theme.palette.darkGray,
  },
  level3: {
    background: "white",
    border: "1px solid darkGray",
  },
  level3critical: {
    background: theme.palette.purple1,
  },
}));

function BubbleMapLegend(props) {
  const { t } = useTranslation();
  const { SM_NETWORK } = props;
  const classes = useStyles();

  return (
    <div className={classes.bubbleMapLegend}>
      <ul className={classes.legendList}>
        {!SM_NETWORK && (
          <div>
            <div className={classes.legendWrapper}>
              <div className={classes.legendItem}>
                <div className={`${classes.level1} ${classes.legendColor}`} />{" "}
                255.x.x.x{" "}
              </div>
              <div className={classes.legendItem}>
                <div className={`${classes.level2} ${classes.legendColor}`} />{" "}
                255.255.x.x{" "}
              </div>
              <div className={classes.legendItem}>
                <div className={`${classes.level3} ${classes.legendColor}`} />{" "}
                255.255.255.x
              </div>
            </div>
            <div className={classes.legendWrapper}>
              <div className={classes.legendItem}>
                <div
                  className={`${classes.level3critical} ${classes.legendColor}`}
                />{" "}
                255.255.255.x with {t("critScoreLabelMany")}
              </div>
            </div>
          </div>
        )}
        {SM_NETWORK && (
          <div>
            <div className={classes.legendWrapper}>
              <div className={classes.legendItem}>
                <div className={`${classes.level1} ${classes.legendColor}`} />{" "}
                255.x.x.x{" "}
              </div>
              <div className={classes.legendItem}>
                <div className={`${classes.level2} ${classes.legendColor}`} />{" "}
                255.255.x.x{" "}
              </div>
              <div className={classes.legendItem}>
                <div className={`${classes.level2B} ${classes.legendColor}`} />{" "}
                255.255.255.x{" "}
              </div>
            </div>
            <div className={classes.legendWrapper}>
              <div className={classes.legendItem}>
                <div className={`${classes.level3} ${classes.legendColor}`} />{" "}
                Low {t("critScoreLabel")}
              </div>
              <div className={classes.legendItem}>
                <div
                  className={`${classes.level3critical} ${classes.legendColor}`}
                />{" "}
                High {t("critScoreLabel")}
              </div>
            </div>
          </div>
        )}
      </ul>
    </div>
  );
}

export default BubbleMapLegend;
