import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import AddIcon from "@material-ui/icons/Add";
import ErrorIcon from "@material-ui/icons/Error";
import DatasourceDialog from "./DatasourceDialog";
import DatasourceMenu from "./DatasourceMenu";
import { useApi } from "../../API";
import getLogoUrl from "./ConnectorUtils";
import DatasourceDeleteDialog from "./DatasourceDeleteDialog";
import { palette } from "../../utils/theme";

const MOCK_DEMO = window._env_ && window._env_.MOCK_OUT_DEMO === "true";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  buttons: {
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  logo: {
    width: "100px",
    marginTop: ".1rem",
  },
  headerCell: {
    fontWeight: 800,
    backgroundColor: theme.palette.lightGray,
  },
  actionsCell: {
    width: "100px",
  },
}));

export default function DatasourceTable() {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const api = useApi();

  const [actionsAnchorEl, setActionsAnchorEl] = React.useState(null);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [newModalOpen, setNewModalOpen] = React.useState(false);
  const [editDatasourceId, setEditDatasourceId] = React.useState(-1);
  const [reloadDatasources, setReloadDatasources] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  const handleClickNewDatasource = () => {
    setNewModalOpen(true);
  };

  const handleCloseNewDatasource = () => {
    setNewModalOpen(false);
  };

  const refreshDatasources = () => {
    setReloadDatasources(!reloadDatasources);
  };

  const handleCloseEditDialog = () => {
    setEditDatasourceId(-1);
    setEditModalOpen(false);
  };

  const handleActionsMenuClick = (event) => {
    setEditDatasourceId(event.currentTarget.getAttribute("data-datasourceid"));
    setActionsAnchorEl(event.currentTarget);
  };

  const handleActionsMenuClose = () => {
    setEditDatasourceId(-1);
    setActionsAnchorEl(null);
  };

  const handleActionsMenuEdit = () => {
    setActionsAnchorEl(null);
    setEditModalOpen(true);
  };

  const handleActionsMenuDelete = () => {
    setActionsAnchorEl(null);
    setDeleteModalOpen(true);
  };

  useEffect(() => {
    api
      .getDatasources()
      .then((response) => {
        response.forEach((datasource) => {
          datasource.logo_url = getLogoUrl(datasource);
        });
        setData(response);
      })
      .catch((error) => console.log(error));
  }, [editModalOpen, newModalOpen, reloadDatasources]);

  return (
    <div className={classes.root}>
      <div className={classes.buttons} />
      {data && (
        <TableContainer
          component={Paper}
          elevation={0}
          variant="outlined"
          data-test="datasource-table-container"
        >
          <Table aria-label="datasource table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Data Source</TableCell>
                <TableCell>Base URL</TableCell>
                <TableCell />
                <TableCell style={{ textAlign: "center" }}>
                  {" "}
                  <Button
                    color="secondary"
                    variant="contained"
                    disableElevation
                    startIcon={<AddIcon />}
                    size="small"
                    onClick={handleClickNewDatasource}
                  >
                    New Data Source
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((datasource) => (
                <TableRow key={datasource.id}>
                  <TableCell>
                    {datasource.logo_url && datasource.logo_url !== "" ? (
                      <img
                        src={datasource.logo_url}
                        className={classes.logo}
                        alt={`${datasource.display_name} Logo`}
                      />
                    ) : (
                      datasource.display_name
                    )}
                  </TableCell>

                  <TableCell>{datasource.base_url}</TableCell>
                  <TableCell>
                    {!datasource.credential_key_exists &&
                      !datasource.credential_secret_exists &&
                      datasource.name !== "file" &&
                      !MOCK_DEMO && (
                        <Grid spacing={1} alignContent="center" container>
                          <Grid item alignItems="center">
                            <ErrorIcon
                              style={{
                                color: palette.orange,
                                fontSize: "25px",
                                marginTop: ".2em",
                              }}
                            />
                          </Grid>
                          <Grid item alignItems="center">
                            <Typography
                              style={{ marginTop: ".6em" }}
                              variant="body2"
                            >
                              Missing Credentials
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <IconButton
                      onClick={handleActionsMenuClick}
                      data-datasourceid={datasource.id}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <DatasourceMenu
        anchorEl={actionsAnchorEl}
        handleClose={handleActionsMenuClose}
        handleEdit={handleActionsMenuEdit}
        handleDelete={handleActionsMenuDelete}
      />
      <DatasourceDialog
        open={editModalOpen}
        handleClose={handleCloseEditDialog}
        handleDatasourcesChanged={refreshDatasources}
        datasourceId={editDatasourceId}
      />
      <DatasourceDialog
        open={newModalOpen}
        handleClose={handleCloseNewDatasource}
        handleDatasourcesChanged={refreshDatasources}
      />
      <DatasourceDeleteDialog
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        handleDatasourcesChanged={refreshDatasources}
        datasourceId={editDatasourceId}
      />
    </div>
  );
}
