import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { useApi } from "../API";
import SeverityIcon from "../components/SeverityIcon";

const TopAlertsTable = () => {
  const api = useApi();
  const [data, setData] = useState();

  useEffect(async () => {
    try {
      const result = await api.topHighAlerts();
      setData(result?.data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <Paper style={{ height: 500 }} variant="outlined">
      <TableContainer>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Alert</TableCell>
              <TableCell>Severity</TableCell>
              <TableCell>Assets</TableCell>
              <TableCell>High Impact</TableCell>
              <TableCell>with Confidential Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, idx) => (
              <TableRow
                key={idx}
                component="a"
                target="_blank"
                hover
                href={`/app/inventory/assets?alert_name=${row.alert_name}`}
              >
                <TableCell scope="row">{row.alert_name}</TableCell>
                <TableCell>
                  <SeverityIcon
                    score={parseFloat(row.vendor_score || 0).toFixed(1)}
                  />
                </TableCell>
                <TableCell>{row.total_assets}</TableCell>
                <TableCell>{row.total_high_impact}</TableCell>
                <TableCell>{row.total_confidential || "--"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TopAlertsTable;
