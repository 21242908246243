import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, TextField } from "@material-ui/core";
import { useApi } from "../API";
import { palette } from "../utils/theme";
import StyledModal from "../components/StyledModal";

const validateFields = (application) => {
  let valid = true;
  if (!application?.display_name?.trim()) {
    valid = false;
  }

  if (application?.annual_revenue) {
    if (Number.isNaN(parseFloat(application.annual_revenue))) {
      valid = false;
    }
  }

  return valid;
};

const useStyles = makeStyles(() => ({
  textField: {},
}));

const ApplicationNewModal = ({ open, onClose, toggleRefresh }) => {
  const api = useApi();
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [newApplication, setNewApplication] = useState({});

  const createApplication = async () => {
    setLoading(true);
    setError(false);
    try {
      await api.createApplication(newApplication);
      onClose();
      toggleRefresh();
    } catch (e) {
      setError(true);
      console.log(e);
    } finally {
      setNewApplication({});
      setLoading(false);
    }
  };

  return (
    <StyledModal
      open={open}
      title="Create New Application"
      onSubmit={createApplication}
      onClose={() => {
        setNewApplication({});
        setError(false);
        onClose();
      }}
      submitDisabled={!validateFields(newApplication)}
    >
      <Grid container spacing={2} direction="column">
        <Grid item>
          {error && (
            <Typography variant="subtitle1" style={{ color: palette.red }}>
              Error creating business process.
            </Typography>
          )}
          <TextField
            fullWidth
            className={classes.textField}
            label="Name"
            error={!newApplication?.display_name?.trim()}
            variant="outlined"
            helperText="Required"
            value={newApplication?.display_name}
            onChange={(e) =>
              setNewApplication({
                ...newApplication,
                display_name: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            className={classes.textField}
            label="Owner"
            variant="outlined"
            value={newApplication?.owner}
            onChange={(e) =>
              setNewApplication({ ...newApplication, owner: e.target.value })
            }
          />
        </Grid>
        <Grid item>
          <TextField
            className={classes.textField}
            label="Annual Revenue"
            variant="outlined"
            fullWidth
            error={
              newApplication?.annual_revenue &&
              Number.isNaN(parseFloat(newApplication?.annual_revenue))
            }
            helperText="Must be a valid number"
            value={newApplication?.annual_revenue}
            onChange={(e) =>
              setNewApplication({
                ...newApplication,
                annual_revenue: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item>
          <TextField
            className={classes.textField}
            label="Description"
            fullWidth
            variant="outlined"
            multiline
            value={newApplication?.description}
            onChange={(e) =>
              setNewApplication({
                ...newApplication,
                description: e.target.value,
              })
            }
          />
        </Grid>
      </Grid>
    </StyledModal>
  );
};

export default ApplicationNewModal;
