import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Chip,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import AssetIcon from "../AssetIcon";
import networkIcon from "../../assets/network.svg";
import { abbrCurrencyFormatter } from "../../utils";
import AssetHoverPopoverTitle from "./AssetHoverPopoverTitle";
import { AssetPropType } from "../../utils/propTypes";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "90%",
    height: "100%",
  },
  listValue: {
    fontSize: "120%",
    fontWeight: "bold",
  },
  nameHeader: {
    borderBottom: `1px solid${theme.palette.lightGray}`,
    padding: "0 10px 10px",
    position: "relative",
    margin: "0 -10px 0 -10px",
    display: "flex",
    justifyContent: "space-between",
  },
  listItem: {
    listStyle: "none",
    marginBottom: "10px",
  },
  internet: {
    textTransform: "capitalize",
  },
  badges: {
    display: "flex",
    margin: "20px 0 0 5px",
  },
  highRisk: {
    color: "white",
    backgroundColor: "red",
    borderColor: "red",
  },
  mediumRisk: {
    color: "white",
    backgroundColor: "orange",
    borderColor: "orange",
  },
  link: {
    color: theme.palette.darkBlue,
  },
  tagHeader: {
    fontSize: "120%",
  },
  tagBox: {
    margin: 0,
    padding: ".5rem",
    height: "300px",
    overflowX: "scroll",
    border: `1px solid ${theme.palette.lightGray}`,
  },
}));

const AssetHoverPopoverBody = ({ asset, module }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!module) {
    module = "inventory";
  }

  let riskClass;
  if (!!asset.cvss_score && asset.cvss_score > 7) {
    riskClass = classes.highRisk;
  } else if (!!asset.cvss_score && asset.cvss_score > 4) {
    riskClass = classes.mediumRisk;
  }

  const assetTable = [];

  if (typeof asset.critical_assets !== "undefined") {
    assetTable.push({
      label: `${t("criticalAssetLabelMany")}`,
      data: asset.critical_assets,
    });
  }

  if (typeof asset.size !== "undefined") {
    assetTable.push({
      label: `${t("totalAssets")}`,
      data: asset.size,
    });
  }

  if (asset.ipv4) {
    assetTable.push({
      label: "IP Address",
      data: asset.ipv4,
    });
  }

  if (asset.hostname) {
    assetTable.push({
      label: "Hostname",
      data: asset.hostname,
    });
  }

  if (asset.asset_type) {
    assetTable.push({ label: "Type", data: asset.asset_type });
  }

  if (asset.location) {
    assetTable.push({
      label: "Location",
      data: asset.location,
    });
  }

  if (asset.business_unit) {
    assetTable.push({
      label: "Business Unit",
      data: asset.business_unit,
    });
  }

  if (asset.environment) {
    assetTable.push({
      label: "Environment",
      data: asset.environment,
    });
  }

  if (asset.application) {
    assetTable.push({
      label: "Application",
      data: asset.application,
    });
  }

  if (asset.owner) {
    assetTable.push({
      label: "Owner",
      data: asset.owner,
    });
  }

  if (asset.mac_address) {
    assetTable.push({
      label: "MAC Address",
      data: asset.mac_address,
    });
  }

  if (asset.os) {
    assetTable.push({ label: "Operating System", data: asset.os });
  }

  if (asset.os_version) {
    assetTable.push({ label: "OS Version", data: asset.os_version });
  }

  if (asset.description) {
    assetTable.push({ label: "Description", data: asset.description });
  }

  const assetDetails = [];

  if (asset.controls) {
    assetTable.push({
      label: "Controls",
      data:
        asset.controls &&
        asset.controls.map((control, i) =>
          i + 1 < asset.controls.length
            ? `${control.display_name}, `
            : control.display_name
        ),
    });
  }

  if (asset.cve_count) {
    assetTable.push({
      label: "Vulnerability Count",
      data: asset.cves ? asset.cves.length : "0",
    });

    assetTable.push({
      label: "Vulnerabilities",
      data:
        asset.cves && asset.cves.length
          ? asset.cves.map((cve, i) =>
              i + 1 < asset.cves.length ? `${cve}, ` : cve
            )
          : null,
    });
  }

  return (
    <div className={classes.root}>
      {asset.summary && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingRight: ".5rem",
          }}
        >
          {!asset.ipv4 ||
            (asset.ipv4 && !asset.ipv4.includes("/") && (
              <AssetIcon
                svgWidth={22}
                criticalityPercentage={asset.criticality || 5}
                isCritical={asset.is_critical}
                riskPercentage={asset.risk_score || undefined}
              />
            ))}
          <Typography component="p" variant="h6" style={{ paddingLeft: "4px" }}>
            {asset.name || asset.ipv4}
          </Typography>
        </div>
      )}
      {(asset.ipv4 || asset.name) &&
        asset.type !== "internet" &&
        !asset.summary && (
          <div className={classes.nameHeader}>
            <div style={{ display: "flex" }}>
              {!asset.ipv4 ||
                (asset.ipv4 && !asset.ipv4.includes("/") && (
                  <div style={{ position: "relative", top: "3px" }}>
                    <AssetIcon
                      svgWidth={22}
                      criticalityPercentage={asset.criticality || 5}
                      isCritical={asset.is_critical}
                      riskPercentage={asset.risk_score || undefined}
                    />
                  </div>
                ))}
              {asset.name &&
                (asset.name.includes("/8") ||
                  asset.name.includes("/16") ||
                  asset.name.includes("/24")) && (
                  <img src={networkIcon} width="22px" alt="Subnet" />
                )}
              <AssetHoverPopoverTitle
                asset={asset}
                titleLink={`/app/${module}/details/${asset.id}`}
              />
            </div>
          </div>
        )}{" "}
      {!asset.summary && (
        <>
          <Box style={{ marginTop: ".5rem" }}>
            {!!asset.risk && (
              <>
                <Chip
                  style={{ margin: ".25rem" }}
                  variant="outlined"
                  size="small"
                  label={
                    <span>
                      {t("riskScoreLabel")}:{" "}
                      <b>{abbrCurrencyFormatter(asset.risk)}</b>
                    </span>
                  }
                />{" "}
                =
              </>
            )}
            {asset.lef ? (
              <>
                <Chip
                  style={{ margin: ".25rem" }}
                  variant="outlined"
                  size="small"
                  label={
                    <span>
                      Likelihood: <b>{asset.lef.toFixed(1)}%</b>
                    </span>
                  }
                />{" "}
                x
              </>
            ) : (
              ""
            )}
            {asset.loss_amount ? (
              <Chip
                style={{ margin: ".25rem" }}
                variant="outlined"
                size="small"
                label={
                  <span>
                    Impact: <b>{abbrCurrencyFormatter(asset.loss_amount)}</b>
                  </span>
                }
              />
            ) : (
              ""
            )}
            {!!asset.criticality && (
              <Chip
                style={{ margin: ".25rem" }}
                variant="outlined"
                size="small"
                label={
                  <span>
                    {t("critScoreLabel")}: <b>{asset.criticality.toFixed(0)}</b>
                  </span>
                }
              />
            )}
            {!!asset.cvss_score && (
              <Chip
                label={
                  <span>
                    CVSS Score: <b>{asset.cvss_score.toFixed(1)}</b>
                  </span>
                }
                className={riskClass}
                style={{ margin: ".25rem" }}
                size="small"
                variant="outlined"
              />
            )}
          </Box>
          {asset.type !== "internet" && (
            <div style={{ width: "450px" }}>
              <Table size="small" style={{ margin: "1rem 0" }}>
                <TableBody>
                  {assetTable.map((d, i) => {
                    if (d.data !== null) {
                      return (
                        <TableRow key={i}>
                          <TableCell>{d.label}</TableCell>
                          <TableCell>
                            <strong>
                              {d.label === "Application" &&
                              asset.application_id ? (
                                <Link
                                  className={classes.link}
                                  to={`/app/resilience/connections/application/${asset.application_id}`}
                                  target="blank"
                                >
                                  {d.data}
                                </Link>
                              ) : (
                                <div>{d.data}</div>
                              )}
                            </strong>
                          </TableCell>
                        </TableRow>
                      );
                    }
                    return false;
                  })}

                  {assetDetails.map((d) => (
                    <>
                      {d.data !== null && asset.hostname !== "internet" && (
                        <TableRow>
                          <TableCell>{d.label}</TableCell>
                          <TableCell>{d.data}</TableCell>
                        </TableRow>
                      )}
                    </>
                  ))}
                </TableBody>
              </Table>

              <div>
                {!!asset.tags && asset.tags.length > 0 && (
                  <div>
                    <div className={classes.tagHeader}>Tags</div>
                    <ul className={classes.tagBox}>
                      <li className={classes.listItem}>
                        <Typography variant="body">
                          {asset.tags.map((tag, index) => {
                            return (
                              <Chip
                                index={index}
                                label={`${tag.display_name} - ${tag.value}`}
                                style={{
                                  margin: ".25rem",
                                  maxWidth: "380px",
                                  overflow: "hidden",
                                }}
                                variant="outlined"
                                title={`${tag.display_name} - ${tag.value}`}
                              />
                            );
                          })}
                        </Typography>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

AssetHoverPopoverBody.propTypes = {
  asset: AssetPropType.isRequired,
};

export default AssetHoverPopoverBody;
