import React from "react";
import Grid from "@material-ui/core/Grid";
import CoverageOverTime from "../controls/CoverageOverTime";
import TrendsLineCharts from "./TrendsReportingCharts";

function CISOSection() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CoverageOverTime />
      </Grid>
      <TrendsLineCharts sectionName="executive" />
    </Grid>
  );
}

export default CISOSection;
