import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { abbreviateLargeValue } from "../utils";

const ProbabilityDistributionCurve = ({ data, colors }) => {
  const formatData = (data) => {
    const result = [];

    for (const d of data) {
      if (d) {
        const { x, y } = d;
        let formattedData = [];
        if (x && y) {
          formattedData = x.map((el, i) => {
            return {
              x: parseFloat(el).toFixed(1),
              y: y[i],
            };
          });
        }

        result.push({
          id: d.name,
          data: formattedData,
        });
      }
    }
    return result;
  };

  return (
    <div style={{ height: 300 }}>
      <ResponsiveLine
        isInteractive={false}
        lineWidth={3}
        margin={{ top: 20, right: 150, bottom: 75, left: 50 }}
        curve="basis"
        colors={colors}
        enablePoints={false}
        data={formatData(data)}
        xScale={{
          type: "log",
          base: 10,
          max: "auto",
        }}
        yScale={{
          type: "linear",
          min: 0,
          max: "auto",
        }}
        axisBottom={{
          legend: "Loss Amount",
          format: abbreviateLargeValue,
          legendOffset: 50,
          legendPosition: "middle",
        }}
        axisLeft={{
          legend: "Likelihood",
          tickSize: 0,
          format: () => "",
          legendOffset: -30,
          legendPosition: "middle",
        }}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default ProbabilityDistributionCurve;
