import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import {
  TableBody,
  Checkbox,
  Typography,
  Grid,
  Divider,
} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import EditIcon from "@material-ui/icons/Edit";
import { palette } from "../utils/theme";
import EnhancedTableToolbar from "./EnhancedTableToolBar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: 0,
    overflowX: "auto",
  },
  headerRow: {
    backgroundColor: "#fff",
  },
  loading: {
    opacity: 0.3,
    "&:hover": {
      backgroundColor: "white !important",
    },
  },
  tableRow: {
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#D9F1F4 !important",
    },
  },
  tableCell: {
    padding: "2px 4px",
  },
  tableHeaderCell: {
    padding: "6px 8px",
  },
  tableText: {
    whiteSpace: "nowrap",
    minWidth: "135px",
    maxWidth: "135px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // fontSize: "small",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    outline: "none!important",
    padding: theme.spacing(2, 4, 3),
  },
}));

const DEFAULT_ROWS_PER_PAGE = 50; // Default rows per page on the data table and in the api query need to be equivalent

const DataTable = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    order,
    orderBy,
    onRequestSort,
    dataIsPaginated,
    selectable,
    disableClientSorting,
    loading,
  } = props;

  const [rowsPerPage, setRowsPerPage] = React.useState(
    props.defaultRowsPerPage
  );
  const [rows, setRows] = React.useState(undefined);
  const [selected, setSelected] = React.useState([]);
  const [internalPage, setInternalPage] = useState(0);

  const page = props.page !== undefined ? props.page : internalPage;
  const setPage = props.setPage !== undefined ? props.setPage : setInternalPage;

  useEffect(() => {
    if (props.data) {
      setSelected([]);
    }
  }, [props.data]);

  const createSortHandler = (property) => () => {
    onRequestSort(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (Array.isArray(b[orderBy])) {
      if (b[orderBy][0] < a[orderBy][0]) {
        return -1;
      }
      if (b[orderBy][0] > a[orderBy][0]) {
        return 1;
      }
      return 0;
    }
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    if (disableClientSorting) {
      return array;
    }

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    if (props.setRowsPerPage) {
      // AssetTable needs to change the data fetch query based on how many we show per page
      props.setRowsPerPage(parseInt(event.target.value, 10));
    }

    setRowsPerPage(parseInt(event.target.value, 10));

    setPage(0);
  };

  function onClick(asset, mode) {
    if (typeof props.setSelected !== "undefined") {
      props.setSelected(asset, mode);
    }
  }

  useEffect(() => {
    setRows(props.data && props.data.rows);
  }, [props.data]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      if (selected.length > 0 && selected.length < rows.length) {
        setSelected([]);
        return;
      }
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  /**
   *
   * Right now the coverage tab and the inventory tab uses the
   * technology headers to indicate coverage of an asset
   * They both pass this data in different ways so this helps
   * determine if the asset has coverage or not
   *
   * This needs to be reworked but works for now.
   *
   * Coverage tab example:
   *  {
   *    id: 1,
   *    crowdstrike: true,
   *    veeam: true
   *  }
   *
   * Inventory tab example:
   *  {
   *    id: 1,
   *    solutions_ids: "3,2"
   *  }
   *
   */
  const getTechnologyValue = (value, row, column) => {
    if (value !== undefined) {
      return value;
    }

    const assetSolutions = row.solution_ids?.split(",") || [];
    const solutionId = column.solutionId?.toString() || "";
    return assetSolutions.includes(solutionId);
  };

  return (
    <Box ml={1} mr={1} className={classes.root}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={
          selected?.length
            ? { backgroundColor: "rgba(59, 115, 199, 0.08)" }
            : {}
        }
      >
        <Grid item style={{ paddingLeft: "1.5em" }}>
          <Grid container alignItems="center" spacing={2}>
            {props.tableActions}

            {selectable && (
              <Typography variant="body2">
                {selected?.length || 0} selected
              </Typography>
            )}

            {selectable && (
              <EnhancedTableToolbar
                applications={props.applications}
                selected={selected}
                reloadTable={() => {
                  setSelected([]);
                  props.reloadTable();
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid item>
          <TablePagination
            rowsPerPageOptions={[props.defaultRowsPerPage]}
            labelRowsPerPage="Rows"
            component="div"
            count={props.n_assets}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={props.tablePaginationActions}
          />
        </Grid>
      </Grid>

      <Divider />
      <TableContainer
        style={{
          // paddingBottom: "1rem",
          maxHeight: "calc(100vh - 14em)",
        }}
      >
        <Table stickyHeader size="small" aria-label="enhanced table">
          <TableHead style={{ overflow: "visible" }}>
            <TableRow
              className={classes.headerRow}
              style={{ overflow: "visible" }}
            >
              {selectable && (
                <TableCell
                  padding="checkbox"
                  style={{ backgroundColor: "white" }}
                >
                  {rows && (
                    <Checkbox
                      color="primary"
                      indeterminate={
                        selected.length > 0 && selected.length < rows.length
                      }
                      checked={
                        rows.length > 0 && selected.length === rows.length
                      }
                      onChange={handleSelectAllClick}
                    />
                  )}
                </TableCell>
              )}

              {/* <TableCell
                onClick={
                  props.sortable ? createSortHandler("criticality") : () => { }
                }
                className={classes.tableHeaderCell}
                key="icon-header"
                style={{
                  width: "215px",
                  textAlign: "center",
                  backgroundColor: "white",
                  // position: "relative",
                  ...(props.sortable ? { cursor: "pointer" } : {}),
                  ...(props.dense ? { padding: "0px" } : {}),
                }}
              >
                Impact Score
              </TableCell> */}
              {props.data &&
                props.data.headers &&
                props.data.headers.map((col, index) => {
                  const sortable = props.sortable && col.type !== "technology";

                  return (
                    <TableCell
                      onClick={sortable ? createSortHandler(col.id) : () => {}}
                      className={classes.tableHeaderCell}
                      data-test={`${col.type}-${col.id}`}
                      key={`${col.id}-${index}`}
                      align={col.align}
                      scope={col.scope}
                      style={{
                        backgroundColor: "white",
                        // textAlign: col.align || "center",
                        overflow: "visible",
                        ...(sortable ? { cursor: "pointer" } : {}),
                        ...(col.width ? { width: col.width } : {}),
                      }}
                    >
                      {col.icon_src && (
                        <img src={col.icon_src} width="100px" alt={col.label} />
                      )}
                      {sortable ? (
                        <TableSortLabel
                          active={orderBy === col.id}
                          direction={order}
                          onClick={createSortHandler(col.id)}
                        >
                          {!col.icon_src && <div>{col.label}</div>}
                        </TableSortLabel>
                      ) : (
                        !col.icon_src && <div>{col.label}</div>
                      )}
                    </TableCell>
                  );
                })}
              {!props.hideEditRow && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              stableSort(rows, getComparator(order, orderBy))
                .slice(
                  dataIsPaginated ? 0 : page * rowsPerPage,
                  dataIsPaginated
                    ? rows.length
                    : page * rowsPerPage + rowsPerPage
                )
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const selectableProps = selectable
                    ? {
                        onClick: (event) => handleClick(event, row.id),
                        "aria-checked": isItemSelected,
                        selected: isItemSelected,
                      }
                    : {};
                  return (
                    <TableRow
                      {...selectableProps}
                      hover
                      role="checkbox"
                      className={!loading ? classes.tableRow : classes.loading}
                      tabIndex={-1}
                      key={`row-${row.id}`}
                    >
                      {selectable && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                      )}
                      {/* <TableCell
                        className={classes.tableCell}
                        style={{
                          // overflow: "visible",
                          // position: "relative",
                          width: "215px",
                          textAlign: row.align === "right" ? "right" : "left",
                          ...(props.dense ? { padding: "0px" } : {}),
                        }}
                      >
                        <div
                          style={{
                            paddingTop: "2px",
                            paddingLeft: "25%",
                            width: "100px",
                          }}
                          onClick={() => {
                            onClick(row.id, "view");
                          }}
                        >
                          <AssetIcon
                            criticalityPercentage={row.criticality}
                            isCritical={row.is_critical}
                            riskPercentage={
                              row.risk_score ? row.risk_score : undefined
                            }
                          />
                        </div>
                      </TableCell> */}

                      {props.data &&
                        props.data.headers &&
                        props.data.headers.map((column) => {
                          const value = row[column.id];
                          const { align } = column;
                          return (
                            <TableCell
                              data-test={`data-table-cell-${row?.hostname}-${column.id}`}
                              onClick={() => {
                                onClick(row.id, "view");
                              }}
                              key={`col-${column.id}`}
                              align={column.align}
                              className={classes.tableCell}
                              width={column.width ? column.width : "auto"}
                              style={{
                                cursor: "pointer",
                                textAlign: column.align,
                              }}
                            >
                              {column.id !== "icon" &&
                                column.id !== "technology" &&
                                column.type !== "technology" &&
                                column.type !== "heatmap" && (
                                  <div
                                    className={classes.tableText}
                                    style={{
                                      ...(column.width
                                        ? {
                                            width: column.width,
                                            maxWidth: column.width,
                                          }
                                        : {}),
                                    }}
                                  >
                                    {column.format
                                      ? column.format(value, row)
                                      : value}
                                  </div>
                                )}
                              {column.id === "icon" && (
                                <div
                                  style={{ paddingTop: "2px" }}
                                  onClick={() => {
                                    onClick(row.id, "view");
                                  }}
                                >
                                  Icon
                                </div>
                              )}
                              {column.type === "technology" && (
                                <div
                                  style={{
                                    // display: "flex",
                                    // flexWrap: "wrap",
                                    // width: column.width ? column.width : "auto",
                                    textAlign: column.align,
                                  }}
                                  data-test={`${row.id}-${column.id}`}
                                >
                                  {!getTechnologyValue(value, row, column) && (
                                    <ClearIcon
                                      style={{
                                        fontSize: "25px",
                                        color: palette.red,
                                        float: align,
                                      }}
                                      data-test={`${row.id}-${column.id}-${value}`}
                                    />
                                  )}
                                  {getTechnologyValue(value, row, column) && (
                                    <CheckCircleIcon
                                      style={{
                                        color: palette.green,
                                        fontSize: "25px",
                                      }}
                                      data-test={`${row.id}-${column.id}-${value}`}
                                    />
                                  )}
                                </div>
                              )}
                              {column.type === "heatmap" && (
                                <div
                                  style={{
                                    height: "4rem",
                                    position: "relative",
                                    lineHeight: "4rem",
                                    display: "flex",
                                    justifyContent: column.align,
                                    alignItems: "center",
                                    width: "100%",
                                    minWidth: "1rem",
                                  }}
                                >
                                  {column.format(value) === "high" && (
                                    <div
                                      style={{
                                        textAlign: "center",
                                        width: "2.25rem",
                                        height: "2.25rem",
                                        lineHeight: "2.75rem",
                                        borderRadius: "2.25rem",
                                        marginRight: ".125rem",
                                        position: "absolute",
                                        background: "white",
                                        border: `2px solid ${theme.palette.red}`,
                                      }}
                                    >
                                      <ClearIcon
                                        style={{
                                          color: theme.palette.red,
                                          fontSize: "1.25rem",
                                        }}
                                      />
                                    </div>
                                  )}
                                  {column.format(value) === "med" && (
                                    <div
                                      style={{
                                        textAlign: "center",
                                        width: "2.25rem",
                                        height: "2.25rem",
                                        lineHeight: "2.75rem",
                                        borderRadius: "2.25rem",
                                        marginRight: ".125rem",
                                        position: "absolute",
                                        background: "white",
                                        border: `2px solid ${theme.palette.yellow}`,
                                      }}
                                    >
                                      <WarningIcon
                                        style={{
                                          color: theme.palette.yellow,
                                          fontSize: "1.25rem",
                                        }}
                                      />
                                    </div>
                                  )}
                                  {column.format(value) === "low" && (
                                    <div
                                      style={{
                                        textAlign: "center",
                                        width: "2.25rem",
                                        height: "2.25rem",
                                        lineHeight: "2.75rem",
                                        borderRadius: "2.25rem",
                                        marginRight: ".125rem",
                                        position: "absolute",
                                        background: "white",
                                        border: `2px solid ${theme.palette.green}`,
                                      }}
                                    >
                                      <CheckIcon
                                        style={{
                                          color: theme.palette.green,
                                          fontSize: "1.25rem",
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                            </TableCell>
                          );
                        })}
                      {!props.hideEditRow && (
                        <TableCell align="right">
                          <IconButton
                            aria-label="edit"
                            onClick={() => onClick(row.id, "edit")}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DataTable;

DataTable.propTypes = {
  data: PropTypes.shape({
    headers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        align: PropTypes.oneOf([
          "left",
          "center",
          "right",
          "justify",
          "inherit",
        ]),
        width: PropTypes.number,
        format: PropTypes.func,
      }).isRequired
    ),
    rows: PropTypes.arrayOf(PropTypes.object.isRequired),
  }),
  setSelected: PropTypes.func,
  hideEditRow: PropTypes.bool,
  n_assets: PropTypes.number,
  defaultRowsPerPage: PropTypes.number,
  sortable: PropTypes.bool,
};

DataTable.defaultProps = {
  defaultRowsPerPage: DEFAULT_ROWS_PER_PAGE,
  hideEditRow: true,
  sortable: false,
};
