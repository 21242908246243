import React from "react";
import { makeStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(() => ({
  root: {
    margin: "5px 0",
    width: "300px",
  },
}));

export default function UseCases({
  onChangeUseCase,
  useCases,
  currentUseCase,
  disabled,
}) {
  const classes = useStyles();

  const handleChange = (event) => {
    onChangeUseCase(useCases[event.target.value]);
  };

  return (
    <>
      <TextField
        value={currentUseCase}
        disabled={disabled}
        onChange={handleChange}
        size="small"
        label="Use Case"
        variant="outlined"
        className={classes.root}
        data-test="resilience-filter-usecases"
        select
      >
        {useCases.map((option, index) => (
          <MenuItem key={index} value={index}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
}
