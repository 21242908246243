import React, { useState } from "react";
import { TextField, MenuItem } from "@material-ui/core";

const MOCK_OUT_DEMO = window._env_ && window._env_.MOCK_OUT_DEMO === "true";

const TimePeriodDropDown = ({ defaultPeriod, handlePeriodChange }) => {
  const [selectedPeriod, setSelectedPeriod] = useState(
    defaultPeriod || MOCK_OUT_DEMO ? "year" : "biweekly"
  );
  return (
    <TextField
      id="standard-select-value"
      select
      size="small"
      label="Time Period"
      style={{ width: 200 }}
      value={selectedPeriod}
      variant="outlined"
      onChange={({ target: { value } }) => {
        handlePeriodChange(value);
        setSelectedPeriod(value);
      }}
    >
      <MenuItem value="biweekly">Past 2 Weeks</MenuItem>
      <MenuItem value="month">Past Month</MenuItem>
      <MenuItem value="quarter">Past Quarter</MenuItem>
      <MenuItem value="year">Past Year</MenuItem>
    </TextField>
  );
};

export default TimePeriodDropDown;
