import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, IconButton, Tooltip } from "@material-ui/core";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SettingsIcon from "@material-ui/icons/Settings";
import TabPanel from "../components/TabPanel";
import { useApi } from "../API";
import AverageRiskTable from "./AverageRiskTable";
import ProbabilityDistributionCurve from "./ProbabilityDistributionCurve";
import useSettings from "../hooks/useSettings";
import ChartWrapper from "../components/ChartWrapper";
import ChartWrapperHeader from "../components/ChartWrapperHeader";
import { palette } from "../utils/theme";
import { RoundedTab, RoundedTabs } from "../components/RoundedTab";
import AlertsDashboard from "../reporting/AlertsDashboard";
import KnownExploitedTable from "../reporting/charts/KnownExploitedVulnsTable";
import AssetsByCVEsTable from "../reporting/charts/AssetsByCVEsTable";
import RiskiestAssets from "./RiskiestAssetsTable";
import HighImpactCheckBox from "../components/HighImpactSwitch";

function Risk() {
  const baseRoute = "/app/risk";
  const dataRef = useRef();
  const location = useLocation();

  const api = useApi();
  const [docTitle, setDocTitle] = useState("KeyCaliber - Risk");
  const [scrollTop, setScrollTop] = useState(0);
  const tabletlg = useMediaQuery("(min-width:1600px)");
  const tablet = useMediaQuery("(min-width:960px)");
  const [settings] = useSettings("financial_risk_enabled");
  const [riskData, setRiskData] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [criticalOnly, setCriticalOnly] = useState(true);

  const useStyles = makeStyles((theme) => ({
    root: theme.layout.root,
    content: theme.layout.content,
    contentWrapper: theme.layout.contentWrapper,
    contentGrid: theme.layout.contentGrid,
    secondaryNav: theme.layout.secondaryNav,
    spWrapper: {
      marginBottom: "1rem",
      minHeight: "500px",
      width: "100%",
    },
    spFixed: {
      position: "fixed",
      zIndex: "1000",
      minHeight: "400px",
      width: tabletlg ? "55%" : tablet ? "48%" : "65%",
      top: "2rem",
    },
  }));

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const classes = useStyles();

  const tabs = [];

  tabs.push(
    { label: "Overview", path: `${baseRoute}`, name: "overview" },
    {
      label: "Asset Risk",
      path: `${baseRoute}/assets`,
      name: "inventory",
    }
  );

  tabs.push({
    label: "Details",
    path: `${baseRoute}/details/`,
    name: "details",
  });

  useEffect(async () => {
    if (settings.data === true) {
      const industry = await api.riskV2Industry();
      const company = await api.riskV2Company();
      const asset = await api.riskV2AssetParams();

      setRiskData({
        industry,
        company,
        asset,
      });
    }
  }, [settings.loading]);

  useEffect(() => {
    api.riskV2HighImpactAssets().then((res) => {
      dataRef.current = res;
    });
  }, []);

  useEffect(() => {
    if (window.location.pathname === baseRoute) {
      setTabValue(0);
    } else if (window.location.pathname.indexOf(`${baseRoute}/assets`) !== -1) {
      setTabValue(1);
      setDocTitle("KeyCaliber - Risk");
    }
    // else if (
    //   window.location.pathname.indexOf(`${baseRoute}/applications`) !== -1
    // ) {
    //   setTabValue(2);
    //   setDocTitle("Risk - Applications");
    // }
    else if (window.location.pathname.indexOf(`${baseRoute}/details`) !== -1) {
      setTabValue(2);
      setDocTitle("KeyCaliber - Risk");
    }
  }, [location]);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  document.title = docTitle;

  return (
    <div className={classes.root} data-test="risk-page">
      <div className={`${classes.contentWrapper}`}>
        <Box className={classes.content}>
          <Switch>
            <Route exact path="/app/risk">
              <RoundedTabs
                value={tabValue}
                onChange={handleChange}
                aria-label="primary tabs example"
              >
                <RoundedTab
                  label="Risk Scores"
                  id="primary-tab-0"
                  aria-controls="primary-tabpanel-0"
                />
                <RoundedTab
                  label="Vulnerabilities"
                  id="primary-tab-1"
                  aria-controls="primary-tabpanel-1"
                  data-test="settings-tab-connectors"
                />
                <RoundedTab
                  label="Alerts"
                  id="primary-tab-2"
                  aria-controls="primary-tabpanel-2"
                />
              </RoundedTabs>
              <TabPanel value={tabValue} index={0}>
                <Grid container spacing={4}>
                  {settings.data === true && (
                    <Grid item lg={12}>
                      <ChartWrapper excludeHeader>
                        <ChartWrapperHeader chartName="Company vs. Industry Risk">
                          <Tooltip
                            placement="left"
                            title="Configure Industry Risk Factors"
                          >
                            <IconButton href="/app/settings">
                              <SettingsIcon />
                            </IconButton>
                          </Tooltip>
                        </ChartWrapperHeader>
                        <ProbabilityDistributionCurve
                          colors={[palette.purple1, "#CBCBCB"]}
                          data={[
                            {
                              name: "Company Risk",
                              x: riskData?.company?.pdf?.x,
                              y: riskData?.company?.pdf?.y,
                            },
                            {
                              name: "Industry Average",
                              x: riskData?.industry?.pdf?.x,
                              y: riskData?.industry?.pdf?.y,
                            },
                          ]}
                        />
                      </ChartWrapper>
                    </Grid>
                  )}

                  <Grid item xs={5}>
                    <AverageRiskTable />
                  </Grid>
                  <Grid item xs={7}>
                    <ChartWrapper
                      chartName="Top 10 Riskiest Assets"
                      chart={<RiskiestAssets />}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <KnownExploitedTable internetFacing />
                  </Grid>
                  <Grid item xs={12}>
                    <KnownExploitedTable />
                  </Grid>
                  <Grid item xs={12}>
                    <ChartWrapper excludeHeader>
                      <ChartWrapperHeader chartName="Top Assets by Vulnerabilities">
                        <HighImpactCheckBox
                          checked={criticalOnly}
                          handleSwitchChange={() =>
                            setCriticalOnly(!criticalOnly)
                          }
                        />
                      </ChartWrapperHeader>
                      <AssetsByCVEsTable criticalOnly={criticalOnly} />
                    </ChartWrapper>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <AlertsDashboard />
              </TabPanel>
            </Route>
            <Redirect
              from="/app/risk/details/:assetId"
              to="/app/asset/:assetId"
            />
          </Switch>
          {/* </Paper> */}
        </Box>
      </div>
    </div>
  );
}

export default Risk;
