import React, { useEffect, useState } from "react";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import { useApi } from "../API";
import SeverityIcon from "../components/SeverityIcon";

export default function AssetAlertTable(props) {
  const [data, setData] = useState([]);
  const api = useApi();
  const { assetId } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    api
      .assetAlerts(assetId)
      .then((res) => {
        if (res.data.length > 0) {
          return setData(res.data);
        }
        return setData([]);
      })
      .catch((e) => console.log(e));
  }, [props.assetId]);

  if (!data.length) {
    return <div>No recent alerts found</div>;
  }

  return (
    <div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Risk</TableCell>
              <TableCell>Alert Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Classification</TableCell>
              <TableCell>Investigation Status</TableCell>
              <TableCell>Detected</TableCell>
              <TableCell>Resolved</TableCell>
            </TableRow>
          </TableHead>
          {data
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((alert) => (
              <TableRow key={alert.id}>
                <TableCell>
                  <SeverityIcon score={alert.vendor_score} />
                </TableCell>
                <TableCell>{alert.alert_name}</TableCell>
                <TableCell>{alert.status || "--"}</TableCell>
                <TableCell>{alert.classification || "--"}</TableCell>
                <TableCell>{alert.investigation_status || "--"}</TableCell>
                <TableCell>{alert.date_detected || "--"}</TableCell>
                <TableCell>{alert.date_fixed || "--"}</TableCell>
              </TableRow>
            ))}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
