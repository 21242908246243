import { palette } from "../utils/theme";

const MOCK_OUT_DEMO =
  window._env_.MOCK_OUT_DEMO && window._env_.MOCK_OUT_DEMO === "true";

const CJ_NAME = "High Impact Assets";

export const cjAcceptableRiskTimeline = MOCK_OUT_DEMO && [
  { x: "2022-07-01", y: 52 },
  { x: "2022-07-15", y: 72 },
  { x: "2022-08-01", y: 44 },
  { x: "2022-08-15", y: 42 },
  { x: "2022-09-01", y: 48 },
  { x: "2022-09-15", y: 87 },
  { x: "2022-10-01", y: 82 },
  { x: "2022-10-15", y: 72 },
  { x: "2022-11-01", y: 54 },
  { x: "2022-11-15", y: 43 },
  { x: "2022-12-01", y: 51 },
  { x: "2022-12-15", y: 32 },
  { x: "2023-01-01", y: 12 },
];

export const cjCoveredOverTime = MOCK_OUT_DEMO && [
  {
    id: "CMDB",
    data: [
      { x: "2021-03-01", y: 42 },
      { x: "2021-03-15", y: 49 },
      { x: "2021-04-01", y: 55 },
      { x: "2021-04-15", y: 47 },
      { x: "2021-05-01", y: 49 },
      { x: "2021-05-15", y: 88 },
      { x: "2021-06-01", y: 82 },
      { x: "2021-06-15", y: 93 },
      { x: "2021-07-01", y: 97 },
      { x: "2021-07-15", y: 95 },
      { x: "2021-08-01", y: 92 },
      { x: "2021-08-15", y: 79 },
      { x: "2021-09-01", y: 85 },
    ],
  },
  {
    id: "Vuln Scan",
    data: [
      { x: "2021-03-01", y: 12 },
      { x: "2021-03-15", y: 18 },
      { x: "2021-04-01", y: 15 },
      { x: "2021-04-15", y: 12 },
      { x: "2021-05-01", y: 10 },
      { x: "2021-05-15", y: 67 },
      { x: "2021-06-01", y: 65 },
      { x: "2021-06-15", y: 69 },
      { x: "2021-07-01", y: 65 },
      { x: "2021-07-15", y: 61 },
      { x: "2021-08-01", y: 75 },
      { x: "2021-08-15", y: 78 },
      { x: "2021-09-01", y: 69 },
    ],
  },
  {
    id: "Other",
    data: [
      { x: "2021-03-01", y: 8 },
      { x: "2021-03-15", y: 2 },
      { x: "2021-04-01", y: 4 },
      { x: "2021-04-15", y: 5 },
      { x: "2021-05-01", y: 10 },
      { x: "2021-05-15", y: 59 },
      { x: "2021-06-01", y: 55 },
      { x: "2021-06-15", y: 65 },
      { x: "2021-07-01", y: 51 },
      { x: "2021-07-15", y: 52 },
      { x: "2021-08-01", y: 57 },
      { x: "2021-08-15", y: 54 },
      { x: "2021-09-01", y: 56 },
    ],
  },
];

export const allCoverageOverTime = MOCK_OUT_DEMO && [
  {
    id: CJ_NAME,
    color: palette.purple1,
    data: [
      { x: "2021-07-01", y: 42 },
      { x: "2021-07-15", y: 49 },
      { x: "2021-08-01", y: 55 },
      { x: "2021-08-15", y: 47 },
      { x: "2021-09-01", y: 49 },
      { x: "2021-09-15", y: 88 },
      { x: "2021-10-01", y: 82 },
      { x: "2021-10-15", y: 93 },
      { x: "2021-11-01", y: 97 },
      { x: "2021-11-15", y: 95 },
      { x: "2021-12-01", y: 92 },
      { x: "2021-12-15", y: 79 },
      { x: "2022-01-01", y: 85 },
    ],
  },
  {
    id: `Non-${CJ_NAME}`,
    color: palette.keyBlue,
    data: [
      { x: "2021-07-01", y: 12 },
      { x: "2021-07-15", y: 18 },
      { x: "2021-08-01", y: 15 },
      { x: "2021-08-15", y: 12 },
      { x: "2021-09-01", y: 10 },
      { x: "2021-09-15", y: 67 },
      { x: "2021-10-01", y: 65 },
      { x: "2021-10-15", y: 69 },
      { x: "2021-11-01", y: 65 },
      { x: "2021-11-15", y: 61 },
      { x: "2021-12-01", y: 75 },
      { x: "2021-12-15", y: 78 },
      { x: "2022-01-01", y: 69 },
    ],
  },
];

export const cjOverTime = MOCK_OUT_DEMO && [
  { x: "2021-07-01", y: "97" },
  { x: "2021-07-15", y: "105" },
  { x: "2021-08-01", y: "105" },
  { x: "2021-08-15", y: "121" },
  { x: "2021-09-01", y: "145" },
  { x: "2021-09-15", y: "160" },
  { x: "2021-10-01", y: "180" },
  { x: "2021-10-15", y: "181" },
  { x: "2021-11-01", y: "184" },
  { x: "2021-11-15", y: "190" },
  { x: "2021-12-01", y: "193" },
  { x: "2021-12-15", y: "201" },
  { x: "2022-01-01", y: "211" },
];

export const cjPatchedMultiBar = MOCK_OUT_DEMO && [
  {
    month: "Jul",
    [CJ_NAME]: 23,
    "Adjacent Assets": 33,
  },
  {
    month: "Aug",
    [CJ_NAME]: 71,
    "Adjacent Assets": 100,
  },
  {
    month: "Sep",
    [CJ_NAME]: 18,
    "Adjacent Assets": 12,
  },
  {
    month: "Oct",
    [CJ_NAME]: 71,
    "Adjacent Assets": 45,
  },
  {
    month: "Nov",
    [CJ_NAME]: 27,
    "Adjacent Assets": 13,
  },
  {
    month: "Dec",
    [CJ_NAME]: 54,
    "Adjacent Assets": 18,
  },
  {
    month: "Jan",
    [CJ_NAME]: 34,
    "Adjacent Assets": 11,
  },
];

export const cjRiskOverTime = MOCK_OUT_DEMO && [
  {
    id: "MCA006",
    data: [
      { x: "2021-07-01", y: 49 },
      { x: "2021-07-15", y: 52 },
      { x: "2021-08-01", y: 54 },
      { x: "2021-08-15", y: 49 },
      { x: "2021-09-01", y: 57 },
      { x: "2021-09-15", y: 45 },
      { x: "2021-10-01", y: 47 },
      { x: "2021-10-15", y: 43 },
      { x: "2021-11-01", y: 46 },
      { x: "2021-11-15", y: 44 },
      { x: "2021-12-01", y: 49 },
      { x: "2021-12-15", y: 42 },
      { x: "2022-01-01", y: 44 },
    ],
  },
  {
    id: "MCA002",
    data: [
      { x: "2021-07-01", y: 41 },
      { x: "2021-07-15", y: 42 },
      { x: "2021-08-01", y: 45 },
      { x: "2021-08-15", y: 43 },
      { x: "2021-09-01", y: 46 },
      { x: "2021-09-15", y: 21 },
      { x: "2021-10-01", y: 24 },
      { x: "2021-10-15", y: 23 },
      { x: "2021-11-01", y: 26 },
      { x: "2021-11-15", y: 21 },
      { x: "2021-12-01", y: 22 },
      { x: "2021-12-15", y: 27 },
      { x: "2022-01-01", y: 22 },
    ],
  },
  {
    id: "MCA003",
    data: [
      { x: "2021-07-01", y: 76 },
      { x: "2021-07-15", y: 75 },
      { x: "2021-08-01", y: 77 },
      { x: "2021-08-15", y: 76 },
      { x: "2021-09-01", y: 74 },
      { x: "2021-09-15", y: 53 },
      { x: "2021-10-01", y: 54 },
      { x: "2021-10-15", y: 55 },
      { x: "2021-11-01", y: 53 },
      { x: "2021-11-15", y: 56 },
      { x: "2021-12-01", y: 52 },
      { x: "2021-12-15", y: 53 },
      { x: "2022-01-01", y: 54 },
    ],
  },
  {
    id: "MCA044",
    data: [
      { x: "2021-07-01", y: 98 },
      { x: "2021-07-15", y: 99 },
      { x: "2021-08-01", y: 95 },
      { x: "2021-08-15", y: 97 },
      { x: "2021-09-01", y: 98 },
      { x: "2021-09-15", y: 4 },
      { x: "2021-10-01", y: 6 },
      { x: "2021-10-15", y: 5 },
      { x: "2021-11-01", y: 3 },
      { x: "2021-11-15", y: 5 },
      { x: "2021-12-01", y: 4 },
      { x: "2021-12-15", y: 7 },
      { x: "2022-01-01", y: 3 },
    ],
  },
  {
    id: "MCA059",
    data: [
      { x: "2021-07-01", y: 74 },
      { x: "2021-07-15", y: 72 },
      { x: "2021-08-01", y: 73 },
      { x: "2021-08-15", y: 71 },
      { x: "2021-09-01", y: 74 },
      { x: "2021-09-15", y: 51 },
      { x: "2021-10-01", y: 54 },
      { x: "2021-10-15", y: 52 },
      { x: "2021-11-01", y: 53 },
      { x: "2021-11-15", y: 51 },
      { x: "2021-12-01", y: 54 },
      { x: "2021-12-15", y: 56 },
      { x: "2022-01-01", y: 53 },
    ],
  },
  {
    id: "MCA009",
    data: [
      { x: "2021-07-01", y: 55 },
      { x: "2021-07-15", y: 54 },
      { x: "2021-08-01", y: 53 },
      { x: "2021-08-15", y: 54 },
      { x: "2021-09-01", y: 56 },
      { x: "2021-09-15", y: 13 },
      { x: "2021-10-01", y: 12 },
      { x: "2021-10-15", y: 11 },
      { x: "2021-11-01", y: 15 },
      { x: "2021-11-15", y: 12 },
      { x: "2021-12-01", y: 13 },
      { x: "2021-12-15", y: 12 },
      { x: "2022-01-01", y: 13 },
    ],
  },
  {
    id: "MCA013",
    data: [
      { x: "2021-07-01", y: 87 },
      { x: "2021-07-15", y: 85 },
      { x: "2021-08-01", y: 83 },
      { x: "2021-08-15", y: 82 },
      { x: "2021-09-01", y: 88 },
      { x: "2021-09-15", y: 14 },
      { x: "2021-10-01", y: 15 },
      { x: "2021-10-15", y: 15 },
      { x: "2021-11-01", y: 16 },
      { x: "2021-11-15", y: 12 },
      { x: "2021-12-01", y: 15 },
      { x: "2021-12-15", y: 14 },
      { x: "2022-01-01", y: 15 },
    ],
  },
  {
    id: "MCA143",
    data: [
      { x: "2021-07-01", y: 72 },
      { x: "2021-07-15", y: 73 },
      { x: "2021-08-01", y: 72 },
      { x: "2021-08-15", y: 71 },
      { x: "2021-09-01", y: 72 },
      { x: "2021-09-15", y: 41 },
      { x: "2021-10-01", y: 40 },
      { x: "2021-10-15", y: 44 },
      { x: "2021-11-01", y: 36 },
      { x: "2021-11-15", y: 40 },
      { x: "2021-12-01", y: 40 },
      { x: "2021-12-15", y: 42 },
      { x: "2022-01-01", y: 40 },
    ],
  },
  {
    id: "MCA098",
    data: [
      { x: "2021-07-01", y: 91 },
      { x: "2021-07-15", y: 90 },
      { x: "2021-08-01", y: 94 },
      { x: "2021-08-15", y: 91 },
      { x: "2021-09-01", y: 90 },
      { x: "2021-09-15", y: 33 },
      { x: "2021-10-01", y: 36 },
      { x: "2021-10-15", y: 30 },
      { x: "2021-11-01", y: 34 },
      { x: "2021-11-15", y: 32 },
      { x: "2021-12-01", y: 35 },
      { x: "2021-12-15", y: 33 },
      { x: "2022-01-01", y: 32 },
    ],
  },
  {
    id: "MCA053",
    data: [
      { x: "2021-07-01", y: 77 },
      { x: "2021-07-15", y: 78 },
      { x: "2021-08-01", y: 79 },
      { x: "2021-08-15", y: 75 },
      { x: "2021-09-01", y: 77 },
      { x: "2021-09-15", y: 34 },
      { x: "2021-10-01", y: 37 },
      { x: "2021-10-15", y: 36 },
      { x: "2021-11-01", y: 38 },
      { x: "2021-11-15", y: 37 },
      { x: "2021-12-01", y: 37 },
      { x: "2021-12-15", y: 35 },
      { x: "2022-01-01", y: 37 },
    ],
  },
];

export const criticalityFunnel = [
  {
    label: `Everything Else`,
    value: 5000,
    backgroundColor: palette.keyBlue,
  },
  {
    label: `Impact Score 76 - 85`,
    value: 500,
    backgroundColor: palette.purple2,
  },
  {
    label: `Impact Score 86 - 95`,
    value: 300,
    backgroundColor: palette.purple1,
  },
  {
    label: `Impact Score 95`,
    value: 200,
    backgroundColor: palette.purple3,
  },
];

export const hopsPie = MOCK_OUT_DEMO && [
  {
    id: "Directly Connected",
    label: "direct",
    value: 10,
    color: "hsl(10, 70%, 50%)",
  },
  {
    id: "One Hop",
    label: "one-hop",
    value: 40,
    color: "hsl(347, 70%, 50%)",
  },
  {
    id: "Two Hops",
    label: "two-hops",
    value: 24,
    color: "hsl(3, 70%, 50%)",
  },
  {
    id: "Three Hops",
    label: "three-hops",
    value: 13,
    color: "hsl(85, 70%, 50%)",
  },
  {
    id: "Other",
    label: "other",
    value: 3,
    color: "hsl(316, 70%, 50%)",
  },
];

export const riskAssetsMultiBar = MOCK_OUT_DEMO && [
  {
    month: "2021-11-22",
    [CJ_NAME]: 98,
    "Adjacent Assets": 120,
  },
  {
    month: "2021-11-29",
    [CJ_NAME]: 45,
    "Adjacent Assets": 74,
  },
  {
    month: "2021-12-06",
    [CJ_NAME]: 112,
    "Adjacent Assets": 234,
  },
  {
    month: "2021-12-13",
    [CJ_NAME]: 71,
    "Adjacent Assets": 114,
  },
  {
    month: "2021-12-20",
    [CJ_NAME]: 49,
    "Adjacent Assets": 78,
  },
  {
    month: "2021-12-27",
    [CJ_NAME]: 23,
    "Adjacent Assets": 66,
  },
  {
    month: "2022-01-03",
    [CJ_NAME]: 13,
    "Adjacent Assets": 35,
  },
];

export const riskConnectionsMultiBar = MOCK_OUT_DEMO && [
  {
    month: "Jul",
    [`High Risk Connections`]: 3,
  },
  {
    month: "Aug",
    [`High Risk Connections`]: 32,
  },
  {
    month: "Sep",
    [`High Risk Connections`]: 114,
  },
  {
    month: "Oct",
    [`High Risk Connections`]: 14,
  },
  {
    month: "Nov",
    [`High Risk Connections`]: 14,
  },
  {
    month: "Dec",
    [`High Risk Connections`]: 14,
  },
  {
    month: "Jan",
    [`High Risk Connections`]: 3,
  },
];

export const vulnMgmtIntegrations = MOCK_OUT_DEMO && [
  {
    id: "Rapid7",
    data: [
      { x: "2021-07-01", y: 7000 },
      { x: "2021-07-15", y: 4900 },
      { x: "2021-08-01", y: 5500 },
      { x: "2021-08-15", y: 6200 },
      { x: "2021-09-01", y: 4900 },
      { x: "2021-09-15", y: 9800 },
      { x: "2021-10-01", y: 8200 },
      { x: "2021-10-15", y: 1299 },
      { x: "2021-11-01", y: 2429 },
      { x: "2021-11-15", y: 4533 },
      { x: "2021-12-01", y: 7990 },
      { x: "2021-12-15", y: 4643 },
      { x: "2022-01-01", y: 8500 },
    ],
  },
];
export const alertMgmtIntegrations = MOCK_OUT_DEMO && [
  {
    id: "SIEM",
    data: [
      { x: "2021-07-01", y: 9000 },
      { x: "2021-07-15", y: 5900 },
      { x: "2021-08-01", y: 8500 },
      { x: "2021-08-15", y: 6200 },
      { x: "2021-09-01", y: 2900 },
      { x: "2021-09-15", y: 1800 },
      { x: "2021-10-01", y: 1200 },
      { x: "2021-10-15", y: 1299 },
      { x: "2021-11-01", y: 3429 },
      { x: "2021-11-15", y: 5533 },
      { x: "2021-12-01", y: 6990 },
      { x: "2021-12-15", y: 3643 },
      { x: "2022-01-01", y: 8500 },
    ],
  },
];

export const newCVEs = MOCK_OUT_DEMO && [
  {
    cve: "CVE-2021-39302",
    severity: "Critical",
    num_crit_assets: 43,
    num_one_hop: 75,
  },
  {
    cve: "CVE-2021-39111",
    severity: "Critical",
    num_crit_assets: 32,
    num_one_hop: 70,
  },
  {
    cve: "CVE-2021-39002",
    severity: "Critical",
    num_crit_assets: 40,
    num_one_hop: 85,
  },
  {
    cve: "CVE-2020-39211",
    severity: "Critical",
    num_crit_assets: 25,
    num_one_hop: 52,
  },
  {
    cve: "CVE-2020-31234",
    severity: "High",
    num_crit_assets: 37,
    num_one_hop: 37,
  },
  {
    cve: "CVE-2021-123",
    severity: "High",
    num_crit_assets: 32,
    num_one_hop: 50,
  },
  {
    cve: "CVE-2021-302",
    severity: "High",
    num_crit_assets: 10,
    num_one_hop: 5,
  },
  {
    cve: "CVE-2021-102",
    severity: "High",
    num_crit_assets: 5,
    num_one_hop: 10,
  },
];

export const riskyAssetsTable = MOCK_OUT_DEMO && [
  { name: "MCA002", risk: 99.98, criticality: 79 },
  { name: "MCA011", risk: 99.56, criticality: 95 },
  { name: "MCA008", risk: 99.2, criticality: 99 },
  { name: "MCA011", risk: 98.55, criticality: 45 },
  { name: "MCA034", risk: 98.47, criticality: 87 },
  { name: "MCA056", risk: 98.23, criticality: 34 },
  { name: "MCA175", risk: 97.82, criticality: 77 },
  { name: "MCA143", risk: 97.62, criticality: 83 },
];

export const avgRiskByApp = MOCK_OUT_DEMO && [
  {
    id: "E-Commerce",
    data: [
      {
        x: "january",
        y: 1.56,
      },
      {
        x: "february",
        y: 1.01,
      },
      {
        x: "march",
        y: 2.03,
      },
      {
        x: "april",
        y: 5.4,
      },
      {
        x: "may",
        y: 6.4,
      },
      {
        x: "june",
        y: 5.3,
      },
      {
        x: "july",
        y: 5.2,
      },
      {
        x: "august",
        y: 2.9,
      },
      {
        x: "september",
        y: 6.7,
      },
      {
        x: "october",
        y: 8.1,
      },
      {
        x: "november",
        y: 2.31,
      },
      {
        x: "december",
        y: 1.72,
      },
    ],
  },
  {
    id: "Ad Generation",
    data: [
      {
        x: "january",
        y: 3.5,
      },
      {
        x: "february",
        y: 2.4,
      },
      {
        x: "march",
        y: 7.2,
      },
      {
        x: "april",
        y: 6.2,
      },
      {
        x: "may",
        y: 5.7,
      },
      {
        x: "june",
        y: 5.9,
      },
      {
        x: "july",
        y: 5.0,
      },
      {
        x: "august",
        y: 3.9,
      },
      {
        x: "september",
        y: 6.9,
      },
      {
        x: "october",
        y: 7.6,
      },
      {
        x: "november",
        y: 2.1,
      },
      {
        x: "december",
        y: 6.1,
      },
    ],
  },
  {
    id: "Analytics",
    data: [
      {
        x: "january",
        y: 3.6,
      },
      {
        x: "february",
        y: 2.52,
      },
      {
        x: "march",
        y: 9.4,
      },
      {
        x: "april",
        y: 2.1,
      },
      {
        x: "may",
        y: 8.7,
      },
      {
        x: "june",
        y: 9.4,
      },
      {
        x: "july",
        y: 2.5,
      },
      {
        x: "august",
        y: 1.16,
      },
      {
        x: "september",
        y: 4.5,
      },
      {
        x: "october",
        y: 6.6,
      },
      {
        x: "november",
        y: 5.3,
      },
      {
        x: "december",
        y: 1.0,
      },
    ],
  },
  {
    id: "Customer Loyalty",
    data: [
      {
        x: "january",
        y: 9.3,
      },
      {
        x: "february",
        y: 2.15,
      },
      {
        x: "march",
        y: 2.92,
      },
      {
        x: "april",
        y: 1.4,
      },
      {
        x: "may",
        y: 2.19,
      },
      {
        x: "june",
        y: 4.7,
      },
      {
        x: "july",
        y: 2.25,
      },
      {
        x: "august",
        y: 1.4,
      },
      {
        x: "september",
        y: 1.42,
      },
      {
        x: "october",
        y: 8.3,
      },
      {
        x: "november",
        y: 7.3,
      },
      {
        x: "december",
        y: 5.4,
      },
    ],
  },
];

export const avgRiskByBizFunction = MOCK_OUT_DEMO && [
  {
    id: "Engineering",
    data: [
      {
        x: "january",
        y: 1.56,
      },
      {
        x: "february",
        y: 1.01,
      },
      {
        x: "march",
        y: 2.03,
      },
      {
        x: "april",
        y: 5.4,
      },
      {
        x: "may",
        y: 6.4,
      },
      {
        x: "june",
        y: 5.3,
      },
      {
        x: "july",
        y: 5.2,
      },
      {
        x: "august",
        y: 2.9,
      },
      {
        x: "september",
        y: 6.7,
      },
      {
        x: "october",
        y: 8.1,
      },
      {
        x: "november",
        y: 2.31,
      },
      {
        x: "december",
        y: 1.72,
      },
    ],
  },
  {
    id: "Sales",
    data: [
      {
        x: "january",
        y: 3.5,
      },
      {
        x: "february",
        y: 2.4,
      },
      {
        x: "march",
        y: 7.2,
      },
      {
        x: "april",
        y: 6.2,
      },
      {
        x: "may",
        y: 5.7,
      },
      {
        x: "june",
        y: 5.9,
      },
      {
        x: "july",
        y: 5.0,
      },
      {
        x: "august",
        y: 3.9,
      },
      {
        x: "september",
        y: 6.9,
      },
      {
        x: "october",
        y: 7.6,
      },
      {
        x: "november",
        y: 2.1,
      },
      {
        x: "december",
        y: 6.1,
      },
    ],
  },
  {
    id: "Marketing",
    data: [
      {
        x: "january",
        y: 3.6,
      },
      {
        x: "february",
        y: 2.52,
      },
      {
        x: "march",
        y: 9.4,
      },
      {
        x: "april",
        y: 2.1,
      },
      {
        x: "may",
        y: 8.7,
      },
      {
        x: "june",
        y: 9.4,
      },
      {
        x: "july",
        y: 2.5,
      },
      {
        x: "august",
        y: 1.16,
      },
      {
        x: "september",
        y: 4.5,
      },
      {
        x: "october",
        y: 6.6,
      },
      {
        x: "november",
        y: 5.3,
      },
      {
        x: "december",
        y: 1.0,
      },
    ],
  },
  {
    id: "HR",
    data: [
      {
        x: "january",
        y: 9.3,
      },
      {
        x: "february",
        y: 2.15,
      },
      {
        x: "march",
        y: 2.92,
      },
      {
        x: "april",
        y: 1.4,
      },
      {
        x: "may",
        y: 2.19,
      },
      {
        x: "june",
        y: 4.7,
      },
      {
        x: "july",
        y: 2.25,
      },
      {
        x: "august",
        y: 1.4,
      },
      {
        x: "september",
        y: 1.42,
      },
      {
        x: "october",
        y: 8.3,
      },
      {
        x: "november",
        y: 7.3,
      },
      {
        x: "december",
        y: 5.4,
      },
    ],
  },
  {
    id: "Finance",
    data: [
      {
        x: "january",
        y: 9.3,
      },
      {
        x: "february",
        y: 2.15,
      },
      {
        x: "march",
        y: 2.92,
      },
      {
        x: "april",
        y: 1.4,
      },
      {
        x: "may",
        y: 2.19,
      },
      {
        x: "june",
        y: 4.7,
      },
      {
        x: "july",
        y: 2.25,
      },
      {
        x: "august",
        y: 1.4,
      },
      {
        x: "september",
        y: 1.42,
      },
      {
        x: "october",
        y: 8.3,
      },
      {
        x: "november",
        y: 7.3,
      },
      {
        x: "december",
        y: 5.4,
      },
    ],
  },
];

export const timeToPatch = MOCK_OUT_DEMO && [
  {
    id: "<7 Days",
    data: [
      {
        x: "january",
        y: 22,
      },
      {
        x: "february",
        y: 26,
      },
      {
        x: "march",
        y: 33,
      },
      {
        x: "april",
        y: 25,
      },
      {
        x: "may",
        y: 60,
      },
      {
        x: "june",
        y: 69,
      },
      {
        x: "july",
        y: 73,
      },
      {
        x: "august",
        y: 78,
      },
      {
        x: "september",
        y: 73,
      },
      {
        x: "october",
        y: 85,
      },
      {
        x: "november",
        y: 89,
      },
      {
        x: "december",
        y: 96,
      },
    ],
  },
  {
    id: "7-14 Days",
    data: [
      {
        x: "january",
        y: 40,
      },
      {
        x: "february",
        y: 40,
      },
      {
        x: "march",
        y: 36,
      },
      {
        x: "april",
        y: 35,
      },
      {
        x: "may",
        y: 38,
      },
      {
        x: "june",
        y: 20,
      },
      {
        x: "july",
        y: 20,
      },
      {
        x: "august",
        y: 16,
      },
      {
        x: "september",
        y: 23,
      },
      {
        x: "october",
        y: 10,
      },
      {
        x: "november",
        y: 9,
      },
      {
        x: "december",
        y: 3,
      },
    ],
  },
  {
    id: "14+ Days",
    data: [
      {
        x: "january",
        y: 38,
      },
      {
        x: "february",
        y: 34,
      },
      {
        x: "march",
        y: 31,
      },
      {
        x: "april",
        y: 40,
      },
      {
        x: "may",
        y: 2,
      },
      {
        x: "june",
        y: 11,
      },
      {
        x: "july",
        y: 7,
      },
      {
        x: "august",
        y: 6,
      },
      {
        x: "september",
        y: 4,
      },
      {
        x: "october",
        y: 5,
      },
      {
        x: "november",
        y: 2,
      },
      {
        x: "december",
        y: 1,
      },
    ],
  },
];

// Metric names that correspond to each section.
// In the future we could manage these categories and store them in the DB schema
// but for now this is easiest when we want to make a change
export const metricSectionsMapOld = {
  // A Lot of metrics in Top Metrics because usually we dont have data for all of them.
  // Ideally we have 4 or 8 here
  cio: [],
  ciso: [
    {
      display_name: "Coverage Gaps",
      metrics: [
        "percent_edr_coverage",
        "percent_high_impact_edr_coverage",
        "percent_no_vis",
        "percent_high_impact_no_vis",
      ],
    },
  ],
  vulnMgmt: [
    // "percent_high_impact_patched_within_pla",
    {
      display_name: "Unpatched Critical Vulnerabilities and Adjacent Assets",
      metrics: [
        "high_impact_unpatched_critical",
        "adjacent_high_impact_unpatched_critical",
      ],
    },
    {
      display_name: "Time to Patch Critical Vulnerabilities",
      metrics: [
        "patch_time_critical_vulns",
        "patch_time_critical_high_impact",
        "patch_time_critical_vuln_confidential",
      ],
    },
  ],
  secops: [
    {
      display_name: "Total Alerts Last Week",
      metrics: [
        "num_assets_with_alert_last_week",
        "num_high_impact_with_alert_last_week",
        "num_confidential_with_alert_last_week",
      ],
    },
    {
      display_name: "Total Risky Alerts Last Week",
      metrics: [
        "num_risky_last_week",
        "num_high_impact_risky_last_week",
        "num_confidential_risky_last_week",
      ],
    },
    {
      display_name: "Total Alerts Older than 6 Months",
      metrics: [
        "num_old_alerts",
        "num_high_impact_old_alerts",
        "num_confidential_old_alerts",
      ],
    },
  ],
  dataGov: [
    {
      display_name: "Database Coverage Breakdown",
      metrics: [
        "percent_dbs_in_nopsec",
        "percent_dbs_confidential_in_cmdb",
        "percent_dbs_with_vuln_scan",
        "percent_dbs_scanned",
        "percent_critical_dbs_scanned",
      ],
    },
    {
      display_name: "Assets with Confidential Data Coverage",
      metrics: [
        "percent_assets_confidential_in_nopsec",
        "percent_assets_confidential_vuln_scanned",
        "percent_confidential_in_cmdb",
      ],
    },
    {
      display_name: "Total Assets with Sensitive Data",
      metrics: [
        "num_confidential_assets",
        "num_ssn_assets",
        "num_bank_acct_assets",
        "num_cc_assets",
      ],
    },
  ],
  topMetrics: [],
  vulnMgmtSection: [],
  lineCharts: [],
};

export const metricSectionsMap = {
  vulnMgmt: [
    {
      display_name: "Total Assets with Vulnerabilities Over Time",
      metrics: ["total_with_vulns_high_impact", "total_with_vulns"],
    },
  ],
  secops: [
    {
      display_name: "Total Assets with Alerts Over Time",
      metrics: ["total_with_alerts_high_impact", "total_with_alerts"],
    },
  ],
  seceng: [],
  dataGov: [
    {
      display_name: "Total Assets with Sensitive Data",
      metrics: [
        "num_confidential_assets",
        "num_ssn_assets",
        "num_bank_acct_assets",
        "num_cc_assets",
      ],
    },
  ],
  executive: [
    {
      display_name: "Assets with EDR Coverage",
      metrics: [
        "total_with_kc_type_endpoint",
        "total_with_kc_type_endpoint_high_impact",
      ],
    },
    {
      display_name: "Assets with Vulnerability Scanner Coverage",
      metrics: [
        "total_with_kc_type_vuln",
        "total_with_kc_type_vuln_high_impact",
      ],
    },
    {
      display_name: "Assets in CMDB",
      metrics: [
        "total_with_kc_type_cmdb",
        "total_with_kc_type_cmdb_high_impact",
      ],
    },
  ],
};
