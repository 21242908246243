import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { palette } from "./utils/theme";
import error from "./assets/error.svg";

function PageNotFound() {
  const tablet = useMediaQuery("(max-width:1200px)");

  const useStyles = makeStyles(() => ({
    outerWrapper: {
      transform: "translate(0, 130px)",
      marginBottom: "180px",
    },
    wrapper: {
      background: palette.darkGray,
      height: "calc(100vh - 180px)",
      overflowY: "hidden",
    },
    innerWrapper: {
      height: "100%",
      position: "relative",
      zIndex: 0,
      background: palette.background,
    },
    paper: {
      position: "absolute",
      padding: "0 4rem",
      background: "white",
      width: "calc(100vw - 4rem)",
      left: "2rem",
      top: "200px",
      height: "calc(100% - 200px)",
      zIndex: 1,
    },
    heading: {
      textAlign: "center",
      fontWeight: 100,
      textTransform: "uppercase",
      position: "relative",
      top: tablet ? "-186px" : "-226px",
      marginBottom: tablet ? "-180px" : "-340px",
      fontSize: "120px",
      color: "white",
      lineHeight: tablet ? "120px" : "200px",
    },
    subheading: {
      position: "relative",
      top: tablet ? "0" : "-78px",
      color: "#D1D9DB",
    },
    paragraph: {
      textAlign: "center",
      marginBottom: "5rem",
    },
    image: {
      width: "60%",
      margin: "0 auto",
      display: "block",
    },
  }));

  const classes = useStyles();

  return (
    <>
      <div className={classes.outerWrapper}>
        <div className={classes.wrapper}>
          <div className={classes.innerWrapper} />
          <div className={classes.paper}>
            <h1 className={classes.heading}>
              404
              <div className={classes.subheading}>Page Not Found</div>
            </h1>
            <p className={classes.paragraph}>
              The URL you are trying to reach is unavailable. Please try again,
              or click one of the tabs above.
            </p>
            <img src={error} className={classes.image} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default PageNotFound;
