import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import { Button, Typography, AppBar } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import logo from "../assets/logo.svg";
import { palette } from "../utils/theme";
import PageNotFound from "../404";
import useQuery from "../hooks/useQuery";

const ForgotPasswordForm = () => {
  const params = useQuery();
  const [newPassword, setNewPassword] = useState("");
  const [confirmedNewPassword, setConfirmedNewPassword] = useState("");
  const [token, setToken] = useState();
  const [newPasswordError, setNewPasswordError] = useState();
  const [confirmedError, setConfirmedError] = useState();
  const [error, setError] = useState(false);

  const isValid = (string) => {
    return string.length >= 8 && string.length <= 128;
  };

  useEffect(() => {
    setToken(params.get("token"));
  }, []);

  const handleChangePassword = async () => {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ token, new_password: newPassword }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      await fetch(`/api/user/set-password`, requestOptions);
      window.location.href = "/login";
    } catch {
      setError(true);
    } finally {
      setConfirmedNewPassword("");
      setNewPassword("");
    }
  };

  if (!token) {
    return <PageNotFound />;
  }

  return (
    <div>
      <AppBar>
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" edge="start" />
          <img height="23px" src={logo} alt="Logo" />
        </Toolbar>
      </AppBar>
      <Grid
        style={{ marginTop: "8.5em" }}
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Typography>Please set a new password.</Typography>
        <Typography>You will be redirected to the login</Typography>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          spacing={2}
          style={{ marginTop: "1em" }}
        >
          <Grid item xs={12}>
            <TextField
              value={newPassword}
              onInput={(e) => {
                setNewPassword(e.target.value);
                setNewPasswordError(!isValid(e.target.value));
              }}
              style={{ width: "300px" }}
              error={newPasswordError}
              variant="outlined"
              type="password"
              label="New Password"
              helperText="Must be at least 8 characters long"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={confirmedNewPassword}
              onInput={(e) => {
                setConfirmedNewPassword(e.target.value);
                setConfirmedError(e.target.value !== newPassword);
              }}
              style={{ width: "300px" }}
              error={confirmedError}
              variant="outlined"
              type="password"
              label="Confirm New Password"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={handleChangePassword}
              variant="contained"
              color="secondary"
              disabled={confirmedError || newPasswordError}
            >
              Reset Password
            </Button>
            {error && (
              <Typography style={{ color: palette.red }}>
                There was an error.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ForgotPasswordForm;
