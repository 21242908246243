export function titleCase(string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
export function numberWithCommas(x) {
  if (Number.isNaN(parseFloat(x))) {
    return x;
  }
  try {
    const intlFormatter = new Intl.NumberFormat("en-US");
    return intlFormatter.format(x);
  } catch {
    return x;
  }
}

export function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    maxHeight: "95vh",
    transform: `translate(-${top}%, -${left}%)`,
    overflowY: "scroll",
    overflowX: "hidden",
  };
}

export const abbreviateLargeValue = (value, disableCurrency) => {
  const prefix = !disableCurrency ? "$" : "";

  if (value < 1_000) {
    return `${prefix}${value}`;
  }

  if (value < 1_000_000) {
    return `${prefix}${parseFloat((value / 1_000).toFixed(2))}K`;
  }

  if (value < 1_000_000_000) {
    return `${prefix}${parseFloat((value / 1_000_000).toFixed(2))}M`;
  }

  if (value < 1_000_000_000_000) {
    return `${prefix}${parseFloat((value / 1_000_000_000).toFixed(2))}B`;
  }

  if (value >= 1_000_000_000_000) {
    return `${prefix}${parseFloat((value / 1_000_000_000_000).toFixed(2))}T`;
  }

  return `${prefix}${value}`;
};

export function emailIsValid(email) {
  if (!email || email.length > 320 || email.length < 5) {
    return false;
  }
  // eslint-disable-next-line no-useless-escape
  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const matches = email.match(regex);
  if (!matches) {
    return false;
  }
  return true;
}

export function basicCurrencyFormatter(n) {
  if (!n) return n;

  const intlFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return intlFormatter.format(n);
}

export const abbrCurrencyFormatter = (x, dollars = true) => {
  if (!x) return x;

  let n = x;

  if (Number.isNaN(parseInt(n))) {
    n = parseInt(n.replace(/[^0-9.-]+/g, ""));
  }

  n = parseInt(n);

  if (Math.abs(n) > 999 && Math.abs(n) < 999999) {
    return (
      dollars &&
      `$${new Intl.NumberFormat().format(
        Math.sign(n) * (Math.abs(n) / 1000).toFixed(1)
      )}K`
    );
  }
  if (Math.abs(n) > 999999 && Math.abs(n) <= 999999999) {
    return (
      dollars &&
      `$${new Intl.NumberFormat().format(
        Math.sign(n) * (Math.abs(n) / 1000000).toFixed(1)
      )}M`
    );
  }
  if (Math.abs(n) > 999999999 && Math.abs(n) <= 999999999999) {
    return (
      dollars &&
      `$${new Intl.NumberFormat().format(
        Math.sign(n) * (Math.abs(n) / 1000000000).toFixed(1)
      )}B`
    );
  }
  if (Math.abs(n) > 999999999999) {
    return (
      dollars &&
      `$${new Intl.NumberFormat().format(
        Math.sign(n) * (Math.abs(n) / 1000000000000).toFixed(1)
      )}T`
    );
  }
  return (
    dollars && `$${new Intl.NumberFormat().format(Math.sign(n) * Math.abs(n))}`
  );
};

export const percentFormatter = (n, min, max) =>
  new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  }).format(n);

export const capitalize = (word) =>
  word[0].toUpperCase() + word.substring(1).toLowerCase();

/**
 *
 * Shorten metric names for MetricsCard component
 */
export const abbreviateMetricName = (name) => {
  return (
    name
      // .replaceAll("without", "w/o")
      // .replaceAll("Vulnerabilities", "Vulns")
      // .replaceAll("Total", "#")
      .replaceAll("Percent of", "%")
      // .replaceAll("with ", "w/ ")
      .replaceAll("and", "&")
      .replaceAll("More than One", ">1")
  );
};

/**
 *
 * Determine whether an outcome metric is trending in
 * a desired or undesired way
 */
export const isPrefferedTrend = (metric) => {
  const increasing =
    metric.change !== "no_change" && metric.change
      ? parseFloat(metric.change.replace("%", "")) > 0
      : null;
  // const no_trend =
  //     metric.prefer_increase === null ||
  //     parseFloat(metric.change.replace("%", "")) === 0;

  const goodTrend =
    (increasing && metric.prefer_increase) ||
    (increasing === false && metric.prefer_increase === false);
  const badTrend =
    (increasing && metric.prefer_increase === false) ||
    (increasing === false && metric.prefer_increase);

  return goodTrend ? true : badTrend ? false : null;
};
