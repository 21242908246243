import React, { useState } from "react";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableBody,
} from "@material-ui/core";

export default function UserLogons(props) {
  const { data } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (!data.length) {
    return <div>No user logons found</div>;
  }

  return (
    <div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Domain</TableCell>
              <TableCell>Account Name</TableCell>
              <TableCell>Is Domain Admin</TableCell>
              <TableCell>First Seen</TableCell>
              <TableCell>Last Seen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((logon) => (
                <TableRow key={logon.id}>
                  <TableCell>{logon.account_domain || "--"}</TableCell>
                  <TableCell>{logon.account_name || "--"}</TableCell>
                  <TableCell>
                    {logon.is_domain_admin ? "True" : "False"}
                  </TableCell>
                  <TableCell>{logon.first_seen || "--"}</TableCell>
                  <TableCell>{logon.last_seen || "--"}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
