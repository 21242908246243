import React from "react";
import { withOktaAuth } from "@okta/okta-react";

import OktaAuth from "./Okta";
import JWTAuth from "./JWT";

const Auth = (props) => {
  if (props.authType === "okta") {
    return <OktaAuth />;
  }
  return <JWTAuth />;
};

export default withOktaAuth(Auth);
