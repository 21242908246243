import React, { useContext, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Popover } from "@material-ui/core";

const useStyles = makeStyles(({ spacing }) => ({
  // Hovering over the popover itself *shouldn't* trigger mouse events, because
  // this element ends up covering the entire screen. The contents just cover
  // the visible area of the popover though, so we do want that to trigger
  // mouse events
  popover: {
    pointerEvents: "none",
    display: "table!important",
  },
  popoverContent: {
    display: "table!important",
    pointerEvents: "auto",
    padding: spacing(1),
  },
}));

/**
 * This context provides both data and callbacks to the popover itself and
 * any other consumer that wants to trigger the popover or use its associated
 * data (e.g. its contents). This context isn't exposed itself to prevent
 * bootleg providers, you have to use the prescribed provider then the hook
 * to get a consumer.
 */
const HoverPopoverContext = React.createContext();

/** A provider for a single hover popover. Any child of this component will have
 * the ability to show/hide a hover popover, and access the popover's contents.
 * Any descendant element of this provider can trigger a popover, and the
 * context can also be used of children of the popover to render contents.
 */
export const HoverPopoverProvider = ({ children }) => {
  // This one needs a ref so that we can properly check against the value in
  // hidePopover when determining if the element trigger the hide was the one
  // to show the popover in the first place. This is important to eliminate
  // bugginess in nested trigger elements (e.g. on the bubble chart). Doesn't
  // work as a state value because not all consumers properly reload the
  // closures generated below as necessary.
  const anchorElRef = useRef(null);

  // When we close, we don't want to actually wipe out the anchor/data
  // fields, just mark it as closed. This will allow the popover to
  // perform its pretty close animation without any flickering. Once it's
  // closed, the anchor/data fields will sit unused until the next one is opened.
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);

  /**
   * Show a popover for a particular target.
   *
   * @param {Element} trigger The element that the user interacted with to
   *  trigger this "show" action
   * @param {any} data Data associated with the trigger that the popover can
   *  use to render contents
   */
  const showPopover = ({ trigger, data }) => {
    anchorElRef.current = trigger;
    setData(data);
    setOpen(true);
  };

  /**
   * Hide the current popover. This action will be delayed by a small amount,
   * so that if we re-trigger the popover immediately after, there's no
   * flickering. This gives the user time to move their mouse from the trigger
   * element to the popover itself without it disappearing, so they can interact
   * with the contents of the popover.
   *
   * @param {Element} anchorEl The element that the user interacted with to
   *  trigger this "hide" action. If this element isn't the one that triggered
   *  the popover originally, this action will do nothing
   */
  const hidePopover = ({ trigger }) => {
    // Do we already have a "hide" action pending?
    // The element that triggered this hide action - is that the element that
    // the tooltip is actually attached to? If not, we'll ignore the request
    const triggerIsAnchor = anchorElRef.current === trigger;

    if (triggerIsAnchor) {
      setOpen(false);
    }
  };

  // From time to time we will need to force the popover to close programmatically.
  // For example, in resilience, the popover is opening up in the top left corner
  // sometimes when the user shift+clicks on a node.
  const forceHidePopover = () => {
    setOpen(false);
  };

  return (
    <HoverPopoverContext.Provider
      value={{
        open,
        anchorEl: anchorElRef.current,
        data,
        showPopover,
        hidePopover,
        forceHidePopover,
      }}
    >
      {children}
    </HoverPopoverContext.Provider>
  );
};

/**
 * A hook for consuming data and callbacks related to a hover popover. This
 * should be used by any child of the HoverPopoverProvider that needs access
 * to data/callbacks.
 */
export const useHoverPopover = () => {
  const context = useContext(HoverPopoverContext);

  if (!context) {
    // Friendly little error message when context isn't present
    // eslint-disable-next-line no-console
    console.error(
      "No hover tip context available.",
      `Make sure \`${HoverPopover.displayName}\` is wrapped by a \`${HoverPopoverProvider.displayName}\`.`
    );
  }

  return context;
};

/**
 * A popover (aka tooltip) that appears when the user hovers over some target.
 * This has additional logic that persists the popover when the user hovers
 * over the popover itself, which allows for interactive elements in the
 * popover. To use this component, you need to wrap it and the trigger element
 * in a HoverPopoverProvider. The trigger can then use `useHoverTooltip` to get
 * access to the show/hide functions. The contents of the popover can also grab
 * the `data` field from the context to get arbitrary data provided by the
 * trigger, e.g. which asset is hovered.
 *
 * Note: we use the term "popover" rather than "tooltip" to stay consistent with
 * MUI's naming, so stick to that where you can.
 */
const HoverPopover = ({ children }) => {
  const classes = useStyles();
  // const theme = useTheme();
  const { open, anchorEl, showPopover, hidePopover, data } = useHoverPopover();
  return (
    <Popover
      className={classes.popover}
      classes={{
        paper: classes.popoverContent,
      }}
      open={open}
      anchorEl={anchorEl}
      // Make sure we don't cover the trigger element
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      disableScrollLock
      disableRestoreFocus
      PaperProps={{
        // Persist the popover when the mouse is on its contents
        // We re-pass the same anchor/data here because we want to persist
        // whatever's already showing
        onMouseOver: () => {
          return showPopover({ trigger: anchorEl, data });
        },
        onMouseLeave: () => {
          return hidePopover({ trigger: anchorEl });
        },
      }}
      transitionDuration={0}
    >
      {children}
    </Popover>
  );
};

export default HoverPopover;
