import React from "react";
import { Grid } from "@material-ui/core";
import ChartWrapper from "../components/ChartWrapper";
import CoverageMatrix from "./CoverageMatrix";
import CoverageHorizontal from "./CoverageHorizontal";
import TelemetryHealth from "./TelemetryHealth";

function OverviewTab() {
  return (
    <Grid container spacing={2} justifyContent="space-between">
      <Grid item xs={12}>
        <CoverageMatrix />
      </Grid>
      <Grid item xs={6}>
        <CoverageHorizontal />
      </Grid>
      <Grid item xs={6}>
        <ChartWrapper
          chartName="Connector Health"
          chart={<TelemetryHealth />}
        />
      </Grid>
    </Grid>
  );
}

export default OverviewTab;
