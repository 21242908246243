import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { InputLabel, FormControl, Select, MenuItem } from "@material-ui/core";
import { ResponsiveBar } from "@nivo/bar";
import { useApi } from "../API";
import { palette } from "../utils/theme";
import ChartWrapperHeader from "../components/ChartWrapperHeader";
import ChartWrapper from "../components/ChartWrapper";
import ActiveAssetsCheckbox from "../components/ActiveAssetsSwitch";

const names = {
  business_unit: "Business Unit",
  owner: "Owner",
  location: "Location",
  asset_type: "Asset Type",
  application_id: "Business Process",
};

const CoverageOverview = () => {
  const api = useApi();
  const [data, setData] = useState();
  const [selected, setSelected] = useState();
  const [solutions, setSolutions] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [applications, setApplications] = useState([]);
  const [dateFilter, setDateFilter] = useState(true);
  // const [riskyData, setRiskyData] = useState();
  const [groupBy, setGroupBy] = useState("business_unit");
  // const [groupByFilter, setGroupByFilter] = useState();
  const renderTooltip = (data) => {
    const displayName = applications?.find(
      // eslint-disable-next-line eqeqeq
      (el) => el.id == data.data[groupBy]
    )?.display_name;
    return (
      <div
        style={{
          backgroundColor: "white",
          color: "inherit",
          fontSize: "inherit",
          borderRadius: "2px",
          boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
          padding: "5px 9px",
        }}
      >
        <strong>{displayName || data.data[groupBy]}</strong>:{" "}
        <strong>{Math.floor(data.data.percent_covered)}%</strong> {selected}{" "}
        coverage
      </div>
    );
  };

  useEffect(async () => {
    try {
      setLoading(true);

      let defaultSolution = selected;
      if (!solutions) {
        const response = await api.getActiveSolutions();
        setSolutions(response);
        defaultSolution = response[0].name;
        setSelected(defaultSolution);
      }

      const result = await api.cioOverview(
        defaultSolution,
        groupBy,
        dateFilter
      );
      setData(result.sort((a, b) => (a[groupBy] > b[groupBy] ? -1 : 1)));
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [selected, groupBy, dateFilter]);

  useEffect(() => {
    api.getApplications().then((res) => {
      setApplications(res);
    });
  }, []);

  if (error) {
    return <div>There was an error.</div>;
  }

  if (!solutions || !selected || (!solutions && loading)) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ height: 400 }}>
      <ChartWrapper excludeHeader>
        <ChartWrapperHeader
          chartName={`Solutions Coverage by ${names[groupBy]}`}
        >
          <Grid container spacing={2}>
            <Grid item>
              <FormControl
                variant="outlined"
                size="small"
                style={{ backgroundColor: "white", width: "150px" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Group By
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={groupBy}
                  onChange={(e) => {
                    e.preventDefault();
                    setLoading(true);
                    setError(false);
                    setGroupBy(e.target.value);
                    setData(null);
                  }}
                  label="Group By"
                >
                  <MenuItem value="business_unit">Business Unit</MenuItem>
                  <MenuItem value="owner">Owner</MenuItem>
                  <MenuItem value="location">Location</MenuItem>
                  <MenuItem value="asset_type">Asset Type</MenuItem>
                  <MenuItem value="application_id">Business Process</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl
                size="small"
                variant="outlined"
                style={{ width: 200, backgroundColor: "white" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Solution
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={selected}
                  defaultValue={selected}
                  onChange={(e) => setSelected(e.target.value)}
                  label="Solution"
                >
                  {solutions.map((el, idx) => {
                    return (
                      <MenuItem key={idx} value={el.name}>
                        {el.display_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <ActiveAssetsCheckbox
                checked={dateFilter}
                handleSwitchChange={(event) => {
                  event.preventDefault();
                  setDateFilter(event.target.checked);
                  setLoading(true);
                  setError(false);
                  setData(null);
                }}
              />
            </Grid>
          </Grid>
        </ChartWrapperHeader>
        {!loading && (
          <ResponsiveBar
            tooltip={renderTooltip}
            data={data}
            keys={["percent_covered"]}
            colors={[palette.keyBlue]}
            indexBy={groupBy}
            maxValue={100}
            groupMode="grouped"
            margin={{
              top: 10,
              right: 50,
              bottom: 130,
              left: 40,
            }}
            axisLeft={{
              tickSize: 0,
              tickValues: 8,
              tickPadding: 10,
              format: (d) => `${d}%`,
            }}
            innerPadding={3}
            gridYValues={10}
            axisBottom={{
              tickSize: 5,
              tickValues: 8,
              tickRotation: 20,
              format: (d) => {
                const displayName = applications?.find(
                  // eslint-disable-next-line eqeqeq
                  (el) => el.id == d
                )?.display_name;
                return displayName || d;
              },
            }}
            padding={0.4}
            layout="vertical"
            borderColor="inherit:darker(1.6)"
            enableLabel={false}
            enableGridX={false}
            enableGridY
            theme={{
              axis: {
                domain: {
                  line: {
                    stroke: "#a9acbe",
                    strokeWidth: 1,
                  },
                },
              },
              grid: {
                line: {
                  stroke: "#e7e8ec",
                  strokeWidth: 1,
                },
              },
            }}
          />
        )}
      </ChartWrapper>
    </div>
  );
};

export default CoverageOverview;
