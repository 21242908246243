import React, { useEffect, useState } from "react";
import { Card, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ResponsiveBar } from "@nivo/bar";
import { useApi } from "../../API";
import { useHistory } from "react-router-dom";
import { palette } from "../../utils/theme";
import ChartWrapper from "../../components/ChartWrapper";
import ChartWrapperHeader from "../../components/ChartWrapperHeader";
const useStyles = makeStyles(() => ({
  chartWrapper: {
    height: "400px",
  },
}));

function KevsBarChart() {
  const [data, setData] = useState([]);
  const [criticalData, setCriticalData] = useState([]);

  const history = useHistory();
  const classes = useStyles();



  const api = useApi();

  useEffect(async () => {
    const allData = await api.kevsCount(false);
    const allCriticalData = await api.kevsCount(true);



    const data = allData.data;
    setData(data)

    const criticalData = allCriticalData.data;
    setCriticalData(criticalData)

  }, []);

  return (
    <ChartWrapper excludeHeader>
      <ChartWrapperHeader chartName="Vulnerabilities">
      </ChartWrapperHeader>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        textAlign="center"
        style={{ marginTop: "24px", marginBottom: "16px" }}
      >
        <Grid container item xs={4} justifyContent="center">
          <strong>All Assets</strong>
        </Grid>
        <Grid container item xs={4} justifyContent="center">
          {" "}
        </Grid>
        <Grid container item xs={4} justifyContent="center">
          <strong>High Impact Assets</strong>
        </Grid>
      </Grid>
      <Card>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          textAlign="center"
          style={{ marginTop: "24px", marginBottom: "24px", marginLeft: "12px" }}
        >
          <Grid
            item
            // style={{ paddingTop: "1em" }}
            xs={6}
            className={classes.chartWrapper}
            data-test="assets-location-wrapper1"
          >
            <ResponsiveBar
              enableLabel
              labelTextColor="white"
              // label={(d) => `${parseFloat(d.value).toFixed()}%`}
              data={data}
              onClick={(data) => {
                let url = `/app/inventory/assets${data.data.id === 'Total Vulnerabilities' ? '?min_cves=1' : '?cisa_identification=true'}`
                history.push(url);
              }}
              onMouseEnter={(_datum, event) => {
                event.currentTarget.style.cursor = "pointer";
              }}
              keys={["total"]}
              indexBy="id"
              height={400}
              margin={{
                top: 30,
                right: 80,
                bottom: 60,
                left: 160,
              }}
              axisLeft={{
                tickSize: 0,
                // renderTick: CustomTick,
              }}
              gridXValues={5}
              axisBottom={{
                tickSize: 0,
                tickValues: 4,
                legendPosition: "middle",
                legendOffset: 50,
                legend: "Vulnerabilities on All Assets",
              }}
              groupMode="grouped"
              colors={[palette.keyBlue]}
              padding={0.3}
              layout="horizontal"
              borderColor="inherit:darker(1.6)"
              enableGridX
              enableGridY
              isInteractive
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: "#a9acbe",
                      strokeWidth: 1,
                    },
                  },
                },
                grid: {
                  line: {
                    stroke: "#e7e8ec",
                    strokeWidth: 1,
                  },
                },
              }}
            // tooltip={renderTooltip}
            />

          </Grid>
          <Grid
            item
            // style={{ paddingTop: "1em" }}
            xs={6}
            className={classes.chartWrapper}
            data-test="assets-location-wrapper1"
          >
            <ResponsiveBar
              enableLabel
              labelTextColor="white"
              // label={(d) => `${parseFloat(d.value).toFixed()}%`}
              data={criticalData}
              onClick={(data) => {
                let url = `/app/inventory/assets?is_critical=true&${data.data.id === 'Total Vulnerabilities' ? 'min_cves=1' : 'cisa_identification=true'}`
                history.push(url);
              }}
              onMouseEnter={(_datum, event) => {
                event.currentTarget.style.cursor = "pointer";
              }}
              keys={["total"]}
              indexBy="id"
              height={400}
              maxValue={data[1] ? data[1].total : 0}
              margin={{
                top: 30,
                right: 80,
                bottom: 60,
                left: 160,
              }}
              axisLeft={{
                tickSize: 0,
                // renderTick: CustomTick,
              }}
              gridXValues={5}
              axisBottom={{
                tickSize: 0,
                tickValues: 4,
                legendPosition: "middle",
                legendOffset: 50,
                legend: "Vulnerabilities on High Impact Assets",
              }}
              groupMode="grouped"
              colors={[palette.purple1]}
              padding={0.3}
              layout="horizontal"
              borderColor="inherit:darker(1.6)"
              enableGridX
              enableGridY
              isInteractive
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: "#a9acbe",
                      strokeWidth: 1,
                    },
                  },
                },
                grid: {
                  line: {
                    stroke: "#e7e8ec",
                    strokeWidth: 1,
                  },
                },
              }}
            // tooltip={renderTooltip}
            />

          </Grid>
        </Grid>

      </Card>



    </ChartWrapper>
  );
}
export default KevsBarChart;
