import React from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import { Box } from "@material-ui/core";
import { palette } from "../utils/theme";

const CircleIcon = ({
  percentage,
  fill,
  svgWidth,
  r,
  scale,
  extendedVersion,
}) => {
  return (
    <Box
      style={{
        position: "relative",
        display: "inline-block",
        height: svgWidth + 4,
        borderRadius: svgWidth + 4,
      }}
    >
      <svg height={svgWidth} width={svgWidth} className="assetIcon">
        {extendedVersion ? (
          <rect
            width={scale(100) * 3.2}
            height={scale(100) * 2}
            fill={fill}
            rx="30%"
          />
        ) : (
          <circle r={scale(100)} fill={fill} cx={r} cy={r} />
        )}
        {/* <circle r={r * 0.85} fill={fill} cx={r} cy={r} />
         */}

        <text
          fill="white"
          style={{
            fontWeight: 500,
            textAnchor: "middle",
            alignmentBaseline: "central",
            fontSize: svgWidth <= 40 ? "15px" : "18px",
          }}
          x={extendedVersion ? r + 1 : r}
          y={extendedVersion ? r - 3 : r}
        >
          {Math.floor(percentage).toFixed(0)}
        </text>
        <text
          fill="white"
          style={{
            fontWeight: 500,
            textAnchor: "middle",
            alignmentBaseline: "central",
            fontSize: svgWidth <= 40 ? "8px" : "11px",
          }}
          x={r + 25}
          y={r}
        >
          {extendedVersion ? "/100" : ""}
        </text>
      </svg>
    </Box>
  );
};

function AssetIcon(props) {
  const { svgWidth, criticalityPercentage, isCritical, risk, extendedVersion } =
    props;
  const diameter = svgWidth;
  const r = diameter / 2;
  // const arcOuterRadius = r;
  // const arcInnerRadius = 0;

  const displayAsRisk = risk;

  const scale = d3
    .scaleLinear()
    .range([2, r - 3])
    .domain([0, 100]);

  let riskFill;
  let riskPercentage;

  if (props.riskPercentage !== null && props.riskPercentage !== undefined) {
    riskPercentage = Math.floor(props.riskPercentage);
    if (riskPercentage < 40) {
      riskFill = palette.green;
    } else if (riskPercentage < 80 && riskPercentage >= 40) {
      riskFill = palette.yellow;
    } else if (riskPercentage >= 80 && riskPercentage <= 100) {
      riskFill = palette.red;
    } else {
      riskFill = "transparent";
    }
  }

  let criticalityFill;
  if (isCritical) {
    criticalityFill = palette.purple1; // purple
  } else {
    criticalityFill = palette.keyBlue; // turquoise
  }

  // const arcGenerator = d3
  //   .arc()
  //   .innerRadius(arcInnerRadius)
  //   .outerRadius(arcOuterRadius)
  //   .startAngle(0);

  // const riskArc = (value) =>
  //   arcGenerator({
  //     endAngle: 2 * Math.PI * value,
  //   });

  const dialData = [{}];

  // const cTextSize = diameter / 3;

  // calculate dialData points
  const calcDialData = (percentage) => {
    // theta
    const a = Math.PI + 2.0 * Math.PI * (1 - percentage);
    // points
    dialData[0].x1 = r;
    dialData[0].y1 = r;
    // r + adj
    dialData[0].x2 = dialData[0].x1 + r * Math.sin(a);
    // r + opp
    dialData[0].y2 = dialData[0].y1 + r * Math.cos(a);
  };

  const percentage = riskPercentage / 100;

  calcDialData(percentage);

  if (displayAsRisk) {
    if ((!riskPercentage && riskPercentage !== 0) || riskPercentage === 101) {
      return <div />;
    }

    return (
      <CircleIcon
        extendedVersion={extendedVersion}
        r={r}
        scale={scale}
        svgWidth={svgWidth}
        fill={riskFill}
        percentage={riskPercentage}
      />
    );
  }

  return (
    <CircleIcon
      extendedVersion={extendedVersion}
      r={r}
      scale={scale}
      svgWidth={svgWidth}
      fill={criticalityFill}
      percentage={criticalityPercentage}
    />
  );
}

AssetIcon.propTypes = {
  svgWidth: PropTypes.number,
  riskPercentage: PropTypes.number,
  criticalityPercentage: PropTypes.number,
  isCritical: PropTypes.bool,
};

AssetIcon.defaultProps = {
  svgWidth: 36,
  isCritical: false,
  riskPercentage: 101,
};

export default AssetIcon;
