import React, { useState, useEffect } from "react";
import StackedLine from "./StackedLine";
import ChartWrapper from "../../components/ChartWrapper";
import ChartWrapperHeader from "../../components/ChartWrapperHeader";
import TimePeriodDropDown from "../../components/TimePeriodDropDown";
import { useApi } from "../../API";

/**
 *
 * This component is built to take in outcome metrics data
 * and build a stacked line chart out of 1 or more metrics
 *
 * The metric names passed to this component should have the same
 * metric value type (e.g. float, dollars, percentage, etc) and also be related
 *
 *
 */
const MetricsStackedLine = ({ metricNames, chartName, defaultPeriod }) => {
  const api = useApi();
  const [selectedPeriod, setSelectedPeriod] = useState(defaultPeriod || "year");

  const [response, setResponse] = useState({
    data: [],
    loading: true,
    error: false,
  });

  const handlePeriodChange = (value) => {
    setSelectedPeriod(value);
  };

  const parseDate = (date) => {
    return new Date(Date.parse(date)).toISOString().slice(0, 10);
  };

  const formatMetricDataForNivo = (metricsData) => {
    const nivoData = [];

    for (const metric of metricsData) {
      const isPercentage = metric.format === "percentage";
      const dates = [];
      const formattedData = {
        data: [
          {
            x: parseDate(metric.date),
            y: isPercentage ? metric.value * 100 : metric.value,
          },
        ],
        id: metric.display_name,
      };
      dates.push(parseDate(metric.date));
      for (const pastMetric of metric.history) {
        if (!dates.includes(parseDate(pastMetric.date))) {
          formattedData.data.push({
            x: parseDate(pastMetric.date),
            y: isPercentage ? pastMetric.value * 100 : pastMetric.value,
          });
        }
      }

      nivoData.push(formattedData);
    }
    return nivoData;
  };

  useEffect(async () => {
    setResponse({
      data: [],
      loading: true,
      error: false,
    });

    try {
      const res = await api.allMetrics({
        metrics: metricNames,
        period: selectedPeriod,
      });

      setResponse({
        data: formatMetricDataForNivo(res.data.metrics),
        loading: false,
        error: false,
      });
    } catch (e) {
      console.log(e);
      setResponse({
        data: [],
        loading: false,
        error: true,
      });
    }
  }, [selectedPeriod]);

  return (
    <div style={{ height: 400 }}>
      <ChartWrapper excludeHeader>
        <ChartWrapperHeader chartName={chartName}>
          <TimePeriodDropDown
            handlePeriodChange={handlePeriodChange}
            defaultPeriod={defaultPeriod}
          />
        </ChartWrapperHeader>
        <StackedLine selectedPeriod={selectedPeriod} data={response.data} />
      </ChartWrapper>
    </div>
  );
};

export default MetricsStackedLine;
