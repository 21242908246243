import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { palette } from "../utils/theme";

const useStyles = makeStyles(() => ({
  chartWrapper: {
    padding: ".5rem 1rem",
    height: "100%",
    background: "white",
    margin: "0 1rem",
  },
  heading: {
    minHeight: 55,
    marginRight: "-1rem",
    marginLeft: "-1rem",
    borderBottom: `1px solid ${palette.border}`,
    marginBottom: "1rem",
    padding: "0 1rem .5rem",
    display: "flex",
    alignItems: "center",
  },
}));

function ChartWrapperHeader(props) {
  const classes = useStyles();

  return (
    <div className={classes.heading}>
      <div style={{ flexGrow: 1, padding: 0 }}>
        <Typography
          variant="subtitle1"
          style={{ paddingLeft: ".3em", fontSize: "1.1em" }}
        >
          {props.chartName}
        </Typography>
        <Typography
          variant="body1"
          style={{ fontSize: "small", color: "#484848" }}
        >
          {props.subtitle}
        </Typography>
      </div>
      <div style={{ flexGrow: 1 }}>
        <div style={{ float: "right" }}>{props.children}</div>
      </div>
    </div>
  );
}

export default ChartWrapperHeader;
