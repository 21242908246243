import React, { useEffect, useState } from "react";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import { useApi } from "../../API";
import HighImpactCheckBox from "../../components/HighImpactSwitch";
import ChartWrapper from "../../components/ChartWrapper";
import ChartWrapperHeader from "../../components/ChartWrapperHeader";
import StandardTable from "../../table/GenericTable";

function KnownExploitedTable({ internetFacing }) {
  const CVE_INFO_BASE_URL = "https://nvd.nist.gov/vuln/detail";
  const cveFormat = /^CVE-\d{4}-\d{4,7}$/;
  const [data, setData] = useState([]);
  const [criticalOnly, setCriticalOnly] = useState(true);

  const api = useApi();

  const matchesCVEFormat = (cveId) => {
    return cveFormat.test(cveId);
  };

  useEffect(async () => {
    const res = await api.kevs(internetFacing, criticalOnly);
    setData(res);
  }, [criticalOnly]);

  const history = useHistory();

  const headers = [
    {
      name: "cve_name",
      display_name: "Vulnerability Name",
      format: (d) => {
        return d.cve_name === "" || !d.cve_name
          ? `No name available. Vulnerability ID: ${d.cve_id}`
          : d.cve_name;
      },
    },
    {
      name: "cve_id",
      display_name: "Vulnerability ID",
      format: (d) => {
        return matchesCVEFormat(d.cve_id) ? (
          <Link
            target="_blank"
            href={`${CVE_INFO_BASE_URL}/${d.cve_id.toUpperCase()}`}
            underline="hover"
          >
            NIST {d.cve_id}
          </Link>
        ) : (
          d.cve_id
        );
      },
    },
    {
      name: "total_assets",
      display_name: "Total Assets",
    },
  ];

  return (
    <ChartWrapper excludeHeader>
      <ChartWrapperHeader
        chartName={`Top Known Exploited Vulnerabilities ${
          internetFacing ? "on Internet Facing Assets" : ""
        }`}
      >
        <HighImpactCheckBox
          checked={criticalOnly}
          handleSwitchChange={() => {
            setCriticalOnly(!criticalOnly);
          }}
          name="kevs"
        />
      </ChartWrapperHeader>
      <StandardTable
        firstColWidth={300}
        onClick={(row) => {
          history.push(
            `/app/inventory/assets?cve=${row.cve_id}${
              criticalOnly ? "&is_critical=true" : ""
            }${internetFacing ? "&internet_facing=true" : ""}`
          );
        }}
        data={{
          headers,
          data,
        }}
      />
    </ChartWrapper>
  );
}
export default KnownExploitedTable;
