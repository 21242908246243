import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import StandardTable from "../table/GenericTable";
import { useApi } from "../API";
import AssetIcon from "../components/AssetIcon";

function RiskiestAssets() {
  const history = useHistory();
  const [data, setData] = useState();
  const api = useApi();

  useEffect(() => {
    api
      .riskV2Assets(10)
      .then((res) => {
        setData(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const headers = [
    {
      name: "hostname",
      display_name: "Hostname",
    },
    {
      name: "risk_score",
      display_name: "Risk Score",
      format: (row) => {
        return (
          <AssetIcon
            riskPercentage={Math.floor(row.risk_score).toFixed()}
            risk
          />
        );
      },
    },
    {
      name: "criticality",
      display_name: "Impact Score",
      format: (row) => {
        return (
          <AssetIcon
            criticalityPercentage={Math.floor(row.criticality).toFixed()}
            isCritical={row.is_critical}
          />
        );
      },
    },
    {
      name: "ipv4",
      display_name: "IP Address",
      format: (row) => row.ipv4,
    },
    {
      name: "asset_type",
      display_name: "Asset Type",
    },
    {
      name: "application",
      display_name: "Application",
      format: (row) => {
        return row.application || "--";
      },
    },
  ];
  if (!data) {
    return <>Loading...</>;
  }
  return (
    <StandardTable
      onClick={(row) => {
        history.push(`/app/asset/${row.id}`);
      }}
      data={{
        headers,
        data,
      }}
    />
  );
}

export default RiskiestAssets;
