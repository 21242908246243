import React from "react";
import AppBar from "@material-ui/core/AppBar";
import { NavLink } from "react-router-dom";
import {
  Toolbar,
  IconButton,
  MenuItem,
  Menu,
  Link,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as oktaClient from "@okta/okta-react";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import HomeIcon from "@material-ui/icons/Home";
import ListAltIcon from "@material-ui/icons/ListAlt";
import SpeedIcon from "@material-ui/icons/Speed";
import AssessmentIcon from "@material-ui/icons/Assessment";
import SettingsIcon from "@material-ui/icons/Settings";
import logo from "../assets/logo.svg";
import TopBar from "./TopBar";
import { useApi } from "../API";
import ResilienceIcon from "../icons/resilienceIcon";
import CoverageIcon from "../icons/controlsIcon";

const AUTH_TYPE = window._env_.REACT_APP_AUTH_TYPE;
const MOCK_OUT_DEMO = window._env_ && window._env_.MOCK_OUT_DEMO === "true";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    display: "flex",
  },
  content: {
    backgroundColor: "#D1D9DB",
  },
  headerbar: {
    backgroundColor: "#2A2A2A",
    // paddingLeft: "2rem",
    // paddingRight: "2rem",
  },
  navbar: {
    backgroundColor: theme.navbar.background,
    height: "3.5em",
    minHeight: "3.5em",
    paddingRight: 0,
  },
  navlink: {
    "&:hover": {
      color: "#EEEEEE",
    },
    fontSize: "1.1em",
    paddingTop: "1em",
    marginBottom: 0,
    height: "3.5em",
    color: "#B6B6B6",
    textTransform: "capitalize",
    marginLeft: "1em",
  },
  navActive: {
    color: "white",
    fontWeight: "500",
  },
  navIcon: {
    color: theme.palette.lightGray,
    padding: ".3rem 0 0 0",
  },
  navLabel: {
    fontSize: "105%",
    padding: "0 .5rem",
  },
  utilNavContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  utilNavlink: {
    fontSize: "1rem",
    color: "white",
    padding: "0 .5rem",
    "&:hover": {
      color: theme.palette.keyBlue,
      textDecoration: "none",
      borderBottomColor: "blue",
    },
  },
  text: {
    whiteSpace: "nowrap",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    backgroundColor: "#474747",
    alignItems: "center",
    justifyContent: "center",
    color: "#CBCBCB",
    borderRadius: "5px",
  },
  search: {
    position: "relative",
    backgroundColor: "#474747",
    marginLeft: 0,
    // width: '100%',
    borderRadius: "5px",
    maxWidth: "12em",
  },
  toolbar: {
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    ...theme.mixins.toolbar,
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: ".5em",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    color: "#CBCBCB",
    transition: theme.transitions.create("width"),
    maxWidth: "12em",
  },
}));

const Navigation = (props) => {
  const { setPane } = props;
  const classes = useStyles();
  const api = useApi();
  const [menuAnchor, setAnchorEl] = React.useState(null);
  let oktaAuth = null;
  if (AUTH_TYPE === "okta") {
    oktaAuth = oktaClient.useOktaAuth().oktaAuth;
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // TODO handle okta
  const logout = async () => {
    if (AUTH_TYPE === "okta") {
      await oktaAuth.signOut({
        postLogoutRedirectUri: `${window.location.origin}/okta_logout`,
      });
    } else {
      await api.logout();
      window.location.href = "/login";
    }
  };

  const navLinks = [
    {
      icon: <HomeIcon />,
      path: "/app/dashboard",
      pane: "Dashboard",
    },
    {
      icon: <ListAltIcon />,
      path: "/app/inventory",
      pane: "Inventory",
    },
    {
      icon: <SpeedIcon />,
      path: "/app/risk",
      pane: "Risk",
    },
    {
      icon: <CoverageIcon />,
      path: "/app/coverage",
      pane: "Coverage",
    },
    {
      icon: <ResilienceIcon />,
      path: "/app/resilience",
      pane: "Resilience",
      title: "Terrain",
    },
    {
      icon: <AssessmentIcon />,
      path: "/app/reporting",
      pane: "Reports",
      title: "Trends",
    },
  ];
  return (
    <AppBar className={classes.headerbar} elevation={2}>
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item container sm={11} md={7} alignItems="center">
            <a href="/app" style={{ height: "18px", marginLeft: ".3em" }}>
              <img height="18px" src={logo} alt="Logo" />
            </a>
            <div className={classes.navlink}>|</div>
            {navLinks.map((el) => {
              return (
                <NavLink
                  className={classes.navlink}
                  activeClassName={classes.navActive}
                  to={el.path}
                  key={el.pane}
                  onClick={() => setPane(el.pane)}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: ".3em",
                      alignItems: "center",
                      marginRight: ".75em",
                    }}
                  >
                    {/* {el.icon} */}
                    {el.title || el.pane}
                  </div>
                </NavLink>
              );
            })}
          </Grid>
          <Grid
            item
            sm={1}
            md={5}
            alignItems="center"
            container
            justifyContent="flex-end"
          >
            <Grid
              item
              style={{ fontSize: "small" }}
              id="topbar"
              container
              justifyContent="flex-end"
              xs={8}
            >
              <TopBar />
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              id="searchInput"
              justifyContent="flex-end"
              xs={3}
            >
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  // disabled
                  placeholder="Search"
                  onClick={props.handleOpenSearch}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
              </div>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="flex-end"
              xs={1}
            >
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <SettingsIcon style={{ color: "E6E6E6" }} />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={menuAnchor}
                keepMounted
                open={Boolean(menuAnchor)}
                onClose={handleClose}
              >
                <Link href="/app/settings">
                  <MenuItem onClick={handleClose}>Settings</MenuItem>
                </Link>{" "}
                {AUTH_TYPE !== "okta" && !MOCK_OUT_DEMO && (
                  <Link href="/app/account">
                    <MenuItem onClick={handleClose}>My account</MenuItem>
                  </Link>
                )}
                {AUTH_TYPE !== "okta" && !MOCK_OUT_DEMO && (
                  <Link href="/app/users">
                    <MenuItem onClick={handleClose}>Users</MenuItem>
                  </Link>
                )}
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;
